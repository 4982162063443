<div class="col-sm-1 col-md-1 col-lg-1 play-cell" >
  <app-play-program-button [iconSize]="playButtonIconSize.Medium" [item]="item"></app-play-program-button>
</div>
<div class="col-sm-10 col-md-10 col-lg-10" [routerLink]="['/programs/details', item.id]" attr.aria-label="Program: {{item.title}}, click to go to the detail page" tabindex="0">
  <div class="row">
    <div class="col-sm-4 col-md-4 col-lg-4">
      <img [src]="item.id | HosProgramImageUrl:180:(item.genres)" alt="Cover image for {{item.title}}">
    </div>
    <div class="col-sm-8 col-md-8 col-lg-8" [routerLink]="['/programs/details', item.id]">
      <p class="title"><span class="program-id">{{ item.id }}</span> {{ item.title }}</p>
      <p class="description" [innerHtml]="item.shortDescription | htmlToPlainText"></p>
    </div>
  </div>
</div>
<div class="col-sm-1 col-md-1 col-lg-1">
  <div class="option-wrapper">
    <app-option-popup [item]="item" [fullPageHeight]="true" [topMargin]="0">
      <img src="/assets/images/ellipsis.svg" alt="" aria-hidden="true">
    </app-option-popup>
  </div>
</div>
