<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12 no-indents tab-router-wrapper">
      <div class="row container-header">
        <nav class="col-sm-8 col-lg-8">
          <ul role="group" aria-label="Programs filter" tabindex="0">
            <li routerLinkActive="active">
              <a routerLink="programs/recent">
                RECENT
              </a>
            </li>
            <li routerLinkActive="active">
              <a routerLink="programs/popular" >
                POPULAR
              </a>
            </li>
            <li routerLinkActive="active">
              <a routerLink="programs/genres">
                GENRE
              </a>
            </li>
            <li routerLinkActive="active">
              <a routerLink="programs/number">
                NUMBER
              </a>
            </li>
            <li routerLinkActive="active">
              <a routerLink="programs/year">
                YEAR
              </a>
            </li>
            <li routerLinkActive="active">
              <a routerLink="programs/seasons">
                SEASON
              </a>
            </li>
            <li routerLinkActive="active">
              <a routerLink="programs/holidays">
                HOLIDAYS
              </a>
            </li>
            <li routerLinkActive="active">
              <a routerLink="programs/browse">
                BROWSE
              </a>
            </li>
          </ul>
        </nav>
        <div class="col-sm-4 col-lg-4 program-actions" *ngIf="data['prev'] || data['next']">
          <button id="programPrevious" attr.aria-label="Previous" [routerLink]="['/programs/details', data['prev']]" [queryParams]="data['ctxPrev']" *ngIf="data['prev']">PREVIOUS</button>
          <span>PROGRAM</span>
          <button id="programNext" attr.aria-label="Next" [routerLink]="['/programs/details', data['next']]" [queryParams]="data['ctxNext']" *ngIf="data['next']">NEXT</button>
        </div>
      </div>
    </div>
  </div>
</div>
