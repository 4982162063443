import {Injectable} from '@angular/core';
import {HosError} from "../../api-client/model/HosError";
import {HosLocalError} from "../../api-client/helper/api-helper.service";
import {AppConstants} from "../../app.constants";
import {AlertsUtilService} from '../../services/alerts-util.service';

@Injectable()
export class LoadingHelperService {
  private defaultGroup = "MAIN";

  private loading: Map<string, boolean> = null;
  private error: Map<string, HosError> = null;

  constructor(private alertsUtilService: AlertsUtilService) {
    this.loading = new Map<string, boolean>();
    this.error = new Map<string, HosError>();
  }

  startLoading(group: string = this.defaultGroup) {
    this.loading[group] = true;
  }

  private removeError(group: string) {
    if (this.error[group]) { // if it's a retry
      delete this.error[group];
    }
  }

  loadingOK(group: string = this.defaultGroup) {
    this.loading[group] = false;
    this.removeError(group);
  }

  loadingKO(error?: any, group: string = this.defaultGroup) {
    let serverTimeout = AppConstants.clientErrors.SERVER_TIMEOUT;
    if (error) {
      try {
        this.error[group] = error;
      } catch (error) {

        this.error[group] = new HosLocalError(serverTimeout.errorDescription, serverTimeout.errorCode, serverTimeout.errorName);
      }
    } else {
      this.error[group] = new HosLocalError(serverTimeout.errorDescription, serverTimeout.errorCode, serverTimeout.errorName);
    }
    this.loading[group] = false;
  }

  isLoading(group: string = this.defaultGroup): boolean {
    return this.loading[group];
  }

  hasError(group: string = this.defaultGroup): boolean {
    return this.error[group] !== undefined;
  }

  getError(group: string = this.defaultGroup): HosError {
    return this.error[group];
  }

  getErrorMessage(error: any, defaultMessage: string = null, preMessage: string = null): string {
    if (defaultMessage === null) {
      defaultMessage = AppConstants.clientErrors.GENERIC_ERROR.errorDescription;
    }
    if (error && error.errorName) {
      if (error.errorDescription) {
        return ((preMessage ? preMessage + ': ' : '') + error.errorName + (preMessage ? ', ' : ': ') + error.errorDescription);
      } else {
        return ((preMessage ? preMessage + ': ' : '') + error.errorName);
      }
    } else {
      return (defaultMessage);
    }
  }

  showErrorAlert(error: any, defaultMessage: string = null, preMessage: string = null) {
    this.alertsUtilService.showAlert(this.getErrorMessage(error, defaultMessage, preMessage));
  }

}
