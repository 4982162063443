/**
 * HOS API
 *
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 *
 *
 *
 */



export interface User {
    /**
     * User id
     */
    id: number;

    /**
     * First name
     */
    firstName: string;

    /**
     * Last name
     */
    lastName: string;

    /**
     * Email
     */
    email: string;

    /**
     * Phone number
     */
    phoneNumber?: string;

    /**
     * Status
     */
    status?: User.StatusEnum;

    /**
     * Create time
     */
    createTime?: Date;

    /**
     * Update time
     */
    updateTime?: Date;

}
export namespace User {
    export enum StatusEnum {
        Inchoate = <any> 'inchoate',
        Open = <any> 'open',
        Closed = <any> 'closed',
        Locked = <any> 'locked',
        Reset = <any> 'reset',
        Closable = <any> 'closable'
    }
}
