import {Injectable} from '@angular/core';
import {FavoriteItemWrapper} from '../api-client';
import * as dayjs from 'dayjs';

@Injectable()
export class OrderingUtilsService {

  constructor() {
  }

  reorderFavorites(favorites: FavoriteItemWrapper[], orderBy: string, orderDirectionAsc: boolean) {
    return favorites.sort((a, b) => {
      let v1 = "";
      let v2 = "";
      let va = a.hosItem[orderBy];
      let vb = b.hosItem[orderBy];
      if (orderBy === 'artists') {
        if (va && va.length > 0) {
          v1 = va[0].name;
        }
        if (vb && vb.length > 0) {
          v2 = vb[0].name;
        }
      } else if (orderBy === 'album') {
        if (va) {
          v1 = va.title;
        }
        if (vb) {
          v2 = vb.title;
        }
      } /*else if (orderBy === 'time') {
        if (va) {
          const mVa = dayjs(va);
          if (mVa.isValid()) {
            v1 = mVa.unix().toString();
          }
        }
        if (vb) {
          const mVb = dayjs(vb);
          if (mVb.isValid()) {
            v2 = mVb.unix().toString();
          }
        }
      }*/ else {
        v1 = va;
        v2 = vb;
      }
      if (orderDirectionAsc) {
        if (v1 < v2) return -1;
        if (v1 > v2) return 1;
        return 0;
      } else {
        if (v1 < v2) return 1;
        if (v1 > v2) return -1;
        return 0;
      }
    });
  }

}
