/**
 * HOS API
 *
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 *
 *
 *
 */



export interface PlayTokenWrapper {
    /**
     * Signed URL
     */
    signedUrl: string;

    /**
     * Play token
     */
    playToken: string;

}
