import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {GeneralService} from '../../services/general.service';
import {ProgramsService} from '../../api-client/api/programs.service';
import {HosPlayerService} from '../../services/player/hos-player.service';
import {Program} from '../../api-client/model/Program';
import {LoadingHelperService} from '../loading-helper/loading-helper.service';
import {HosQueueSingleProgram} from '../../services/player/hos-queue';
import {hoslog, PlayButtonIconSize} from '../../app.constants';
import {AlertsUtilService} from '../../services/alerts-util.service';
import {MetaUtilsService} from '../../services/meta-utils.service';
import {ShareService} from '../../services/share.service';
import {LoggedUserPlanService} from '../../services/logged-user-plan.service';
import {PageReferral} from '../../services/player/hos-play-item';
import {TopBarComponentService} from '../../services/top-bar-component.service';
import {ProgramsTopBarComponent} from '../programs-top-bar/programs-top-bar.component';
import {SubSink} from 'subsink';
import {MixpanelService} from '../../services/mixpanel.service';
import {PlaylistsDialogsUtilsService} from '../../services/playlists-dialogs-utils.service';
import {ChannelsListService} from '../../services/channels-list.service';
import {Channel, HosItem, Playlist, PlaylistsService} from '../../api-client';
import {Subscription} from 'rxjs';
import {FileSaverService} from 'ngx-filesaver';
import {ChannelsListRefreshService} from '../../services/channels-list-refresh.service';

interface ProgramContextParams {
  filterBy?: string;
  filterValue?: string;
  orderByValue?: string;
  orderByDirection?: string;
  position?: number;
  rouid?: number; // Rating order User ID
  skipThisWeekProgram?: boolean;
}

@Component({
  selector: 'app-program-detail',
  templateUrl: './program-detail.component.html',
  styleUrls: ['./program-detail.component.scss']
})
export class ProgramDetailComponent implements OnInit, OnDestroy {
  private subs = new SubSink();

  // @ViewChild('play', { static: false }) play: PlayProgramButtonComponent;

  public data: Program;

  private downloadObs: Subscription;

  public PageReferralEnum = PageReferral;

  playButtonIconSize = PlayButtonIconSize;

  public copyrightYear = null;

  private type: string; // could be 'this-week' or 'surprise-me'
  programId: number;
  private title = 'PROGRAMS';
  private sequenceNumber = 0;

  private isFromChannel: Channel = null;
  private isFromPlaylist: Playlist = null;
  private programIdxInPlaylist: number = null;
  private isFromRecent: boolean = false;

  private subSubs = new SubSink();
  public showLessDescription = true;

  constructor(private activatedRoute: ActivatedRoute,
              private gs: GeneralService,
              public hosPlayerService: HosPlayerService,
              public loadingHelperService: LoadingHelperService,
              private loggedUserPlanService: LoggedUserPlanService,
              private playlistsService: PlaylistsService,
              private programsService: ProgramsService,
              private metaUtilsService: MetaUtilsService,
              private shareService: ShareService,
              private mixpanelService: MixpanelService,
              private channelsListService: ChannelsListService,
              private channelsListRefreshService: ChannelsListRefreshService,
              private fileSaverService: FileSaverService,
              private router: Router,
              private topBarComponentService: TopBarComponentService,
              private playlistsDialogsUtilsService: PlaylistsDialogsUtilsService,
              private alertsUtilService: AlertsUtilService) {
    this.gs.setTitlePageAndMetadata(this.title);
    this.metaUtilsService.updateDefaultInfo();
  }

  ngOnInit() {
    this.subs.sink = this.activatedRoute.params.subscribe(
      (param: any) => {
        this.programId = param['programId'];
        this.type = param['type'];

        this.refresh();

        this.subSubs.unsubscribe();
        this.subSubs.sink = this.activatedRoute.queryParams.subscribe(qparams => {
          // checking if there are the context params
          const contextParams: ProgramContextParams = {
            filterBy: qparams['filterBy'],
            filterValue: qparams['filterValue'],
            rouid: qparams['rouid'],
            orderByValue: qparams['orderByValue'],
            orderByDirection: qparams['orderByDirection'],
            position: +qparams['position'],
            skipThisWeekProgram: qparams['stwp']
          };
          if (contextParams.position != null && contextParams.filterBy != null) {
            // call the API to get the previous / next item
            // and show the selection and the prev / next box in the top bar
            // console.log('contextParams = ' + JSON.stringify(contextParams));
            this.programsService.getProgramsPrevNext(this.programId, contextParams.position, contextParams.filterBy, contextParams.filterValue, contextParams.orderByValue, contextParams.orderByDirection, contextParams.rouid, contextParams.skipThisWeekProgram)
              .subscribe(res => {
                // hoslog('getAlbumsPrevNext: ' + JSON.stringify(res));
                const data = res;
                data['albumId'] = this.programId;
                if (data.prev) {
                  data['ctxPrev'] = JSON.parse(JSON.stringify(contextParams));
                  data['ctxPrev']['position'] = contextParams.position - 1;
                }
                if (data.next) {
                  data['ctxNext'] = JSON.parse(JSON.stringify(contextParams));
                  data['ctxNext']['position'] = contextParams.position + 1;
                }

                this.refreshTopBar(res);
              });
          } else {
            this.refreshTopBar();
          }
        });
      });
  }

  refresh() {
    this.sequenceNumber = 0;

    if (!this.programId) {
      if (this.type === 'surprise-me') {
        this.findSurpriseMeProgram();
      } else {
        this.findThisWeekProgram();
      }
    } else {
      this.refreshProgram();
    }
  }

  refreshTopBar(data: any = null) {
    if (this.programId) {
      // top bar only for programs
      setTimeout(() => {
        this.topBarComponentService.setTopBarComponent(ProgramsTopBarComponent, data);
      });
    }
  }

  findThisWeekProgram() {
    let me = this;
    this.loadingHelperService.startLoading();
    this.programsService.getThisWeekProgram()
      .subscribe(
        res => {
          this.title = 'THIS WEEK';
          this.gs.setTitlePageAndMetadata(this.title);

          hoslog('getThisWeekProgram loaded' /*+ JSON.stringify(res)*/);
          me.setData(res);
          me.loadingHelperService.loadingOK();
        },
        error => {
          hoslog('getThisWeekProgram error: ' + JSON.stringify(error));
          me.loadingHelperService.loadingKO(error);
        });
  }

  findSurpriseMeProgram() {
    let me = this;
    this.loadingHelperService.startLoading();
    this.programsService.getSurpriseMeProgram()
      .subscribe(
        res => {
          /*this.title = "SURPRISE ME";
          this.gs.setTitlePageAndMetadata(this.title);

          hoslog("getSurpriseMeProgram loaded" /!*+ JSON.stringify(res)*!/);
          me.setData(res);*/

          me.loadingHelperService.loadingOK();

          // Redirecting to the proper url page
          me.router.navigate(['/programs', 'details', res.id], {replaceUrl: true});
        },
        error => {
          hoslog('getSurpriseMeProgram error: ' + JSON.stringify(error));
          me.loadingHelperService.loadingKO(error);
        });
  }

  refreshProgram() {
    let me = this;
    this.loadingHelperService.startLoading();
    this.programsService.getProgramDetail(this.programId)
      .subscribe(
        res => {
          hoslog('getProgramDetail loaded'/* + JSON.stringify(res)*/);
          me.setData(res);

          // Checking if it's coming from a channel
          const channelId = this.activatedRoute.snapshot.queryParams['cid'];
          if (channelId && !isNaN(channelId)) {
            const isItemInChannelId = this.channelsListRefreshService.isItemInChannelId(+channelId, this.data.id);
            if (isItemInChannelId) {
              this.isFromChannel = this.channelsListRefreshService.getChannelById(+channelId);
              //this.gs.setTitlePageAndMetadata('CHANNEL: ' + (this.data.title));
              this.gs.setTitlePageAndMetadata('CHANNEL: PGM DETAIL');
            }
            this.isFromPlaylist = null; // reset the other param
            this.programIdxInPlaylist = null; // reset the index
            // console.log('this.isItemInChannelId = ' + this.isItemInChannelId);
          } else {
            // Checking if it's coming from a playlist
            const playlistId = this.activatedRoute.snapshot.queryParams['plid'];
            if (playlistId && !isNaN(playlistId)) {

              if(this.data.type === HosItem.TypeEnum.Program) {
                this.playlistsService.getPlaylistIfContainsProgram(+playlistId, this.data.id)
                  .subscribe(res => {
                      this.isFromPlaylist = res;
                      //this.gs.setTitlePageAndMetadata('MY PLAYLIST ' + res.name + ": " + (this.data.title));
                      this.gs.setTitlePageAndMetadata('PLAYLIST ' + res.name + ": PGM DETAIL");

                      this.programIdxInPlaylist = null;
                      if (res.items !== null && res.items !== undefined && res.items.length > 0) {
                        for (let i = 0; i < res.items.length; i++) {
                          if (res.items[i].hosItem.type === HosItem.TypeEnum.Program && res.items[i].hosItem.id === this.data.id) {
                            this.programIdxInPlaylist = i;
                          }
                        }
                      }

                      this.isFromChannel = null; // reset the other param
                    },
                    error => {
                      hoslog('getProgramDetail error: ' + JSON.stringify(error));
                    })
              }
            } else {
              let isFromRecentQueryParamPassed = this.activatedRoute.snapshot.queryParams['ir'] == '1';
              if (isFromRecentQueryParamPassed) {
                // check that the item is in the recents list
                this.isProgramInRecentList(this.data.id);
              }
            }
          }

          me.loadingHelperService.loadingOK();
        },
        error => {
          hoslog('getProgramDetail error: ' + JSON.stringify(error));
          me.loadingHelperService.loadingKO(error);
        });
  }

  setData(data: Program) {
    this.data = data;
    if(this.data.description != null) {
      this.data.description = this.removeHTMLTags(this.data.description)
    } else {
      this.data.description = '';
    }
    this.metaUtilsService.updateProgramInfo(data);
    /* This code add sequence number for every track. Temporary value. */
    this.data.albums.map(album => {
      let prevArtistId = null;
      album.tracks.map((track, index) => {
        this.sequenceNumber = this.sequenceNumber + 1;
        track['sequenceNumber'] = this.sequenceNumber;
        track['trackArtists'] = '';
        if (track['artists'] != null || track['artists'].length > 0 && track['artists'][0].name != null) {
          if (prevArtistId == null || prevArtistId !== track['artists'][0].id) {
            track['trackArtists'] = (track['artists'][0].name).replace(/\r/g, ' & ');
          }
          prevArtistId = track['artists'][0].id;
        }
      });

      let composersSet = new Set<string>();
      album.tracks.forEach(track => {
        if (track.composer) {
          composersSet.add(track.composer);
        }
      });

      album['composers'] = Array.from(composersSet).join(', ');

      let mostRecentYear: number = null;
      if(data.airDates!=null) {
        for (let i = 0; i < data.airDates.length; i++) {
          const curDate = new Date(data.airDates[i]);
          if (mostRecentYear == null || mostRecentYear < curDate.getFullYear()) {
            mostRecentYear = curDate.getFullYear();
          }
        }
      }
      this.copyrightYear = mostRecentYear;
    });
  }

  removeHTMLTags(str: string): string {
    return str.replace(/\<(?!\/?(em|br|strong|i|b|u)).*?\>/g, '');
  }

  playProgram() {
    const twp: boolean = (!this.programId && this.type === 'this-week');
    this.hosPlayerService.play(new HosQueueSingleProgram(this.data, twp ? PageReferral.twp : PageReferral.program));
  }

  shareClicked() {
    this.shareService.showShareProgramModal(this.data);
  }

  addToPLaylistClicked() {
    this.playlistsDialogsUtilsService.showAddtoPlaylistDialog(this.data);
  }

  printProgramClicked() {
    this.loadingHelperService.startLoading('programDetailOverlayDownloadLoading');

    let pid = this.programId
    if ((pid === null || pid === undefined) && this.type === 'this-week' && this.data !== null) {
      pid = this.data.id;
    }

    this.downloadObs = this.programsService.downloadProgram(pid)
      .subscribe(value => {
          this.loadingHelperService.loadingOK('programDetailOverlayDownloadLoading');
          this.fileSaverService.save(value, 'Program_' + pid + '.pdf');
        },
        error => {
          hoslog('printProgram error: ' + JSON.stringify(error));
          this.loadingHelperService.loadingKO(error, 'programDetailOverlayDownloadLoading');
        });
  }

  flickrImgGalleryClicked() {
    if (this.data && this.data.galleryUrl) {
      this.mixpanelService.track('programFlickrImgGalleryClicked', {
        programId: this.data.id,
        title: this.data.title,
        galleryUrl: this.data.galleryUrl
      });
      window.open(this.data.galleryUrl);
    }
  }

  openUrl(url: string) {
    let urlToOpen = url;
    if (url) {
      if (url && !url.startsWith('http')) {
        urlToOpen = 'https://' + url.trim();
      } else {
        urlToOpen = url.trim();
      }
      window.open(urlToOpen);
    }
  }

  /*
  canShowPromo30Sec(): boolean {
    return !this.loggedUserPlanService.canPlayPrograms();
  }
  */

  ngOnDestroy(): void {
    this.topBarComponentService.clearTopBar();
    this.subs.unsubscribe();
  }

  private isProgramInRecentList(programId: number) {
    this.programsService.getPrograms("recent", null, null, 'ASC', 0, 10, true)
      .subscribe(
        res => {
          const found = res?.content?.find(program => program.id);
          this.isFromRecent = found != undefined;
          hoslog("isProgramInRecentList OK: " + this.isFromRecent);

          if(this.isFromRecent) {
            //this.gs.setTitlePageAndMetadata('RECENT: ' + ('PGM ' + this.data.number + ' ' + this.data.title));
            this.gs.setTitlePageAndMetadata('RECENT: ' + 'PGM DETAIL');
          } else {
            //
          }
        },
        error => {
          hoslog("isProgramInRecentList error: " + JSON.stringify(error));
          this.isFromRecent = false;
        });
  }

  getProgramReferral(): PageReferral {
    if (this.isFromRecent) {
      return PageReferral.recent;
    } else if (this.isFromChannel) {
      return  PageReferral.channel;
    } else {
      return (!this.programId && this.type === 'this-week') ? PageReferral.twp : PageReferral.program;
    }
  }
}
