import {Injectable} from '@angular/core';
import {AppSettingsService} from './app-settings.service';
import {ErrorTemplate} from '../api-client/model/ErrorTemplate';
import {AppConstants} from '../app.constants';

@Injectable()
export class ErrorsService {

  constructor(private appSettingsService: AppSettingsService) {
  }

  private findErrorTemplate(errorName: string) {
    return this.appSettingsService.appSettings.value.clientErrors.find(error => error.errorName === errorName);
  }

  getError(errorName: string): ErrorTemplate {
    let errorTemplate = this.findErrorTemplate(errorName);
    if (!errorTemplate) {
      // looking into the constants
      errorTemplate = AppConstants.clientErrors[errorName];
    }
    if (!errorTemplate) {
      // default error
      errorTemplate = AppConstants.clientErrors['GENERIC_ERROR'];
    }
    return errorTemplate
  }
}
