/**
 * HOS API
 *
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 *
 *
 *
 */



export interface TextFragment {

    app: string;

    label: string;

    location: string;

    value?: string;

}
