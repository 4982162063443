/**
 * HOS API
 *
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 */
import {Injectable, Injector} from "@angular/core";
import {HosBaseService} from "./hos-base.service";

import {HttpResponse, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import { map } from 'rxjs/operators';
import "../rxjs-operators";
import {AppConstants} from "../../app.constants";
import {Plan} from "../model/Plan";

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class PlansService extends HosBaseService {


  constructor(injector: Injector) {
     super(injector);
   }

  /**
   * Return the plan detail
   *
   * @param planId The plan id
   */
  public getPlanDetail(planId: number, platform?: string, extraHttpRequestParams?: any): Observable<Plan> {
    return this.getPlanDetailWithHttpInfo(planId, platform, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Return the plans
   *
   * @param giftPlanFilter The gift plan filter
   */
  public getPlans(giftPlanFilter?: boolean, platform?: string, extraHttpRequestParams?: any): Observable<Plan[]> {
    return this.getPlansWithHttpInfo(giftPlanFilter, platform, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }


  /**
   * Return the plan detail
   *
   * @param planId The plan id
   */
  public getPlanDetailWithHttpInfo(planId: number, platform?: string, extraHttpRequestParams?: any): Observable<HttpResponse<Plan>> {
    const path = this.getBasePath() + `/plans/${planId}`;

    let queryParameters = new HttpParams();
    let headers = this.getNoLoginHeaders();

    // verify required parameter 'planId' is not null or undefined
    if (planId === null || planId === undefined) {
      throw new Error('Required parameter planId was null or undefined when calling getPlanDetail.');
    }

    if (platform !== undefined && platform != null) {
      queryParameters = queryParameters.set('platform', <any>platform);
    }

    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];


    let requestOptions = {
      headers: headers,
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.GET, path, requestOptions);
  }

  /**
   * Return the plans
   *
   * @param giftPlanFilter The gift plan filter
   */
  public getPlansWithHttpInfo(giftPlanFilter?: boolean, platform?: string, extraHttpRequestParams?: any): Observable<HttpResponse<Plan[]>> {
    const path = this.getBasePath() + `/plans`;

    let queryParameters = new HttpParams();
    let headers = this.getNoLoginHeaders();

    if (giftPlanFilter !== undefined) {
      queryParameters.set('giftPlanFilter', <any>giftPlanFilter);
    }

    if (platform !== undefined && platform != null) {
      queryParameters = queryParameters.set('platform', <any>platform);
    }

    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];


    let requestOptions = {
      headers: headers,
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.GET, path, requestOptions);
  }

}
