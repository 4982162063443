<div class="col-sm-1 col-md-1 col-lg-1 play-cell">
  <app-play-playlist-button [iconSize]="playButtonIconSize.Medium" [item]="item"></app-play-playlist-button>
</div>
<div class="col-sm-10 col-md-10 col-lg-10" [routerLink]="['/playlists/details', item.id]">
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12">
      <p class="name">{{ item.name }}</p>
      <!--<p class="description">{{ item.shortDescription }}</p>-->
    </div>
  </div>
</div>
<div class="col-sm-1 col-md-1 col-lg-1">
  <div class="option-wrapper">
    <app-option-popup [item]="item" [fullPageHeight]="true" [topMargin]="0">
      <img src="/assets/images/ellipsis.svg" alt="" aria-hidden="true">
    </app-option-popup>
  </div>
</div>
