import {Injectable, OnDestroy} from '@angular/core';
import {User} from "../api-client/model/User";
import {AccountService} from "../api-client/api/account.service";
import {LoggedUserService} from "../api-client/helper/logged-user.service";
import {UserPlan} from "../api-client/model/UserPlan";
import {BehaviorSubject, Subject} from "rxjs";
import {AppConstants, hoslog} from '../app.constants';
import {SubSink} from 'subsink';
import {GenericUtils} from '../shared/utils/generic.utils';

@Injectable()
export class LoggedUserPlanService implements OnDestroy{

  private _plan: UserPlan;
  public plan$: BehaviorSubject<UserPlan>;

  public minutes$: BehaviorSubject<number>;

  private subs = new SubSink();

  public hasCreditCardOnFile = false;
  public refreshPlan$ = new Subject();

  constructor(private loggedUserService: LoggedUserService,
              private accountService: AccountService) {
    // Empty wrapper initially
    this._plan = null;
    this.plan$ = new BehaviorSubject(this._plan);
    this.minutes$ = new BehaviorSubject(0);

    this.subs.sink = this.loggedUserService.loggedUserDetailsObs.subscribe(
      user => {
        if (user != null) {
          // New user, getting account data
          this.refreshUserData();
        } else {
          this._plan = null;
          this.plan$.next(this._plan);
          if (!GenericUtils.isUndefinedOrNull(this._plan) && !GenericUtils.isUndefinedOrNull(this._plan.availablePrepaidMinutes)) {
            this.minutes$.next(this._plan.availablePrepaidMinutes);
          } else {
            this.minutes$.next(0);
          }
          this.hasCreditCardOnFile = false;
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public refreshUserData() {
    // Getting the plan
    this.accountService.getCurrentPlan(AppConstants.USER_PLATFORM_WEB)
      .subscribe(
        res => {
          hoslog("User Plan loaded");
          this._plan = res;
          if (this._plan.billingInfo != null) {
            this.hasCreditCardOnFile = true;
          }
          this.plan$.next(this._plan);
          if(this._plan.availablePrepaidMinutes) {
            this.minutes$.next(this._plan.availablePrepaidMinutes);
          } else {
            this.minutes$.next(0);
          }
        },
        error => {
          hoslog("User Plan: error: " + JSON.stringify(error));
          // TODO schedule a refresh?
        });

  }

  public refreshMinutes() {
    this.accountService.getCurrentMinutes().subscribe(minutes => {
      this.minutes$.next(minutes);
    });
  }

  public refreshPlan() {
    if (this.loggedUserService.loggedUserDetailsObs.value != null) {
      this.refreshUserData();
    }

    this.refreshPlan$.next(true);
  }

  get plan(): UserPlan {
    return this._plan;
  }

  hasPlan(): boolean {
    return this.plan != null && this.plan.plan != null;
  }

  /**
   * only with a full plan or minutes
   */
  canPlayPrograms(): boolean {
    const hasPlan = this.hasPlan();
    const hasFullPlan = hasPlan && this.plan.plan.type === 'full';
    const hasMinutes = this.plan && this.plan.availablePrepaidMinutes > 0;
    return (hasPlan && hasFullPlan) || hasMinutes;
  }
}
