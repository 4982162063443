<td class="play" [class.active]="isPlaying">
  <div class="cell-content">
    <div>
      <app-play-program-button [iconSize]="playButtonIconSize.Medium" [item]="item" extraClasses="program-item-play-button"></app-play-program-button>
    </div>
  </div>
</td>
<td class="img" [routerLink]="['/programs/details', item.id]" [queryParams]="queryParams" [class.active]="isPlaying">
  <div class="cell-content">
    <div>
      <img [src]="item.id | HosProgramImageUrl:180:(item.genres)" alt="{{item.title}}" aria-hidden="true">
    </div>
  </div>
</td>
<td class="program-name" [routerLink]="['/programs/details', item.id]" [queryParams]="queryParams" [class.active]="isPlaying" tabindex="0" role="button" attr.aria-label="{{ item.title }}, {{ item.description }}">
  <div class="cell-content">
    <div>
      <span>{{ item.title }}</span>
      <div class="peek-wrapper">
        <app-program-peek-popup [programId]="item.id"></app-program-peek-popup>
      </div>
      <p *ngIf="item.shortDescription">{{ item.shortDescription | upperfirst }}</p>
      <!--<p *ngIf="item.description">{{ item.description | upperfirst }}</p>-->
    </div>
  </div>
</td>
<td class="pgm-date" [routerLink]="['/programs/details', item.id]" [queryParams]="queryParams" [class.active]="isPlaying" tabindex="0">
  <div class="cell-content">
    <div class="program-number-cell">
      <span attr.aria-label="Program number: {{item.number}}">{{item.number}}</span>
      <p attr.aria-label="Program date: {{ item.date | date:'yyyy-MMM-dd' }}">{{ item.date | date:'yyyy-MMM-dd' }}</p>
    </div>
  </div>
</td>
<td class="genres" [class.active]="isPlaying">
  <div class="cell-content">
    <div>
      <a [routerLink]="['/programs/genres', genre.id, 'list']" [queryParams]="queryParams" *ngFor="let genre of item.genres; let isLast = last" attr.aria-label="Category: {{ genre.name }}, click to show all the items in the category">{{ genre.name }}{{isLast ? '' : ', '}}</a>
    </div>
  </div>
</td>
<td class="rating" [class.active]="isPlaying">
  <div class="cell-content">
    <div>
      <div class="decline-container">
        <app-block-button [item]="item"></app-block-button>
      </div>
      <app-rating [item]="item"></app-rating>
    </div>
  </div>
</td>
<td class="popularity" [class.active]="isPlaying">
  <div class="cell-content">
    <div>
      <app-popularity [popularity]="item.popularity"></app-popularity>
    </div>
  </div>
</td>
<td class="ellipsis" [class.active]="isPlaying">
  <div class="cell-content">
    <div>
      <app-option-popup [item]="item">
        <img src="/assets/images/ellipsis.svg" alt="" aria-hidden="true">
      </app-option-popup>
    </div>
  </div>
</td>
