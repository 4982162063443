import {Pipe, PipeTransform} from '@angular/core';
import {environment} from "../../../environments/environment";

@Pipe({
  name: 'HosGenreImageUrl'
})
export class HosGenreImageUrlPipe implements PipeTransform {

  transform(genreId: number, size: number): any {
    return HosGenreImageUrlPipe.getGenreImageUrl(genreId, size);
  }

  public static getGenreImageUrl(genreId: number, size: number): string {
    if (genreId) {
      /*if (environment.imagesUseOldServlet) {
        // finding the parts of the image
        let url = '/genre/' + genreId; // TODO the path should be plural

        // finding the arguments
        if (size && size > 0) {
          url += "_" + size;
          url += 'w';
        }
        return 'https://cloudfront.hos.com/hos/images' + url + '.jpg';
      } else {*/
      // finding the parts of the image
      let url = '/genres';

      // finding the arguments
      if (size && size > 0) {
        url += `/w/${size}`;
      }
      return `${environment.basePathImageUrl + url}/${genreId}.jpg`;
      // }
    } else {
      return ""; // placeholder?
    }
  }
}
