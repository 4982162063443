/**
 * HOS API
 *
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 */
import {Injectable, Injector} from "@angular/core";
import {HosBaseService} from "./hos-base.service";

import {HttpResponse, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import { map } from 'rxjs/operators';
import "../rxjs-operators";
import {AppConstants} from "../../app.constants";
import {Album} from "../model/Album";
import {Page} from '../model/generic/page';
import {PrevNextAlbumContainer} from '../model/PrevNextAlbumContainer';
import {RequestsUtils} from '../../shared/utils/requests-utils';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class AlbumsService extends HosBaseService {


  constructor(injector: Injector) {
     super(injector);
   }

  /**
   * Return the album detail
   *
   * @param albumId The album id
   */
  public getAlbumDetail(albumId: number, extraHttpRequestParams?: any): Observable<Album> {
    return this.getAlbumDetailWithHttpInfo(albumId, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Return the albums
   *
   * @param filterBy The filter by type
   * @param filterValue The filter value
   * @param orderBy The Order by field
   * @param page page number
   * @param size page size
   */
  public getAlbums(filterBy?: string, filterValue?: string, orderBy?: string, orderByDirection?: string, page?: number, size?: number, ratingOrderUserId?: number, extraHttpRequestParams?: any): Observable<Page<Album>> {
    return this.getAlbumsWithHttpInfo(filterBy, filterValue, orderBy, orderByDirection, page, size, ratingOrderUserId, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Return the albums
   *
   * @param currentId
   * @param position
   * @param filterBy The filter by type
   * @param filterValue The filter value
   * @param orderBy The Order by field
   * @param orderByDirection
   * @param page page number
   * @param size page size
   */
  public getAlbumsPrevNext(currentId: number, position: number, filterBy?: string, filterValue?: string, orderBy?: string, orderByDirection?: string, ratingOrderUserId?: number, extraHttpRequestParams?: any): Observable<PrevNextAlbumContainer> {
    return this.getAlbumsPrevNextWithHttpInfo(currentId, position, filterBy, filterValue, orderBy, orderByDirection, ratingOrderUserId, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Mark the album as blocked
   *
   * @param albumId The album id
   */
  public markBlocked(albumId: number, extraHttpRequestParams?: any): Observable<{}> {
    return this.markBlockedWithHttpInfo(albumId, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Mark the album as favorite
   *
   * @param albumId The album id
   * @param rating The rating
   */
  public markFavorite(albumId: number, rating: number, extraHttpRequestParams?: any): Observable<{}> {
    return this.markFavoriteWithHttpInfo(albumId, rating, extraHttpRequestParams)
      .pipe(
        map((response) => {
        if (response.status === 201) {
          return undefined;
        } else {
          return response.body;
        }
      })
    );
  }

  /**
   * Mark the album as unblocked
   *
   * @param albumId The album id
   */
  public unmarkBlocked(albumId: number, extraHttpRequestParams?: any): Observable<{}> {
    return this.unmarkBlockedWithHttpInfo(albumId, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Unmark the album as favorite
   *
   * @param albumId The album id
   */
  public unmarkFavorite(albumId: number, extraHttpRequestParams?: any): Observable<{}> {
    return this.unmarkFavoriteWithHttpInfo(albumId, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }


  /**
   * Return the album detail
   *
   * @param albumId The album id
   */
  public getAlbumDetailWithHttpInfo(albumId: number, extraHttpRequestParams?: any): Observable<HttpResponse<any>> {
    const path = this.getBasePath() + `/albums/${albumId}`;

    let queryParameters = new HttpParams();
    let headers = this.getNoLoginHeaders();

    // verify required parameter 'albumId' is not null or undefined
    if (albumId === null || albumId === undefined) {
      throw new Error('Required parameter albumId was null or undefined when calling getAlbumDetail.');
    }


    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];


    let requestOptions = {
      headers: headers,
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.GET, path, requestOptions);
  }

  /**
   * Return the albums
   *
   * @param filterBy The filter by type
   * @param filterValue The filter value
   * @param orderBy The Order by field
   * @param page page number
   * @param size page size
   */
  public getAlbumsWithHttpInfo(filterBy?: string, filterValue?: string, orderBy?: string, orderByDirection?: string, page?: number, size?: number, ratingOrderUserId?: number, extraHttpRequestParams?: any): Observable<HttpResponse<Page<Album>>> {
    const path = this.getBasePath() + `/albums`;

    let queryParameters = new HttpParams();
    let headers = this.getNoLoginHeaders();

    if (filterBy !== undefined && filterBy != null) {
      queryParameters = queryParameters.set('filterBy', <any>filterBy);
    }
    if (filterValue !== undefined && filterValue != null) {
      queryParameters = queryParameters.set('filterValue', <any>filterValue);
    }
    if (orderBy !== undefined && orderBy != null) {
      queryParameters = queryParameters.set('orderBy', <any>orderBy);
    }
    if (orderByDirection !== undefined && orderByDirection != null) {
      queryParameters = queryParameters.set('orderByDirection', <any>orderByDirection);
    }
    if (page !== undefined) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined) {
      queryParameters = queryParameters.set('size', <any>size);
    }
    if (ratingOrderUserId !== undefined) {
      queryParameters = queryParameters.set('ratingOrderUserId', <any>ratingOrderUserId);
    }


    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];


    let requestOptions = {
      headers: headers,
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.GET, path, requestOptions);
  }

  /**
   * Return the previous and next albums
   *
   * @param currentId
   * @param position
   * @param filterBy The filter by type
   * @param filterValue The filter value
   * @param orderBy The Order by field
   * @param orderByDirection
   * @param page page number
   * @param size page size
   */
  public getAlbumsPrevNextWithHttpInfo(currentId: number, position: number, filterBy?: string, filterValue?: string, orderBy?: string, orderByDirection?: string, ratingOrderUserId?: number, extraHttpRequestParams?: any): Observable<HttpResponse<PrevNextAlbumContainer>> {
    const path = this.getBasePath() + `/albums/` + currentId + '/prev-next';

    if (position === null || position === undefined) {
      throw new Error('Required parameter position was null or undefined when calling getAlbumsPrevNext.');
    }

    let queryParameters = new HttpParams();
    let headers = this.getNoLoginHeaders();

    if (position !== undefined && position != null) {
      queryParameters = queryParameters.set('position', <any>position);
    }
    if (filterBy !== undefined && filterBy != null) {
      queryParameters = queryParameters.set('filterBy', <any>filterBy);
    }
    if (filterValue !== undefined && filterValue != null) {
      queryParameters = queryParameters.set('filterValue', <any>filterValue);
    }
    if (orderBy !== undefined && orderBy != null) {
      queryParameters = queryParameters.set('orderBy', <any>orderBy);
    }
    if (orderByDirection !== undefined && orderByDirection != null) {
      queryParameters = queryParameters.set('orderByDirection', <any>orderByDirection);
    }
    if (ratingOrderUserId !== undefined && ratingOrderUserId != null) {
      queryParameters = queryParameters.set('ratingOrderUserId', <any>ratingOrderUserId);
    }


    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];


    let requestOptions = {
      headers: headers,
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.GET, path, requestOptions);
  }

  /**
   * Mark the album as blocked
   *
   * @param albumId The album id
   */
  public markBlockedWithHttpInfo(albumId: number, extraHttpRequestParams?: any): Observable<HttpResponse<Object>> {
    const path = this.getBasePath() + `/albums/${albumId}/block`;

    let queryParameters = new HttpParams();
    let headers = this.getDefaultHeaders();

    // verify required parameter 'albumId' is not null or undefined
    if (albumId === null || albumId === undefined) {
      throw new Error('Required parameter albumId was null or undefined when calling markBlocked.');
    }


    // to determine the Accept header
    let produces: string[] = [];


    let requestOptions = {
      headers: headers,
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.PUT, path, requestOptions);
  }

  /**
   * Mark the album as favorite
   *
   * @param albumId The album id
   * @param rating The rating
   */
  public markFavoriteWithHttpInfo(albumId: number, rating: number, extraHttpRequestParams?: any): Observable<HttpResponse<Object>> {
    const path = this.getBasePath() + `/albums/${albumId}/favorite`;

    let queryParameters = new HttpParams();
    let headers = this.getDefaultHeaders();

    // verify required parameter 'albumId' is not null or undefined
    if (albumId === null || albumId === undefined) {
      throw new Error('Required parameter albumId was null or undefined when calling markFavorite.');
    }
    // verify required parameter 'rating' is not null or undefined
    if (rating === null || rating === undefined) {
      throw new Error('Required parameter rating was null or undefined when calling markFavorite.');
    }


    // to determine the Content-Type header
    let consumes: string[] = [];
    let canConsumeForm = this.canConsumeForm(consumes);
    let useForm = false;
    let formParams = new HttpParams();

    // to determine the Accept header
    let produces: string[] = [];


    if (rating !== undefined) {
      formParams = formParams.set('rating', rating.toString());
    }

    let requestOptions = {
      headers: headers,
      body: RequestsUtils.getJsonObjectFromFormParams(formParams),
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.PUT, path, requestOptions);
  }

  /**
   * Mark the album as unblocked
   *
   * @param albumId The album id
   */
  public unmarkBlockedWithHttpInfo(albumId: number, extraHttpRequestParams?: any): Observable<HttpResponse<Object>> {
    const path = this.getBasePath() + `/albums/${albumId}/block`;

    let queryParameters = new HttpParams();
    let headers = this.getDefaultHeaders();

    // verify required parameter 'albumId' is not null or undefined
    if (albumId === null || albumId === undefined) {
      throw new Error('Required parameter albumId was null or undefined when calling unmarkBlocked.');
    }


    // to determine the Accept header
    let produces: string[] = [];


    let requestOptions = {
      headers: headers,
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.DELETE, path, requestOptions);
  }

  /**
   * Unmark the album as favorite
   *
   * @param albumId The album id
   */
  public unmarkFavoriteWithHttpInfo(albumId: number, extraHttpRequestParams?: any): Observable<HttpResponse<Object>> {
    const path = this.getBasePath() + `/albums/${albumId}/favorite`;

    let queryParameters = new HttpParams();
    let headers = this.getDefaultHeaders();

    // verify required parameter 'albumId' is not null or undefined
    if (albumId === null || albumId === undefined) {
      throw new Error('Required parameter albumId was null or undefined when calling unmarkFavorite.');
    }


    // to determine the Accept header
    let produces: string[] = [];


    let requestOptions = {
      headers: headers,
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.DELETE, path, requestOptions);
  }

}
