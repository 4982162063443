
export class ExtLinksUtils {

  public static openExtLink(extLink: string) {
    if (extLink) {
      let link = extLink;
      if (!link.startsWith('http')) {
        link = 'http://' + link;
      }
      window.open(link, "_blank");
    }
  }

  public static convertToSingleUrl(urlListTxt: string): any {
    if (urlListTxt) {
      const urls = urlListTxt.split(';');
      if (urls && urls.length > 0) {
        const url = urls[0];
        return (url.toLowerCase().startsWith('http') ? '' : 'http://') + url;
      }
    }
    return "";
  }
}
