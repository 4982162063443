import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class NoInternetConnectionService {

  private _isInternetAvailableObs: BehaviorSubject<boolean>;

  constructor() {
    this._isInternetAvailableObs = new BehaviorSubject<boolean>(true);
  }

  public changeIsInternetAvailable(value: boolean) {
    this._isInternetAvailableObs.next(value);
  }

  get isInternetAvailable$(): BehaviorSubject<boolean> {
    return this._isInternetAvailableObs;
  }
}
