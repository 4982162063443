import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'activeTrack'
})

export class ActiveTrackPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    return args[0].filter(arg => arg.startPositionInStream === value)[0];
  }

}
