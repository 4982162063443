import {Injectable, OnDestroy} from '@angular/core';
import {User} from "../model/User";
import {BehaviorSubject} from "rxjs";
import {LoginHelperService} from "./login-helper.service";
import {AccountService} from "../api/account.service";
import {MixpanelService} from "../../services/mixpanel.service";
import {Settings} from "../model/Settings";
import {hoslog} from "../../app.constants";
import {SubSink} from 'subsink';

@Injectable()
export class LoggedUserService implements OnDestroy{
  private subs = new SubSink();
  private _loggedUserDetailsObs: BehaviorSubject<User>;

  constructor(private loginHelperService: LoginHelperService,
              private accountService: AccountService) {
    // Initial value
    this._loggedUserDetailsObs = new BehaviorSubject(null);

    // Listening for logged users
    let me = this;
    this.subs.sink = this.loginHelperService.isLoggedIn$.subscribe(
      isLoggedIn => {
        me.onLoginChanged(isLoggedIn);
      }
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private onLoginChanged(isLoggedIn: boolean) {
    if (isLoggedIn) {
      // load user info
      this.updateLoggedUserDetails();
    } else {
      this.loggedUserDetailsObs.next(null);
    }
  }

  public updateLoggedUserDetails() {
    this.accountService.getUserDetails()
      .subscribe(
        res => {
          hoslog("Account info loaded");
          // hoslog(res);
          this.loggedUserDetailsObs.next(res);
        },
        error => {
          hoslog("Account info: error: " + JSON.stringify(error));
          // TODO Sign Out? Or retry?
        });
  }

  get loggedUserDetailsObs(): BehaviorSubject<User> {
    return this._loggedUserDetailsObs;
  }

}
