<div class="row-container" role="group" attr.aria-label="Album: {{item.title}}">
  <div class="item-img" (click)="openBuyCdUrl()" [ngClass]="{'pointer': item.buyCdUrl}">
    <img [src]="item.id | HosAlbumImageUrl:100" alt="{{item.title}}" aria-hidden="true">
    <p>{{ item.title }}</p>
  </div>
  <div class="item-content">
    <p tabindex="0">{{item.attribution}}</p>
    <p tabindex="0">{{ item.title }}</p>
    <p tabindex="0"><span *ngIf="item.date">{{item.date | date:'y'}}&nbsp;</span>{{item.label?.name}} </p>

    <table role="grid" aria-label="Tracks" tabindex="0">
      <thead>
      <tr>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let track of item.tracks">
        <td tabindex="0" aria-label="Track number">{{ track.idx }}</td>
        <td tabindex="0" aria-label="Track title">{{ track.title }}</td>
        <td>{{ track.startPositionInStream | secondsToMinutes | playerExtraCutPipe}}</td>
        <td tabindex="0" aria-label="Track duration">
          {{ (track.startPositionInStream + track.duration) | secondsToMinutes | playerExtraCutPipe}}
          <app-option-popup [item]="track" [album]="item" [canPlay]="canPlay" *ngIf="!hideActions">
            <img src="/assets/images/ellipsis.svg" alt="" aria-hidden="false">
          </app-option-popup>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
