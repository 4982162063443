import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {TopBarComponent} from '../top-bar/top-bar-component';

@Component({
  selector: 'app-footer-links',
  templateUrl: './footer-links.component.html',
  styleUrls: ['./footer-links.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterLinksComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }

}
