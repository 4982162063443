<div class="col-sm-1 col-md-1 col-lg-1 play-cell">
  <app-play-album-track-button [iconSize]="playButtonIconSize.Medium" [track]="item" [album]="item.albumFull" [enabled]="item.albumFull.playable == true"></app-play-album-track-button>
</div>
<div class="col-sm-10 col-md-10 col-lg-10" [routerLink]="['/albums/details', item.album.id]">
  <div class="row">
    <div class="col-sm-2 col-md-2 col-lg-2 cover-img-container">
      <img [src]="item.album.id | HosAlbumImageUrl:80" alt="{{item.album.title}}" aria-hidden="true" *ngIf="item.album">
    </div>
    <div class="col-sm-10 col-md-10 col-lg-10" [routerLink]="['/albums/details', item.album.id]">
      <p class="title">{{ item.title }}</p>
      <p class="artist" *ngIf="item.artists && item.artists.length>0">
        {{ item.artists[0].name }}
      </p>
      <p class="album" *ngIf="item.album">
        {{ item.album.title }}
      </p>
    </div>
  </div>
</div>
<div class="col-sm-1 col-md-1 col-lg-1">
  <div class="option-wrapper">
    <app-option-popup [item]="item" [album]="item.albumFull" [canPlay]="item.albumFull.playable == true" [fullPageHeight]="true" [topMargin]="0">
      <img src="/assets/images/ellipsis.svg" alt="" aria-hidden="true">
    </app-option-popup>
  </div>
</div>
