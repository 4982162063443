import { Component, OnInit, Input } from '@angular/core';
import {PlayButtonIconSize} from '../../../../app.constants';

@Component({
  selector: 'app-search-program-item',
  templateUrl: './search-program-item.component.html',
  styleUrls: ['./search-program-item.component.scss']
})
export class SearchProgramItemComponent implements OnInit {
  @Input() item;

  playButtonIconSize = PlayButtonIconSize;

  constructor() { }

  ngOnInit() {
  }
}
