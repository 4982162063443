<nav class="main-menu" role="navigation" aria-label="Main menu" tabindex="0" role="menu">
  <div class="back-next" *ngIf="showHistoryControlBar()">
    <button class="nav-button" (click)="goBack()" [disabled]="!getPrev()" [attr.aria-disabled]="!getPrev()" aria-label="Go back" role="menuitem">
      <i class="fa fa-chevron-left"></i>
      <span>BACK</span>
    </button>
    <button class="nav-button" (click)="goForward()" [disabled]="!getNext()" [attr.aria-disabled]="!getNext()" aria-label="Go forward" role="menuitem">
      <span>FWD</span>
      <i class="fa fa-chevron-right"></i>
    </button>
  </div>
  <div class="menu-list-container">
  <ol class="menu-list">
    <li *ngIf="loggedUserPlanService.plan && loggedUserPlanService.plan.plan && loggedUserPlanService.plan.plan.type && loggedUserPlanService.plan.plan.type=='std'">
      <!--
      <a routerLink="channels" routerLinkActive="active" id="myServicesLink" role="menuitem">
        MY SERVICES
      </a>
      -->
      <div class="my-services">
        MY SERVICES
      </div>
    </li>
    <li>
      <a routerLink="/this-week/program" routerLinkActive="active" id="weekItemLink" role="menuitem">
        THIS WEEK
      </a>
    </li>
    <li class="recent-list-item">
      <a routerLink="recent" routerLinkActive="active" id="recentItemLink" role="menuitem">
        RECENT
      </a>
    </li>
    <li>
      <a routerLink="channels" routerLinkActive="active" id="channelsItemLink" role="menuitem">
        CHANNELS
      </a>
    </li>
    <li class="separator" *ngIf="loggedUserPlanService.plan && loggedUserPlanService.plan.plan && loggedUserPlanService.plan.plan.type && loggedUserPlanService.plan.plan.type=='std'"></li>
    <li>
      <a routerLink="programs" routerLinkActive="active" id="programsItemLink" role="menuitem">
        PROGRAMS
      </a>
    </li>
    <li>
      <a routerLink="albums" routerLinkActive="active" id="albumsItemLink" role="menuitem">
        ALBUMS
      </a>
    </li>
    <li>
      <a routerLink="/playlists" routerLinkActive="active" id="playlistItemLink" role="menuitem" *ngIf="(loginHelperService.isLoggedIn$ | async)">MY PLAYLISTS</a>
      <a (click)="menuClicked($event, '/playlists')" id="playlistItemLinkDisabled" role="menuitem" *ngIf="!(loginHelperService.isLoggedIn$ | async)">MY PLAYLISTS</a>
    </li>
    <li>
      <a routerLink="/favorites" routerLinkActive="active" id="favoritesItemLink" role="menuitem" *ngIf="(loginHelperService.isLoggedIn$ | async)">FAVORITES</a>
      <a (click)="menuClicked($event, '/favorites')" id="favoritesItemLinkDisabled" role="menuitem" *ngIf="!(loginHelperService.isLoggedIn$ | async)">FAVORITES</a>
    </li>
    <li>
      <a routerLink="/surprise-me/program" routerLinkActive="active" id="surpriseItemLink" role="menuitem" *ngIf="(loginHelperService.isLoggedIn$ | async)">SURPRISE ME</a>
      <a (click)="menuClicked($event, '/surprise-me/program')" id="surpriseItemLinkDisabled" role="menuitem" *ngIf="!(loginHelperService.isLoggedIn$ | async)">SURPRISE ME</a>
    </li>
    <ng-container *ngIf="(planHelperService.subscribeUpgradeButtonText$ | async) as labelText">
    <li class="menu-subscribe menu-subscribe-first" *ngIf="labelText != null">
      <div class="menu-subscribe-inner">
        <a [routerLink]="['/subscription']" routerLinkActive="active" role="menuitem">
          {{labelText}}
        </a>
      </div>
    </li>
    </ng-container>
    <li class="menu-subscribe" [ngClass]="{'menu-subscribe-first' : !(planHelperService.subscribeUpgradeButtonText$ | async)}">
      <div class="menu-subscribe-inner">
        <a [routerLink]="['/account']" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" role="menuitem">
          MY ACCOUNT
        </a>
      </div>
    </li>
    <li class="menu-subscribe menu-subscribe-last">
      <div class="menu-subscribe-inner">
        <a [routerLink]="['/account/settings']" routerLinkActive="active" role="menuitem">
          SETTINGS
        </a>
      </div>
    </li>

    <li class="menu-secondary about-hos-list-item">
      <a routerLink="about" routerLinkActive="active" role="menuitem">
        ABOUT HOS
      </a>
    </li>
    <li class="menu-secondary">
      <a [routerLink]="['/news']" routerLinkActive="active" role="menuitem">
        NEWS
        <!-- page with iframe http://blog.hos.com/-->
      </a>
    </li>
    <li class="menu-secondary">
      <a [routerLink]="['/pages/faq']" routerLinkActive="active" role="menuitem">
        FAQ
      </a>
    </li>
    <li class="menu-secondary">
      <a [routerLink]="['/pages/radio-stations']" routerLinkActive="active" role="menuitem">
        PUBLIC RADIO
      </a>
    </li>
    <li class="menu-secondary">
      <a [routerLink]="['/subscription']" [queryParams]="{gift: 1}" routerLinkActive="active" role="menuitem">
        GIFTS
      </a>
    </li>
    <li class="menu-secondary">
      <a href="https://www.cafepress.com/heartsofspace" target="_blank" role="menuitem">
        MERCH
      </a>
    </li>
    <li class="menu-secondary">
      <a [routerLink]="['/pages/submissions']" routerLinkActive="active" role="menuitem">
        SUBMISSIONS
      </a>
    </li>
    <li class="menu-secondary">
      <a [routerLink]="['/pages/contact-us']" routerLinkActive="active" role="menuitem">
        CONTACT US
      </a>
    </li>
  </ol>
  </div>
</nav>
