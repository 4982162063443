import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'addHttpsToUrl'
})
export class AddHttpsToUrlPipe implements PipeTransform {

  transform(urlTxt: string): any {
    if (urlTxt && !urlTxt.startsWith('http')) {
      return 'https://' + urlTxt.trim();
    } else {
      return urlTxt.trim();
    }
  }

}
