import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FavouriteListFilterService} from '../../services/favourite-list-filter.service';
import {TopBarComponent} from '../top-bar/top-bar-component';
import {hoslog} from '../../app.constants';
import {LoadingHelperService} from '../loading-helper/loading-helper.service';
import {AccountService} from '../../api-client';
import {FileSaverService} from 'ngx-filesaver';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-favorite-top-bar',
  templateUrl: './favorite-top-bar.component.html',
  styleUrls: ['./favorite-top-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FavoriteTopBarComponent implements TopBarComponent, OnInit {
  @Input() data: any;

  private downloadObs: Subscription;

  constructor(public favouriteListFilterService: FavouriteListFilterService,
              public loadingHelperService: LoadingHelperService,
              private accountService: AccountService,
              private fileSaverService: FileSaverService,
              private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
  }

  printFavorites() {
    this.loadingHelperService.startLoading('favoritesOverlayDownloadLoading');

    this.downloadObs = this.accountService.downloadFavorites()
      .subscribe(value => {
          this.loadingHelperService.loadingOK('favoritesOverlayDownloadLoading');
          this.changeDetectorRef.detectChanges();
          this.fileSaverService.save(value, 'MyFavorites' + '.pdf');
        },
        error => {
          hoslog('printFavorites error: ' + JSON.stringify(error));
          this.loadingHelperService.loadingKO(error, 'favoritesOverlayDownloadLoading');
          this.changeDetectorRef.detectChanges();
        });
  }
}
