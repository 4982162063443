import {Component, ElementRef, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {Event as NavigationEvent, NavigationStart, Router} from '@angular/router';
import {SearchService} from "../../api-client/api/search.service";
import {SearchWrapper} from "../../api-client/model/SearchWrapper";
import {LoadingHelperService} from "../../shared/loading-helper/loading-helper.service";
import {Subscription} from "rxjs";
import {delay, filter} from "rxjs/operators";
import {hoslog} from "../../app.constants";
import {Artist} from '../../api-client';
import {GeneralService} from '../../services/general.service';
import {SubSink} from 'subsink';
import {SearchValuesHolderService} from '../../services/search-values-holder.service';
import {MixpanelService} from '../../services/mixpanel.service';


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {

  //public isFocused: boolean;
  public isPopupOpen: boolean;
  public popupFocused: boolean;
  public headerHeight = 75;
  public footerHeight = 120;

  private subs = new SubSink();
  private lastSubscription: Subscription = null;

  constructor(private router: Router,
              private _el: ElementRef,
              private renderer: Renderer2,
              private searchService: SearchService,
              private mixpanelService: MixpanelService,
              public searchValueService: SearchValuesHolderService,
              private generalService: GeneralService,
              public loadingHelperService: LoadingHelperService) {
    this.subs.sink = router.events
      .pipe(
        filter(event => event instanceof NavigationStart)
      )
      .subscribe((event: NavigationEvent) => {
        // this.searchValue = "";
        //this.isFocused = false;
        this.isPopupOpen = false;
        // this.results = null;
      });
  }

  ngOnInit() {
    //this.searchValue = "";
    //this.isFocused = false;
    this.isPopupOpen = false;

    this.subs.sink = this.generalService.requestSearch$.subscribe(
      query => {
        if (query && query.trim().length > 0) {
          this.searchValueService.searchValue = query;
          this.onChange(query);
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    if (this.lastSubscription != null) {
      this.lastSubscription.unsubscribe();
    }
  }

  onFocus($event) {
    // opening on click
    if (!this.isPopupOpen && this.searchValueService.hasText()) {
      // this.onChange(this.searchValueService.searchValue);
      this.isPopupOpen = true;
    }
  }

  onChange(newValue) {
    if (newValue.trim() != "") {
      this.isPopupOpen = true;
      if (newValue.trim().length >= 1) {
        this.searchValueService.results = {};
        // this.renderer.setStyle(this._el.nativeElement.getElementsByClassName('popup-wrapper')[0], 'height', window.innerHeight - this.headerHeight - this.footerHeight + 'px');
        this.renderer.setStyle(this._el.nativeElement.getElementsByClassName('popup-wrapper')[0], 'height', 'auto');
        this.renderer.setStyle(this._el.nativeElement.getElementsByClassName('popup-wrapper')[0], 'max-height', window.innerHeight - this.headerHeight - this.footerHeight + 'px');
        this.doSearch();
      } else {
        this.searchValueService.reset();
        this.renderer.setStyle(this._el.nativeElement.getElementsByClassName('popup-wrapper')[0], 'height', '280px');
      }
    } else {
      this.searchValueService.reset();
      this.isPopupOpen = false;
    }
  }

  /*onBlur(ev) {
   if (!this.popupFocused) {
   //this.searchValue = "";
   //this.isFocused = false;
   // this.isPopupOpen = false;
   // this.results = null;
   } else {
   ev.target.focus();
   }
   }*/

  /*onFocus() {
   this.isFocused = true;
   }*/

  doSearch() {
    let me = this;

    // Searching
    this.loadingHelperService.startLoading("search");
    if (this.lastSubscription) {
      this.lastSubscription.unsubscribe(); // cancel the old request
      this.lastSubscription = null;
    }
    // New request
    // this.lastSubscription = this.searchService.search(this.searchValue.trim())
    this.lastSubscription = this.searchService.searchFullText(this.searchValueService.searchValue.trim())
      .pipe(
        delay(300)
      )
      .subscribe(
        res => {
          hoslog("search loaded");

          this.mixpanelService.incrementUserProperty('Searched');
          this.mixpanelService.track('Search', {query: this.searchValueService.searchValue.trim()});
          // Adding the artists as a single string
          if (res.albums.content && res.albums.content.length > 0) {
            res.albums.content.forEach(item => {
              if (!item.attribution) { // we use the artists name only if the attribution is empty
                let artistsStr = '';
                if (item.artists != null) {
                  item.artists.forEach((item: Artist, index: number, array: Artist[]) => {
                    artistsStr += item.name + (index < (array.length - 1) ? ',' : '')
                  });
                }
                item.artistsStr = artistsStr;
              }
            });
          }

          me.searchValueService.results = res;
          me.loadingHelperService.loadingOK("search");
        },
        error => {
          hoslog("search error: " + JSON.stringify(error));
          me.searchValueService.reset();
          me.loadingHelperService.loadingKO(error, "search");
        });
  }

  resetSearch() {
    this.isPopupOpen = false;
    this.searchValueService.resetAll();
  }

  setPopupFocused() {
    this.popupFocused = true;
    let that = this;
    setTimeout(
      function () {
        that.popupFocused = false;
      },
      100
    );
  }

}
