/**
 * HOS API
 *
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 *
 *
 *
 */

import { HosErrorDialog } from './HosErrorDialog';


export interface HosError {
    errorDescription?: string;

    errorDialog?: HosErrorDialog;

    errorName?: string;

    errorCode?: number;

}
