import {Component, Input, Output, EventEmitter, OnDestroy} from '@angular/core';
import {HosItem} from "../../api-client/model/HosItem";
import {LoginHelperService} from "../../api-client/helper/login-helper.service";
import {LoggedUserFavouritesService} from "../../services/logged-user-favourites.service";
import {AlertsUtilService} from "../../services/alerts-util.service";
import {hoslog} from "../../app.constants";
import {SubSink} from 'subsink';
import {MixpanelService} from '../../services/mixpanel.service';
import {Router} from '@angular/router';
import {StopTimerService} from '../../services/stop-timer.service';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnDestroy {
  @Input() item: HosItem;
  @Input() enabled: boolean = true;

  private subs = new SubSink();

  public loading = false;
  public loggedIn = false;

  constructor(private alertsUtilService: AlertsUtilService,
              private mixpanelService: MixpanelService,
              private loggedUserFavouritesService: LoggedUserFavouritesService,
              private loginHelperService: LoginHelperService,
              private stopTimerService: StopTimerService) {

    this.subs.sink = this.loginHelperService.isLoggedIn$.subscribe(
      isLoggedIn => {
        this.loggedIn = isLoggedIn;
      }
    );
  }

  ngOnInit() {
    this.subs.sink = this.loggedUserFavouritesService.favouritesObs.subscribe(
      favWrapper => {
        // hoslog("Favorite refreshed");
        if (this.item) {
          // hoslog("Refreshing rating...");
          this.item.rating = this.loggedUserFavouritesService.getRating(this.item);
          // hoslog("Rating refreshed: "+this.rating);
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onClick($event): void {
    this.stopTimerService.reset();
    if (this.enabled) {
      if (this.loggedIn) {
        let oldValue = this.item.rating;
        let newValue = $event.rating;

        if (oldValue === newValue) {
          // if the user clicked again on the star with the same value, it removes it
          hoslog("Cancel rating");
          this.item.rating = 0;
          newValue = 0;
        } else {
          hoslog("Set rating: " + newValue);
          this.item.rating = newValue;
        }
        // else it sets the rating
        this.mixpanelService.incrementUserProperty('Rated');
        this.mixpanelService.track('rateItem', {ratingValue: newValue, itemId: this.item.id, title: this.item.title, type: HosItem.TypeEnum[this.item.type]});
        this.loggedUserFavouritesService.setRating(this.item, newValue)
          .subscribe(
            res => {
              hoslog("setRating ok");
            },
            error => {
              hoslog("setRating error: " + JSON.stringify(error));
              this.item.rating = oldValue;
              this.alertsUtilService.showAlert("There was an error while setting the rating for the item, please try again");
            });

      } else {
        this.alertsUtilService.showAlert("Please login or register to continue");
      }
    } else {
      this.alertsUtilService.showAlert("You cannot rate this item");
    }
  }
}
