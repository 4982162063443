import {
  Component, OnInit, ElementRef, Renderer2, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef, OnDestroy
} from '@angular/core';
import {HosItem} from "../../api-client/model/HosItem";
import {Playlist} from "../../api-client/model/Playlist";
import {ModalService} from "../modal.service";
import {Router} from "@angular/router";
import {SelectPlaylistDialogComponent} from "app/shared/option-popup/select-playlist-dialog/select-playlist-dialog.component";
import {HosPlayerService} from "../../services/player/hos-player.service";
import {HosQueue, HosQueueChannel, HosQueuePlaylist, HosQueueSingleAlbum, HosQueueSingleProgram} from '../../services/player/hos-queue';
import {PlaylistsService} from "../../api-client/api/playlists.service";
import {Album} from "../../api-client/model/Album";
import {hoslog} from "../../app.constants";
import {ExtLinksUtils} from '../utils/ext-links.utils';
import {LoginHelperService} from '../../api-client/helper/login-helper.service';
import {CreatePlaylistComponent} from '../create-playlist/create-playlist.component';
import {AlertsUtilService} from '../../services/alerts-util.service';
import {ShareService} from '../../services/share.service';
import {_switch} from 'rxjs-compat/operator/switch';
import {GeneralService} from '../../services/general.service';
import {SubSink} from 'subsink';
import {MixpanelService} from '../../services/mixpanel.service';
import {PlayerUtilsService} from '../utils/player-utils';
import {PlaylistsUtilsService} from '../../services/playlists-utils.service';
import {PlaylistsDialogsUtilsService} from '../../services/playlists-dialogs-utils.service';
import {Subscription} from 'rxjs';
import {LoadingHelperService} from '../loading-helper/loading-helper.service';
import {Channel, ProgramsService} from '../../api-client';
import {FileSaverService} from 'ngx-filesaver';
import {PageReferral} from '../../services/player/hos-play-item';

declare var window: any;

interface PopupAction {
  icon: string;
  title: string;
  action: string;
}

interface HosItemActionList {
  type: string,
  actions: PopupAction[]
}

@Component({
  selector: 'app-option-popup',
  templateUrl: './option-popup.component.html',
  styleUrls: ['./option-popup.component.scss'],
  /*host: {
    '(document:click)': 'lostFocus($event)'
  }*/
})
export class OptionPopupComponent implements OnInit, OnDestroy {
  private subs = new SubSink();

  private downloadObs: Subscription;

  public PageReferralEnum = PageReferral;

  @Input() item: any;
  @Input() album?: Album; // this is just for album tracks
  @Input() detail = false; // It's true when it's not showing on a list but on the detail page
  @Input() fullPageHeight = false;

  @Input() topMargin = 50;

  @Input() pageReferral: PageReferral;
  @Input() channel: Channel;
  @Input() playlist: Playlist;
  @Input() programIdxInPlaylist: number;

  // @Input() actionsList = [];
  // @Output() onAction = new EventEmitter<string>();

  @Input('canPlay') canPlay = true;

  @ViewChild("optionBtn", { static: true }) optionBtn;

  public actionsList = [];

  /*private defaultActionsList = [
   {
   icon: 'play.svg',
   title: 'Play Now',
   action: 'play'
   },
   {
   icon: 'queue.png',
   title: 'Add to Queue',
   action: 'queue'
   }
   ];*/


  public isOpened: boolean = false;
  private _headerHeight = 120;
  private _footerHeight = 120;

  constructor(private _el: ElementRef,
              private renderer: Renderer2,
              private changeDetectorRef: ChangeDetectorRef,
              private alertsUtilService: AlertsUtilService,
              private router: Router,
              private shareService: ShareService,
              private modalService: ModalService,
              private gs: GeneralService,
              private mixpanelService: MixpanelService,
              private hosPlayerService: HosPlayerService,
              public loadingHelperService: LoadingHelperService,
              private programsService: ProgramsService,
              private fileSaverService: FileSaverService,
              private playlistsService: PlaylistsService,
              private playlistsDialogsUtilsService: PlaylistsDialogsUtilsService,
              private loginHelperService: LoginHelperService) {
    /*if (this.actionsList.length === 0) {
     this.actionsList = this.defaultActionsList;
     }*/


  }

  ngOnInit() {
    this.subs.sink = this.loginHelperService.isLoggedIn$.subscribe(
      value => {
        if (value != null) {
          // hoslog('logged in changed: popup refresh');
          this.refreshPopupOptions();
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  refreshPopupOptions() {
    //hoslog('popup refresh');
    let itemType = this.getItemType();
    if (itemType) {
      const isLoggedIn = this.loginHelperService.isLoggedIn$.value;

      this.actionsList = this.getDefaultActionsList(itemType);
      // check if some actions are invalid
      if (itemType == 'album' && this.item) {
        // buy cd option
        if (!this.item.buyCdUrl) {
          this.actionsList.splice(4, 1);
        }

        // play and playlist options
        if (!this.canPlay || this.item.playable === false) {
          this.actionsList.splice(0, 2);
        } else if (!isLoggedIn) { // playlist only
          this.actionsList.splice(1, 2);
        }
      } else if (itemType == 'track') {

        let albumFound = null;
        if (this.item && this.item.albums && this.item.albums.length > 0) {
          albumFound = this.item.albums[0];
        } else if (this.album && this.album.buyCdUrl) {
          albumFound = this.album;
        }

        // buy cd option
        if (!albumFound || !albumFound.buyCdUrl) {
          this.actionsList.splice(4, 1);
        }

        // album details
        if (!albumFound || albumFound.playable == false) {
          this.actionsList.splice(2, 1);
        }

        // play and playlist options
        if (!this.canPlay) {
          this.actionsList.splice(0, 2);
        } else if (!isLoggedIn) { // playlist only
          this.actionsList.splice(1, 2);
        }
      }
      if (this.detail && this.actionsList.length >= 3) {
        this.actionsList.splice(2, 1);
      }
    }
  }

  handleAction(action: string) {
    // hoslog('handleAction');
    this.runAction(action);
    // this.onAction.emit(action);
    this.lostFocus(undefined);
    this.optionBtn.nativeElement.focus();
  }

  runAction(actionName: string) {
    this.track('selected: ' + actionName);

    let itemType = this.getItemType();
    // hoslog(actionName + " " + itemType + " " + this.item.id);
    if (actionName === 'play') {
      // hoslog("play");
      if (itemType == 'program') {
        if (this.channel != null) {
          // hoslog('play from channel');
          this.hosPlayerService.play(new HosQueueChannel(this.channel, this.item));
        } else if (this.playlist != null) {
          // hoslog('play from playlist');
          this.hosPlayerService.play(new HosQueuePlaylist(this.playlist, this.item, this.programIdxInPlaylist));
        } else {
          this.hosPlayerService.play(new HosQueueSingleProgram(this.item, this.pageReferral));
        }
      } else if (itemType == 'album') {
        if (this.item['playable']) {
          if (this.playlist != null) {
            // hoslog('play from playlist');
            this.hosPlayerService.play(new HosQueuePlaylist(this.playlist, this.item, this.programIdxInPlaylist));
          } else {
            this.hosPlayerService.play(new HosQueueSingleAlbum(this.item));
          }
        } else {
          this.alertsUtilService.showAlert('Sorry, this album is not part of our collection as a playable selection.');
        }
      } else if (itemType == 'track') {
        if (this.playlist != null) {
          // hoslog('play from playlist');
          this.hosPlayerService.play(new HosQueuePlaylist(this.playlist, this.item, this.programIdxInPlaylist));
        } else {
          this.hosPlayerService.play(new HosQueueSingleAlbum(this.album), this.item);
        }
      } else if (itemType == 'playlist') {
        this.playPlaylist();
      }
    } else if (actionName === 'playlist') {
      if (itemType == 'album') {
       if (this.item['playable']) {
         this.playlistsDialogsUtilsService.showAddtoPlaylistDialog(this.item);
       } else {
         this.alertsUtilService.showAlert('Sorry, this album is not part of our collection as a playable selection.');
       }
      } else {
        this.playlistsDialogsUtilsService.showAddtoPlaylistDialog(this.item);
      }
    } else if (actionName === 'details') {
      if (itemType == 'album' && this.item) {
        this.router.navigate(['/albums/details/', this.item.id])
      } else if (itemType == 'program' && this.item) {
        this.router.navigate(['/programs/details/', this.item.id])
      } else if (itemType == 'track' && this.item && this.item.albums && this.item.albums.length > 0) {
        this.router.navigate(['/albums/details/', this.item.albums[0].id])
      }
    } else if (actionName === 'share') {
      switch(this.getItemType()) {
        case 'album':
          this.shareService.showShareAlbumModal(this.item);
          break;
        case 'program':
          this.shareService.showShareProgramModal(this.item);
          break;
        case 'track':
          this.shareService.showShareTrackModal(this.item, this.album);
          break;
        case 'playlist':
          this.shareService.showSharePlaylistModal(this.item);
          break;
      }
    } else if (actionName === 'buy') {
      if (itemType == 'album' && this.item) {
        ExtLinksUtils.openExtLink(ExtLinksUtils.convertToSingleUrl(this.item.buyCdUrl));
      } else if (itemType == 'track') {
        if (this.album && this.album.buyCdUrl) {
          ExtLinksUtils.openExtLink(ExtLinksUtils.convertToSingleUrl(this.album.buyCdUrl));
        } else if (this.item && this.item.albums && this.item.albums.length > 0) {
          ExtLinksUtils.openExtLink(ExtLinksUtils.convertToSingleUrl(this.item.albums[0].buyCdUrl));
        }
      }

    } else if (actionName === 'print') {
      if (itemType == 'program') {
        // hoslog('print program ' + this.item.id);
        this.printProgram(this.item.id);
      } else if(itemType == 'playlist') {
        this.printPlaylist(this.item.id);
      }
    }
    // hoslog(actionName + ": " + JSON.stringify(this.item, null, 4));
  }


  public queueItem: HosQueue = null;

  private playPlaylist() {
    if (this.queueItem == null && this.item) {
      if (this.item.items) {
        this.queueItem = new HosQueuePlaylist(this.item);
      } else {
        // loading the items
        // hoslog("Playlist " + this.item.id + ": loading items");
        this.playlistsService.getPlaylistDetail(this.item.id)
          .subscribe(
            res => {
              // hoslog("Playlist " + this.item.id + " loaded");
              this.item = res;
              this.queueItem = new HosQueuePlaylist(this.item);
              this.hosPlayerService.play(this.queueItem);
              //this.loadingHelperService.loadingOK('PLAYLIST');
            },
            error => {
              // hoslog("Playlist " + this.item.id + " error: " + JSON.stringify(error));
              //this.loadingHelperService.loadingKO(error, 'PLAYLIST');
              //this.data = null;
            });
      }
    }
    this.hosPlayerService.play(this.queueItem);
  }

  handlePopup() {
    let subContainerHeader = document.getElementsByClassName('sub-container-header')[0];
    if (subContainerHeader && !this.fullPageHeight) {
      this._headerHeight += 45; // consider the extra header
    }

    this.isOpened = !this.isOpened;
    this.trackPopupToggle();

    if (this.isOpened) {
      this.gs.setOpenPeekPopup(true);
      this.changeDetectorRef.detectChanges();
      this.positioningPopup();
      setTimeout(() => this.positioningPopup(), 0);
    } else {
      this.lostFocus(undefined);
    }
  }

  private trackPopupToggle() {
    let actionName;
    if (this.isOpened) {
      actionName = 'open';
    } else {
      actionName = 'close';
    }
    this.track(actionName);
  }

  private track(actionName: string) {
    let action = { action: actionName };
    if (this.item.id && this.item.title && this.item.type) {
      action['itemId'] = this.item.id;
      action['title'] = this.item.title;
      action['type'] = HosItem.TypeEnum[this.item.type];
    }
    this.mixpanelService.track('ItemPopup', action);
  }

  // investigate deeper why we need magic numbers in calculations ('4', '6')
  positioningPopup() {
    // hoslog('positioningPopup = ' + this.topMargin);
    let offsetHeight = this._el.nativeElement.getBoundingClientRect().top - this._headerHeight - this.topMargin;
    let offsetBottom = window.innerHeight - this._el.nativeElement.getBoundingClientRect().bottom - this._footerHeight;

    /*if (this.fullPageHeight) {
      offsetBottom = window.innerHeight - this._el.nativeElement.getBoundingClientRect().bottom;
    }*/

    let elHeight = this._el.nativeElement.getBoundingClientRect().height;
    let correction = 0;

    let popup = this._el.nativeElement.getElementsByClassName('popup-actions')[0];
    let arrow = this._el.nativeElement.getElementsByClassName('tooltip-arrow')[0];

    if ((popup.offsetHeight / 2) > ((offsetBottom + elHeight) / 2)) {
      // popup do not fit from bottom
      // hoslog('popup do not fit from bottom');
      // correction = -((popup.offsetHeight / 2) - offsetBottom + 6);
      correction = -((popup.offsetHeight / 2) - offsetBottom) + 10;
    } else if ((popup.offsetHeight / 2) > ((offsetHeight + elHeight) / 2)) {
      // popup do not fit from top
      // hoslog('popup do not fit from top');
      correction = (popup.offsetHeight / 2) - ((offsetHeight + elHeight) / 2) - 10;
      // hoslog(correction);
    }
    //hoslog(correction);

    const leftPopup = -popup.offsetWidth - 20;
    let topPopup = -((popup.offsetHeight / 2) - (elHeight / 2) - 4) + correction;

    let topArrow;
    let heightArrow;
    if (correction !== 0) {
      topArrow = popup.offsetHeight / 2 - correction - 6;
      heightArrow = 20 + 'px';
    } else {
      topArrow = 0;
      heightArrow = '100%';
    }

    // hoslog('leftPopup = ' + leftPopup);
    // hoslog('topPopup = ' + topPopup);
    // hoslog('topArrow = ' + topArrow);
    // hoslog('heightArrow = ' + heightArrow);
    // hoslog('elHeight = ' + elHeight);
    // hoslog('offsetBottom = ' + offsetBottom);

    if (topArrow < 0) {
      // hoslog('correction based on arrow');
      topPopup = topPopup + topArrow - 8;
      topArrow = 8;
    }
    this.renderer.setStyle(popup, 'left', leftPopup + 'px');
    this.renderer.setStyle(popup, 'top', topPopup + 'px');

    this.renderer.setStyle(arrow, 'top', topArrow + 'px');
    this.renderer.setStyle(arrow, 'height', heightArrow);
  }

  lostFocus(event) {
    this.preventRedirect(event);
    if (!event || !this._el.nativeElement.contains(event.target)) {
      this.gs.setOpenPeekPopup(false);
      this.isOpened = false;
      this.trackPopupToggle();
      //this.renderer.setStyle(this._el.nativeElement.getElementsByClassName('popup-actions')[0], 'top', -5000 + 'px');
    }
  }

  preventRedirect(event) {
    // do not pass events to parent elements
    if (event && event.stopPropagation) event.stopPropagation();
    if (event && event.preventDefault) event.preventDefault();
  }

  private getItemType(): string {
    if (this.item) {
      if (this.item.type) {
        return this.item.type;
      } else { // playlists have no type
        return "playlist";
      }
    } else {
      return null;
    }
  }

  private getDefaultActionsList(itemType: string) {
    const defaultActionsList =
      {
        "program": [
          {
            icon: 'play.svg',
            title: 'Play Now',
            action: 'play'
          },
          {
            icon: 'tooltip-plus.svg',
            title: 'Add to Playlist',
            action: 'playlist'
          },
          {
            icon: 'info.svg',
            title: 'Program Details',
            action: 'details'
          },
          {
            icon: 'share.svg',
            title: 'Share Program',
            action: 'share'
          },
          {
            icon: 'print.svg',
            title: 'Print Program',
            action: 'print'
          }
        ],
        "album": [
          {
            icon: 'play.svg',
            title: 'Play Now',
            action: 'play'
          },
          {
            icon: 'tooltip-plus.svg',
            title: 'Add to Playlist',
            action: 'playlist'
          },
          {
            icon: 'info.svg',
            title: 'Album Details',
            action: 'details'
          },
          {
            icon: 'share.svg',
            title: 'Share Album',
            action: 'share'
          },
          {
            icon: 'buy.svg',
            title: 'Buy This Music',
            action: 'buy'
          }
        ],
        "track": [
          {
            icon: 'play.svg',
            title: 'Play Now',
            action: 'play'
          },
          {
            icon: 'tooltip-plus.svg',
            title: 'Add to Playlist',
            action: 'playlist'
          },
          {
            icon: 'info.svg',
            title: 'Album Details',
            action: 'details'
          },
          {
            icon: 'share.svg',
            title: 'Share Album',
            action: 'share'
          },
          {
            icon: 'buy.svg',
            title: 'Buy This Music',
            action: 'buy'
          }
        ],
        "playlist": [
          {
            icon: 'play.svg',
            title: 'Play Now',
            action: 'play'
          },
          {
            icon: 'share.svg',
            title: 'Share Playlist',
            action: 'share'
          },
          {
            icon: 'print.svg',
            title: 'Print Playlist',
            action: 'print'
          }
        ]
      }
    ;
    return defaultActionsList[itemType];
  }

  backdropClicked() {
    this.lostFocus(undefined);
  }

  printProgram(programId) {
    this.loadingHelperService.startLoading('programPopupOverlayDownloadLoading');

    this.downloadObs = this.programsService.downloadProgram(programId)
      .subscribe(value => {
          // console.log('downloaded');
          this.fileSaverService.save(value, 'Program_' + programId + '.pdf');
          this.loadingHelperService.loadingOK('programPopupOverlayDownloadLoading');
        },
        error => {
          hoslog('printProgram error: ' + JSON.stringify(error));
          this.loadingHelperService.loadingKO(error, 'programPopupOverlayDownloadLoading');
        });
  }

  printPlaylist(playlistId) {
    this.loadingHelperService.startLoading('playlistPopupOverlayDownloadLoading');

    this.downloadObs = this.playlistsService.downloadPlaylist(playlistId)
      .subscribe(value => {
          // console.log('downloaded');
          this.fileSaverService.save(value, 'Playlist_' + playlistId + '.pdf');
          this.loadingHelperService.loadingOK('playlistPopupOverlayDownloadLoading');
        },
        error => {
          hoslog('printPlaylist error: ' + JSON.stringify(error));
          this.loadingHelperService.loadingKO(error, 'playlistPopupOverlayDownloadLoading');
        });
  }
}
