/**
 * HOS API
 *
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 *
 *
 *
 */

import {MessageFromTheAdministrationToTheUsers} from './MessageFromTheAdministrationToTheUsers';
import {PermissionContext} from './PermissionContext';


export interface Settings {
  /**
   * Startup page
   */
  startUpPage: Settings.StartUpPageEnum;

  /**
   * Signin content
   */
  signInContent: Settings.SignInContentEnum;

  /**
   * SigIn content channel Id
   */
  signInContentChannelId?: number;

  /**
   * Hemisphere
   */
  hemisphere: Settings.HemisphereEnum;

  /**
   * Receive weekly playlist
   */
  receiveHosWeeklyPlaylist: boolean;

  /**
   * Receive HoS newsletter
   */
  receiveHosNewsletter: boolean;

  /**
   * Receive HoS discount offers
   */
  receiveHosDiscountOffers: boolean;

  /**
   * Permissions for the current user
   */
  permissionContexts: Array<PermissionContext>;

  /**
   * Server message for the current user
   */
  serverMessage: MessageFromTheAdministrationToTheUsers;

}
export namespace Settings {
  export enum StartUpPageEnum {
    Recent = <any> 'recent',
    ThisWeek = <any> 'this_week',
    Programs = <any> 'programs',
    Channels = <any> 'channels',
    Albums = <any> 'albums',
    MyPlaylists = <any> 'my_playlists',
    Favorites = <any> 'favorites',
    Home = <any> 'home'
  }
  export enum SignInContentEnum {
    ThisWeekShow = <any> 'this_week_show',
    ChooseChannel = <any> 'choose_channel',
    ResumeLastStream = <any> 'resume_last_stream'
  }
  export enum HemisphereEnum {
    Northern = <any> 'northern',
    Southern = <any> 'southern'
  }
}

export enum StreamingQualityEnum {
  StreamingQualityBest,
  StreamingQualityNormal,
  StreamingQualityEconomy
}
