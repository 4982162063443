import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Channel} from '../api-client';

@Injectable()
export class ChannelsListService implements OnDestroy {

  public channels$ = new BehaviorSubject<Channel[]>([]);

  constructor() {

  }

  ngOnDestroy(): void {

  }
}
