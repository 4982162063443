import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'parseJson'
})
export class ParseJsonPipe implements PipeTransform {

  transform(input: string): any {
    return JSON.parse(input)
  }

}

