<a (click)="handlePopup($event)" class="pointer" title="view playlist"><img src="/assets/images/eye-condensed.svg" alt="" aria-hidden="true" tabindex="-1"></a>

<ng-container *ngIf="isOpened">
  <div class="peek-overlay" (click)="close($event)"></div>
  <div class="tooltip-wrapper" aria-hidden="true" tabindex="-1">
    <div class="custom-tooltip" [class.in]="isOpened" (click)="preventRedirect($event)">
      <div class="tooltip-arrow"></div>
      <div class="peek-tooltip-inner">

        <div (click)="close($event)" class="close-tooltip">
          <img src="/assets/images/close.png" alt="">
        </div>

        <div class="tooltip-content">
          <div *ngIf="loadingHelperService.isLoading('PROGRAM_PEEK_DETAIL'); else loaded">
            LOADING...
          </div>
          <ng-template #loaded>
            <app-small-album-item *ngFor="let item of data?.albums" [smallAlbumItem]="item"></app-small-album-item>
          </ng-template>
        </div>

      </div>
    </div>
  </div>
</ng-container>
