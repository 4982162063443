import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'convertToUrl'
})
export class ConvertToUrlPipe implements PipeTransform {

  transform(urlListTxt: string): any {
    if (urlListTxt) {
      const urls = urlListTxt.split(';');
      if (urls && urls.length > 0) {
        const url = urls[0];
        return (url.toLowerCase().startsWith('http') ? '' : 'http://') + url;
      }
    }
    return "";
  }

}
