<div class="app-modal">
  <div class="modal-title" *ngIf="title">
    {{title}}
  </div>
  <div class="modal-body {{bodyClass ? bodyClass : ''}}">
    <p [innerHtml]="message" *ngIf="!showMoreMessage"></p>
    <a class="link-active-white more" (click)="onMoreClicked()" *ngIf="!showMoreMessage">more</a>
    <p class="scrolling-text" [innerHtml]="moreMessage" *ngIf="showMoreMessage"></p>
  </div>
  <div class="modal-actions">
    <button type="button" name="button" (click)="onCancel()" *ngIf="showCancel">{{cancelLabel}}</button>
    <button type="button" name="button" class="action-button" (click)="onOk()" *ngIf="showOK">{{okLabel}}</button>
  </div>
</div>
<div class="modal-background">
</div>
