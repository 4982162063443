import {Component, Input, OnInit} from '@angular/core';
import {ErrorsService} from '../../services/errors.service';

@Component({
  selector: 'app-no-data-in-table',
  templateUrl: './no-data-in-table.component.html',
  styleUrls: ['./no-data-in-table.component.scss']
})
export class NoDataInTableComponent implements OnInit {

  @Input() errorName: string;

  constructor(public errorsService: ErrorsService) { }

  ngOnInit() {
  }

}
