import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: '[app-order-header]',
  templateUrl: './order-header.component.html',
  styleUrls: ['./order-header.component.scss']
})
export class OrderHeaderComponent implements OnInit {

  @Input() label = "";
  @Input() sortName = null;

  @Input() activeSortName = null;
  @Input() activeSortDirectionAsc = true;

  @Output() sortClicked = new EventEmitter<string>();

  public enabled = false;

  constructor() { }

  ngOnInit() {
    if (this.sortName != null) {
      this.enabled = true;
    }
  }

  public headerSortClicked() {
    if (this.enabled) {
      this.sortClicked.emit(this.sortName);
    }
  }

}
