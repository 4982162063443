/**
 * HOS API
 *
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 *
 *
 *
 */

export enum PermissionContextActionsEnum {
  View = <any> 'view',
  Add = <any> 'add',
  Remove = <any> 'remove',
  On = <any> 'on',
  Off = <any> 'off',
}


export interface PermissionContext {
    /**
     * Context name
     */
    name: string;

    /**
     * Actions allowed
     */
    actions: Array<PermissionContextActionsEnum>;

}
export namespace PermissionContext {

}
