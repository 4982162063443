/**
 * HOS API
 *
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 *
 *
 *
 */


export interface Artist {
  /**
   * Artist id
   */
  id: number;

  /**
   * Name
   */
  name: string;

  /**
   * Description
   */
  description?: string;

  /**
   * URL
   */
  url?: string;

  /**
   * Email
   */
  email?: string;

}
