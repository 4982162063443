/**
 * HOS API
 *
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 *
 *
 *
 */



export interface SessionToken {
    /**
     * Session token
     */
    sessionToken?: string;

    /**
     * Token type (default Bearer)
     */
    tokenType?: SessionToken.TokenTypeEnum;

    /**
     * Validity seconds left
     */
    expiresIn?: number;

}
export namespace SessionToken {
    export enum TokenTypeEnum {
        Bearer = <any> 'Bearer'
    }
}
