<div class="bg-container {{backgroundService.nextBgClass}}"></div>
<div>
  <header>
    <div class="logo-box">
      <a routerLink="/home">
        <div>
          <img src="/assets/images/hos-logo-white.svg" alt="Welcome to Hearts of Space">
          SLOW MUSIC FOR FAST TIMES
        </div>
      </a>
    </div>
    <app-header *ngIf="!(gs.loginFormVisible$ | async)" [class.logged-header]="loginHelperService.isLoggedIn$ | async"></app-header>
    <app-search *ngIf="!(gs.loginFormVisible$ | async)"></app-search>
    <app-auth [class.login-form]="(gs.loginFormVisible$ | async)" *ngIf="(gs.loginFormVisible$ | async)"></app-auth>
    <div class="top-header-action" *ngIf="!(loginHelperService.isLoggedIn$ | async)">
      <button type="button" name="sign-out" *ngIf="(gs.loginFormVisible$ | async)" (click)="gs.hideLoginForm()" aria-label="Cancel sign in">CANCEL</button>
      <button type="button" name="sign-in" *ngIf="!(gs.loginFormVisible$ | async)" (click)="gs.showLoginForm()" aria-label="Sign in">SIGN IN</button>
    </div>
  </header>
  <div class="main-container {{deviceService.browser.toLowerCase()}}">
    <app-navigation></app-navigation>
    <main aria-live="polite">
      <div id="mainContainer" class="content-container">
        <ng-template top-bar-host></ng-template>
        <div #contentContainer class="router-container" [ngClass]="{'no-scroll': (!(gs.pageScroll$ | async) || (gs.peekPopup$ | async)), 'extra-top-bar': (extraTopContentAdded$ | async)}">
          <router-outlet></router-outlet>
        </div>
        <modal-placeholder></modal-placeholder>
      </div>
    </main>
  </div>
  <app-player></app-player>
</div>
