<div class="col-lg-12 no-indents">
  <div class="item-img">
    <img [src]="item.id | HosAlbumImageUrl:80" alt="{{item.title}}" aria-hidden="true">
<!--    <p class="text-uppercase">{{ item.title }}</p>-->
  </div>
  <div class="item-content">
    <p class="artists-name" *ngIf="item.attribution">{{item.attribution}}</p>
    <ng-container *ngIf="!item.attribution">
      <ng-container *ngIf="item.tracks && item.tracks.length>0 && item.tracks[0]['trackArtists'] && item.tracks[0]['trackArtists'].length>0">
        <p class="artists-name">
          <span class="" [title]="item.tracks[0]['trackArtists']">{{ item.tracks[0]['trackArtists'] }}</span>
        </p>
      </ng-container>

      <ng-container *ngIf="!(item.tracks && item.tracks.length>0 && item.tracks[0]['trackArtists'] && item.tracks[0]['trackArtists'].length>0)">
        <p class="artists-name" *ngFor="let artist of item.artists"><span class="" [title]="artist.name">{{ artist.name }}</span></p>
      </ng-container>

    </ng-container>
    <p class="album-name text-uppercase">{{ item.title }}<span *ngIf="isCompilation(item)">&nbsp;(compilation)</span></p>

    <table>
      <thead>
      <tr>
        <th></th>
        <th></th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let track of item.tracks">
        <td>{{ track.title }}</td>
        <td>{{ track.startPositionInStream | secondsToMinutes | playerExtraCutPipe}}</td>
        <td>{{ (track.startPositionInStream + track.duration) | secondsToMinutes | playerExtraCutPipe}}</td>
      </tr>
      </tbody>
    </table>

  </div>
</div>
