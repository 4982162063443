import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-rating-stars',
  templateUrl: './rating-stars.component.html',
  styleUrls: ['./rating-stars.component.scss']
})
export class RatingStarsComponent implements OnInit {

  @Input() rating: number;
  @Input() itemId: number;
  @Output() ratingClick: EventEmitter<any> = new EventEmitter<any>();
  @Input() enabled: boolean = true;

  public inputName: string;

  constructor() {
  }

  ngOnInit() {
    this.inputName = this.itemId + '_rating_' + Math.random(); // to avoid clashes in case the same item is in the list
  }

  onClick(rating: number): void {
    this.ratingClick.emit({
      itemId: this.itemId,
      rating: rating
    });
  }
}
