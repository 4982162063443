import { Component, OnInit, Input } from '@angular/core';
import {Album} from '../../../../api-client';
import {PlayButtonIconSize} from '../../../../app.constants';

@Component({
  selector: 'app-search-album-item',
  templateUrl: './search-album-item.component.html',
  styleUrls: ['./search-album-item.component.scss']
})
export class SearchAlbumItemComponent implements OnInit {
  @Input('item') item: Album;

  playButtonIconSize = PlayButtonIconSize;

  constructor() { }

  ngOnInit() {
  }
}
