import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'columnize'
})
export class ColumnizePipe implements PipeTransform {

  transform(value: any, columns: number): any {
    var arr = [];
    for (let i = 0; i < value.length; i++) {
      var colIdx = i % columns;
      arr[colIdx] = arr[colIdx] || [];
      arr[colIdx].push(value[i]);
    }
    return arr;
  }

}
