<div id="container-content-album">
  <div id="container-router">
    <div class="container-fluid inner-component-container">
      <app-loading *ngIf="loadingHelperService.isLoading();else loaded"></app-loading>
      <ng-template #loaded>
        <app-error-message [error]="loadingHelperService.getError()" *ngIf="loadingHelperService.hasError()" [showTryAgain]="true"
                           (tryAgain)="refresh()"></app-error-message>

        <div *ngIf="data">
          <div class="row">
            <div class="col-sm-3 col-md-3 col-lg-3 album-cover">
              <img [src]="data.id | HosAlbumImageUrl:400" alt="{{data.title}}" aria-hidden="true">
            </div>
            <div class="col-sm-9 col-md-9 col-lg-9 album-details">
              <div class="album-play-title" tabindex="0" role="group" attr.aria-label="{{data.title}}">
                <app-play-album-button [iconSize]="playButtonIconSize.Big" [item]="data" [enabled]="data.playable == true"></app-play-album-button>
                <div class="album-title">
                  <h2>{{data.title}}</h2>
                  <h3 class="white-big" *ngFor="let artist of data?.artists; let i = index">
                    {{ artist.name }}
                  </h3>
                  <h3 class="grey-small" *ngIf="!multipleArtists && data.label"><span *ngIf="data.date">{{data.date | date:'y'}} - </span>{{data.label.name}}</h3>
                </div>
              </div>
              <div class="album-description" role="group" tabindex="0" *ngIf="data.description">
                <div>
                  <p [ngClass]="{'truncated-details':!detailsExpanded}" (click)="detailsExpand()" [innerHtml]="detailsExpanded ? data.description : truncatedDescription"></p>
                </div>
              </div>
            </div>
          </div>
          <div class="row" role="group" tabindex="0">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="row album-actions">
                <div class="pull-left">
                  <div class="flex-wrapper">
                    <div>
                      <app-block-button [item]="data" [enabled]="data.playable == true"></app-block-button>
                    </div>
                    <div>
                      <app-rating [item]="data" [enabled]="data.playable == true"></app-rating>
                    </div>
                  </div>
                </div>
                <div class="pull-left">
                  <app-popularity [popularity]="data.popularity"></app-popularity>
                </div>
                <div class="pull-left">
                  <app-option-popup [item]="data" [detail]="true" [canPlay]="data.playable == true">
                    <img src="/assets/images/ellipsis.svg" alt="" aria-hidden="true">
                  </app-option-popup>
                </div>
                <div class="pull-left" (click)="share()">
                  <img src="/assets/images/share.svg" class="share-image-button" alt="Share album" aria-hidden="true">
                  <span>SHARE</span>
                </div>
                <div class="pull-left" *ngIf="data?.label?.url">
<!--                  <span>LINKS:</span><div class="url"><a [href]="data.label.url | convertToUrl" target="_blank">{{data.label.url | convertToUrl}}</a></div>-->
                  <div class="url">
                    <a [href]="data.artists[0].url | convertToUrl" class="link-active-white" *ngIf="data.artists && data.artists.length > 0 && data.artists[0].url" target="_blank">Artist</a>
                    <a disabled="true" class="link-disabled" *ngIf="!data.artists || data.artists.length === 0 || !data.artists[0].url">Artist</a>
                  </div>
                  <div class="url">
                    <a [href]="data.label.url | convertToUrl" class="link-active-white" target="_blank" *ngIf="data.label?.url">Label</a>
                    <a disabled="true" class="link-disabled" *ngIf="!(data.label?.url)">Label</a>
                  </div>
                  <div class="url">
                    <a [href]="data.buyCdUrl | convertToUrl" class="link-active-white" target="_blank" *ngIf="data.buyCdUrl">Buy This Music</a>
                    <a disabled="true" class="link-disabled" *ngIf="!data.buyCdUrl">Buy This Music</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row table-container" role="group" tabindex="0" aria-label="Tracks in the album" *ngIf="data && data.tracks && data.tracks.length > 0">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <table [class.extended-table]="multipleArtists">
                <thead>
                <tr>
                  <th app-order-header label="#" sortName="no" [activeSortName]="orderBy" [activeSortDirectionAsc]="orderDirectionAsc" (sortClicked)="headerSortClicked($event)" class="text-center"></th>
                  <th class="play"></th>
                  <th app-order-header label="TRACK" sortName="title" [activeSortName]="orderBy" [activeSortDirectionAsc]="orderDirectionAsc" (sortClicked)="headerSortClicked($event)"></th>
                  <th app-order-header *ngIf="multipleArtists" label="ARTIST" sortName="artists" [activeSortName]="orderBy" [activeSortDirectionAsc]="orderDirectionAsc" (sortClicked)="headerSortClicked($event)"></th>
                  <th app-order-header label="TIME" sortName="duration" [activeSortName]="orderBy" [activeSortDirectionAsc]="orderDirectionAsc" (sortClicked)="headerSortClicked($event)"></th>
                  <th app-order-header label="MY FAVORITES" sortName="rating" [activeSortName]="orderBy" [activeSortDirectionAsc]="orderDirectionAsc" (sortClicked)="headerSortClicked($event)"></th>
                  <th app-order-header label="POPULARITY" sortName="popularity" [activeSortName]="orderBy" [activeSortDirectionAsc]="orderDirectionAsc" (sortClicked)="headerSortClicked($event)"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of data?.tracks; let i = index" [no]="i + 1" [trackItem]="item" [trackAlbum]="data" [extData]="(multipleArtists) ? true : false"
                    [multipleArtists]="multipleArtists">
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
