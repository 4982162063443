import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search',
  pure: false
})
export class SearchPipe implements PipeTransform {

  transform(value: any, term: string, wasSelected: boolean): any {
    if (term && !wasSelected) {
      return value.filter((item) => item.toLowerCase().startsWith(term.toLowerCase()));
    } else {
      return value;
    }
  }

}
