/**
 * HOS API
 *
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 */
import {Injectable, Injector} from "@angular/core";
import {HosBaseService} from "./hos-base.service";

import {HttpResponse, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import { map } from 'rxjs/operators';
import "../rxjs-operators";
import {AppConstants} from "../../app.constants";
import {News} from "../model/News";
import {Page} from '../model/generic/page';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class NewsService extends HosBaseService {

  constructor(injector: Injector) {
    super(injector);
  }

  /**
   * Return the news
   *
   * @param firstResult First results
   * @param maxResults Max results
   */
  public getNews(firstResult?: number, maxResults?: number, extraHttpRequestParams?: any): Observable<Page<News>> {
    return this.getNewsWithHttpInfo(firstResult, maxResults, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Return the news detail
   *
   * @param newsId The news id
   */
  public getNewsDetail(newsId: number, extraHttpRequestParams?: any): Observable<News> {
    return this.getNewsDetailWithHttpInfo(newsId, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }


  /**
   * Return the news
   *
   * @param firstResult First results
   * @param maxResults Max results
   */
  public getNewsWithHttpInfo(firstResult?: number, maxResults?: number, extraHttpRequestParams?: any): Observable<HttpResponse<Page<News>>> {
    const path = this.getBasePath() + `/news`;

    let queryParameters = new HttpParams();
    let headers = this.getNoLoginHeaders();

    if (firstResult !== undefined) {
      queryParameters = queryParameters.set('firstResult', <any>firstResult);
    }
    if (maxResults !== undefined) {
      queryParameters = queryParameters.set('maxResults', <any>maxResults);
    }


    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];


    let requestOptions = {
      headers: headers,
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.GET, path, requestOptions);
  }

  /**
   * Return the news detail
   *
   * @param newsId The news id
   */
  public getNewsDetailWithHttpInfo(newsId: number, extraHttpRequestParams?: any): Observable<HttpResponse<News>> {
    const path = this.getBasePath() + `/news/${newsId}`;

    let queryParameters = new HttpParams();
    let headers = this.getNoLoginHeaders();

    // verify required parameter 'newsId' is not null or undefined
    if (newsId === null || newsId === undefined) {
      throw new Error('Required parameter newsId was null or undefined when calling getNewsDetail.');
    }


    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];


    let requestOptions = {
      headers: headers,
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.GET, path, requestOptions);
  }

}
