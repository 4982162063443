import {Injectable} from '@angular/core';
import {CoolLocalStorage} from '@angular-cool/storage';
import {Plan, PlanCost} from '../api-client';

@Injectable()
export class AppStorageService {
  private static planKey = 'plan';
  private static priceKey = 'price';

  readOnly = true;

  constructor(private localStorage: CoolLocalStorage) {
  }

  hasInfo(): boolean {
    return this.getPlanStored() != null;
  }

  getPlanStored(): {plan: Plan, price: PlanCost} {
    const planStr = this.localStorage.getItem(AppStorageService.planKey);
    const priceStr = this.localStorage.getItem(AppStorageService.priceKey);
    if (planStr && priceStr) {
      const plan = JSON.parse(planStr) as Plan;
      const price = JSON.parse(priceStr) as PlanCost;
      return {
        plan: plan,
        price: price
      };
    }
    return null;
  }

  setPlanStored(plan: Plan, price: PlanCost) {
    if (plan && price) {
      const planStr = JSON.stringify(plan);
      this.localStorage.setItem(AppStorageService.planKey, planStr);
      const priceStr = JSON.stringify(price);
      this.localStorage.setItem(AppStorageService.priceKey, priceStr);
    }
  }

  clear() {
    this.localStorage.removeItem(AppStorageService.planKey);
    this.localStorage.removeItem(AppStorageService.priceKey);
  }
}
