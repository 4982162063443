import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'secondsToHours'
})
export class SecondsToHoursPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let minutes = Math.floor(value / 60);
    let hours = Math.floor(minutes / 60);
    let minutesShown = minutes - (hours * 60);
    let finalTime: string = null;
    if (hours > 999) {
      finalTime = this.str_pad_left(hours, '0', 4) + ':' + this.str_pad_left(minutesShown, '0', 2);
    } else if (hours > 99) {
      finalTime = this.str_pad_left(hours, '0', 3) + ':' + this.str_pad_left(minutesShown, '0', 2);
    } else {
      finalTime = this.str_pad_left(hours, '0', 2) + ':' + this.str_pad_left(minutesShown, '0', 2);
    }

    return finalTime;
  }

  private str_pad_left(string, pad, length) {
    return (new Array(length + 1).join(pad) + string).slice(-length);
  }

}
