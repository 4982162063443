import {Component, OnInit} from '@angular/core';
import {Modal} from "../../modal.service";
import {PlaylistsService} from "../../../api-client/api/playlists.service";
import {Playlist} from "../../../api-client/model/Playlist";
import {LoadingHelperService} from "../../loading-helper/loading-helper.service";
import {HosItem} from "../../../api-client/model/HosItem";
import {AppConstants, hoslog} from "../../../app.constants";
import {PlayerUtilsService} from '../../utils/player-utils';
import {AlertsUtilService} from '../../../services/alerts-util.service';
import {PlaylistsUtilsService} from '../../../services/playlists-utils.service';
import {ToastrService} from 'ngx-toastr';
import * as moment from 'moment';

@Component({
  selector: 'app-select-playlist-dialog',
  templateUrl: './select-playlist-dialog.component.html',
  styleUrls: ['./select-playlist-dialog.component.scss']
})
@Modal()
export class SelectPlaylistDialogComponent implements OnInit {
  public playlists: Playlist[] = [];

  item: HosItem;

  ok: Function;
  cancel: Function;
  destroy: Function;
  closeModal: Function;

  private lastAttemptToSavePlaylist: Playlist;

  onCancel(noPlaylists: boolean = false): void {
    if (this.closeModal) this.closeModal();
    this.destroy();
    if (this.cancel) this.cancel(noPlaylists);
  }

  public onOk(playlist: Playlist): void {
    if (this.closeModal) this.closeModal();
    this.destroy();
    if (this.ok) this.ok(playlist);
  }

  constructor(private playlistsService: PlaylistsService,
              private playlistsUtilsService: PlaylistsUtilsService,
              private playerUtilsService: PlayerUtilsService,
              private alertsUtilService: AlertsUtilService,
              private toastr: ToastrService,
              public loadingHelperService: LoadingHelperService) {
  }

  ngOnInit() {
    this.loadPlaylists();
  }

  public loadPlaylists() {
    this.loadingHelperService.startLoading('PLAYLIST_MODAL');
    this.playlistsService.getPlaylists()
      .subscribe(
        res => {
          hoslog("getPlaylists loaded");
          this.playlists = res;
          this.reorder(this.playlists);
          this.loadingHelperService.loadingOK('PLAYLIST_MODAL');
          if (res.length === 0) { // if no playlists
            // close saying there is no playlist
            this.onCancel(true);
          }
        },
        error => {
          hoslog("getPlaylists error: " + JSON.stringify(error));
          this.loadingHelperService.loadingKO(error, 'PLAYLIST_MODAL');
        });
  }

  public saveToPlaylist(playlist: Playlist) {
    hoslog('Saving item: ' + JSON.stringify(this.item));
    this.lastAttemptToSavePlaylist = playlist;
    this.loadingHelperService.startLoading('ADD_TO_PLAYLIST_MODAL');
    this.playlistsService.addItemToPlaylist(playlist.id, this.item.type.toString(), this.item.id)
      .subscribe(
        res => {
          hoslog("addItemToPlaylist loaded");
          this.onOk(playlist);
          this.loadingHelperService.loadingOK('ADD_TO_PLAYLIST_MODAL');
          this.toastr.success('Item added to the playlist');
          this.playlistsUtilsService.setPlaylistEdited(playlist);
          if (this.playerUtilsService.isPlaylistPlaying(playlist.id)) {
            hoslog('Playing playlist edited!');
            this.showRestartPlaylistAlert(playlist.id);
          }
        },
        error => {
          hoslog("addItemToPlaylist error: " + JSON.stringify(error));
          this.loadingHelperService.loadingKO(error, 'ADD_TO_PLAYLIST_MODAL');
          this.toastr.error('An error happened while adding the item to the playlist');
        });
  }

  public reTrySaveToPlaylist() {
    if (this.lastAttemptToSavePlaylist) {
      this.saveToPlaylist(this.lastAttemptToSavePlaylist);
    }
  }

  showRestartPlaylistAlert(playlistId: number) {
    this.alertsUtilService.showConfirmationDialog(AppConstants.EditPlayingPlaylistAlertMsg,
      "RESTART PLAYLIST", "OK",
      () => {
        hoslog('Restarting the current playlist');
        this.refreshPlaylist(playlistId);
      });
  }

  refreshPlaylist(playlistId: number) {
    this.loadingHelperService.startLoading('ADD_TO_PLAYLIST_MODAL');
    this.playlistsService.getPlaylistDetail(playlistId)
      .subscribe(
        res => {
          hoslog("Playlist " + playlistId + " loaded");
          this.playerUtilsService.restartPlaylist(res);
          this.loadingHelperService.loadingOK('ADD_TO_PLAYLIST_MODAL');
        },
        error => {
          hoslog("Playlist " + playlistId + " error: " + JSON.stringify(error));
          this.loadingHelperService.loadingKO(error, 'PLAYLIST');
        });
  }

  reorder(data) {
    const orderByValue = 'updatedTime';
    data.sort((a, b) => {
      let v1 = '';
      let v2 = '';
      const va = a[orderByValue];
      const vb = b[orderByValue];

      if (va) {
        const mVa = moment(va);
        if (mVa.isValid()) {
          v1 = mVa.unix().toString();
        }
      }
      if (vb) {
        const mVb = moment(vb);
        if (mVb.isValid()) {
          v2 = mVb.unix().toString();
        }
      }

      // orderBy direction desc
      if (v1 < v2) { return 1; }
      if (v1 > v2) { return -1; }
      return 0;
    });
  }
}
