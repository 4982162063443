import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-drop-down-category-list',
  templateUrl: './drop-down-category-list.component.html',
  styleUrls: ['./drop-down-category-list.component.scss']
})
export class DropDownCategoryListComponent implements OnInit {

  @Input() data: any;
  @Input() linkPath1: string = "programs";
  @Input() linkPath2: string;
  @Input() ariaItemLabel: string;
  @Input() ariaItemDescription: string;

  @Input() selectedName: string;
  @Input() selectedId: string;

  visible: boolean = false;

  constructor() { }

  ngOnInit() {
    // console.log('data: ' + JSON.stringify(this.data));
  }

  toggle() {
    this.visible = !this.visible;
  }

}
