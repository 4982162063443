import {AuthConfig} from 'angular-oauth2-oidc';
import {environment} from '../../../environments/environment';

export const authPasswordFlowConfig: AuthConfig = {
  // Url of the Identity Provider
  issuer: environment.oauth.issuer,

  // useHttpBasicAuthForPasswordFlow: false,
  tokenEndpoint: environment.oauth.tokenEndpoint,

  requireHttps: environment.production ? 'remoteOnly' : false,

  // URL of the SPA to redirect the user to after login
  redirectUri: null, // window.location.origin + '/index.html',

  // URL of the SPA to redirect the user after silent refresh
  silentRefreshRedirectUri: null,

  // The SPA's id. The SPA is registerd with this id at the auth-server
  clientId: 'hos-web-app-client',

  dummyClientSecret: '96d0c39b-0e21-414b-8ce0-c57fa749184b',

  // set the scope for the permissions the client should request
  // The first three are defined by OIDC. The 4th is a usecase-specific one
  scope: 'write read',

  showDebugInformation: !environment.production,

  oidc: false,

  requestAccessToken: true
};
