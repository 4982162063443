import {Injectable} from '@angular/core';
import {User} from "../api-client/model/User";
import {AccountService} from "../api-client/api/account.service";
import {LoggedUserService} from "../api-client/helper/logged-user.service";
import {HosItem} from "../api-client/model/HosItem";
import {Observable, BehaviorSubject} from "rxjs";
import {tap} from "rxjs/operators";
import {TracksService} from "../api-client/api/tracks.service";
import {AlbumsService} from "../api-client/api/albums.service";
import {ProgramsService} from "../api-client/api/programs.service";
import {hoslog} from "../app.constants";
import {SubSink} from 'subsink';
import {ToastrService} from 'ngx-toastr';

class BlockedItemsWrapper {
  programs: HosItem[];
  albums: HosItem[];
  tracks: HosItem[];
}

@Injectable()
export class LoggedUserBlockedItemsService {
  private subs = new SubSink();

  private blockedItems: BlockedItemsWrapper;
  private _blockedItemsObs: BehaviorSubject<BlockedItemsWrapper>;

  constructor(private loggedUserService: LoggedUserService,
              private accountService: AccountService,
              private programsService: ProgramsService,
              private albumsService: AlbumsService,
              private toastr: ToastrService,
              private tracksService: TracksService) {
    this.blockedItems = {
      programs: [],
      albums: [],
      tracks: []
    };

    this._blockedItemsObs = new BehaviorSubject(this.blockedItems);
    this.subs.sink = this.loggedUserService.loggedUserDetailsObs.subscribe(
      user => {
        if (user != null) {
          // New user, getting account data
          this.refreshUserData(user);
        } else {
          this.blockedItems = {
            programs: [],
            albums: [],
            tracks: []
          };
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private refreshUserData(user: User) {
    // Getting the blocked items
    this.accountService.getBlockedItems("program")
      .subscribe(
        res => {
          hoslog("BlockedItems programs loaded");
          this.blockedItems.programs = res;
          this.setBlockedItems(this.blockedItems);
        },
        error => {
          if (error.status != 404) {
            hoslog("BlockedItems programs: error: " + JSON.stringify(error));
            // TODO schedule a refresh?
          } else {
            hoslog("BlockedItems programs: no programs blocked");
          }
        });

    this.accountService.getBlockedItems("album")
      .subscribe(
        res => {
          hoslog("BlockedItems albums loaded");
          this.blockedItems.albums = res;
          this.setBlockedItems(this.blockedItems);
        },
        error => {
          if (error.status != 404) {
            hoslog("BlockedItems albums: error: " + JSON.stringify(error));
            // TODO schedule a refresh?
          } else {
            hoslog("BlockedItems albums: no albums blocked");
          }
        });

    this.accountService.getBlockedItems("track")
      .subscribe(
        res => {
          hoslog("BlockedItems tracks loaded");
          this.blockedItems.tracks = res;
          this.setBlockedItems(this.blockedItems);
        },
        error => {
          if (error.status != 404) {
            hoslog("BlockedItems tracks: error: " + JSON.stringify(error));
            // TODO schedule a refresh?
          } else {
            hoslog("BlockedItems tracks: no tracks blocked");
          }
        });
  }

  get blockedItemsObs(): BehaviorSubject<BlockedItemsWrapper> {
    return this._blockedItemsObs;
  }

  private setBlockedItems(bi: BlockedItemsWrapper) {
    this.blockedItems = bi;
    this.notifyObservers();
  }

  private notifyObservers() {
    this._blockedItemsObs.next(this.blockedItems);
  }

  public refreshBlockedItems() {
    if (this.loggedUserService.loggedUserDetailsObs.value != null) {
      this.refreshUserData(this.loggedUserService.loggedUserDetailsObs.value);
    }
  }

  public isBlocked(item: HosItem): boolean {
    return this.findItem(item) != null;
  }

  private getItemArrayName(item: HosItem): string {
    let itemArrayName = "";
    switch (item.type) {
      case HosItem.TypeEnum.Program:
        itemArrayName = 'programs';
        break;
      case HosItem.TypeEnum.Album:
        itemArrayName = 'albums';
        break;
      case HosItem.TypeEnum.Track:
        itemArrayName = 'tracks';
        break;
    }
    return itemArrayName;
  }

  private findItem(item: HosItem): HosItem {
    let itemArrayName = this.getItemArrayName(item);

    for (let itm of this.blockedItems[itemArrayName]) {
      if (itm.id === item.id) {
        return itm;
      }
    }
    // Not found
    return null;
  }

  public block(item: HosItem): Observable<{}> {
    if (item.type == HosItem.TypeEnum.Program) {
      return this.programsService.markBlocked(item.id).pipe(tap(() => this.addItem("programs", item)));
    } else if (item.type == HosItem.TypeEnum.Album) {
      return this.albumsService.markBlocked(item.id).pipe(tap(() => this.addItem("albums", item)));
    } else if (item.type == HosItem.TypeEnum.Track) {
      return this.tracksService.markBlocked(item.id).pipe(tap(() => this.addItem("tracks", item)))
    }
  }

  public unblock(item: HosItem): Observable<{}> {
    if (item.type == HosItem.TypeEnum.Program) {
      return this.programsService.unmarkBlocked(item.id).pipe(tap(() => this.removeItem("programs", item)));
    } else if (item.type == HosItem.TypeEnum.Album) {
      return this.albumsService.unmarkBlocked(item.id).pipe(tap(() => this.removeItem("albums", item)));
    } else if (item.type == HosItem.TypeEnum.Track) {
      return this.tracksService.unmarkBlocked(item.id).pipe(tap(() => this.removeItem("tracks", item)))
    }
  }

  private addItem(itemArrayName: string, item: HosItem) {
    if (!this.isBlocked(item)) {
      this.blockedItems[itemArrayName].push(item);
      this.toastr.success('Item blocked');
      this.notifyObservers();
    }
  }

  private removeItem(itemArrayName: string, item: HosItem) {
    var index = this.blockedItems[itemArrayName].findIndex(i => i.id == item.id);
    if (index > -1) {
      this.blockedItems[itemArrayName].splice(index, 1);
      this.toastr.success('Item unblocked');
      this.notifyObservers();
    }
  }
}
