/**
 * HOS API
 *
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 *
 *
 *
 */



export interface Producer {
    /**
     * Producer Id
     */
    id: number;

    /**
     * Name
     */
    name: string;

    /**
     * First name
     */
    firstName?: string;

    /**
     * Last name
     */
    lastName?: string;

}
