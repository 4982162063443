/**
 * HOS API
 *
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 *
 *
 *
 */

import { Faq } from './Faq';


export interface FaqSection {
    /**
     * Id
     */
    id: number;

    /**
     * Name
     */
    name: string;

    description: string;

    /**
     * Ordering
     */
    ordering?: number;

    /**
     * Faqs
     */
    faqs?: Array<Faq>;

}
