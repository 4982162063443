<td>
  <app-play-album-button [iconSize]="playButtonIconSize.Medium" [item]="item" extraClasses="album-item-play-button" [enabled]="item.playable !== false"></app-play-album-button>
  <a [routerLink]="['/albums/details', item.id]" [queryParams]="queryParams" aria-hidden="true" tabindex="-1">
    <img [src]="item.id | HosAlbumImageUrl:80" alt="{{item.title}}" aria-hidden="true">
  </a>
</td>
<td [routerLink]="['/albums/details', item.id]" [queryParams]="queryParams" tabindex="0" role="button" attr.aria-label="{{ item.title }}">
  <p class="artist-name" *ngIf="item.attribution"><a (click)="searchArtist($event, item.attribution); $event.stopPropagation()" class="white-link">{{ item.attribution }}</a></p>
  <span>{{ item.title }}</span>
  <div class="peek-wrapper">
    <app-album-peek-popup [albumId]="item.id"></app-album-peek-popup>
  </div>
</td>
<td [routerLink]="['/albums/details', item.id]" [queryParams]="queryParams" tabindex="0">
  <p *ngIf="item.label"><a [routerLink]="['/albums', 'labels', item.label.id ,'list']" class="white-link">{{ item.label.name }}</a><span *ngIf="item.date"><br><a [routerLink]="['/albums', 'years', (item.date | date:'y') ,'list']" class="white-link">{{ item.date | date:'y' }}</a></span></p>
</td>
<td tabindex="0">
  <a [routerLink]="['/albums/genres', genre.id, 'list']" *ngFor="let genre of item.genres; let isLast = last" attr.aria-label="Category: {{ genre.name }}, click to show all the items in the category">{{ genre.name }}{{isLast ? '' : ', '}}</a>
</td>
<td>
  <div class="actions-wrapper">
    <app-block-button [item]="item" [enabled]="item.playable !== false"></app-block-button>
    <app-rating [item]="item" [enabled]="item.playable !== false"></app-rating>
  </div>
</td>
<td>
  <div class="popularity-wrapper">
    <app-popularity [popularity]="item.popularity"></app-popularity>
  </div>
  <div  class="actions-wrapper float-end">
    <app-option-popup [item]="item" [canPlay]="item.playable !== false">
      <img src="/assets/images/ellipsis.svg" alt="" aria-hidden="true">
    </app-option-popup>
  </div>
</td>
