/**
 * HOS API
 *
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 */



export interface AuthToken {
    /**
     * Auth token
     */
    authToken?: string;

}
