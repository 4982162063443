import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';


@Injectable()
export class ViewportUtilsService {
  viewportWidth = 1024; // default for server side rendering
  viewportHeight = 768;

  innerWidth = 1024; // default for server side rendering
  innerHeight = 768;

  isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId) {
    this.isBrowser = isPlatformBrowser(platformId);
    if (this.isBrowser) {
      this.viewportWidth = window.screen.width;
      this.viewportHeight = window.screen.height;

      // console.log('viewportWidth = ' + this.viewportWidth);
      // console.log('viewportHeight = ' + this.viewportHeight);

      this.innerWidth = window.innerWidth;
      this.innerHeight = window.innerHeight;

      // console.log('innerWidth = ' + this.innerWidth);
      // console.log('innerHeight = ' + this.innerHeight);
    }
  }
}
