import {Component, OnDestroy, OnInit, SecurityContext} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import AlbumTypeEnum = Album.AlbumTypeEnum;
import {SubSink} from 'subsink';
import {Album, AlbumsService, HosItem, Playlist, PlaylistsService} from 'app/api-client';
import {OrderableList} from '../data-helpers/orderable-list';
import {GeneralService} from '../../services/general.service';
import {TopBarComponentService} from '../../services/top-bar-component.service';
import {LoadingHelperService} from '../loading-helper/loading-helper.service';
import {MetaUtilsService} from '../../services/meta-utils.service';
import {ShareService} from '../../services/share.service';
import {AlbumsTopBarComponent} from '../albums-top-bar/albums-top-bar.component';
import {hoslog, PlayButtonIconSize} from '../../app.constants';

interface AlbumContextParams {
  filterBy?: string;
  filterValue?: string;
  orderByValue?: string;
  orderByDirection?: string;
  position?: number;
  rouid?: number; // Rating order User ID
}

@Component({
  selector: 'app-album-detail',
  templateUrl: './album-detail.component.html',
  styleUrls: ['./album-detail.component.scss']
})
export class AlbumDetailComponent extends OrderableList implements OnInit, OnDestroy {
  private static TEXT_TRUNCATION_LENGTH = 600;

  private title = "ALBUMS";

  private albumId: number;
  public data: Album = null;

  public isPlaying = false; // TODO dynamic
  public detailsExpanded: boolean = false;
  public truncatedDescription = null;

  playButtonIconSize = PlayButtonIconSize;

  //private isFromPlaylist: Playlist = null;

  private subs = new SubSink();
  private subSubs = new SubSink();

  constructor(private activatedRoute: ActivatedRoute,
              private gs: GeneralService,
              private topBarComponentService: TopBarComponentService,
              public loadingHelperService: LoadingHelperService,
              private metaUtilsService: MetaUtilsService,
              private domSanitizer: DomSanitizer,
              private shareService: ShareService,
              private playlistsService: PlaylistsService,
              private albumsService: AlbumsService) {
    super();
    this.gs.setTitlePageAndMetadata(this.title);
  }

  ngOnInit() {
    this.subs.sink = this.activatedRoute.params.subscribe(params => {
      this.albumId = params['albumId'];
      this.refresh();

      this.subSubs.unsubscribe();
      this.subSubs.sink = this.activatedRoute.queryParams.subscribe(qparams => {
        // checking if there are the context params
        const contextParams: AlbumContextParams = {
          filterBy: qparams['filterBy'],
          filterValue: qparams['filterValue'],
          rouid: qparams['rouid'],
          orderByValue: qparams['orderByValue'],
          orderByDirection: qparams['orderByDirection'],
          position: +qparams['position']
        };
        if (contextParams.position != null && contextParams.filterBy != null) {
          // call the API to get the previous / next item
          // and show the selection and the prev / next box in the top bar
          // console.log('contextParams = ' + JSON.stringify(contextParams));
          this.albumsService.getAlbumsPrevNext(this.albumId, contextParams.position, contextParams.filterBy, contextParams.filterValue, contextParams.orderByValue, contextParams.orderByDirection, contextParams.rouid)
            .subscribe(res => {
              // hoslog('getAlbumsPrevNext: ' + JSON.stringify(res));
              const data = res;
              data['albumId'] = this.albumId;
              if (data.prev) {
                data['ctxPrev'] = JSON.parse(JSON.stringify(contextParams));
                data['ctxPrev']['position'] = contextParams.position - 1;
              }
              if (data.next) {
                data['ctxNext'] = JSON.parse(JSON.stringify(contextParams));
                data['ctxNext']['position'] = contextParams.position + 1;
              }

              setTimeout(() => {
                this.topBarComponentService.setTopBarComponent(AlbumsTopBarComponent, res);
              });
            });
        } else {
          setTimeout(() => {
            this.topBarComponentService.setTopBarComponent(AlbumsTopBarComponent);
          });
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.topBarComponentService.clearTopBar();
  }

  refresh() {
    let me = this;
    this.loadingHelperService.startLoading();
    this.albumsService.getAlbumDetail(this.albumId)
      .subscribe(
        res => {
          hoslog("getAlbumDetail loaded"/* + JSON.stringify(res)*/);
          me.data = res;

          const playlistId = this.activatedRoute.snapshot.queryParams['plid'];
          if (playlistId && !isNaN(playlistId)) {
            if(this.data.type === HosItem.TypeEnum.Album) {
              //this.playlistsService.getPlaylistIfContainsAlbum(+playlistId, this.data.id)
              this.playlistsService.getPlaylistDetail(playlistId)
                .subscribe(res => {
                    // this.isFromPlaylist = res;
                    if(res!=null) {
                      this.gs.setTitlePageAndMetadata('PLAYLIST ' + res.name + ": ABM DETAIL");
                    }
                  },
                  error => {
                    hoslog('getProgramDetail error: ' + JSON.stringify(error));
                  })
            }
          }

          this.metaUtilsService.updateAlbumInfo(res);
          if (res.description && res.description.length > AlbumDetailComponent.TEXT_TRUNCATION_LENGTH) {
            this.detailsExpanded = false;
            me.truncatedDescription = me.domSanitizer.sanitize(SecurityContext.HTML, res.description.substring(0, AlbumDetailComponent.TEXT_TRUNCATION_LENGTH) + '...&nbsp;<a class=\"more\">MORE</a>'); // truncate
          } else {
            this.detailsExpanded = true;
          }
          me.loadingHelperService.loadingOK();
        },
        error => {
          hoslog("getAlbumDetail error: " + JSON.stringify(error));
          me.loadingHelperService.loadingKO(error);
        });
  }

  public get multipleArtists(): boolean {
    return (this.data && this.data.albumType === AlbumTypeEnum.Compilation)
    // return (this.data && this.data.artists && (/*this.data.artists.length > 1 || */this.data.artists.length == 1 && this.data.artists[0].name.toLowerCase().indexOf('various artists') > -1))
  }

  /**
   * Reorder the tracks list
   */
  reload() {
    this.data.tracks.sort((a, b) => {
      let v1 = "";
      let v2 = "";
      let va = a[this.getOrderByValue()];
      let vb = b[this.getOrderByValue()];
      if (this.getOrderByValue() === 'artists') {
        if (va) {
          v1 = va[0].name;
        }
        if (vb) {
          v2 = vb[0].name;
        }
      } else {
        v1 = va;
        v2 = vb;
      }
      if (this.getOrderByDirection() === "ASC") {
        if (v1 < v2) return -1;
        if (v1 > v2) return 1;
        return 0;
      } else {
        if (v1 < v2) return 1;
        if (v1 > v2) return -1;
        return 0;
      }
    });
    // console.log("getOrderByValue(): " + this.getOrderByValue());
    // console.log("getOrderByDirection(): " + this.getOrderByDirection());
  }

  detailsExpand() {
    if (!this.detailsExpanded) {
      this.detailsExpanded = !this.detailsExpanded;
    }
  }

  share() {
    this.shareService.showShareAlbumModal(this.data);
  }
}
