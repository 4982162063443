<div class="login-form">
  <div>
    <a routerLink="/account/create" routerLinkActive="active" aria-label="Create a free account">CREATE ACCOUNT</a>
  </div>
  <form class="navbar-form"
        novalidate
        [formGroup]="myForm">
    <input focus="true" type="email" name="email" class="form-control" [ngClass]="{'error': !myForm.get('username').valid}" placeholder="Email"
           (keydown.space)="$event.preventDefault()" aria-label="Login form, insert your email address" formControlName="username" required autocomplete="username">
    <input type="password" name="password" value="" class="form-control" [ngClass]="{'error': !myForm.get('password').valid}" placeholder="Password"
           aria-label="Login form, insert your password, 8 character min, case sensitive" formControlName="password"
           autocomplete="current-password" required>
    <button type="text" name="button" class="btn btn-default submit" (click)="doLogin()" aria-label="Click to sign in"
            [disabled]="loading">{{loading ? "LOADING...":"ENTER"}}
    </button>
  </form>
  <div>
    <a [routerLink]="['/account/reset-password']" aria-label="Click to reset your password">RESET PASSWORD</a>
  </div>
</div>
