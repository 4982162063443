<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12 no-indents tab-router-wrapper">
      <div class="container-header">
        <nav>
          <ul role="group" aria-label="Blocked top navigation bar" tabindex="0">
            <li routerLinkActive="active">
              <a routerLink="blocked/programs">
                PROGRAMS
              </a>
            </li>
            <li routerLinkActive="active">
              <a routerLink="blocked/albums">
                ALBUMS
              </a>
            </li>
            <li routerLinkActive="active">
              <a routerLink="blocked/tracks">
                TRACKS
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</div>
