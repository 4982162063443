import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {Settings, StreamingQualityEnum} from "../model/Settings";
import {LoginHelperService} from "./login-helper.service";
import {AccountService} from "../api/account.service";
import {CoolLocalStorage} from "@angular-cool/storage";
import {AppConstants, hoslog} from "../../app.constants";
import {PermissionContext, PermissionContextActionsEnum} from "../model/PermissionContext";
import {LoggedUserPlanService} from '../../services/logged-user-plan.service';
import {SubSink} from 'subsink';
import {MixpanelService} from '../../services/mixpanel.service';

@Injectable()
export class LoggedUserSettingsService {
  private _loggedUserSettingsObs: BehaviorSubject<Settings>;
  private _streamingQualityObs: BehaviorSubject<StreamingQualityEnum>;

  private subs = new SubSink();

  constructor(private loginHelperService: LoginHelperService,
              private loggedUserPlanService: LoggedUserPlanService,
              private mixpanelService: MixpanelService,
              private accountService: AccountService,
              private localStorage: CoolLocalStorage) {
    // Initial value
    this._loggedUserSettingsObs = new BehaviorSubject(null);
    this._streamingQualityObs = new BehaviorSubject(StreamingQualityEnum.StreamingQualityBest);

    // Listening for logged users
    let me = this;
    this.subs.sink = this.loginHelperService.isLoggedIn$.subscribe(
      isLoggedIn => {
        me.onLoginChanged(isLoggedIn);
      }
    );

    this.subs.sink = this.loggedUserPlanService.plan$.subscribe(
      newPlan => {
        if (newPlan) {
          hoslog('New plan found: getting the new settings for the user');
          if (newPlan.plan && newPlan.plan.prices) {
            this.mixpanelService.saveUserProperty('Plan', newPlan.plan.prices[0].planId.toString());
          }
          this.mixpanelService.saveUserProperty('PrepaidMinutes', newPlan.availablePrepaidMinutes.toString());
          this.refreshSettings();
        }
      }
    );
  }

  private readonly streamingQualityKey = "streaming_quality";

  private onLoginChanged(isLoggedIn: boolean) {
    if (isLoggedIn) {
      // load user settings
      this.refreshSettings();
      // Streaming quality
      let savedStreamingQuality = this.localStorage.getObject<StreamingQualityEnum>(this.streamingQualityKey);
      if (savedStreamingQuality) {
        this.streamingQualityObs.next(savedStreamingQuality);
      }
    } else {
      this.loggedUserSettingsObs.next(null);
      // reset streaming quality
      this.changeStreamingQuality(StreamingQualityEnum.StreamingQualityBest);
    }
  }

  private refreshSettings() {
    this.accountService.getSettings()
      .subscribe(
        res => {
          // hoslog("Account settings loaded");
          //hoslog("Account settings loaded: " + JSON.stringify(res));
          this.loggedUserSettingsObs.next(res);
          // hoslog("Has off: " + this.hasPermissionAndAction(AppConstants.PermissionVoiceoverControl, PermissionContextActionsEnum.Off));
          // hoslog("Has on: " + this.hasPermissionAndAction(AppConstants.PermissionVoiceoverControl, PermissionContextActionsEnum.On));
          // me.appSettingsLoaded = true;
        },
        error => {
          hoslog("Account settings: error: " + JSON.stringify(error));
          // TODO Sign Out? Or retry?
        });
  }

  get loggedUserSettingsObs(): BehaviorSubject<Settings> {
    return this._loggedUserSettingsObs;
  }

  get streamingQualityObs(): BehaviorSubject<StreamingQualityEnum> {
    return this._streamingQualityObs;
  }

  changeStreamingQuality(newQuality: StreamingQualityEnum) {
    this.localStorage.setObject(this.streamingQualityKey, newQuality);
    this.streamingQualityObs.next(newQuality);
  }

  settingsChanged(newSettings: Settings) {
    if (newSettings) {
      this.loggedUserSettingsObs.next(newSettings);
    } else { // if null is passed, I read the settings again
      this.refreshSettings();
    }
  }

  public hasPermissionAndAction(permissionName: string, action: PermissionContextActionsEnum): boolean {
    let settings = this._loggedUserSettingsObs.getValue();
    if (settings && settings.permissionContexts && settings.permissionContexts.length > 0) {
      let res = settings.permissionContexts.find((permission: PermissionContext) => {
        let actionsEnum = permission.actions.find((a: PermissionContextActionsEnum) => {
          return a === action;
        });
        return (permission.name === permissionName && permission.actions && permission.actions.length > 0 && actionsEnum != null);
      });

      return res != null;
    }
    return false;
  }
}
