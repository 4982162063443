<div class="flex-separator"></div>
<div class="title-box">
  <h1 class="title" tabindex="0" role="heading" attr.aria-label="Current page: {{ gs.title$ | async }}">{{ gs.title$ | async }}</h1>
</div>

<div class="flex-separator"></div>

<div class="header-menu" [class.logged]="loginHelperService.isLoggedIn$ | async" role="navigation" aria-label="Top menu">
  <ul role="menubar" tabindex="0">
    <li class="items profile-item" *ngIf="loginHelperService.isLoggedIn$ | async">
      <a routerLink="/account" routerLinkActive="active" role="menuitem">
        <div class="profile-block">
          <span class="icon icon-profile"></span>
        </div>
        <div class="profile-name">
          <span class="welcome-text">Welcome</span>
          <div class="user-info">{{(loggedUserService.loggedUserDetailsObs | async)?.firstName}} {{(loggedUserService.loggedUserDetailsObs | async)?.lastName}}</div>
          <div class="pre-paid" *ngIf="loggedUserPlanService.plan?.availablePrepaidMinutes">Prepaid Time: {{(loggedUserPlanService.minutes$ | async) + ' minutes'}}</div>
        </div>
      </a>
    </li>
    <li class="items help-item">
      <a [routerLink]="['/pages/help']" routerLinkActive="active" role="menuitem">
        <span class="icon icon-help"></span>
        HELP
      </a>
    </li>
    <!--<li class="items about-item">
      <a href="#" role="menuitem" aria-haspopup="true">
        <span class="icon icon-about"></span>
        ABOUT HOS
      </a>
      <div class="" style="position:relative;">
        <div class="dropdown-content">
          <div class="patch">
            <div></div>
          </div>
          <div class="header-dropdown-menu">
            <ul>
              <li>
                <a routerLink="about" routerLinkActive="active" role="menuitem">
                  ABOUT
                </a>
              </li>
              <li>
                <a href="http://blog.hos.com/" target="_blank" role="menuitem">
                  BLOG
                </a>
              </li>
              <li>
                <a href="#" role="menuitem">
                  PUBLIC RADIO
                </a>
              </li>
              <li>
                <a [routerLink]="['/pages/faq']" role="menuitem">
                  FAQ
                </a>
              </li>
              <li>
                <a [routerLink]="['/pages/what-people-say']" href="#top" role="menuitem">
                  WHAT PEOPLE SAY
                </a>
              </li>
              <li>
                <a href="http://www.cafepress.com/heartsofspace" target="_blank" role="menuitem">
                  GIFTS & MERCHANDISE
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </li>-->
  </ul>
</div>

<div class="flex-separator"></div>
