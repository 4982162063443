import {
  Component, NgModule, ViewChild, OnInit, ViewContainerRef, Injectable, Injector,
  ComponentRef, ComponentFactory, ComponentFactoryResolver
} from "@angular/core";
import {Observable, ReplaySubject} from "rxjs";

// the modalservice
@Injectable()
export class ModalService {
  private vcRef: ViewContainerRef;
  private injector: Injector;
  public activeInstances: number = 0;

  private _resolver: ComponentFactoryResolver;

  constructor(resolver: ComponentFactoryResolver/*private compiler: Compiler*/) {
    this._resolver = resolver;
  }

  public registerResolver(resolver: ComponentFactoryResolver) {
    this._resolver = resolver;
  }

  getVcRef() {
    return this.vcRef;
  }

  registerViewContainerRef(vcRef: ViewContainerRef): void {
    this.vcRef = vcRef;
  }

  registerInjector(injector: Injector): void {
    this.injector = injector;
  }

  /*create<T>(component: any, parameters?: Object): Observable<ComponentRef<T>> {
      let componentRef$ = new ReplaySubject();
      let module = AppModule;
      this.compiler.compileModuleAndAllComponentsAsync(module)
          .then(factory => {
              let componentFactory = factory.componentFactories.filter(item => item.componentType === component)[0];
              const childInjector = ReflectiveInjector.resolveAndCreate([], this.injector);
              let componentRef = this.vcRef.createComponent(componentFactory, 0, childInjector);
              Object.assign(componentRef.instance, parameters); // pass the @Input parameters to the instance
              this.activeInstances ++;
              componentRef.instance["componentIndex"] = this.activeInstances;
              componentRef.instance["destroy"] = () => {
                  this.activeInstances --;
                  componentRef.destroy();
              };
              componentRef$.next(componentRef);
              componentRef$.complete();
          });
      return <Observable<ComponentRef<T>>> componentRef$.asObservable();
  }*/

  create<T>(component: any, parameters?: Object): Observable<ComponentRef<T>> {
    // let componentRef$ = new ReplaySubject();
    // let module = AppModule;
    const factory = this._resolver.resolveComponentFactory(component);

    return <Observable<ComponentRef<T>>> this.createFromFactory(factory, parameters);
    /*let componentFactory = factory.componentFactories.filter(item => item.componentType === component)[0];
    const childInjector = ReflectiveInjector.resolveAndCreate([], this.injector);
    let componentRef = this.vcRef.createComponent(componentFactory, 0, childInjector);
    Object.assign(componentRef.instance, parameters); // pass the @Input parameters to the instance
    this.activeInstances++;
    componentRef.instance["componentIndex"] = this.activeInstances;
    componentRef.instance["destroy"] = () => {
      this.activeInstances--;
      componentRef.destroy();
    };
    componentRef$.next(componentRef);
    componentRef$.complete();

    return <Observable<ComponentRef<T>>> componentRef$.asObservable();*/
  }

  createFromFactory<T>(componentFactory: ComponentFactory<T>,
                       parameters?: Object): Observable<ComponentRef<T>> {
    let componentRef$ = new ReplaySubject();
    const childInjector = Injector.create([], this.injector);
    let componentRef = this.vcRef.createComponent(componentFactory, 0, childInjector);
    // pass the @Input parameters to the instance
    Object.assign(componentRef.instance, parameters);
    this.activeInstances++;
    componentRef.instance["destroy"] = () => {
      this.activeInstances--;
      componentRef.destroy();
    };
    componentRef$.next(componentRef);
    componentRef$.complete();
    return <Observable<ComponentRef<T>>> componentRef$.asObservable();
  }
}

// this is the modal-placeholder, it will container the created modals
@Component({
  selector: "modal-placeholder",
  template: `
    <div #modalplaceholder></div>`
})
export class ModalPlaceholderComponent implements OnInit {
  @ViewChild("modalplaceholder", { read: ViewContainerRef, static: true }) viewContainerRef;

  constructor(private modalService: ModalService,
              private injector: Injector,
              private resolver: ComponentFactoryResolver) {
  }

  ngOnInit(): void {
    this.modalService.registerViewContainerRef(this.viewContainerRef);
    this.modalService.registerInjector(this.injector);
    this.modalService.registerResolver(this.resolver);
  }
}

// These 2 items will make sure that you can annotate
// a modalcomponent with @Modal()
export class ModalContainer {
  destroy: Function;
  componentIndex: number;

  closeModal(): void {
    this.destroy();
  }
}

export function Modal() {
  return function (target) {
    Object.assign(target.prototype, ModalContainer.prototype);
  };
}

// module definition
@NgModule({
  declarations: [ModalPlaceholderComponent],
  exports: [ModalPlaceholderComponent],
  providers: [ModalService]
})
export class ModalModule {
}
