/**
 * HOS API
 *
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 *
 *
 *
 */


export interface Faq {
  /**
   * Id
   */
  id: number;

  /**
   * Question
   */
  question: string;

  /**
   * Answer
   */
  answer: string;

  /**
   * Ordering
   */
  ordering?: number;

  // dynamic
  opened?: boolean;

}
