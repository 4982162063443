import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {TopBarComponent} from '../top-bar/top-bar-component';

@Component({
  selector: 'app-programs-top-bar',
  templateUrl: './programs-top-bar.component.html',
  styleUrls: ['./programs-top-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgramsTopBarComponent implements TopBarComponent, OnInit {
  @Input() data: any;

  constructor() { }

  ngOnInit() {
    if (!this.data) {
      this.data = {}
    }
  }

}
