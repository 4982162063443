import {Injectable} from '@angular/core';
import * as dayjs from 'dayjs';
import {AppConstants} from '../app.constants';
import {HosPlayerService} from './player/hos-player.service';
import {AlertsUtilService} from './alerts-util.service';

@Injectable()
export class StopTimerService {
  timer = null;

  constructor(private hosPlayerService: HosPlayerService,
              private alertsUtilService: AlertsUtilService) {
    this.reset();
  }

  reset() {
    // console.log('Reset StopTimerService');
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      // console.log('StopTimerService: Timeout!');
      if (this.hosPlayerService.isPlaying()) {
        this.hosPlayerService.pause();
        this.alertsUtilService.showConfirmationDialog(AppConstants.clientErrors['TIMER_UP'].errorDescription, 'OK', 'CLOSE', () => {
          this.continueStream();
        });
      } else {
        // console.log('StopTimerService: Not playing!');
        this.reset();
      }
    }, AppConstants.StopTimerElapsedMinutes * 60 * 1000);
    //}, 3 * 60 * 1000); // TODO 3 minutes for tests
  }

  private continueStream() {
    this.reset();
    this.hosPlayerService.play();
  }
}
