import {Component, OnInit} from '@angular/core';
import {AppSettingsService} from '../../services/app-settings.service';
import {Genre} from '../../api-client';
import {hoslog} from '../../app.constants';

@Component({
  selector: 'app-select-genre-dialog',
  templateUrl: './select-genre-dialog.component.html',
  styleUrls: ['./select-genre-dialog.component.scss']
})
export class SelectGenreDialogComponent implements OnInit {

  ok: Function;
  cancel: Function;
  destroy: Function;
  closeModal: Function;

  constructor(public appSettingsService: AppSettingsService) { }

  ngOnInit() {
  }

  onCancel(): void {
    if (this.closeModal) this.closeModal();
    this.destroy();
    if (this.cancel) this.cancel();
  }

  public onOk(genre: Genre): void {
    if (this.closeModal) this.closeModal();
    this.destroy();
    if (this.ok) this.ok(genre);
  }

  selectGenre(genre: Genre) {
    hoslog('Selected genre: ' + genre.name);
    this.onOk(genre);
  }
}
