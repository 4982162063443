import {Album} from "../../api-client/model/Album";
import {Program} from "../../api-client/model/Program";
import {HosPlayItemInfo} from "./hos-play-item";
import {Track} from "../../api-client/model/Track";
import {HosAlbumImageUrlPipe} from '../../shared/image-url/hos-album-image-url.pipe';

export class HosPlayUtils {

  public static getProgramInfoAtTime(program: Program, atTime: number): HosPlayItemInfo {
    let info: HosPlayItemInfo = null;
    if (program != null && program.albums != null) {
      let albumsNumber = program.albums.length;
      for (let c = 0; c < albumsNumber; c++) {
        let album = program.albums[c];
        let i = this.getAlbumInfoAtTime(album, atTime);
        if (i != null) {
          // Find previous and next track
          let prevTrackTime = i.prevTrackTime;
          if (prevTrackTime == null && c > 0) {  // if the current is the first track of the album
            // Go to the last track of the previous album if available
            let prevTrack = HosPlayUtils.getLastTrackOfAlbum(program.albums[c - 1]);
            if (prevTrack != null) {
              prevTrackTime = prevTrack.startPositionInStream;
            }
          }
          let nextTrackTime = i.nextTrackTime;
          if (nextTrackTime == null && c < (albumsNumber - 1)) {  // if the current is the last track of the album
            let nextTrack = HosPlayUtils.getFirstTrackOfAlbum(program.albums[c + 1]);
            if (nextTrack != null) {
              nextTrackTime = nextTrack.startPositionInStream;
            }
          }
          // move the info to adapt to the ones of the program
          return {
            fromTime: i.fromTime,
            toTime: i.toTime,
            track: i.track,
            prevTrackTime: prevTrackTime,
            nextTrackTime: nextTrackTime,
            line1: `PGM ${program.id} "${program.title}"`,
            line2: i.line2,
            line3: i.line1,
            imageUrl: i.imageUrl,
            itemUrl: `/programs/details/${program.id}`
          };
        }
      }
    }
    return info;
  }

  public static getAlbumInfoAtTime(album: Album, atTime: number): HosPlayItemInfo {
    if (album != null && album.tracks != null) {
      let tracksNumber = album.tracks.length;
      for (let c = 0; c < tracksNumber; c++) {
        let track = album.tracks[c];
        let endPositionInStream = (track.startPositionInStream + track.duration);
        if (atTime >= track.startPositionInStream && atTime <= endPositionInStream) {
          // track found
          let artists = "";
          if (album.attribution) {
            artists = album.attribution;
          } else {
            if (album.tracks && album.tracks.length > 0 && album.tracks[0].artists && album.tracks[0].artists.length > 0) {
              artists = album.tracks[0].artists[0].name;
            } else if (album.artists != null && album.artists && album.artists.length > 0) {
              artists = album.artists[0].name;
              /*for (let artist of album.artists) {
                artists = ((artists.length > 0) ? ', ' : '') + artist.name;
              }*/
            }
          }
          // Find previous and next track
          let prevTrackTime = null;
          if (c > 0) {
            prevTrackTime = album.tracks[c - 1].startPositionInStream;
          }
          let nextTrackTime = null;
          if (c < (tracksNumber - 1)) {
            nextTrackTime = album.tracks[c + 1].startPositionInStream;
          }
          return {
            fromTime: track.startPositionInStream,
            toTime: endPositionInStream,
            track: track,
            prevTrackTime: prevTrackTime,
            nextTrackTime: nextTrackTime,
            line1: album.title,
            line2: `${artists} <i>${track.title}</i>`,
            line3: null,
            imageUrl: HosAlbumImageUrlPipe.getAlbumImageUrl(album.id, 150),
            itemUrl: `/albums/details/${album.id}`
          };
        }
      }
    }
  }

  private static getFirstTrackOfAlbum(album: Album): Track {
    if (album != null && album.tracks != null && album.tracks.length > 0) {
      return album.tracks[0];
    }
    return null;
  }

  private static getLastTrackOfAlbum(album: Album): Track {
    if (album != null && album.tracks != null && album.tracks.length > 0) {
      return album.tracks[album.tracks.length - 1];
    }
    return null;
  }


}
