<a (click)="handlePopup($event)" class="link-like" title="view playlist"><img src="/assets/images/eye-condensed.svg" alt="" aria-hidden="true" tabindex="-1"></a>

<ng-container *ngIf="isOpened">
  <div class="peek-overlay" (click)="close($event)"></div>
  <div class="tooltip-wrapper" aria-hidden="true" tabindex="-1">
    <div class="custom-tooltip" [class.in]="isOpened" (click)="preventRedirect($event)">
      <div class="tooltip-arrow"></div>
      <div class="peek-tooltip-inner">

        <div (click)="close($event)" class="close-tooltip">
          <img src="/assets/images/close.png" alt="">
        </div>
        <div class="tooltip-content">
          <div class="tooltip-header">
            <div class="cover">
              <img [src]="data?.id | HosAlbumImageUrl:80" alt="{{data?.title}}" aria-hidden="true" *ngIf="!loadingHelperService.isLoading('ALBUM_PEEK_DETAIL')">
            </div>
            <div class="info">
              <p class="artist text-uppercase">{{ loadingHelperService.isLoading('ALBUM_PEEK_DETAIL') ? 'Loading...' : data?.attribution ? data?.attribution : data?.artists[0].name }}</p>
              <p class="album text-uppercase">{{ data?.title }}</p>
              <p class="description" [innerHtml]="data?.description" appMoreText></p>
            </div>
            <div class="clearfix"></div>
          </div>

          <div class="tooltip-body">

            <app-error-message [error]="loadingHelperService.getError('ALBUM_PEEK_DETAIL')"
                               *ngIf="loadingHelperService.hasError('ALBUM_PEEK_DETAIL')"
                               [showTryAgain]="true"
                               (tryAgain)="loadAlbumDetail()"></app-error-message>
            <table *ngIf="data?.tracks?.length > 0">
              <thead>
              <tr>
                <th>#</th>
                <th>TRACK</th>
                <th>TIME</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let item of data?.tracks; let i = index" [smallTrackItem]="item" [idx]="i+1"></tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>
  </div>
</ng-container>
