import {Injectable} from '@angular/core';
import {CoolLocalStorage} from '@angular-cool/storage';
import {hoslog} from '../app.constants';

export enum TwpPingResult {
  Upsell, NagMsg, Continue
}

@Injectable()
export class UnregisteredUsersStreamingCheckService /*implements OnDestroy*/ {
  // currentTwpId: number = 0;
  twpIds = [];
  firstPingExecuted = false;
  twpIdAdded = false;

  // private isLoggedInSub: Subscription;
  // private statusObsSub: Subscription;

  constructor(private localStorage: CoolLocalStorage) {
              // private appSettingsService: AppSettingsService,
              // private loginHelperService: LoginHelperService,
              // private hosPlayerService: HosPlayerService
    this.refreshCookies();

    // get the twp id
    /*const appSettingsSub = appSettingsService.appSettings.subscribe(
      settings => {
        const twpIdStr = appSettingsService.getGlobalSetting('twpId');
        if (twpIdStr) {
          this.currentTwpId = +twpIdStr; // setting the twp of the week
          appSettingsSub.unsubscribe(); // unsubscribing
        }
      }
    );

    this.isLoggedInSub = loginHelperService.isLoggedIn$.subscribe(isLoggedIn => {
      // only if logged out
      if (isLoggedIn) {
        this.deactivate();
      } else {
        this.activate();
      }
    });*/


  }

  /*activate() {
    // subscribe to player events
    this.statusObsSub = this.hosPlayerService.player.statusObs.subscribe(
        newStatus => {
          hoslog('newStatus: ' + HosCurrentPlayState[newStatus].toString());

          if (newStatus === HosCurrentPlayState.playing) {
            hoslog('UnregisteredUsersStreamingCheckService: play started');
          } else if (newStatus === HosCurrentPlayState.idle) {
            hoslog('UnregisteredUsersStreamingCheckService: play finished');
          }
        }
      );
    hoslog('UnregisteredUsersStreamingCheckService: activated');
  }

  deactivate() {
    if (this.statusObsSub) {
      this.statusObsSub.unsubscribe();
    }
    hoslog('UnregisteredUsersStreamingCheckService: deactivated');
  }*/

  /*ngOnDestroy(): void {
    if (this.isLoggedInSub) {
      this.isLoggedInSub.unsubscribe();
    }
    this.deactivate()
  }*/

  private refreshCookies() {
    // Loading from the local storage
    this.twpIds = this.localStorage.tryGetObject('twpIds');
    if (!this.twpIds) {
      this.twpIds = [];
    }
  }

  private saveCookies() {
    // Loading from the local storage
    this.localStorage.setObject('twpIds', this.twpIds);
  }

  twpProgramForUnloggedEnded(twpId: number) {
    // add the twpId to the cookie array
    if (!this.cookiesContains(twpId)) {
      this.addTwpId(twpId);
      this.reset();
    }
  }

  manageUnloggedTwpPing(twpId: number): TwpPingResult {
    // if first ping
    if (!this.firstPingExecuted) {
      this.firstPingExecuted = true;

      // If cookie exists and:
      // array has 7+ ids, end the stream with the upsell message.
      // or
      // array contains TWP id, end the stream with the upsell message.
      // or
      // array has 4+ ids, allow stream but display nag message.

      if (this.twpIds.length >= 7 || this.cookiesContains(twpId)) {
        // end the stream with the upsell message.
        hoslog('manageUnloggedTwpPing: upsell');
        this.reset();
        return TwpPingResult.Upsell;
      } else if (this.twpIds.length >= 4) {
        // allow stream but display nag message.
        hoslog('manageUnloggedTwpPing: nagmsg');
        return TwpPingResult.NagMsg;
      }
    }
    hoslog('manageUnloggedTwpPing: continue');
    return TwpPingResult.Continue;
  }

  reset() {
    this.firstPingExecuted = false;
  }

  private cookiesContains(twpId: number) {
    return this.twpIds.find(value => value === twpId) !== undefined;
  }

  private addTwpId(twpId: number) {
    if (!this.twpIdAdded) {
      this.twpIdAdded = true;
      this.twpIds.push(twpId);
      this.saveCookies();
    }
  }
}
