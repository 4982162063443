import {Injectable} from '@angular/core';
import {Settings} from '../api-client';
import {hoslog} from '../app.constants';
import {Router} from '@angular/router';
import {LoginHelperService} from '../api-client/helper/login-helper.service';
import StartUpPageEnum = Settings.StartUpPageEnum;
import {ServerMessageService} from './server-message.service';
import {AppStorageService} from './app-storage.service';

@Injectable()
export class HomeRedirectService {
  private homeRedirectDone = false;

  constructor(private router: Router,
              private serverMessageService: ServerMessageService,
              private appStorageService: AppStorageService,
              private loginHelperService: LoginHelperService) {
    loginHelperService.isLoggedIn$.subscribe(isLoggedIn => {
      if (!isLoggedIn) {
        hoslog('Resetting redirect...');
        this.resetRedirect();
      }
    });
  }

  setRedirectDone() {
    this.homeRedirectDone = true;
  }

  resetRedirect() {
    this.homeRedirectDone = false;
  }

  checkHomeRedirect(settings: Settings) {
    if (!this.homeRedirectDone && settings && settings.startUpPage) {
      this.homeRedirectDone = true;

      // if there isn't a server message
      if (!this.serverMessageService.systemMaintenanceVisible$.value) {
        if (this.appStorageService.hasInfo()) {
          this.router.navigate(['/subscription']);
        } else {
          let startUpPage: Settings.StartUpPageEnum = settings.startUpPage;
          hoslog("Startup page: " + startUpPage);
          if (startUpPage != StartUpPageEnum.Home) {
            switch (startUpPage) {
              case StartUpPageEnum.Albums:
                this.router.navigate(['/albums', 'recent']);
                break;
              case StartUpPageEnum.Channels:
                this.router.navigate(['/channels']);
                break;
              case StartUpPageEnum.Favorites:
                this.router.navigate(['/favorites', 'programs']);
                break;
              case StartUpPageEnum.MyPlaylists:
                this.router.navigate(['/playlists']);
                break;
              case StartUpPageEnum.Programs:
                this.router.navigate(['/programs', 'recent']);
                break;
              case StartUpPageEnum.Recent:
                this.router.navigate(['/recent']);
                break;
              case StartUpPageEnum.ThisWeek:
                this.router.navigate(['/this-week', 'program']);
                break;
            }
          }
        }
      }
    }
  }
}
