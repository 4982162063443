import {Injectable} from '@angular/core';

@Injectable()
export class SubscriptionGiftService {

  public isGift = false; // default false
  public recipientInfo: any = null;

  constructor() {
  }

  public setGiftRecipient(recipientInfo: any) {
    this.isGift = true;
    this.recipientInfo = recipientInfo;
  }
}
