/**
 * HOS API
 *
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 */
import {Injectable, Injector} from "@angular/core";
import {HosBaseService} from "./hos-base.service";

import {HttpResponse, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import { map } from 'rxjs/operators';
import "../rxjs-operators";
import {AppConstants} from "../../app.constants";
import {Review} from "../model/Review";

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class ReviewsService extends HosBaseService {


  constructor(injector: Injector) {
     super(injector);
   }

  /**
   * Return the reviews
   *
   * @param firstResult First result
   * @param maxResults Max results
   */
  public getReviews(firstResult?: number, maxResults?: number, extraHttpRequestParams?: any): Observable<Review> {
    return this.getReviewsWithHttpInfo(firstResult, maxResults, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }


  /**
   * Return the reviews
   *
   * @param firstResult First result
   * @param maxResults Max results
   */
  public getReviewsWithHttpInfo(firstResult?: number, maxResults?: number, extraHttpRequestParams?: any): Observable<HttpResponse<Review>> {
    const path = this.getBasePath() + `/reviews`;

    let queryParameters = new HttpParams();
    let headers = this.getDefaultHeaders();

    if (firstResult !== undefined) {
      queryParameters = queryParameters.set('firstResult', <any>firstResult);
    }
    if (maxResults !== undefined) {
      queryParameters = queryParameters.set('maxResults', <any>maxResults);
    }


    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];


    let requestOptions = {
      headers: headers,
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.GET, path, requestOptions);
  }

}
