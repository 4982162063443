<div class="app-modal">
  <div class="modal-body">
    <p>Your old plan's value exceeds the value of your new plan, so please select an option below:</p>
      <div class="options-container">
        <a class="option" (click)="selectOption(1)" *ngIf="expiryDate">1) Delay the change until my plan's current term ends on {{expiryDate | date:'MMM dd yyyy'}}, then do it automatically.</a>
        <a class="option" (click)="selectOption(1)" *ngIf="!expiryDate">1) Delay the change until my current term ends, then do it automatically.</a>
        <a class="option" (click)="selectOption(2)">2) To make the change immediately, I'll select a longer term or higher level plan (so the cost of the new plan exceeds the value of the old one).</a>
      </div>
  </div>
  <div class="modal-actions">
    <button type="button" name="button" (click)="onCancel()">CANCEL</button>
  </div>
</div>
<div class="modal-background">
</div>
