import {Pipe, PipeTransform} from '@angular/core';
import {environment} from "../../../environments/environment";

@Pipe({
  name: 'HosChannelImageUrl'
})
export class HosChannelImageUrlPipe implements PipeTransform {

  transform(channelId: number, size: number): any {
    return HosChannelImageUrlPipe.getChannelImageUrl(channelId, size);
  }

  public static getChannelImageUrl(channelId: number, size: number): string {
    if (channelId) {
      /*return environment.basePathImageUrl + '/' + bannerUrl;*/

      // finding the parts of the image
      let url = '/channels';

      // finding the arguments
      if (size && size > 0) {
        url += `/w/${size}`;
      }
      return `${environment.basePathImageUrl + url}/${channelId}.jpg`;
    } else {
      return ""; // placeholder?
    }
  }
}
