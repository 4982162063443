import {Component, Input, OnInit} from '@angular/core';
import {HosPlayerService} from '../../../services/player/hos-player.service';
import {Program} from '../../../api-client/model/Program';
import {HosPlayItem, HosPlayProgram, PageReferral} from '../../../services/player/hos-play-item';
import {HosQueueSingleProgram} from '../../../services/player/hos-queue';
import {LoggedUserBlockedItemsService} from '../../../services/logged-user-blocked-items.service';
import {AlertsUtilService} from '../../../services/alerts-util.service';
import {StopTimerService} from '../../../services/stop-timer.service';
import {PlayButtonIconSize} from '../../../app.constants';

@Component({
  selector: 'app-play-program-button',
  templateUrl: './play-program-button.component.html',
  styleUrls: ['./play-program-button.component.scss']
})
export class PlayProgramButtonComponent implements OnInit {
  @Input() iconSize : PlayButtonIconSize;
  @Input() item: Program;
  @Input() extraClasses: string = "";
  @Input() enabled: boolean = true;
  @Input() twp: boolean = false; // TODO deprecated (to be removed after tests)
  @Input() pageReferral: PageReferral = PageReferral.program; // program by default

  playButtonIconSize = PlayButtonIconSize;

  public playItem: HosPlayItem;

  constructor(public hosPlayerService: HosPlayerService,
              private loggedUserBlockedItemsService: LoggedUserBlockedItemsService,
              private alertsUtilService: AlertsUtilService,
              private stopTimerService: StopTimerService) {
  }

  ngOnInit() {
    if (this.item) {
      this.playItem = new HosPlayProgram(this.item);
    }
  }

  playProgram() {
    this.stopTimerService.reset();
    if (this.enabled) {
      if (!this.loggedUserBlockedItemsService.isBlocked(this.item)) {
        // console.log('pageRef: ' + this.pageReferral);
        // this.hosPlayerService.play(new HosQueueSingleProgram(this.item, this.twp ? PageReferral.twp : PageReferral.program));
        // this.hosPlayerService.play(new HosQueueSingleProgram(this.item, (this.pageReferral ? this.pageReferral : (this.twp ? PageReferral.twp : PageReferral.program))));
        this.hosPlayerService.play(new HosQueueSingleProgram(this.item, this.pageReferral));
      } else {
        this.alertsUtilService.showAlert("The item is blocked, please unblock it and try again");
      }
    } else {
      this.alertsUtilService.showAlertError("ALBUM_UNPLAYABLE");
    }
  }

}
