import {Component, Input} from "@angular/core";
import {Album} from "../../../../api-client/model/Album";
import AlbumTypeEnum = Album.AlbumTypeEnum;

@Component({
    selector: "app-small-album-item",
    templateUrl: './small-album-item.component.html',
    styleUrls: ['./small-album-item.component.scss']
})

export class SmallAlbumItemComponent {
  @Input('smallAlbumItem') item: Album;

  constructor() {}

  ngOnInit() {

  }

  isCompilation(item: Album): boolean {
    return (item.albumType === AlbumTypeEnum.Compilation);
  }
}
