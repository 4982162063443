/**
 * HOS API
 *
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 */
import {Injectable, Injector} from "@angular/core";
import {HosBaseService} from "./hos-base.service";
import {HttpResponse, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from 'rxjs/operators';
import "../rxjs-operators";
import {AppConstants} from "../../app.constants";
import {PlayTokenWrapper} from "../model/PlayTokenWrapper";
import {HosItem} from '../model/HosItem';
import {RequestsUtils} from '../../shared/utils/requests-utils';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class PlayerApiService extends HosBaseService {


  constructor(injector: Injector) {
    super(injector);
  }

  /**
   * Ping
   *
   * @param itemType The item type
   * @param itemId The item id
   * @param streamCurrentPosition The current stream position
   * @param streamPlayedSecondsSinceLastCall The elapsed seconds since last call
   * @param playToken The play token
   * @param pageReferral
   */
  public ping(itemType: string, itemId: number, streamCurrentPosition: number, streamPlayedSecondsSinceLastCall: number, playToken: string, pageReferral: string, pageReferralRefId?: number, pingCount?: number, extraHttpRequestParams?: any): Observable<{}> {
    const type = itemType === HosItem.TypeEnum.Track.toString() ? HosItem.TypeEnum.Album.toString() : itemType;
    return this.pingWithHttpInfo(type, itemId, streamCurrentPosition, streamPlayedSecondsSinceLastCall, playToken, pageReferral, pageReferralRefId, pingCount, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204 || response.status === 200) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Play
   *
   * @param itemType The item type
   * @param itemId The item id
   * @param pageReferral
   * @param voiceover Voiceover
   */
  public play(itemType: string, itemId: number, pageReferral: string, pageReferralRefId?: number, voiceover?: string, itemTitle?: string, appVersion?: string, extraHttpRequestParams?: any): Observable<PlayTokenWrapper> {
    const type = itemType === HosItem.TypeEnum.Track.toString() ? HosItem.TypeEnum.Album.toString() : itemType;
    return this.playWithHttpInfo(type, itemId, pageReferral, pageReferralRefId, voiceover, itemTitle, appVersion, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

/**
   * Play
   *
   * @param itemType The item type
   * @param itemId The item id
   * @param pageReferral
   * @param voiceover Voiceover
   */
  public refreshPlayToken(originalPlayToken: string, itemType: string, itemId: number, pageReferral: string, voiceover?: string, extraHttpRequestParams?: any): Observable<PlayTokenWrapper> {
    const type = itemType === HosItem.TypeEnum.Track.toString() ? HosItem.TypeEnum.Album.toString() : itemType;
    return this.refreshPlayTokenWithHttpInfo(originalPlayToken, type, itemId, pageReferral, voiceover, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }


  /**
   * Ping
   *
   * @param itemType The item type
   * @param itemId The item id
   * @param streamCurrentPosition The current stream position
   * @param streamPlayedSecondsSinceLastCall The elapsed seconds since last call
   * @param playToken The play token
   */
  public pingWithHttpInfo(itemType: string, itemId: number, streamCurrentPosition: number, streamPlayedSecondsSinceLastCall: number, playToken: string, pageReferral: string, pageReferralRefId?: number, pingCount?: number, extraHttpRequestParams?: any): Observable<HttpResponse<Object>> {
    const path = this.getBasePath() + `/player/ping`;

    let queryParameters = new HttpParams();
    let headers = this.getDefaultHeaders();

    // verify required parameter 'itemType' is not null or undefined
    if (itemType === null || itemType === undefined) {
      throw new Error('Required parameter itemType was null or undefined when calling ping.');
    }
    // verify required parameter 'itemId' is not null or undefined
    if (itemId === null || itemId === undefined) {
      throw new Error('Required parameter itemId was null or undefined when calling ping.');
    }
    // verify required parameter 'streamCurrentPosition' is not null or undefined
    if (streamCurrentPosition === null || streamCurrentPosition === undefined) {
      throw new Error('Required parameter streamCurrentPosition was null or undefined when calling ping.');
    }
    // verify required parameter 'streamPlayedSecondsSinceLastCall' is not null or undefined
    if (streamPlayedSecondsSinceLastCall === null || streamPlayedSecondsSinceLastCall === undefined) {
      throw new Error('Required parameter streamPlayedSecondsSinceLastCall was null or undefined when calling ping.');
    }
    // verify required parameter 'playToken' is not null or undefined
    if (playToken === null || playToken === undefined) {
      throw new Error('Required parameter playToken was null or undefined when calling ping.');
    }
    if (pageReferral === null || pageReferral === undefined) {
      throw new Error('Required parameter pageReferral was null or undefined when calling ping.');
    }


    // to determine the Content-Type header
    let consumes: string[] = [];
    let canConsumeForm = this.canConsumeForm(consumes);
    let useForm = false;
    let formParams = new HttpParams();

    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];


    if (itemType !== undefined) {
      formParams = formParams.set('itemType', itemType);
    }
    if (itemId !== undefined) {
      formParams = formParams.set('itemId', itemId.toString());
    }
    if (streamCurrentPosition !== undefined) {
      formParams = formParams.set('streamCurrentPosition', streamCurrentPosition.toString());
    }
    if (streamPlayedSecondsSinceLastCall !== undefined) {
      formParams = formParams.set('streamPlayedSecondsSinceLastCall', streamPlayedSecondsSinceLastCall.toString());
    }
    if (playToken !== undefined) {
      formParams = formParams.set('playToken', playToken);
    }
    if (pageReferral !== undefined) {
      formParams = formParams.set('pageReferral', pageReferral);
    }
    if (pageReferralRefId!==null && pageReferralRefId !== undefined) {
      formParams = formParams.set('pageReferralRefId', pageReferralRefId);
    }
    if (pingCount !== undefined) {
      formParams = formParams.set('pingCount', pingCount.toString());
    }

    let requestOptions = {
      headers: headers,
      body: RequestsUtils.getJsonObjectFromFormParams(formParams),
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.POST, path, requestOptions);
  }

  /**
   * Play
   *
   * @param itemType The item type
   * @param itemId The item id
   * @param voiceover? Voiceover
   */
  public playWithHttpInfo(itemType: string, itemId: number, pageReferral: string, pageReferralRefId?: number, voiceover?: string, itemTitle?: string, appVersion?: string, extraHttpRequestParams?: any): Observable<HttpResponse<PlayTokenWrapper>> {
    const path = this.getBasePath() + `/player/play`;

    let queryParameters = new HttpParams();
    let headers = this.getDefaultHeaders();

    // verify required parameter 'itemType' is not null or undefined
    if (itemType === null || itemType === undefined) {
      throw new Error('Required parameter itemType was null or undefined when calling play.');
    }
    // verify required parameter 'itemId' is not null or undefined
    if (itemId === null || itemId === undefined) {
      throw new Error('Required parameter itemId was null or undefined when calling play.');
    }
    if (pageReferral === null || pageReferral === undefined) {
      throw new Error('Required parameter pageReferral was null or undefined when calling ping.');
    }

    // to determine the Content-Type header
    let consumes: string[] = [];
    let canConsumeForm = this.canConsumeForm(consumes);
    let useForm = false;
    let formParams = new HttpParams();

    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];


    if (itemType !== undefined) {
      formParams = formParams.set('itemType', itemType);
    }
    if (itemId !== undefined) {
      formParams = formParams.set('itemId', itemId.toString());
    }
    if (pageReferral !== undefined) {
      formParams = formParams.set('pageReferral', pageReferral);
    }
    if (pageReferralRefId!==null && pageReferralRefId !== undefined) {
      formParams = formParams.set('pageReferralRefId', pageReferralRefId);
    }
    if (voiceover !== undefined) {
      formParams = formParams.set('voiceover', voiceover.toString());
    }
    if (itemTitle !== undefined) {
      formParams = formParams.set('itemTitle', itemTitle);
    }
    if (appVersion !== undefined) {
      formParams = formParams.set('appVersion', appVersion);
    }

    let requestOptions = {
      withCredentials: true,
      headers: headers,
      body: RequestsUtils.getJsonObjectFromFormParams(formParams),
      params: queryParameters
    };
    /*,
      withCredentials: true*/

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.POST, path, requestOptions);
  }
/**
   * Refresh Play Token
   *
   * @param originalPlayToken The original play token
   * @param itemType The item type
   * @param itemId The item id
   * @param voiceover? Voiceover
   */
  public refreshPlayTokenWithHttpInfo(originalPlayToken: string, itemType: string, itemId: number, pageReferral: string, voiceover?: string, extraHttpRequestParams?: any): Observable<HttpResponse<PlayTokenWrapper>> {
    const path = this.getBasePath() + `/player/play/refresh`;

    let queryParameters = new HttpParams();
    let headers = this.getDefaultHeaders();

    // verify required parameter 'originalPlayToken' is not null or undefined
    if (originalPlayToken === null || originalPlayToken === undefined) {
      throw new Error('Required parameter originalPlayToken was null or undefined when calling play.');
    }
    // verify required parameter 'itemType' is not null or undefined
    if (itemType === null || itemType === undefined) {
      throw new Error('Required parameter itemType was null or undefined when calling play.');
    }
    // verify required parameter 'itemId' is not null or undefined
    if (itemId === null || itemId === undefined) {
      throw new Error('Required parameter itemId was null or undefined when calling play.');
    }
    if (pageReferral === null || pageReferral === undefined) {
      throw new Error('Required parameter pageReferral was null or undefined when calling ping.');
    }


    // to determine the Content-Type header
    let consumes: string[] = [];
    let canConsumeForm = this.canConsumeForm(consumes);
    let useForm = false;
    let formParams = new HttpParams();

    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];


    if (originalPlayToken !== undefined) {
      formParams = formParams.set('originalPlayToken', originalPlayToken);
    }
    if (itemType !== undefined) {
      formParams = formParams.set('itemType', itemType);
    }
    if (itemId !== undefined) {
      formParams = formParams.set('itemId', itemId.toString());
    }
    if (pageReferral !== undefined) {
      formParams = formParams.set('pageReferral', pageReferral);
    }
    if (voiceover !== undefined) {
      formParams = formParams.set('voiceover', voiceover.toString());
    }

    let requestOptions = {
      withCredentials: true,
      headers: headers,
      body: RequestsUtils.getJsonObjectFromFormParams(formParams),
      params: queryParameters
    };
    /*,
      withCredentials: true*/

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.POST, path, requestOptions);
  }

}
