/**
 * HOS API
 *
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 *
 *
 *
 */



export interface Payment {
    /**
     * Card type
     */
    cardType: string;

    /**
     * Last 4 card digits
     */
    last4cardDigits: string;

    /**
     * Full name
     */
    fullName: string;

    /**
     * Address
     */
    address: string;

    /**
     * Date
     */
    date?: Date;

}
export namespace Payment {

}
