import { Component, Input } from "@angular/core";
import { Router } from '@angular/router';

import { ModalService } from "../modal.service";
import { ActionModalComponent } from "../action-modal/action-modal.component";
import {Album} from "../../api-client/model/Album";
import {Artist} from '../../api-client';
import {GeneralService} from '../../services/general.service';
import {PlayButtonIconSize} from '../../app.constants';

@Component({
    selector: "[albumItem]",
    templateUrl: './album-item.component.html',
    styleUrls: ['./album-item.component.scss']
})

export class AlbumItemComponent {
  @Input('albumItem') item : Album;
  @Input('queryParams') queryParams: any;

  playButtonIconSize = PlayButtonIconSize;

  constructor(private generalService: GeneralService) {}

  ngOnInit() {
    this.queryParams = JSON.parse(JSON.stringify(this.queryParams)); // copy the object
    this.queryParams['position'] = this.item['idx'];
  }

  searchArtist($event, artistAttribution: string) {
    this.preventRedirect($event);
    this.generalService.requestSearch(artistAttribution);
  }

  preventRedirect(event) {
    // do not pass events to parent elements
    if (event && event.stopPropagation) event.stopPropagation();
    if (event && event.preventDefault) event.preventDefault();
  }

}
