import { Component, OnInit, Input } from "@angular/core";

@Component({
    selector: "[smallTrackItem]",
    templateUrl: './small-track-item.component.html',
    styleUrls: ['./small-track-item.component.scss']
})

export class SmallTrackItemComponent {
  @Input('smallTrackItem') item;
  @Input('idx') idx: number;

  constructor() {}

  ngOnInit() {
  }

}
