<div class="sub-container-header" *ngIf="data">
  <div class="drop-down">
    <button (click)="toggle()" [class.active]="visible" tabindex="0"
            attr.aria-label="{{ariaItemLabel}}: {{ariaItemDescription}}">
      <a>{{selectedName}} <img src="/assets/images/icon_dropdown.svg" alt="" aria-hidden="true"> </a>
    </button>
    <div class="dropdown-table" *ngIf="visible" role="dialog" tabindex="0" aria-label="">
      <table>
        <tr *ngFor="let columnArray of data">
          <td *ngFor="let item of columnArray">
            <a (click)="toggle()" [routerLink]="['/', linkPath1, linkPath2, item.id, 'list']" tabindex="0"
               [ngClass]="{ 'current': item.id == selectedId, 'disabled': (item.enabled === false)}"
               attr.aria-label="{{ariaItemLabel}}: {{item.name}}"
               *ngIf="item.name">{{ item.name }}</a>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
