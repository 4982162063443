<div class="container-fluid no-indents" *ngIf="serverMessageService.systemMaintenanceVisible$ | async">
  <div class="message-block">
    <div class="row">
      <div class="col-lg-12">
        <!--<div class="gift-header">
          {{serverMessage.date | date:'MM/DD/YYYY'}} - {{serverMessage.title | uppercase}}
        </div>-->
        <div class="gift-notice">
          {{serverMessageService.serverMessage$ | async}}
        </div>
      </div>
      <!--<div class="col-lg-6">
        <div class="gift-from">
          From: Stephen Hill (stephen@hos.com)
        </div>
        <div class="gift-message">
          Here’s something you’re sure to enjoy, from all of us at HOS.
        </div>
      </div>-->
      <div class="col-lg-2">
        <button type="button" name="button" (click)="toggleSystemMaintenance()">CLOSE</button>
      </div>
    </div>
  </div>
</div>
