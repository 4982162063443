import {Injectable} from '@angular/core';
import {Plan} from '../api-client';
import {PlansService} from '../api-client';
import {PlanCost} from '../api-client';
import {AppConstants, hoslog} from '../app.constants';
import DurationUnitEnum = PlanCost.DurationUnitEnum;
import {BehaviorSubject, Observable} from 'rxjs';
import {AccountService} from '../api-client';
import {SubscriptionPreview} from '../api-client/model/subscriptionPreview';
import {LoadingHelperService} from '../shared/loading-helper/loading-helper.service';
import {AlertsUtilService} from './alerts-util.service';
import {AppStorageService} from './app-storage.service';
import {PriceTable} from '../modules/subscription/price-table';

export interface PricePlan {
  plan: Plan;
  price: PlanCost;
}

export enum PageType {
  Create, Edit, Gift, UpdateBilling
}

@Injectable()
export class SubscriptionService {
  public pageType: PageType;

  public allPlans: Plan[] = null;
  public publishedSubscriptions: Plan[] = null;
  public publishedNonSubscriptions: PricePlan[] = null;

  public selectedPlan: Plan;
  public selectedPrice: PlanCost;

  public priceTable: PriceTable;

  public changePlanImmediately: boolean = true;

  public discountCodeApplied$ = new BehaviorSubject<boolean>(false);
  public discountCode$ = new BehaviorSubject<string>(null);
  public discountCodeValid$ = new BehaviorSubject<boolean>(null);
  public discountCodeDescription$ = new BehaviorSubject<string>(null);
  public discountCodeValue$ = new BehaviorSubject<number>(null);

  public isDowngrade$ = new BehaviorSubject<boolean>(null);

  public prorataDiscount$ = new BehaviorSubject<number>(null);
  public totalDiscountedPrice$ = new BehaviorSubject<number>(null);
  public totalPrice$ = new BehaviorSubject<number>(null);

  constructor(private plansService: PlansService,
              private accountService: AccountService,
              private loadingHelperService: LoadingHelperService,
              private alertsUtilService: AlertsUtilService,
              private appStorageService: AppStorageService,
              /*private http: Http*/) {
    this.priceTable = new PriceTable();
  }

  public init(pageType: PageType) {
    this.pageType = pageType;

    this.priceTable.clearAll();

    /*this.http.get('assets/data/plans.json')
      .subscribe(res => {
        hoslog("getThisWeekProgram loaded" /!*+ JSON.stringify(res)*!/);
        this.allPlans = res.json();

        this.filterPlans();
      });*/

     this.plansService.getPlans(null, AppConstants.USER_PLATFORM_WEB)
       .subscribe(
         res => {
           hoslog("getPlans loaded" /*+ JSON.stringify(res)*/);
           this.allPlans = res;

           this.filterPlans();

           if (this.appStorageService.hasInfo()) {
             hoslog("Stored selected plan and price loaded");
             const storedInfo = this.appStorageService.getPlanStored();
             this.selectPlan(storedInfo.plan);
             this.selectPrice(storedInfo.price);
             this.appStorageService.clear();
           }
         },
         error => {

         });
  }

  public selectPlan(plan: Plan) {
    this.selectedPlan = plan;
    this.selectedPrice = null;
    this.clearTotalCalculations();
    this.recalculatePriceTable();
    this.clearIsDowngrade();
  }

  public selectPrice(price: PlanCost) {
    this.selectedPrice = price;
    this.clearTotalCalculations();
  }

  private recalculatePriceTable() {
    this.priceTable.clearAll();
    for (let cost of this.selectedPlan.prices) {
      // OneTime/Recurring buckets
      this.priceTable.distribute(cost);
    }
  }

  private filterPlans() {
    // First filter
    let filteredPlans = this.allPlans;
    if (this.pageType == PageType.Gift) {
      // console.log('Filter gift plans only');
      filteredPlans = this.allPlans.filter(plan => (plan.giftPlan == true));
    }
    // main subscriptions
    this.publishedSubscriptions = filteredPlans.filter(plan => (plan.published && !plan.secondary));
    // Sorting
    this.publishedSubscriptions = this.publishedSubscriptions.sort((leftSide, rightSide): number => {
      return rightSide.ordering - leftSide.ordering;
    });
    // Calculation low price
    for (let plan of this.publishedSubscriptions) {
      // console.log('Plan ' + plan.id + ': ' + plan.name + ' - Gift? ' + plan.giftPlan);
      plan.asLowAs = 9999;
      for (let cost of plan.prices) {
        if (this.pageType == PageType.Gift && cost.billingType === PlanCost.BillingTypeEnum.Recurring) {
          continue; // skipping the recurring for gift plans
        }
        // asLowAs
        let monthlyPrice = this.getMonthlyPrice(cost);
        if (monthlyPrice < plan.asLowAs) {
          plan.asLowAs = monthlyPrice;
          // console.log('Low price found = ' + monthlyPrice);
        }
      }
    }

    // extra subscriptions
    let publishedNonSubscriptionsPlans = filteredPlans.filter(plan => (plan.published && plan.secondary));
    // Sorting
    publishedNonSubscriptionsPlans = publishedNonSubscriptionsPlans.sort((leftSide, rightSide): number => {
      return leftSide.ordering - rightSide.ordering;
    });
    // Calculation low price
    this.publishedNonSubscriptions = [];
    for (let plan of publishedNonSubscriptionsPlans) {
      for (let cost of plan.prices) {
        this.publishedNonSubscriptions.push({
          plan: plan,
          price: cost
        });
      }
    }

  }

  private getMonthlyPrice(cost: PlanCost): number {
    let duration = cost.duration;
    let totalPrice = cost.cost;
    if (cost.durationUnit == DurationUnitEnum.Year) {
      duration = cost.duration * 12; // in months so it can calculate the total cost
    }
    return totalPrice / duration;
  }

  refreshTotalPrices() {
    if (this.selectedPlan && this.selectedPrice) {
      // check if the discount code is valid
      this.loadingHelperService.startLoading();
      let couponCode = this.discountCode$.value;
      this.accountService.previewChangeCurrentPlan(this.selectedPrice.planId, 'Recurly', undefined, couponCode ? couponCode : undefined, this.changePlanImmediately)
        .subscribe(
          (res: SubscriptionPreview) => {
            this.orderPreviewOK(res);

            this.loadingHelperService.loadingOK();
          },
          err => {
            // console.log('err = ' + JSON.stringify(err));
            this.loadingHelperService.loadingKO(err);
            this.orderPreviewError(err)
          });
    }
  }

  applyDiscountCode(discountCode: string) {
    if (this.selectedPlan && this.selectedPrice && discountCode && discountCode.trim().length > 0) {
      // check if the discount code is valid
      this.loadingHelperService.startLoading();
      this.accountService.previewChangeCurrentPlan(this.selectedPrice.planId, 'Recurly', undefined, discountCode, this.changePlanImmediately)
        .subscribe(
          (res: SubscriptionPreview) => {
            this.orderPreviewOK(res);

            this.loadingHelperService.loadingOK();
          },
          err => {
            // console.log('err = ' + JSON.stringify(err));
            this.loadingHelperService.loadingKO(err);
            this.orderPreviewError(err);
            this.discountCodeApplied$.next(true);
            this.discountCodeValid$.next(false);
          });
    } else {
      this.removeDiscountCode();
    }
  }

  orderPreviewOK(res: SubscriptionPreview) {
    hoslog('changePlan preview received: ' + JSON.stringify(res));
    if (res.discountCode && res.discountValue && res.discountValue > 0) {
      this.discountCodeApplied$.next(true);
      this.discountCode$.next(res.discountCode);
      this.discountCodeValid$.next(true);

      this.discountCodeValue$.next(res.discountValue / 100);

      if (res.couponDescription) {
        this.discountCodeDescription$.next(res.couponDescription);
      } else {
        this.discountCodeDescription$.next('Discount');
      }

    } else {
      this.discountCodeApplied$.next(false);
      this.discountCode$.next(null);
      this.discountCodeValid$.next(null);
      this.discountCodeDescription$.next(null);
    }

    if (res.discountedPrice && res.discountedPrice > 0) {
      this.totalDiscountedPrice$.next(res.discountedPrice / 100);
    }
    if (res.prorataDiscount && res.prorataDiscount > 0) {
      this.prorataDiscount$.next(res.prorataDiscount / 100);
    }
    if (res.totalPrice && res.totalPrice > 0) {
      this.totalPrice$.next(res.totalPrice / 100);
    }

    this.isDowngrade$.next(res.downgrade);

  }

  orderPreviewError(err: any) {
    // this.alertsUtilService.showErrorAlert(err);
    this.clearDiscountCode();
  }

  removeDiscountCode() {
    this.clearTotalCalculations();

    // Refresh the calculation
    this.refreshTotalPrices();
  }

  previewPlanChangeSub(): Observable<SubscriptionPreview> {
    return this.accountService.previewChangeCurrentPlan(this.selectedPrice.planId, 'Recurly', undefined, this.discountCode$.value, this.changePlanImmediately)
  }

  private clearDiscountCode() {
    this.discountCodeApplied$.next(false);
    this.discountCode$.next(null);
    this.discountCodeValid$.next(null);
    this.discountCodeDescription$.next(null);
    this.discountCodeValue$.next(null);
  }

  private clearTotalCalculations() {
    this.clearDiscountCode();
    this.prorataDiscount$.next(null);
    this.totalDiscountedPrice$.next(null);
    this.totalPrice$.next(null);
  }

  private clearIsDowngrade() {
    this.isDowngrade$.next(null);
  }

  storeInfo() {
    this.appStorageService.setPlanStored(this.selectedPlan, this.selectedPrice);
  }

  clearStoredInfo() {
    this.appStorageService.clear();
  }
}
