/**
 * HOS API
 *
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 *
 *
 *
 */

import { Album } from './Album';
import { Artist } from './Artist';
import { Genre } from './Genre';
import { Producer } from './Producer';
import {HosItem} from "./HosItem";


export interface Program extends HosItem {
    /**
     * Program id
     */
    id: number;

    /**
     * Title
     */
    title: string;

    /**
     * Description
     */
    description?: string;

    /**
     * Short description
     */
    shortDescription?: string;

    /**
     * Genres
     */
    genres?: Array<Genre>;

    /**
     * Albums
     */
    albums?: Array<Album>;

    /**
     * Duration
     */
    duration?: number;

    /**
     * Popularity
     */
    popularity?: number;

    /**
     * Number
     */
    number?: number;

    /**
     * Date
     */
    date?: Date;

    /**
     * Producer
     */
    producer?: string;

    galleryUrl?: string;

    /**
     * Artists
     */
    artists?: Array<Artist>;

    type?: HosItem.TypeEnum;

    airDates?: Array<Date>;

    /**
     * Weather report
     */
    weatherReport?: string;

}
/*export namespace Program {
    export enum TypeEnum {
        Program = <any> 'program',
        Track = <any> 'track',
        Album = <any> 'album'
    }
}*/
