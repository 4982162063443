<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <div class="footer-links-container">
        <div>
          <a [routerLink]="['/pages/terms-and-conditions']" target="_blank">Terms &amp; Conditions</a>
        </div>
        <div>
          <a [routerLink]="['/pages/privacy-policy']" target="_blank">Privacy Policy</a>
        </div>
        <div>
          <a [routerLink]="['/pages/service-details']" target="_blank">Service Details</a>
        </div>
      </div>
    </div>
  </div>
</div>
