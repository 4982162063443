import {Injectable} from '@angular/core';
import {MixpanelService} from './mixpanel.service';
import {LoginHelperService} from '../api-client/helper/login-helper.service';
import {LoggedUserService} from '../api-client/helper/logged-user.service';

@Injectable()
export class LoginAnalyticsHelperService {

  constructor(private mixpanelService: MixpanelService,
              private loginHelperService: LoginHelperService,
              private loggedUserService: LoggedUserService) {

    // Login event
    loginHelperService.loggedIn.subscribe(username => {
      this.mixpanelService.identify(username);
      this.mixpanelService.track('login', {username: username});
    });

    // Sign Out event
    loginHelperService.loggedOut.subscribe(value => {
      this.mixpanelService.track('logout');
      this.mixpanelService.clear();
    });

    // User detail loaded
    loggedUserService.loggedUserDetailsObs.subscribe(user => {
      if (user) {
        this.mixpanelService.setUser(user);
        this.mixpanelService.identify(user.email);
      }
    });
  }

}
