import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-pay-pal-payment-accept-terms',
  templateUrl: './pay-pal-payment-accept-terms.component.html',
  styleUrls: ['./pay-pal-payment-accept-terms.component.scss']
})
export class PayPalPaymentAcceptTermsComponent implements OnInit {

  ok: Function;
  cancel: Function;
  destroy: Function;
  closeModal: Function;
  paypal: any;

  termsAccepted = false;
  formSubmitted = false;

  onCancel(result: boolean): void {
    if (this.closeModal) this.closeModal();
    this.destroy();
    if (this.cancel) this.cancel(true);
  }

  public onOk(result: boolean): void {
    if (this.closeModal) this.closeModal();
    this.destroy();
    if (this.ok) this.ok(true);
  }

  constructor() { }

  ngOnInit() {
  }

  pay() {
    this.formSubmitted = true;
    if (this.paypal && this.termsAccepted) {
      this.paypal.start();
      this.onOk(true);
    }
  }
}
