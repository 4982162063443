<td *ngIf="no" class="no-block" tabindex="0" aria-label="No">
  {{ no }}
</td>
<td class="play-block">
  <app-play-album-track-button [iconSize]="playButtonIconSize.Medium" [track]="item" [album]="album" extraClasses="track-item-play-button" [enabled]="album.playable == true"></app-play-album-track-button>
</td>
<td class="title-block" tabindex="0" aria-label="Track">{{ item.title }}</td>
<td *ngIf="extData" class="artist-block" tabindex="0" aria-label="Artist">
  <span *ngIf="item.artists && item.artists.length > 0"><a (click)="searchArtist(item.artists[0])" class="white-link">{{ item.artists[0].name }}</a></span>
</td>
<td *ngIf="extData && !multipleArtists" class="album-block" tabindex="0" aria-label="Album" [routerLink]="['/albums/details', album.id]">
  {{ item.album.title }}
</td>
<td class="duration-block" [class.smaller]="extData" tabindex="0" aria-label="Track duration">{{ item.duration | secondsToMinutes | playerExtraCutPipe}}</td>
<td class="rating-block" [class.smaller]="extData">
  <div class="flex-wrapper">
    <div>
      <app-block-button [item]="item" [enabled]="album.playable == true"></app-block-button>
    </div>
    <div>
      <app-rating [item]="item" [enabled]="album.playable == true"></app-rating>
    </div>
  </div>
</td>
<td class="action-block" [class.smaller]="extData">
  <div class="flex-wrapper">
    <app-popularity [popularity]="item.popularity"></app-popularity>
    <app-option-popup [item]="item" [album]="album" [canPlay]="album.playable == true">
      <img src="/assets/images/ellipsis.svg" alt="" aria-hidden="true">
    </app-option-popup>
  </div>
</td>
