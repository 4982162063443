import {HosPlayAlbum, HosPlayItem, HosPlayProgram, PageReferral} from "./hos-play-item";
import {Program} from "../../api-client/model/Program";
import {Album} from "../../api-client/model/Album";
import {Playlist, PlaylistItem} from "../../api-client/model/Playlist";
import {HosItem} from "../../api-client/model/HosItem";
import {PlayTokenWrapper} from "../../api-client/model/PlayTokenWrapper";
import {Channel} from "../../api-client/model/Channel";
import {HosPlayItemUtils} from './hos-play-item-utils';
import {hoslog} from '../../app.constants';

export enum QueueType {
  Empty, Channel, Playlist, SingleProgram, SingleAlbum, ThisWeekProgram, Recent
}

export interface HosQueue {

  getQueueType(): QueueType;

  getQueueTypeStr(): string;

  /**
   * It's the id of the current playlist, if it's null it means there is n playlist in the queue
   */
  getQueueUniqueId(): string;

  /**
   * It's the id of the current item
   */
  getUniqueId(): string;

  /**
   * Return True if there is a track to play before the current one.
   * @return True if there is a track to play before the current one, false otherwise
   */
  canPrevious(): boolean;

  /**
   * Play the previous track
   * @throws HosPlayerException if there isn't any track to play before the current one
   */
  previous(): HosPlayItem;

  /**
   * Return True if there is a track to play after the current one.
   * @return True if there is a track to play after the current one, false otherwise
   */
  canNext(): boolean;

  /**
   * Play the next track
   * @throws HosPlayerException if there isn't any track to play after the current one
   */
  next(): HosPlayItem;

  canRepeat(): boolean;

  isRepeatEnabled(): boolean;

  setRepeatStatus(enabled: boolean);

  canShuffle(): boolean;

  isShuffleEnabled(): boolean;

  setShuffleStatus(enabled: boolean);

  /**
   * Restart from the first track
   */
  restart();

  hasItems(): boolean;
  getTotalItemsNumber(): number;

  /**
   * Set the next item to play
   * @param playItem the next item to play
   */
  addNext(playItem: HosPlayItem);

  getCurrentItem(): HosPlayItem;

  getCurrentItemIdx(): number;

  updateCurrentItemInfo(completePlayItem: HosPlayItem): void;

  setCurrentItemPlayToken(playTokenWrapper: PlayTokenWrapper): void;

  serialize(): any;

  getItems(): HosPlayItem[];
}

export abstract class HosQueueImpl implements HosQueue {
  private items: HosPlayItem[];
  private currentItemIdx = -1;
  protected queueType: QueueType;

  private repeat = false;
  private shuffle = false;

  constructor(queueType: QueueType, items: HosPlayItem[]) {
    this.queueType = queueType;
    this.initPlaylist(items);
  }

  abstract getQueueUniqueId(): string;

  abstract getUniqueId(): string;

  abstract serialize(): any;

  getQueueType(): QueueType {
    return this.queueType;
  }

  getQueueTypeStr(): string {
    return QueueType[this.queueType].toString();
  }

  protected initPlaylist(items: HosPlayItem[], startIdx: number = null) {
    this.items = items;
    if (startIdx) {
      this.currentItemIdx = startIdx;
    } else {
      this.restart();
    }
  }

  canPrevious(): boolean {
    return (this.hasItems() && this.currentItemIdx > 0);
  }

  previous(): HosPlayItem {
    if (this.canPrevious()) {
      this.currentItemIdx--;
      return this.getCurrentItem();
    }
    return null;
  }

  canNext(): boolean {
    // if repeat (or shuffle) is enabled
    if (this.hasItems() && /* this.getTotalItemsNumber() > 1 &&*/
      (
        (this.canRepeat() && this.isRepeatEnabled()) ||
        (this.canShuffle() && this.isShuffleEnabled())
      )) {
      return true; // always possible as it will go back to the first one
    }
    // if repeat is not enabled
    return (this.hasItems() && this.currentItemIdx < (this.items.length - 1));
  }

  next(): HosPlayItem {
    if (this.canNext()) {
      // Shuffle enabled
      if ((this.canShuffle() && this.isShuffleEnabled())) {
        const totalItems = this.items.length;
        let nextItemIdx = this.currentItemIdx;
        while (nextItemIdx == this.currentItemIdx) {
          nextItemIdx = Math.floor(Math.random() * totalItems);
          hoslog('Shuffle: getting a random item idx: got idx: ' + nextItemIdx);
        }
        this.currentItemIdx = nextItemIdx;
      } else {
        // Shuffle disabled
        if (this.currentItemIdx < (this.items.length - 1)) {
          this.currentItemIdx++;
        } else {
          // if I'm here, repeat is true, so we go back to the first item
          this.currentItemIdx = 0;
        }
      }
      return this.getCurrentItem();
    }
    return null;
  }

  canRepeat(): boolean {
    return true;
  }

  isRepeatEnabled(): boolean {
    return this.repeat;
  }

  setRepeatStatus(enabled: boolean) {
    this.repeat = enabled;
  }

  canShuffle(): boolean {
    return false;
  }

  isShuffleEnabled(): boolean {
    return this.shuffle;
  }

  setShuffleStatus(enabled: boolean) {
    this.shuffle = enabled;
  }

  restart() {
    if (this.hasItems()) {
      this.currentItemIdx = 0; // at least 1 item, starting from the beginning
    } else {
      this.currentItemIdx = -1; // empty queue
    }
  }

  addNext(playItem: HosPlayItem) {
    if (this.hasItems()) {
      this.items.push(playItem);
    } else {
      // creating a new playlist
      this.initPlaylist([playItem]);
    }
  }

  getCurrentItem(): HosPlayItem {
    if (this.hasItems() && this.currentItemIdx >= 0) {
      return this.items[this.currentItemIdx];
    } else {
      return null;
    }
  }

  getCurrentItemIdx(): number {
    return this.currentItemIdx;
  }

  private setCurrentItem(item: HosPlayItem) {
    if (this.hasItems() && this.currentItemIdx >= 0) {
      // saving the page referral of the old item
      item.setPageReferral(this.items[this.currentItemIdx].getPageReferral());
      this.items[this.currentItemIdx] = item;
    }
  }

  hasItems() {
    return this.items && this.items.length > 0;
  }

  getTotalItemsNumber(): number {
    if (this.items) {
      return this.items.length;
    }
    return 0;
  }

  protected getPlayItem(item: HosItem, playlistIdx: number = null): HosPlayItem {
    return HosPlayItemUtils.getPlayItem(item, playlistIdx);
  }

  protected getPlayItems(items: HosItem[]) {
    let playItems: HosPlayItem[] = [];
    for (let item of items) {
      playItems.push(this.getPlayItem(item));
    }
    return playItems;
  }

  public updateCurrentItemInfo(playItem: HosPlayItem): void {
    let currentItem = this.getCurrentItem();
    if (currentItem.getUniqueId() == playItem.getUniqueId()) {
      this.setCurrentItem(playItem);
    }
  }

  public setCurrentItemPlayToken(playTokenWrapper: PlayTokenWrapper): void {
    let currentItem = this.getCurrentItem();
    if (currentItem != null) {
      currentItem.setPlayTokenWrapper(playTokenWrapper);
      this.setCurrentItem(currentItem);
    }
  }

  public getItems(): HosPlayItem[] {
    return this.items;
  }
}

export class HosQueueEmpty extends HosQueueImpl implements HosQueue {
  constructor() {
    super(QueueType.Empty, []);
  }

  getQueueUniqueId(): string {
    return null;
  }

  getUniqueId(): string {
    return null;
  }

  serialize(): any {
    return null;
  }
}

export class HosQueueSingleProgram extends HosQueueImpl implements HosQueue {
  private readonly originalProgram: Program;
  private readonly program: HosPlayProgram;
  private pageReferral: PageReferral;

  constructor(program: Program, pageReferral: PageReferral = PageReferral.program) {
    let hosPlayProgram = new HosPlayProgram(program);
    hosPlayProgram.setPageReferral(pageReferral);
    super(pageReferral === PageReferral.twp ? QueueType.ThisWeekProgram : (pageReferral === PageReferral.recent ? QueueType.Recent : QueueType.SingleProgram), [hosPlayProgram]);
    this.program = hosPlayProgram;
    this.originalProgram = program;
    this.pageReferral = pageReferral;
  }

  getQueueUniqueId(): string {
    return "PR" + this.program.getUniqueId();
  }

  getUniqueId(): string {
    return this.program.getUniqueId();
  }

  serialize(): any {
    return this.originalProgram;
  }
}

export class HosQueueSingleAlbum extends HosQueueImpl implements HosQueue {
  private readonly originalAlbum: Album;
  private readonly album: HosPlayAlbum;

  constructor(album: Album) {
    let hosPlayAlbum = new HosPlayAlbum(album);
    super(QueueType.SingleAlbum, [hosPlayAlbum]);
    this.album = hosPlayAlbum;
    this.originalAlbum = album;
  }

  getQueueUniqueId(): string {
    return "AL" + this.album.getUniqueId();
  }

  getUniqueId(): string {
    return this.album.getUniqueId();
  }

  serialize(): any {
    return this.originalAlbum;
  }
}

// This is for a playlist object
export class HosQueuePlaylist extends HosQueueImpl implements HosQueue {
  private readonly playlist: Playlist;
  private readonly playlistTrackItem: HosItem;

  constructor(playlist: Playlist, playlistTrackItem: HosItem = null, playlistTrackItemIdx: number = null) {
    super(QueueType.Playlist, []);
    this.playlist = playlist;
    this.playlistTrackItem = playlistTrackItem;
    if (playlist.items) {
      let playItems = this.getPlayItemsFromPlaylistItem(playlist.items);

      let startItemIdx = null;

      if (playlistTrackItem) {
        const playlistTrackPlayItem = this.getPlayItem(playlistTrackItem, playlistTrackItemIdx);
        // const a = playlistTrackPlayItem.getUniqueId();
        const idxFound = playItems.findIndex(value => value.getUniqueId() === playlistTrackPlayItem.getUniqueId());
        if (idxFound >= 0) {
          startItemIdx = idxFound;
        }
      }
      this.initPlaylist(playItems, startItemIdx);
    }
  }

  getQueueUniqueId(): string {
    return "PL" + this.playlist.id;
  }

  getUniqueId(): string {
    if (this.getCurrentItem()) {
      return this.getCurrentItem().getUniqueId();
    } else {
      return null;
    }
  }

  protected getPlayItemsFromPlaylistItem(items: PlaylistItem[]) {
    let playItems: HosPlayItem[] = [];
    let idx = 0;
    for (let item of items) {
      let playItem = this.getPlayItem(item.hosItem, idx);
      if (playItem) {
        playItems.push(playItem);
      }
      idx++;
    }
    return playItems;
  }

  canShuffle(): boolean {
    return (this.hasItems() && this.getTotalItemsNumber() > 1);
  }

  serialize(): any {
    return {
      playlist: this.playlist,
      playlistTrackItemIdx: this.getCurrentItemIdx()
    };
  }

  getPlaylist(): Playlist {
    return this.playlist;
  }
}

export class HosQueueChannel extends HosQueueImpl implements HosQueue {
  private readonly channel: Channel;
  private readonly channelTrackItem: HosItem;

  constructor(channel: Channel, channelTrackItem: HosItem = null) {
    super(QueueType.Channel, []);

    // hoslog('HosQueue constructor...');

    this.channel = channel;
    this.channelTrackItem = channelTrackItem;

    if (channel.programs) {
      let playItems = this.getPlayItems(channel.programs);

      // Set the page referral to channel for all the items
      playItems.forEach(item => {
        item.setPageReferral(PageReferral.channel);
        item.setPageReferralRefId(channel.id)
      });

      let startItemIdx = null;

      if (channelTrackItem) {
        const playlistTrackHosItem = this.getPlayItem(channelTrackItem);
        // hoslog('playlistTrackHosItem: ' + JSON.stringify(playlistTrackHosItem));

        // playlistTrackHosItem.setPageReferral(PageReferral.channel); // Set the page referral to channel
        const idxFound = playItems.findIndex(value => value.getUniqueId() === playlistTrackHosItem.getUniqueId());
        if (idxFound >= 0) {
          startItemIdx = idxFound;
        }
      }
      this.initPlaylist(playItems, startItemIdx);
    }
  }

  getQueueUniqueId(): string {
    return "CH" + this.channel.id;
  }

  getUniqueId(): string {
    if (this.getCurrentItem()) {
      // return this.getCurrentItem().getUniqueId();
      return "CH_" + this.getCurrentItem().getUniqueId(); // we add CH_ in the channel to differentiate the program
    } else {
      return null;
    }
  }

  getChannelId(): number {
    return this.channel.id;
  }

  getChannel(): Channel {
    return this.channel;
  }

  canRepeat(): boolean {
    return false;
  }

  serialize(): any {
    return {
      channel: this.channel,
      channelTrackItemIdx: this.getCurrentItemIdx()
    };
  }
}
