import {Injectable} from '@angular/core';
import {ActionModalComponent} from "../shared/action-modal/action-modal.component";
import {ModalService} from "../shared/modal.service";
import {Router} from "@angular/router";
import {AppConstants, hoslog} from "../app.constants";
import {HosLocalError} from "../api-client/helper/api-helper.service";
import {HosError} from "../api-client/model/HosError";
import {MixpanelService} from "./mixpanel.service";
import {ErrorsService} from './errors.service';

@Injectable()
export class AlertsUtilService {

  private shownDialogRef = null; // if a dialog is currently shown it's != null

  constructor(private router: Router,
              private modalService: ModalService,
              // private mixpanelService: MixpanelService,
              private errorsService: ErrorsService) {
  }

  public show504Alert(cancelCallback?: Function) {
    this.closeCurrentlyOpenedDialog();

    let serverTimeout = AppConstants.clientErrors.SERVER_TIMEOUT_CONTACT_US;

    let modal$ = this.modalService.create(ActionModalComponent, {
      cancel: () => {
        // hoslog("Close Modal: ");
        if(cancelCallback) {
          cancelCallback();
        }
      }
    });

    modal$.subscribe((ref) => {
      this.shownDialogRef = ref;
      let i = ref.instance as ActionModalComponent;
      i.message = serverTimeout.errorDescription;
      i.okLabel = "";
      i.cancelLabel = "CLOSE";
      i.showOK = false;
      i.showCancel = true;
      this.router.events.subscribe((val) => {
        ref.destroy();
        this.shownDialogRef = null;
      });
    });
  }

  public showAlert(msg: string) {
    this.closeCurrentlyOpenedDialog();

    // this.mixpanelService.track('alertModalShows', {text: msg});

    let modal$ = this.modalService.create(ActionModalComponent, {
      closeModal: (response) => {
        //hoslog("CloseModal: " + response);
      }
    });

    modal$.subscribe((ref) => {
      this.shownDialogRef = ref;
      let i = ref.instance as ActionModalComponent;
      i.message = msg;
      i.okLabel = "";
      i.cancelLabel = "OK";
      i.showOK = false;
      i.showCancel = true;
      this.router.events.subscribe((val) => {
        ref.destroy();
        this.shownDialogRef = null;
      });
    });
  }

  public showAlertError(errorName: string) {
    const error = this.errorsService.getError(errorName);
    this.showAlert(error.dialogMessage ? error.dialogMessage : error.errorDescription);
  }

  public closeCurrentlyOpenedDialog() {
    if (this.shownDialogRef != null) {
      this.shownDialogRef.destroy();
      this.shownDialogRef = null;
    }
  }

  /**
   * It alsocheck the upsell message
   * TODO upsell dialog
   * @param {any} error
   */
  public showErrorAlert(error: any) {
    let serverTimeout = AppConstants.clientErrors.SERVER_TIMEOUT_CONTACT_US;
    let e: HosError = null;
    if (error) {
      try {
        if (error.errorCode) {
          e = {
            errorDescription: error.errorDescription,
            errorDialog: error.errorDialog,
            errorName: error.errorName,
            errorCode: error.errorCode
          }
        } else if (error.error.errorCode) {
          e = {
            errorDescription: error.error.errorDescription,
            errorDialog: error.error.errorDialog,
            errorName: error.error.errorName,
            errorCode: error.error.errorCode
          }
        } else {
          e = new HosLocalError(serverTimeout.errorDescription, serverTimeout.errorCode, serverTimeout.errorName);
        }
      } catch (exception) {
        e = new HosLocalError(serverTimeout.errorDescription, serverTimeout.errorCode, serverTimeout.errorName);
      }
    } else {
      e = new HosLocalError(serverTimeout.errorDescription, serverTimeout.errorCode, serverTimeout.errorName);
    }

    let errorDlg = e.errorDialog;
    if (errorDlg) {
      // Upsell dialog
      let dialogTitle = errorDlg.dialogTitle;
      if (!dialogTitle || dialogTitle.trim().length == 0) {
        dialogTitle = "Error";
      }
      let dialogOKButtonLabel = errorDlg.dialogOKButtonLabel;
      if (!dialogOKButtonLabel || dialogOKButtonLabel.trim().length == 0) {
        dialogOKButtonLabel = null;
      }
      this.showConfirmationDialog("<b>" + dialogTitle + "</b><br/><br/>" + errorDlg.dialogMessage, dialogOKButtonLabel, "CLOSE",
        (okValue) => {
          hoslog("OK: " + errorDlg.dialogOKButtonRedirect);

          //this.router.navigateByUrl(errorDlg.dialogOKButtonRedirect);
          this.redirectToUrl(errorDlg.dialogOKButtonRedirect)
        },
        (cancelValue) => {
          hoslog("CANCEL");
        },
        (closeValue) => {
          hoslog("CLOSE");
        }
      );
    } else {
      // Standard dialog
      this.showAlert(e.errorDescription ? e.errorDescription : e.errorName);
    }
  }
  private redirectToUrl(url?: string) {
    if(url == null || url.length == 0) {
      // nothing
    } else if (url.startsWith('/') || url.startsWith(this.router.url)) {
      this.router.navigateByUrl(url);
    } else {
      window.location.href = url;
    }
  }

  public showConfirmationDialog(msg: string, okLabel: string = "OK", cancelLabel: string = "CANCEL",
                                okResponse?: (value: any) => void, cancelResponse?: (value: any) => void,
                                closeResponse?: (value: any) => void) {
    this.closeCurrentlyOpenedDialog();

    // this.mixpanelService.track('confirmDialogModalShows', {text: msg});

    let modal$ = this.modalService.create(ActionModalComponent, {
      ok: okResponse,
      cancel: cancelResponse,
      closeModal: closeResponse
    });

    modal$.subscribe((ref) => {
      this.shownDialogRef = ref;
      let i = ref.instance as ActionModalComponent;
      i.message = msg;
      i.okLabel = okLabel;
      i.cancelLabel = cancelLabel;
      i.showOK = okLabel != null;
      i.showCancel = true;
      this.router.events.subscribe((val) => {
        ref.destroy();
        this.shownDialogRef = null;
      });
    });
  }

  public showInfo(msg: string, title: string, bodyClass = null) {
    this.closeCurrentlyOpenedDialog();

    let modal$ = this.modalService.create(ActionModalComponent, {
      closeModal: (response) => {
        //hoslog("CloseModal: " + response);
      }
    });

    modal$.subscribe((ref) => {
      this.shownDialogRef = ref;
      let i = ref.instance as ActionModalComponent;
      i.bodyClass = bodyClass;
      i.message = msg;
      i.title = title;
      i.okLabel = "";
      i.cancelLabel = "OK";
      i.showOK = false;
      i.showCancel = true;
      this.router.events.subscribe((val) => {
        ref.destroy();
        this.shownDialogRef = null;
      });
    });
  }

  public showProgressBar(msg: string) {
    this.closeCurrentlyOpenedDialog();

    let modal$ = this.modalService.create(ActionModalComponent, {
      closeModal: (response) => {
        //hoslog("CloseModal: " + response);
      }
    });

    modal$.subscribe((ref) => {
      this.shownDialogRef = ref;
      let i = ref.instance as ActionModalComponent;
      i.message = msg;
      i.okLabel = "";
      i.cancelLabel = "";
      i.showOK = false;
      i.showCancel = false;
      this.router.events.subscribe((val) => {
        ref.destroy();
        this.shownDialogRef = null;
      });
    });
  }
}
