import {Pipe, PipeTransform} from '@angular/core';
import {CurrencyPipe} from '@angular/common';

@Pipe({
  name: 'customCurrency'
})
export class CustomCurrencyPipe implements PipeTransform {

  constructor() {

  }

  /*
  Currency pipe, digits info with either 0 or 2 decimals (but not 1)
   */
  transform(value: number, ...args: any[]): any {
    let currencyPipe = new CurrencyPipe('en-US');
    if (value % 1 > 0) {
      return currencyPipe.transform(value,'USD','symbol','1.2-2');
    } else {
      return currencyPipe.transform(value,'USD','symbol','1.0-0');
    }
  }
}
