import {Injectable} from '@angular/core';
import {AccountService} from "../api/account.service";
import {ApiHelperService} from "./api-helper.service";
import {MixpanelService} from "../../services/mixpanel.service";
import {SessionToken} from "../model/SessionToken";
import {BehaviorSubject, from, Observable, Subject} from "rxjs";
import {tap} from 'rxjs/operators';
import {OAuthErrorEvent, OAuthEvent, OAuthResourceServerErrorHandler, OAuthService, TokenResponse} from 'angular-oauth2-oidc';
import {authPasswordFlowConfig} from './auth-password-flow.config';
import {_throw} from 'rxjs-compat/observable/throw';
import {HttpResponse} from '@angular/common/http';
import {HomeRedirectService} from '../../services/home-redirect.service';

@Injectable()
export class LatteOAuthResourceServerErrorHandler implements OAuthResourceServerErrorHandler {
  handleError(err: HttpResponse<any>): Observable<any> {
    // console.log('Error! ' + JSON.stringify(err));
    return _throw(err);
  }
}

@Injectable()
export class LoginHelperService {

  // store the URL so we can redirect after logging in
  redirectUrl: string = null;

  private _isLoggedInObs: BehaviorSubject<boolean>;
  private _tokenRefreshedObs: Subject<string>;

  public loggedIn = new Subject<string>();
  public loggedOut = new Subject();

  constructor(private apiHelperService: ApiHelperService,
              private oauthService: OAuthService/*,
              private accountService: AccountService*/) {
    // Tweak config for password flow
    // This is just needed b/c this demo uses both,
    // implicit flow as well as password flow

    this.oauthService.configure(authPasswordFlowConfig);
    // this.oauthService.setupAutomaticSilentRefresh();
    this.oauthService.events.subscribe((e: OAuthEvent) => {
      // console.log('oauth/oidc event', e);
      if (e instanceof OAuthErrorEvent && e.type === 'token_refresh_error') {
        // invalid refresh token, Sign Out
        this.doLogout();
      }
    });
    // this.oauthService.loadDiscoveryDocument();

    this._isLoggedInObs = new BehaviorSubject(this.isAuthTokenAvailable());
    this._tokenRefreshedObs = new Subject();
  }

  public doLogin(username: string, password: string): Observable<TokenResponse> {
    // return this.accountService.getAuthToken(environment.apiConfig.clientId, username, password)  // Get auth token
    //   .do(authTokenWrapper => this.apiHelperService.setAuthToken(authTokenWrapper.authToken))
    //   .mergeMap(authTokenWrapper => this.accountService.getSessionToken(authTokenWrapper.authToken)) // Get session token
    //   .do(sessionTokenWrapper => {
    //     this.apiHelperService.setSessionToken(sessionTokenWrapper);
    //     this.mixpanelService.identify(username);
    //     this.mixpanelService.track('login', {username: username});
    //     this._isLoggedInObs.next(true);
    //   });

    /*return this.accountService.getAuthToken(environment.apiConfig.clientId, username, password).pipe(
      tap(authTokenWrapper => this.apiHelperService.setAuthToken(authTokenWrapper.authToken)),
      mergeMap(authTokenWrapper => this.accountService.getSessionToken(authTokenWrapper.authToken)),
      tap(sessionTokenWrapper => {
        this.apiHelperService.setSessionToken(sessionTokenWrapper);
        this.mixpanelService.identify(username);
        this.mixpanelService.track('login', {username: username});
        this._isLoggedInObs.next(true);
      })
    );*/

    const oauthPromise = this.oauthService
    // .fetchTokenUsingPasswordFlowAndLoadUserProfile(
      .fetchTokenUsingPasswordFlow(username, password);

    return from(oauthPromise)
      .pipe(
        tap(value => {
          this.loggedIn.next(username);
          this._isLoggedInObs.next(true);
        }));
  }

  public doLogout() {
    this.oauthService.logOut();
    this.loggedOut.next(true);
    this._isLoggedInObs.next(false);
  }

  get isLoggedIn$(): BehaviorSubject<boolean> {
    return this._isLoggedInObs;
  }

  refreshAccessToken(): Promise<Object> {
    return this.oauthService.refreshToken();
  }

  /*get tokenRefreshed(): Subject<string> {
    return this._tokenRefreshedObs;
  }*/

  public isAuthTokenAvailable() {
    return this.oauthService.hasValidAccessToken();
  }

  /*public refreshSessionToken(): Observable<SessionToken> {
    return this.accountService.getSessionToken(this.apiHelperService.getAuthToken()).pipe(
      tap(sessionTokenWrapper => {
        this.apiHelperService.setSessionToken(sessionTokenWrapper); // set the new token
      })
    );
  }*/

  get access_token() {
    return this.oauthService.getAccessToken();
  }

  get access_token_expiration() {
    return this.oauthService.getAccessTokenExpiration();
  }

  get refresh_token() {
    return this.oauthService.getRefreshToken();
  }
}
