import {Pipe, PipeTransform} from '@angular/core';
import {environment} from "../../../environments/environment";
import {Genre} from '../../api-client';

@Pipe({
  name: 'HosProgramImageUrl'
})
export class HosProgramImageUrlPipe implements PipeTransform {

  transform(programId: number, size: number, genres: Genre[] = undefined): any {
    return HosProgramImageUrlPipe.getProgramImageUrl(programId, size, genres);
  }

  public static getProgramImageUrl(programId: number, size: number, genres: Genre[] = undefined): string {
    if (programId) {
      /*if (environment.imagesUseOldServlet) {
        // finding the parts of the image
        let url = '/program/' + programId; // TODO the path should be plural

        // finding the arguments
        if (size && size > 0) {
          url += "_" + size;
          url += 'w';
        }
        return 'https://cloudfront.hos.com/hos/images' + url + '.jpg';
      } else {*/
      // finding the parts of the image
      let url = '/programs';

      // finding the arguments
      if (size && size > 0) {
        url += `/w/${size}`;
      }
      let finalUrl = `${environment.basePathImageUrl + url}/${programId}.jpg`;

      if (genres && genres.length > 0) {
        finalUrl += '?backupGenreId=' + genres[0].id;
      }
      return finalUrl;
      // }
    } else {
      return ""; // TODO add placeholder
    }
  }
}
