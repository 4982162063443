import {Modal} from "../modal.service";
import { Component} from "@angular/core";

@Component({
  selector: "share-modal/share-modal",
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.scss']
})
@Modal()
export class ShareModalComponent {
  bodyHeight = null;
  title = null;
  message = "Are you sure?";
  shareTitle = "";
  shareUrl = "";
  shareDescription = "";
  shareImageUrl = "";
  okLabel = "OK";
  cancelLabel = "CANCEL";
  showOK = true;
  showCancel = true;

  private addThisReset = false;

  constructor(){}

  ok: Function;
  cancel: Function;
  destroy: Function;
  closeModal: Function;
  response = "success";

  onCancel(): void {
    if (this.closeModal) this.closeModal();
    this.destroy();
    if (this.cancel) this.cancel();
    // this.mixpanelService.track('cancelModalClicked');
  }

  onOk(): void {
    if (this.closeModal) this.closeModal();
    this.destroy();
    if (this.ok) this.ok(this.response);
    // this.mixpanelService.track('okModalClicked');
  }
}
