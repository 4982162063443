import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {TopBarComponent} from '../top-bar/top-bar-component';
import {hoslog} from '../../app.constants';

@Component({
  selector: 'app-albums-top-bar',
  templateUrl: './albums-top-bar.component.html',
  styleUrls: ['./albums-top-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlbumsTopBarComponent implements TopBarComponent, OnInit {
  @Input() data: any;

  constructor() { }

  ngOnInit() {
    if (!this.data) {
      this.data = {}
    }
  }
}
