import {HosItem} from '../../api-client';
import {HosPlayItem} from '../../services/player/hos-play-item';
import {HosQueue} from '../../services/player/hos-queue';


export class TrackingUtils {

  public static getHosQueuePlayValue(action: string, queue: HosQueue) {
    let ret = {action: action};
    if (queue) {
      ret['queueType'] = queue.getQueueTypeStr();
      ret['queueUniqueId'] = queue.getQueueUniqueId();
      ret['uniqueId'] = queue.getUniqueId();
      ret['totalItemsNumber'] = queue.getTotalItemsNumber();
      let currentItem = queue.getCurrentItem();
      if (currentItem) {
        ret['itemUniqueId'] = currentItem.getUniqueId();
        ret['itemId'] = currentItem.getId();
        ret['itemType'] = HosItem.TypeEnum[currentItem.getType()];
        ret['itemLine1'] = currentItem.getLine1();
        ret['itemLine2'] = currentItem.getLine2();
        ret['itemLine3'] = currentItem.getLine3();
      }
    }
    return ret;
  }
  public static getHosPlayItemTrackingValue(playItem: HosPlayItem): any {
    return {uniqueId: playItem.getUniqueId(), id: playItem.getId(), type: HosItem.TypeEnum[playItem.getType()], line1: playItem.getLine1(), line2: playItem.getLine2(), line3: playItem.getLine3() };
  }
}
