import { Component, OnInit, Input } from "@angular/core";

@Component({
    selector: "app-plan-item",
    templateUrl: './plan-item.component.html',
    styleUrls: ['./plan-item.component.scss']
})

export class PlanItemComponent {
  @Input('planItem') item;
  @Input('selected') selected = false;

  constructor() {}

  ngOnInit() {
  }
}
