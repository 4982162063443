import {Component, Input, OnInit} from '@angular/core';
import {Album} from "../../../api-client/model/Album";
import {HosPlayAlbum, HosPlayItem} from "../../../services/player/hos-play-item";
import {HosPlayerService} from "../../../services/player/hos-player.service";
import {HosQueueSingleAlbum} from "../../../services/player/hos-queue";
import {AlertsUtilService} from "../../../services/alerts-util.service";
import {LoggedUserBlockedItemsService} from "../../../services/logged-user-blocked-items.service";
import {MixpanelService} from "../../../services/mixpanel.service";
import {HosItem} from '../../../api-client';
import {TrackingUtils} from '../../utils/tracking.utils';
import {Router} from '@angular/router';
import {StopTimerService} from '../../../services/stop-timer.service';
import {PlayButtonIconSize} from '../../../app.constants';

@Component({
  selector: 'app-play-album-button',
  templateUrl: './play-album-button.component.html',
  styleUrls: ['./play-album-button.component.scss']
})
export class PlayAlbumButtonComponent implements OnInit {
  @Input() iconSize : PlayButtonIconSize;
  @Input() item: Album;
  @Input() extraClasses: string = "";
  @Input() enabled: boolean = true;

  playButtonIconSize = PlayButtonIconSize;

  public playItem: HosPlayItem;

  constructor(public hosPlayerService: HosPlayerService,
              private loggedUserBlockedItemsService: LoggedUserBlockedItemsService,
              private alertsUtilService: AlertsUtilService,
              private stopTimerService: StopTimerService) {
  }

  ngOnInit() {
    if (this.item) {
      this.playItem = new HosPlayAlbum(this.item);
    }
  }

  playAlbum() {
    this.stopTimerService.reset();
    if (this.enabled) {
      if (!this.loggedUserBlockedItemsService.isBlocked(this.item)) {
        this.hosPlayerService.play(new HosQueueSingleAlbum(this.item));
      } else {
        this.alertsUtilService.showAlert("The item is blocked, please unblock it and try again");
      }
    } else {
      this.alertsUtilService.showAlertError("ALBUM_UNPLAYABLE");
    }
  }

}
