<div class="relative-block">
  <div #optionBtn class="content-wrapper" (click)="handlePopup()" tabindex="0" aria-label="Show options" role="button">
    <ng-content></ng-content>
  </div>

  <div class="popup-actions left tooltip-left" [class.in]="isOpened" role="dialog" [attr.aria-hidden]="!isOpened" *ngIf="isOpened">
    <div class="tooltip-arrow"></div>
    <div class="tooltip-inner">
      <div class="tooltip-content">
        <ul role="menu">
          <li *ngFor="let item of actionsList" (click)="handleAction(item.action)" [smallPopupItem]="item" tabindex="{{isOpened ? 0 : -1}}" attr.aria-label="{{item.title}}">
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="modal-background" (click)="backdropClicked()" *ngIf="isOpened"></div>
</div>
