<fieldset class="rating" role="group" aria-label="Rate and add to favourites" tabindex="0" [disabled]="!enabled">
  <input type="radio" value="3" [name]="inputName" [checked]="rating===3" tabindex="-1" aria-hidden="true"  />
  <label title="3 stars" (click)='onClick(3)' tabindex="0">3 stars</label>

  <input type="radio" value="2" [name]="inputName" [checked]="rating===2" tabindex="-1" aria-hidden="true" />
  <label title="2 stars" (click)='onClick(2)' tabindex="0">2 stars</label>

  <input type="radio" value="1" [name]="inputName" [checked]="rating===1" tabindex="-1" aria-hidden="true" />
  <label title="1 star" (click)='onClick(1)' tabindex="0">1 star</label>
</fieldset>
