/**
 * HOS API
 *
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 */
import {Injectable, Injector} from "@angular/core";
import {HosBaseService} from "./hos-base.service";

import {HttpResponse, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import { map } from 'rxjs/operators';
import "../rxjs-operators";
import {AppConstants} from "../../app.constants";
import {RequestsUtils} from '../../shared/utils/requests-utils';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class TracksService extends HosBaseService {


  constructor(injector: Injector) {
     super(injector);
   }

  /**
   * Mark the track as blocked
   *
   * @param trackId The track id
   */
  public markBlocked(trackId: number, extraHttpRequestParams?: any): Observable<{}> {
    return this.markBlockedWithHttpInfo(trackId, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Mark the track as favorite
   *
   * @param trackId The track id
   * @param rating The rating
   */
  public markFavorite(trackId: number, rating: number, extraHttpRequestParams?: any): Observable<{}> {
    return this.markFavoriteWithHttpInfo(trackId, rating, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Mark the track as unblocked
   *
   * @param trackId The track id
   */
  public unmarkBlocked(trackId: number, extraHttpRequestParams?: any): Observable<{}> {
    return this.unmarkBlockedWithHttpInfo(trackId, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Unmark the track as favorite
   *
   * @param trackId The track id
   */
  public unmarkFavorite(trackId: number, extraHttpRequestParams?: any): Observable<{}> {
    return this.unmarkFavoriteWithHttpInfo(trackId, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }


  /**
   * Mark the track as blocked
   *
   * @param trackId The track id
   */
  public markBlockedWithHttpInfo(trackId: number, extraHttpRequestParams?: any): Observable<HttpResponse<Object>> {
    const path = this.getBasePath() + `/tracks/${trackId}/block`;

    let queryParameters = new HttpParams();
    let headers = this.getDefaultHeaders();

    // verify required parameter 'trackId' is not null or undefined
    if (trackId === null || trackId === undefined) {
      throw new Error('Required parameter trackId was null or undefined when calling markBlocked.');
    }


    // to determine the Accept header
    let produces: string[] = [];


    let requestOptions = {
      headers: headers,
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.PUT, path, requestOptions);
  }

  /**
   * Mark the track as favorite
   *
   * @param trackId The track id
   * @param rating The rating
   */
  public markFavoriteWithHttpInfo(trackId: number, rating: number, extraHttpRequestParams?: any): Observable<HttpResponse<Object>> {
    const path = this.getBasePath() + `/tracks/${trackId}/favorite`;

    let queryParameters = new HttpParams();
    let headers = this.getDefaultHeaders();

    // verify required parameter 'trackId' is not null or undefined
    if (trackId === null || trackId === undefined) {
      throw new Error('Required parameter trackId was null or undefined when calling markFavorite.');
    }
    // verify required parameter 'rating' is not null or undefined
    if (rating === null || rating === undefined) {
      throw new Error('Required parameter rating was null or undefined when calling markFavorite.');
    }


    // to determine the Content-Type header
    let consumes: string[] = [];
    let canConsumeForm = this.canConsumeForm(consumes);
    let useForm = false;
    let formParams = new HttpParams();

    // to determine the Accept header
    let produces: string[] = [];


    if (rating !== undefined) {
      formParams = formParams.set('rating', rating.toString());
    }

    let requestOptions = {
      headers: headers,
      body: RequestsUtils.getJsonObjectFromFormParams(formParams),
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.PUT, path, requestOptions);
  }

  /**
   * Mark the track as unblocked
   *
   * @param trackId The track id
   */
  public unmarkBlockedWithHttpInfo(trackId: number, extraHttpRequestParams?: any): Observable<HttpResponse<Object>> {
    const path = this.getBasePath() + `/tracks/${trackId}/block`;

    let queryParameters = new HttpParams();
    let headers = this.getDefaultHeaders();

    // verify required parameter 'trackId' is not null or undefined
    if (trackId === null || trackId === undefined) {
      throw new Error('Required parameter trackId was null or undefined when calling unmarkBlocked.');
    }


    // to determine the Accept header
    let produces: string[] = [];


    let requestOptions = {
      headers: headers,
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.DELETE, path, requestOptions);
  }

  /**
   * Unmark the track as favorite
   *
   * @param trackId The track id
   */
  public unmarkFavoriteWithHttpInfo(trackId: number, extraHttpRequestParams?: any): Observable<HttpResponse<Object>> {
    const path = this.getBasePath() + `/tracks/${trackId}/favorite`;

    let queryParameters = new HttpParams();
    let headers = this.getDefaultHeaders();

    // verify required parameter 'trackId' is not null or undefined
    if (trackId === null || trackId === undefined) {
      throw new Error('Required parameter trackId was null or undefined when calling unmarkFavorite.');
    }


    // to determine the Accept header
    let produces: string[] = [];


    let requestOptions = {
      headers: headers,
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.DELETE, path, requestOptions);
  }

}
