import {
  Component,
  ComponentFactoryResolver,
  ElementRef,
  HostListener,
  Inject,
  Renderer2,
  ViewChild
} from '@angular/core';
import {ActivatedRoute, Event, NavigationEnd, NavigationStart, Params, Router} from '@angular/router';
import {HistoryService} from './services/history.service';
import {GeneralService} from './services/general.service';
import {MixpanelService} from './services/mixpanel.service';
import {AppSettingsService} from './services/app-settings.service';
import {LoginHelperService} from './api-client/helper/login-helper.service';
import {LoggedUserService} from './api-client/helper/logged-user.service';
import {LoggedUserBlockedItemsService} from './services/logged-user-blocked-items.service';
import {LoggedUserFavouritesService} from './services/logged-user-favourites.service';
import {LoggedUserPlanService} from './services/logged-user-plan.service';
import {LoggedUserSettingsService} from './api-client/helper/logged-user-settings.service';
import {AppConstants, hoslog} from './app.constants';
import {TopBarComponentService} from './services/top-bar-component.service';
import {TopBarDirective} from './directives/top-bar.directive';
import {TopBarItem} from './shared/top-bar/top-bar-item';
import {TopBarComponent} from './shared/top-bar/top-bar-component';
import {DeviceDetectorService} from 'ngx-device-detector';
import {AccountService} from './api-client';
import {HosPlayerAutoStorageService} from './services/player/hos-player-auto-storage.service';
import {ChannelsListService} from './services/channels-list.service';
import {BackgroundService} from './services/background.service';
import {SubSink} from 'subsink';
import {ServerMessageService} from './services/server-message.service';
import {MetaUtilsService} from './services/meta-utils.service';
import {filter} from 'rxjs/operators';
import {LoginAnalyticsHelperService} from './services/login-analytics-helper.service';
import {StopTimerService} from './services/stop-timer.service';
import {DOCUMENT} from '@angular/common';
import {BehaviorSubject} from 'rxjs';
import {AlertsUtilService} from './services/alerts-util.service';
import {NoInternetConnectionService} from './services/no-internet-connection.service';

/*
 host: {
 '(window:resize)': 'onResize($event)'
 }
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [HistoryService],
})
export class AppComponent {

  @ViewChild(TopBarDirective, { static: true }) topBarHost: TopBarDirective;

  @ViewChild('contentContainer', { static: true }) contentContainer: ElementRef;
  extraTopContentAdded$ = new BehaviorSubject(false);

  private subs = new SubSink();

  private subHeaderLeftOffset = 211;
  private currentUrl: string;

  // canShowUI = true;

  constructor(public loginHelperService: LoginHelperService,
              private loggedUserService: LoggedUserService,
              public history: HistoryService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              public gs: GeneralService,
              private appSettingsService: AppSettingsService, // force loading the settings on startup
              private loggedUserFavouritesService: LoggedUserFavouritesService,
              private loggedUserBlockedItemsService: LoggedUserBlockedItemsService,
              private loggedUserPlanService: LoggedUserPlanService,
              private loggedUserSettingsService: LoggedUserSettingsService,
              private topBarComponentService: TopBarComponentService,
              private componentFactoryResolver: ComponentFactoryResolver,
              private hosPlayerAutoStorageService: HosPlayerAutoStorageService,
              private noInternetConnectionService: NoInternetConnectionService,
              private accountService: AccountService,
              private mixpanelService: MixpanelService,
              private metaUtilsService: MetaUtilsService,
              private serverMessageService: ServerMessageService,
              private channelsListService: ChannelsListService,
              public backgroundService: BackgroundService,
              private loginAnalyticsHelperService: LoginAnalyticsHelperService,
              private alertsUtilService: AlertsUtilService,
              public deviceService: DeviceDetectorService,
              private stopTimerService: StopTimerService,
              @Inject(DOCUMENT) document,
              private renderer2: Renderer2) {


    router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        // You only receive NavigationStart events
        // console.log(JSON.stringify(event));

        // headless mode for mobile
        if (event.url && event.url.startsWith('/headless')) {
          this.renderer2.addClass(document.body, 'headless');
        }

        // Scroll top
        if (this.contentContainer && this.contentContainer.nativeElement) {
          this.contentContainer.nativeElement.scrollTo(0, 0);
          // window.scrollTo(0, 0);
          // console.log('scroll main');
        }

        // Reset stop timer
        this.stopTimerService.reset();

        // Meta updates
        metaUtilsService.updateDefaultInfo();
      });

    mixpanelService.init();

    this.subs.sink = this.topBarComponentService.addComponentRequests$.subscribe(topBarItem => {
      hoslog('New component request');
      this.setTopBarComponent(topBarItem);
    });

    this.subs.sink = this.topBarComponentService.clearTopBarRequests$.subscribe(topBarItem => {
      hoslog('Clear top bar');
      this.clearTopBar();
    });

    this.subs.sink = this.noInternetConnectionService.isInternetAvailable$.subscribe(
      v => {
        if (v === true) {
          // hoslog('Is Internet Available');
        } else {
          // hoslog('No Internet Available');
          // show dialog
          this.alertsUtilService.show504Alert(
            () => {
              this.noInternetConnectionService.changeIsInternetAvailable(true);
            }
          );
        }
      }
    );

    this.gs.hideLoginForm();
    const me = this;

    this.subs.sink = this.loginHelperService.isLoggedIn$.subscribe(
      isLoggedIn => {
        // when the user does the login, hide the form automatically
        if (isLoggedIn === true) {
          hoslog('Setting loginFormVisible: ' + !isLoggedIn);
          me.gs.setLoginFormVisible(!isLoggedIn);

          me.checkFirstRun();
        }
      }
    );

    this.subs.sink = this.appSettingsService
      .appSettings
      .subscribe(settings => {
        // console.log('App settings loaded');
      });

    this.subs.sink = this.activatedRoute.queryParams.subscribe(
      (params: Params) => {
        hoslog('loginRequired: ' + params['loginRequired']);
        if (params['loginRequired'] == 'true') {
          hoslog('Show modal panel telling the user to login');
          this.gs.showLoginForm();
        }

        /*if (params['headless'] == 'true') {
          this.renderer2.addClass(document.body, 'headless');
        } else {
          this.renderer2.removeClass(document.body, 'headless');
        }*/
      }
    );

    router.events.forEach((ev: Event) => {
      if (ev instanceof NavigationEnd) {
        history.pushUrl(router.url);

        mixpanelService.track('Page view', {url: ev.url});

        this.currentUrl = ev.url;

        this.gs.setChildCompLoaded(false);
        this.gs.setPageScroll(true);

        // close the peek popup
        this.gs.setOpenPeekPopup(false);
        /*if (document.getElementsByClassName('peek-overlay').length > 0) {
          let overlay = document.getElementsByClassName('peek-overlay')[0];
          overlay.parentNode.removeChild(overlay);
        }*/

        this.gs.checkChildLoaded();
      }
    });

    window.onpopstate = function (event) {
      history.popUrl();
    };

    /*
    // Redirect to to home if there is a system maintenance message
    this.subs.sink = this.serverMessageService.systemMaintenanceVisible$.subscribe(
      visible => {
        if (visible && this.currentUrl !== '/home') {
          this.router.navigate(['/home']);
        }
      }
    );
    */
  }


  checkFirstRun() {
    this.subs.sink = this.accountService.getIsFirstRun().subscribe(isFirstRun => {
      // console.log("getIsFirstRun: " + isFirstRun);
      if (isFirstRun) {
        this.subs.sink = this.accountService.setFirstRunDone().subscribe(isFirstRun => {
          // console.log("getIsFirstRunDone");
          this.router.navigate([AppConstants.SubscriptionPage], {queryParams: {create: 1}});
        });
      }
    });
  }

  ngOnInit() {
    const that = this;
    /*this.subs.sink = this.gs.title$
      .subscribe(item => (item === "PROGRAMS") ? document.getElementsByTagName("body")[0].className = "new-background" : document.getElementsByTagName("body")[0].className = "");*/

    /*this.subs.sink = this.gs.childLoaded$
      .subscribe(loaded => {
        that.setChildWidth(loaded);
      });*/


  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  setTopBarComponent(topBarItem: TopBarItem) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(topBarItem.component);

    const viewContainerRef = this.topBarHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    (<TopBarComponent>componentRef.instance).data = topBarItem.data;

    this.extraTopContentAdded$.next(true);
  }

  clearTopBar() {
    const viewContainerRef = this.topBarHost.viewContainerRef;
    viewContainerRef.clear();
    this.extraTopContentAdded$.next(false);
  }

  /*setChildWidth(loaded) {
    if (loaded) {
      let mainCont = <HTMLElement>document.getElementsByClassName('inner-component-container')[0];
      if (mainCont) {
        mainCont.style.width = document.getElementById('mainContainer').offsetWidth + 'px';
      } else {
        console.warn("setChildWidth: inner-component-container not found on the current page, please add it at the top of the inner component associated with the current page");
      }
    }
  }

  onResize(event) {
    this.setChildWidth(true);
  }*/

  /*toggleUi(canShowUI: boolean) {
    console.log('toggleUi: ' + canShowUI);
    this.canShowUI = canShowUI;
  }*/

  /*@HostListener('mousemove', ['$event'])
  onMousemove(event: MouseEvent) {
    console.log('mouse moved');
  }*/
}
