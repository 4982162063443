/**
 * HOS API
 *
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 */
import {Injectable, Injector} from "@angular/core";
import {HosBaseService} from "./hos-base.service";

import {HttpResponse, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import { map } from 'rxjs/operators';
import "../rxjs-operators";
import {AppConstants} from "../../app.constants";
import {Channel} from "../model/Channel";

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class ChannelsService extends HosBaseService {


  constructor(injector: Injector) {
     super(injector);
   }

  /**
   * Return the channel detail
   *
   * @param channelId The channel id
   */
  public getChannelDetail(channelId: number, extraHttpRequestParams?: any): Observable<Channel> {
    return this.getChannelDetailWithHttpInfo(channelId, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Return the channels
   *
   */
  public getChannels(hemisphere: Channel.HemisphereEnum, includePrograms: boolean = false, extraHttpRequestParams?: any): Observable<Channel[]> {
    return this.getChannelsWithHttpInfo(hemisphere, includePrograms, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }


  /**
   * Return the channel detail
   *
   * @param channelId The channel id
   */
  public getChannelDetailWithHttpInfo(channelId: number, extraHttpRequestParams?: any): Observable<HttpResponse<Channel>> {
    const path = this.getBasePath() + `/channels/${channelId}`;

    let queryParameters = new HttpParams();
    let headers = this.getNoLoginHeaders();

    // verify required parameter 'channelId' is not null or undefined
    if (channelId === null || channelId === undefined) {
      throw new Error('Required parameter channelId was null or undefined when calling getChannelDetail.');
    }


    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];


    let requestOptions = {
      headers: headers,
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.GET, path, requestOptions);
  }

  /**
   * Return the channels
   *
   */
  public getChannelsWithHttpInfo(hemisphere: Channel.HemisphereEnum, includePrograms: boolean = false, extraHttpRequestParams?: any): Observable<HttpResponse<Channel[]>> {
    const path = this.getBasePath() + `/channels` + (includePrograms ? '/complete' : '');

    let queryParameters = new HttpParams();
    let headers = this.getNoLoginHeaders();

    queryParameters = queryParameters.set('hemisphere', <any>hemisphere);

    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];

    let requestOptions = {
      headers: headers,
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.GET, path, requestOptions);
  }

}
