<div class="search-wrapper">
  <input class="search-input" placeholder="Search" name="search" role="search" [(ngModel)]="searchValueService.searchValue"
         (ngModelChange)="onChange($event)" (focus)="onFocus($event)" aria-label="Search">
  <span class="search-icon"
        [ngClass]="{'has-text': searchValueService.hasText()}" (click)="resetSearch()"></span>
  <div class="popup" [class.active]="isPopupOpen" (mousedown)="setPopupFocused()">
    <div class="popup-wrapper">
      <div class="no-results" *ngIf="loadingHelperService.isLoading('search');else loaded" aria-label="Loading">LOADING...</div>
      <ng-template #loaded>
        <div class="no-results" *ngIf="searchValueService.isResultsEmpty()" aria-label="No results found">NO RESULTS FOUND</div>
        <div class="search-results" *ngIf="!searchValueService.isResultsEmpty()">
          <div class="part-wrapper" *ngIf="searchValueService.results?.programs && !searchValueService.results?.programs?.empty">
            <div class="container-fluid programs">
              <div class="row header">
                <div class="col-sm-10 col-md-10 col-lg-10" role="heading" tabindex="0">
                  PROGRAMS
                </div>
                <div class="col-sm-2 col-md-2 col-lg-2 show-less-more" *ngIf="searchValueService.results?.programs?.numberOfElements>3">
                  <a (click)="searchValueService.showToggle('programs')" attr.aria-label="{{searchValueService.showAllToggles.programs ? 'show less programs' : 'show all programs'}}">{{searchValueService.showAllToggles.programs ? 'show less' : 'show all'}}</a>
                </div>
              </div>
              <app-search-program-item class="row result" *ngFor="let item of searchValueService.results?.programs?.content | limitToFirst:3:!searchValueService.showAllToggles.programs"
                                       [item]="item"></app-search-program-item>
            </div>
          </div>
          <div class="part-wrapper" *ngIf="searchValueService.results?.albums && !searchValueService.results?.albums?.empty">
            <div class="container-fluid albums">
              <div class="row header">
                <div class="col-sm-10 col-md-10 col-lg-10" role="heading" tabindex="0">
                  ALBUMS
                </div>
                <div class="col-sm-2 col-md-2 col-lg-2 show-less-more" *ngIf="searchValueService.results?.albums?.numberOfElements>3">
                  <a (click)="searchValueService.showToggle('albums')" attr.aria-label="{{searchValueService.showAllToggles.albums ? 'show less albums' : 'show all albums'}}">{{searchValueService.showAllToggles.albums ? 'show less' : 'show all'}}</a>
                </div>
              </div>
              <app-search-album-item class="row result" *ngFor="let item of searchValueService.results?.albums?.content | limitToFirst:3:!searchValueService.showAllToggles.albums"
                                     [item]="item"></app-search-album-item>
            </div>
          </div>
          <div class="part-wrapper" *ngIf="searchValueService.results?.playlists && !searchValueService.results?.playlists?.empty">
            <div class="container-fluid playlists">
              <div class="row header">
                <div class="col-sm-10 col-md-10 col-lg-10" role="heading" tabindex="0">
                  PLAYLISTS
                </div>
                <div class="col-sm-2 col-md-2 col-lg-2 show-less-more" *ngIf="searchValueService.results?.playlists?.numberOfElements>3">
                  <a (click)="searchValueService.showToggle('playlists')" attr.aria-label="{{searchValueService.showAllToggles.playlists ? 'show less playlists' : 'show all playlists'}}">{{searchValueService.showAllToggles.playlists ? 'show less' : 'show all'}}</a>
                </div>
              </div>
              <app-search-playlist-item class="row result" *ngFor="let item of searchValueService.results?.playlists?.content | limitToFirst:3:!searchValueService.showAllToggles.playlists"
                                        [item]="item"></app-search-playlist-item>
            </div>
          </div>
          <div class="part-wrapper" *ngIf="searchValueService.results?.tracks && !searchValueService.results?.tracks?.empty">
            <div class="container-fluid tracks">
              <div class="row header">
                <div class="col-sm-10 col-md-10 col-lg-10" role="heading" tabindex="0">
                  TRACKS
                </div>
                <div class="col-sm-2 col-md-2 col-lg-2 show-less-more" *ngIf="searchValueService.results?.tracks?.numberOfElements>3">
                  <a (click)="searchValueService.showToggle('tracks')" attr.aria-label="{{searchValueService.showAllToggles.tracks ? 'show less tracks' : 'show all tracks'}}">{{searchValueService.showAllToggles.tracks ? 'show less' : 'show all'}}</a>
                </div>
              </div>
              <app-search-track-item class="row result" *ngFor="let item of searchValueService.results?.tracks?.content | limitToFirst:3:!searchValueService.showAllToggles.tracks"
                                     [item]="item"></app-search-track-item>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
