/**
 * HOS API
 *
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 *
 *
 *
 */

import { Payment } from './Payment';
import { Plan } from './Plan';


export interface PlanPayment {
    /**
     * Plan
     */
    plan: Plan;

    /**
     * Payment
     */
    payment: Payment;

}
