import { Injectable } from '@angular/core';
import {hoslog} from '../app.constants';

@Injectable()
export class HistoryService {

  private historyUrls = [];
  private currentIndex : number = 0;
  private skipSave : boolean = false;

  constructor() { }

  pushUrl(url) {
    if (url && url.startsWith('/surprise-me')) return; // skipping the surprise-me redirect

    // hoslog(`pushUrl: ${url}`);
    // hoslog(`history before: ${this.historyUrls}`);
    if (/*url != this.historyUrls[this.historyUrls.length - 1] && */!this.skipSave) {
      // remove everything that is after the current index
      this.historyUrls.splice(this.currentIndex+1, (this.historyUrls.length - this.currentIndex));
      this.historyUrls.push(url);
      // prevent to increase index when initial page loaded
      if (this.historyUrls.length > 1) {
        this.currentIndex++;
      }
    } else {
      this.skipSave = false;
    }
    // hoslog(`history after: ${this.historyUrls}`);
  }

  // remove url from history if it was added with back/forward browser button
  popUrl() {
    // hoslog(`popUrl`);
    // hoslog(`history: ${this.historyUrls}`);
    this.historyUrls.pop();
    this.currentIndex--;
  }

  getPrev(navigate : boolean = false) {
    if (this.historyUrls[this.currentIndex - 1]) {
      if (navigate) {
        this.currentIndex--;
        return this.historyUrls[this.currentIndex];
      } else {
        return this.historyUrls[this.currentIndex - 1];
      }
    } else {
      return null;
    }
  }

  getCurrent() {
    return (this.historyUrls[this.currentIndex]) ? this.historyUrls[this.currentIndex] : null;
  }

  getNext(navigate : boolean = false) {
    if (this.historyUrls[this.currentIndex + 1]) {
      if (navigate) {
        this.currentIndex++;
        return this.historyUrls[this.currentIndex];
      } else {
        return this.historyUrls[this.currentIndex + 1];
      }
    } else {
      return null;
    }
  }

  // do not save url because we go back or forward
  setSkipSave() {
    this.skipSave = true;
  }
}
