<div class="sleep-panel" (blur)="closePanel()" *ngIf="!(sleepTimerService.sleepTimerEnabled$ | async); else timerOn">
  <div class="sleep-duration">
    <span class="input-label">PLAY FOR</span>
    <div class="sleep-input-selector">
      <div class="duration-action-area" (click)="changeDuration(this.sleepOptions.stepHr)">
        <img class="rotate-180" src="/assets/images/select_arrow.svg">
      </div>
      <input type="text" name="sleepHr" value="{{sleepValue.hr}}" readonly>
      <div class="duration-action-area" (click)="changeDuration(-this.sleepOptions.stepHr)">
        <img src="/assets/images/select_arrow.svg">
      </div>
    </div>
    <span class="input-label">HR</span>
    <div class="sleep-input-selector">
      <div class="duration-action-area" (click)="changeDuration(this.sleepOptions.stepMin)">
        <img class="rotate-180" src="/assets/images/select_arrow.svg">
      </div>
      <input type="text" name="sleepMin" value="{{sleepValue.min}}" readonly>
      <div class="duration-action-area" (click)="changeDuration(-this.sleepOptions.stepMin)">
        <img src="/assets/images/select_arrow.svg">
      </div>
    </div>
    <span class="input-label">MIN</span>
  </div>
  <button id="playToEndOfStream" attr.aria-label="Play to end of current stream" [ngClass]="{'selected':sleepData.playToStreamEnd}" (click)="togglePlayToEndStream()">PLAY TO END OF CURRENT STREAM</button>
  <button id="sleepClose" attr.aria-label="Close" (click)="closePanel()">CANCEL</button>
  <button id="sleepSet" attr.aria-label="Set" (click)="setTimer(sleepData)">SET</button>
</div>
<ng-template #timerOn>
  <div class="sleep-panel sleep-panel-set" (blur)="closePanel()">
    <div class="sleep-duration sleep-duration-set">
      <span class="date-set-label" *ngIf="sleepTimerService.sleepTimerDate !== -1">PLAY UNTIL {{sleepTimerService.getFormattedDate()}} </span>
      <span class="date-set-label" *ngIf="sleepTimerService.sleepTimerDate === -1">PLAY TO END OF CURRENT STREAM</span>
    </div>
    <div class="sleep-cancel-box">
      <button id="sleepCancel" attr.aria-label="Close" (click)="cancelTimer()">CANCEL</button>
    </div>
  </div>
</ng-template>
