import {Injectable, OnDestroy} from '@angular/core';
import {LoggedUserService} from "../api-client/helper/logged-user.service";
import {AccountService} from "../api-client/api/account.service";
import {User} from "../api-client/model/User";
import {FavoriteWrapper} from "../api-client/model/FavoriteWrapper";
import {HosItem} from "../api-client/model/HosItem";
import {FavoriteItemWrapper} from "../api-client/model/FavoriteItemWrapper";
import {AlbumsService} from "../api-client/api/albums.service";
import {TracksService} from "../api-client/api/tracks.service";
import {ProgramsService} from "../api-client/api/programs.service";
import {BehaviorSubject, Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {Track} from "../api-client/model/Track";
import {hoslog} from "../app.constants";
import {Page} from '../api-client/model/generic/page';
import {SubSink} from 'subsink';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class LoggedUserFavouritesService implements OnDestroy{
  private subs = new SubSink();

  private favourites: FavoriteWrapper;
  private _favouritesObs: BehaviorSubject<FavoriteWrapper>;

  constructor(private loggedUserService: LoggedUserService,
              private accountService: AccountService,
              private albumsService: AlbumsService,
              private tracksService: TracksService,
              private toastr: ToastrService,
              private programsService: ProgramsService) {
    // Empty wrapper initially
    this.favourites = this.getEmptyWrapper();
    this._favouritesObs = new BehaviorSubject(this.favourites);
    this.subs.sink = this.loggedUserService.loggedUserDetailsObs.subscribe(
      user => {
        if (user != null) {
          // New user, getting account data
          this.refreshUserData(user);
        } else {
          // empty
          this.setFavourites(this.getEmptyWrapper());
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private setFavourites(fw: FavoriteWrapper) {
    this.favourites = fw;
    this.notifyObservers();
  }

  private notifyObservers() {
    this._favouritesObs.next(this.favourites);
  }

  private refreshUserData(user: User) {
    // Getting the blocked items
    //this.accountService.getBlockedItems();
    // Getting the plan
    //this.accountService.getCurrentPlan();
    // Getting the favourites
    this.accountService.getFavorites()
      .subscribe(
        res => {
          hoslog("Favourites loaded");

          // Favourite tracks: removing all the other tracks
          if (res && res.tracks && res.tracks.content && res.tracks.content.length > 0) {
            for (let track of res.tracks.content) {
              if (track.hosItem && track.hosItem.tracks) {
                let trackFound = track.hosItem.tracks.filter(subtrack => {
                  return subtrack.id == track.hosItem.id;
                });
                if (trackFound && trackFound.length > 0) {
                  track.hosItem.tracks = trackFound;
                }
              }
            }
          }
          this.setFavourites(res);
        },
        error => {
          hoslog("Favourites: error: " + JSON.stringify(error));
          // TODO schedule a refresh?
        });

  }

  public refreshFavourites() {
    if (this.loggedUserService.loggedUserDetailsObs.value != null) {
      this.refreshUserData(this.loggedUserService.loggedUserDetailsObs.value);
    }
  }

  get favouritesObs(): BehaviorSubject<FavoriteWrapper> {
    return this._favouritesObs;
  }

  /**
   * Return 0 if not in favourites
   * @param item
   */
  public getRating(item: HosItem): number {
    let itemFound = this.findItem(item);
    if (itemFound != null) {
      // hoslog("Get rating: " + itemFound.rating);
      if (itemFound.rating > 3) {
        return 3;
      } else {
        return itemFound.rating;
      }
    } else {
      return 0;
    }
  }

  public setRating(item: HosItem, rating: number): Observable<{}> {
    if (rating > 3) {
      rating = 3; // fixing upper overflow
    }

    let successMessage = rating + ' star' + (rating === 1 ? '' : 's') + (rating > 0 ? ' set' : ' removed');
    let erroMessage = 'An error happened, please try again or contact support@hos.com';

    // If I want to mark as favourite
    if (rating > 0) {
      if (item.type == HosItem.TypeEnum.Program) {
        return this.programsService.markFavorite(item.id, rating).pipe(
          tap(
            res => {
              hoslog("Favourites set to " + rating + " for item id = " + item.id);
              this.setRatingInternally(item, rating); // avoid the complete refresh
              this.toastr.success(successMessage);
            },
            error => {
              hoslog("Favourites set: error: " + JSON.stringify(error));
              this.toastr.error(erroMessage);
            })
        );
      } else if (item.type == HosItem.TypeEnum.Album) {
        return this.albumsService.markFavorite(item.id, rating).pipe(
          tap(
            res => {
              hoslog("Favourites set to " + rating + " for item id = " + item.id);
              this.setRatingInternally(item, rating); // avoid the complete refresh
              this.toastr.success(successMessage);
            },
            error => {
              hoslog("Favourites set: error: " + JSON.stringify(error));
              this.toastr.error(erroMessage);
            })
        );
      } else if (item.type == HosItem.TypeEnum.Track) {
        return this.tracksService.markFavorite(item.id, rating).pipe(
          tap(
            res => {
              hoslog("Favourites set to " + rating + " for item id = " + item.id);
              this.setRatingInternally(item, rating); // avoid the complete refresh
              this.toastr.success(successMessage);
            },
            error => {
              hoslog("Favourites set: error: " + JSON.stringify(error));
              this.toastr.error(erroMessage);
            })
        );
      }
    } else { // If I want to remove from favourites
      if (item.type == HosItem.TypeEnum.Program) {
        return this.programsService.unmarkFavorite(item.id).pipe(
          tap(
            res => {
              hoslog("Favourites removed for item id = " + item.id);
              this.setRatingInternally(item, rating); // avoid the complete refresh
              this.toastr.success(successMessage);
            },
            error => {
              hoslog("Favourites remove error: " + JSON.stringify(error));
              this.toastr.error(erroMessage);
            })
        );
      } else if (item.type == HosItem.TypeEnum.Album) {
        return this.albumsService.unmarkFavorite(item.id).pipe(
          tap(
            res => {
              hoslog("Favourites removed item id = " + item.id);
              this.setRatingInternally(item, rating); // avoid the complete refresh
              this.toastr.success(successMessage);
            },
            error => {
              hoslog("Favourites remove error: " + JSON.stringify(error));
              this.toastr.error(erroMessage);
            })
        );
      } else if (item.type == HosItem.TypeEnum.Track) {
        return this.tracksService.unmarkFavorite(item.id).pipe(
          tap(
            res => {
              hoslog("Favourites removed for item id = " + item.id);
              this.setRatingInternally(item, rating); // avoid the complete refresh
              this.toastr.success(successMessage);
            },
            error => {
              hoslog("Favourites remove error: " + JSON.stringify(error));
              this.toastr.error(erroMessage);
            })
        );
      }
    }
  }

  /**
   * changes only the internal array
   * @param item
   * @param rating
   */
  private setRatingInternally(item: HosItem, rating: number) {
    // Update the array to avoid a complete refresh
    let itemFound = this.findItem(item);
    if (itemFound != null) {
      // found, change it
      itemFound.rating = rating;
    } else { // new item, add it
      let itemArrayName = this.getItemArrayName(item);
      if (!this.favourites[itemArrayName]) {
        // create an empty array
        this.favourites[itemArrayName] = this.getEmptyWrapperItems(itemArrayName);
      }
      this.favourites[itemArrayName].content.push({
        hosItem: item,
        rating: rating
      });
    }
    this.notifyObservers();
  }

  private getItemArrayName(item: HosItem): string {
    let itemArrayName = "";
    switch (item.type) {
      case HosItem.TypeEnum.Program:
        itemArrayName = 'programs';
        break;
      case HosItem.TypeEnum.Album:
        itemArrayName = 'albums';
        break;
      case HosItem.TypeEnum.Track:
        itemArrayName = 'tracks';
        break;
    }
    return itemArrayName;
  }

  private findItem(item: HosItem): FavoriteItemWrapper {
    let itemArrayName = this.getItemArrayName(item);

    if (itemArrayName && this.favourites && this.favourites[itemArrayName] && this.favourites[itemArrayName]['content']) {
      for (let itm of this.favourites[itemArrayName]['content']) {
        if (itm.hosItem.id === item.id) {
          return itm;
        }
      }
    }

    // Not found
    return null;
  }

  private getEmptyWrapper(): FavoriteWrapper {
    return {
      tracks: this.getEmptyWrapperItems('tracks'),
      programs: this.getEmptyWrapperItems('programs'),
      albums: this.getEmptyWrapperItems('albums')
    };
  }

  private getEmptyWrapperItems(itemType: string): Page<FavoriteItemWrapper> {
    switch (itemType) {
      case 'tracks':
        return {
          content: [],
          empty: true,
          first: true,
          last: true,
          number: 0,
          numberOfElements: 0,
          size: 0,
          totalElements: 0,
          totalPages: 0
        };
      case 'programs':
        return {
          content: [],
          empty: true,
          first: true,
          last: true,
          number: 0,
          numberOfElements: 0,
          size: 0,
          totalElements: 0,
          totalPages: 0
        };
      case 'albums':
        return {
          content: [],
          empty: true,
          first: true,
          last: true,
          number: 0,
          numberOfElements: 0,
          size: 0,
          totalElements: 0,
          totalPages: 0
        };
    }
  }

}
