import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {LoginHelperService} from "../login-helper.service";

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private loginHelperService: LoginHelperService,
              private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let url: string = state.url;

    return this.checkLogin(url);
  }

  checkLogin(url: string): boolean {
    if (this.loginHelperService.isLoggedIn$.value) {
      return true;
    }

    // Store the attempted URL for redirecting
    this.loginHelperService.redirectUrl = url;

    // Navigate to the home page specifying that the login is required
    this.router.navigate(["/"], {
      queryParams: {
        "loginRequired": true
      }
    });
    return false;
  }
}
