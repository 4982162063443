import { Component, OnInit, Input } from '@angular/core';
import {Album} from "../../api-client/model/Album";
import {ExtLinksUtils} from '../utils/ext-links.utils';

@Component({
  selector: 'app-album-table-item',
  templateUrl: './album-table-item.component.html',
  styleUrls: ['./album-table-item.component.scss']
})
export class AlbumTableItemComponent implements OnInit {

  @Input('albumData') item: Album = null;
  @Input('hideActions') hideActions;
  @Input('canPlay') canPlay = true;

  constructor() { }

  ngOnInit() {
  }

  openBuyCdUrl() {
    ExtLinksUtils.openExtLink(this.item.buyCdUrl);
  }
}
