/**
 * HOS API
 *
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 */
import {Injectable, Injector} from "@angular/core";
import {HosBaseService} from "./hos-base.service";

import {HttpResponse, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import { map } from 'rxjs/operators';
import "../rxjs-operators";
import {AppConstants} from "../../app.constants";
import {TextFragment} from "../model/TextFragment";

/* tslint:disable:no-unused-variable member-ordering */

export enum TextFragmentAppType {
  IOS, Web, All
}

@Injectable()
export class TextFragmentsService extends HosBaseService {


  constructor(injector: Injector) {
     super(injector);
   }

  /**
   * Return the page
   *
   */
  public getTextFragments(app: TextFragmentAppType, extraHttpRequestParams?: any): Observable<TextFragment[]> {
    return this.getTextFragmentsWithHttpInfo(app, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }


  /**
   * Return the page
   *
   */
  public getTextFragmentsWithHttpInfo(app: TextFragmentAppType, extraHttpRequestParams?: any): Observable<HttpResponse<TextFragment[]>> {
    const path = this.getBasePath() + `/text-fragments/${app}`;

    let queryParameters = new HttpParams();
    let headers = this.getDefaultHeaders();

    // verify required parameter 'pageId' is not null or undefined
    if (app === null || app === undefined) {
      throw new Error('Required parameter app was null or undefined when calling getPage.');
    }


    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];


    let requestOptions = {
      headers: headers,
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.GET, path, requestOptions);
  }

  /**
   * Return the page
   *
   */
  public getTextFragment(app: TextFragmentAppType, location: string, label: string, extraHttpRequestParams?: any): Observable<TextFragment> {
    return this.getTextFragmentWithHttpInfo(app, location, label, extraHttpRequestParams)
      .pipe(
        map((response) => {
        if (response.status === 204) {
          return undefined;
        } else {
          return response.body;
        }
        })
      );
  }


  /**
   * Return the page
   *
   */
  public getTextFragmentWithHttpInfo(app: TextFragmentAppType, location: string, label: string, extraHttpRequestParams?: any): Observable<HttpResponse<TextFragment>> {
    const path = this.getBasePath() + `/text-fragments/${TextFragmentAppType[app]}/${location}/${label}`;

    let queryParameters = new HttpParams();
    let headers = this.getDefaultHeaders();

    // verify required parameter 'pageId' is not null or undefined
    if (app === null || app === undefined) {
      throw new Error('Required parameter app was null or undefined when calling getPage.');
    }

  if (location === null || location === undefined) {
      throw new Error('Required parameter location was null or undefined when calling getPage.');
    }

  if (label === null || label === undefined) {
      throw new Error('Required parameter label was null or undefined when calling getPage.');
    }


    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];


    let requestOptions = {
      headers: headers,
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.GET, path, requestOptions);
  }

}
