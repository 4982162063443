import {Component, Input, OnInit} from '@angular/core';
import {HosPlayerService} from "../../../services/player/hos-player.service";
import {HosQueue, HosQueueChannel, HosQueuePlaylist} from "../../../services/player/hos-queue";
import {Channel} from "../../../api-client/model/Channel";
import {MixpanelService} from "../../../services/mixpanel.service";
import {Router} from '@angular/router';
import {StopTimerService} from '../../../services/stop-timer.service';
import {PlayButtonIconSize} from '../../../app.constants';

@Component({
  selector: 'app-play-channel-button',
  templateUrl: './play-channel-button.component.html',
  styleUrls: ['./play-channel-button.component.scss']
})
export class PlayChannelButtonComponent implements OnInit {
  @Input() iconSize : PlayButtonIconSize;
  @Input() item: Channel;
  @Input() extraClasses: string = "";
  @Input() enabled: boolean = true;

  public queueItem: HosQueue = null;

  playButtonIconSize = PlayButtonIconSize;

  constructor(public hosPlayerService: HosPlayerService,
              private stopTimerService: StopTimerService) {
  }

  ngOnInit() {
    if (this.item) {
      this.queueItem = new HosQueueChannel(this.item);
    }
  }

  playChannel() {
    this.stopTimerService.reset();
    if (this.enabled) {
      if (this.queueItem == null && this.item) {
        this.queueItem = new HosQueueChannel(this.item);
      }
      const currentQueue = this.hosPlayerService.currentQueueObs.value;
      if (currentQueue && currentQueue.getQueueUniqueId() === this.queueItem.getQueueUniqueId()) { // if it's already in the queue
        // getting the queue version as it should be the completed one
        this.queueItem = currentQueue;
      }
      this.hosPlayerService.play(this.queueItem);
    }
  }

}
