import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'breakSlash'
})
export class BreakSlashPipe implements PipeTransform {

  transform(text: string): any {
    if (text) {
      return text.replace('/', ' / ');
    } else {
      return ""; // placeholder?
    }
  }

}
