/**
 * HOS API
 *
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 *
 *
 *
 */

import {PlanCost} from './PlanCost';


export interface Plan {
  /**
   * Plan id
   */
  id: number;

  /**
   * Name
   */
  name: string;

  /**
   * Plan type
   */
  type: string;

  /**
   * Subscription
   */
  subscription: boolean;

  secondary: boolean;

  /**
   * Is Gift plan
   */
  giftPlan?: boolean;

  /**
   * Description
   */
  description?: string;

  /**
   * Ordering
   */
  ordering: number;

  /**
   * In-app purchase product id
   */
  iapProductId?: string;

  /**
   * Prices
   */
  prices?: Array<PlanCost>;

  /**
   * Published plan
   */
  published?: boolean;

  /*
   * Calculated values
   */

  asLowAs?: number;
}

export namespace Plan {
  /*export enum TypeEnum {
      Budget = <any> 'budget',
      Basic = <any> 'basic',
      Standard = <any> 'standard',
      FullAccess = <any> 'full_access'
  }*/
}
