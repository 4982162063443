/**
 * HOS API
 *
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 *
 *
 *
 */



export interface Label {
    /**
     * Label id
     */
    id: number;

    /**
     * Name
     */
    name: string;

    /**
     * URL
     */
    url?: string;

    /**
     * Email
     */
    email?: string;

}
