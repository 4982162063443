import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {SearchPipe} from './search/search.pipe';
import {RatingComponent} from './rating/rating.component';
import {PopularityComponent} from './popularity/popularity.component';
import {CustomSelectComponent} from './custom-select/customSelect.component';
import {CarouselPaginationComponent} from './carousel-pagination/carouselPagination.component';
import {OptionPopupComponent} from './option-popup/option-popup.component';
import {AlbumPeekPopupComponent} from './album-item/peek-popup/peek-popup.component';
import {SmallTrackItemComponent} from './album-item/peek-popup/small-track-item/small-track-item.component';
import {SleepPanelComponent} from './sleep-panel/sleepPanel.component';
import {ProgramPeekPopupComponent} from './program-item/peek-popup/peek-popup.component';
import {SmallAlbumItemComponent} from './program-item/peek-popup/small-album-item/small-album-item.component';

import {MoreTextDirective} from './more-text/more-text.directive';

import {ProgramItemComponent} from './program-item/program-item.component';
import {AlbumItemComponent} from './album-item/album-item.component';
import {TrackItemComponent} from './track-item/track-item.component';
import {AlbumTableItemComponent} from './album-table-item/album-table-item.component';
import {PlanItemComponent} from './plan-item/plan-item.component';

import {SmallPopupItemComponent} from './option-popup/popup-item/small-popup-item.component';
import {LoadingComponent} from './loading/loading.component';
import {DebugJsonComponent} from './debug-json/debug-json.component';
import {ErrorMessageComponent} from './error-message/error-message.component';
import {LoadingHelperService} from './loading-helper/loading-helper.service';
import {SecondsToMinutesPipe} from './pipes/seconds-to-minutes.pipe';
import {NgSlugifyPipeModule, NgStringPipesModule, NgTruncatePipeModule, NgUpperFirstPipeModule} from 'angular-pipes';
import {ColumnizePipe} from './pipes/columnize.pipe';
import {RowizePipe} from './pipes/rowize.pipe';
import {DropDownCategoryListComponent} from './drop-down-category-list/drop-down-category-list.component';
import {CategoryListComponent} from './category-list/category-list.component';
import {FilterGenresPipe} from './pipes/filter-genres.pipe';
import {LimitToFirstPipe} from './pipes/limit-to-first.pipe';
import {VirtualScrollerModule} from 'ngx-virtual-scroller';
import {BlockButtonComponent} from './block/block-button/block-button.component';
import {RatingStarsComponent} from './rating-stars/rating-stars.component';
import {HtmlToPlainTextPipe} from './pipes/html-to-plain-text.pipe';
import {SelectPlaylistDialogComponent} from './option-popup/select-playlist-dialog/select-playlist-dialog.component';
import {PlayAlbumButtonComponent} from './play-buttons/play-album-button/play-album-button.component';
import {PlayProgramButtonComponent} from './play-buttons/play-program-button/play-program-button.component';
import {PlayAlbumTrackButtonComponent} from './play-buttons/play-album-track-button/play-album-track-button.component';
import {PlayChannelButtonComponent} from './play-buttons/play-channel-button/play-channel-button.component';
import {PlayPlaylistButtonComponent} from './play-buttons/play-playlist-button/play-playlist-button.component';
import {ProgramDetailComponent} from './program-detail/program-detail.component';
import {OrderHeaderComponent} from './order-header/order-header.component';
import {HosProgramImageUrlPipe} from './image-url/hos-program-image-url.pipe';
import {HosChannelImageUrlPipe} from './image-url/hos-channel-image-url.pipe';
import {HosAlbumImageUrlPipe} from './image-url/hos-album-image-url.pipe';
import {NoDataInTableComponent} from './no-data-in-table/no-data-in-table.component';
import {FavoriteTopBarComponent} from './favorite-top-bar/favorite-top-bar.component';
import {AlbumsTopBarComponent} from './albums-top-bar/albums-top-bar.component';
import {ProgramsTopBarComponent} from './programs-top-bar/programs-top-bar.component';
import {CreatePlaylistComponent} from './create-playlist/create-playlist.component';
import {SecondsToHoursPipe} from './pipes/seconds-to-hours.pipe';
import {BlockedTopBarComponent} from './blocked-top-bar/blocked-top-bar.component';
import {HosGenreImageUrlPipe} from './image-url/hos-genre-image-url.pipe';
import {BreakSlashPipe} from './pipes/break-slash.pipe';
import {GenresListComponent} from './genres-list/genres-list.component';
import {SelectGenreDialogComponent} from './select-genre-dialog/select-genre-dialog.component';
import {SplitUrlsPipe} from './pipes/split-urls.pipe';
import {AddHttpsToUrlPipe} from './pipes/add-https-to-url.pipe';
import {ColonNewLinePipe} from './pipes/colon-new-line.pipe';
import {PlayPlaylistTrackButtonComponent} from './play-buttons/play-playlist-track-button/play-playlist-track-button.component';
import {PlayerUtilsService} from './utils/player-utils';
import {PlayChannelTrackButtonComponent} from './play-buttons/play-channel-track-button/play-channel-track-button.component';
import {CustomCurrencyPipe} from './pipes/custom-current.pipe';
import {ConvertToUrlPipe} from './pipes/convert-to-url.pipe';
import {ServerMessageComponent} from './server-message/server-message.component';
import {ParseJsonPipe} from './pipes/parse-json.pipe';
import {CardImgPipe} from './pipes/card-img.pipe';
import {PayPalPaymentAcceptTermsComponent} from './pay-pal-payment-accept-terms/pay-pal-payment-accept-terms.component';
import {SelectChangePlanTypeDialogComponent} from './select-change-plan-type-dialog/select-change-plan-type-dialog.component';
import {RouterModule} from '@angular/router';
import {PlayerExtraCutPipe} from './pipes/player-extra-cut.pipe';
import {FooterLinksComponent} from './footer-links/footer-links.component';
import { ActiveTrackPipe } from './pipes/active-track.pipe';
import {AbsPipe} from './pipes/abs.pipe';
import {SubscriptionPaymentComponent} from './payment/payment.component';
import {RecurlyFormComponent} from './recurly-form/recurly-form.component';
import {ChannelItemComponent} from './channel-item/channel-item.component';
import {AlbumDetailComponent} from './album-detail/album-detail.component';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {PromoPlayerComponent} from './promo-player/promo-player.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgStringPipesModule,
        VirtualScrollerModule,
        RouterModule,
        NgUpperFirstPipeModule,
        NgSlugifyPipeModule,
        NgTruncatePipeModule,
        TooltipModule
    ],
  declarations: [
    SearchPipe,
    RatingComponent,
    RatingStarsComponent,
    PopularityComponent,
    CustomSelectComponent,
    OptionPopupComponent,
    AlbumPeekPopupComponent,
    SmallTrackItemComponent,
    ProgramPeekPopupComponent,
    SmallAlbumItemComponent,
    SleepPanelComponent,
    AlbumItemComponent,
    ProgramItemComponent,
    ProgramDetailComponent,
    TrackItemComponent,
    AlbumTableItemComponent,
    PlanItemComponent,
    MoreTextDirective,
    SmallPopupItemComponent,
    LoadingComponent,
    DebugJsonComponent,
    ErrorMessageComponent,
    CategoryListComponent,
    CarouselPaginationComponent,
    DropDownCategoryListComponent,
    HosProgramImageUrlPipe,
    HosAlbumImageUrlPipe,
    HosGenreImageUrlPipe,
    HosChannelImageUrlPipe,
    SecondsToMinutesPipe,
    SecondsToHoursPipe,
    ColumnizePipe,
    RowizePipe,
    BreakSlashPipe,
    PlayerExtraCutPipe,
    CardImgPipe,
    ColonNewLinePipe,
    SplitUrlsPipe,
    AddHttpsToUrlPipe,
    FilterGenresPipe,
    LimitToFirstPipe,
    ParseJsonPipe,
    BlockButtonComponent,
    HtmlToPlainTextPipe,
    SelectPlaylistDialogComponent,
    PlayProgramButtonComponent,
    PlayAlbumButtonComponent,
    PlayAlbumTrackButtonComponent,
    PlayChannelButtonComponent,
    PlayChannelTrackButtonComponent,
    PlayPlaylistButtonComponent,
    PlayPlaylistTrackButtonComponent,
    OrderHeaderComponent,
    NoDataInTableComponent,
    FavoriteTopBarComponent,
    AlbumsTopBarComponent,
    ProgramsTopBarComponent,
    CreatePlaylistComponent,
    BlockedTopBarComponent,
    GenresListComponent,
    SelectGenreDialogComponent,
    SelectChangePlanTypeDialogComponent,
    CustomCurrencyPipe,
    ConvertToUrlPipe,
    ServerMessageComponent,
    PayPalPaymentAcceptTermsComponent,
    FooterLinksComponent,
    ActiveTrackPipe,
    AbsPipe,
    SubscriptionPaymentComponent,
    RecurlyFormComponent,
    ChannelItemComponent,
    AlbumDetailComponent,
    PromoPlayerComponent
  ],
  providers: [
    LoadingHelperService,
    PlayerUtilsService
  ],
  exports: [
    SearchPipe,
    RatingComponent,
    RatingStarsComponent,
    PopularityComponent,
    CustomSelectComponent,
    OptionPopupComponent,
    AlbumPeekPopupComponent,
    SmallTrackItemComponent,
    ProgramPeekPopupComponent,
    SmallAlbumItemComponent,
    SleepPanelComponent,
    AlbumItemComponent,
    ProgramItemComponent,
    ProgramDetailComponent,
    TrackItemComponent,
    AlbumTableItemComponent,
    PlanItemComponent,
    SmallPopupItemComponent,
    LoadingComponent,
    DebugJsonComponent,
    ErrorMessageComponent,
    CategoryListComponent,
    CarouselPaginationComponent,
    DropDownCategoryListComponent,
    HosProgramImageUrlPipe,
    HosAlbumImageUrlPipe,
    HosGenreImageUrlPipe,
    HosChannelImageUrlPipe,
    SecondsToMinutesPipe,
    SecondsToHoursPipe,
    ColumnizePipe,
    RowizePipe,
    BreakSlashPipe,
    PlayerExtraCutPipe,
    CardImgPipe,
    ColonNewLinePipe,
    SplitUrlsPipe,
    AddHttpsToUrlPipe,
    FilterGenresPipe,
    LimitToFirstPipe,
    ParseJsonPipe,
    BlockButtonComponent,
    HtmlToPlainTextPipe,
    SelectPlaylistDialogComponent,
    PlayProgramButtonComponent,
    PlayAlbumButtonComponent,
    PlayAlbumTrackButtonComponent,
    PlayChannelButtonComponent,
    PlayChannelTrackButtonComponent,
    PlayPlaylistButtonComponent,
    PlayPlaylistTrackButtonComponent,
    OrderHeaderComponent,
    NoDataInTableComponent,
    FavoriteTopBarComponent,
    AlbumsTopBarComponent,
    ProgramsTopBarComponent,
    CreatePlaylistComponent,
    BlockedTopBarComponent,
    GenresListComponent,
    CustomCurrencyPipe,
    ConvertToUrlPipe,
    ServerMessageComponent,
    PayPalPaymentAcceptTermsComponent,
    FormsModule,
    VirtualScrollerModule,
    NgUpperFirstPipeModule,
    NgSlugifyPipeModule,
    FooterLinksComponent,
    NgTruncatePipeModule,
    AbsPipe,
    SubscriptionPaymentComponent,
    ChannelItemComponent,
    AlbumDetailComponent,
    PromoPlayerComponent
  ]
})
export class SharedModule {
}
