/**
 * HOS API
 *
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 *
 *
 *
 */

import { Artist } from './Artist';
import { HosItem } from './HosItem';


export interface Playlist {
    /**
     * Playlist id
     */
    id: number;

    /**
     * Name
     */
    name: string;

    /**
     * Artists
     */
    artists?: Array<Artist>;

    /**
     * Duration
     */
    duration?: number;

    /**
     * Items count
     */
    itemsCount?: number;

    addedTime?: Date;

    updatedTime?: Date;

    sort?: number;

    /**
     * Items
     */
    items?: Array<PlaylistItem>;

    // Dynamically calculated
    artistsString?: String;
    idx: number;
}

export interface PlaylistItem {
  /**
   * Playlist id
   */
  playlistItemId: number;

  hosItem: HosItem;
}
