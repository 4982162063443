import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'splitUrls'
})
export class SplitUrlsPipe implements PipeTransform {

  transform(urlListTxt: string): any {
    if (urlListTxt) {
      return urlListTxt.split(';');
    } else {
      return "";
    }
  }

}
