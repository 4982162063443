import {
  Component, OnInit
} from '@angular/core';
import {LoginHelperService} from "../../api-client/helper/login-helper.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {hoslog} from "../../app.constants";
import {AlertsUtilService} from "../../services/alerts-util.service";
import {HomeRedirectService} from '../../services/home-redirect.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  public myForm: FormGroup;

  public loading = false;

  constructor(private fb: FormBuilder,
              private router: Router,
              private loginHelperService: LoginHelperService,
              private homeRedirectService: HomeRedirectService,
              private alertsUtilService: AlertsUtilService) {
    this.setupForm();
  }

  ngOnInit() {
  }

  private setupForm(): void {
    this.myForm = this.fb.group({
      'username': ['', [Validators.required, Validators.email, Validators.minLength(4)]],
      'password': ['', [Validators.required, Validators.minLength(4)]]
    });
  }

  doLogin() {
    var form = this.myForm;
    var value = form.value;
    //hoslog('you submitted value:', value);
    if (this.myForm.valid) {
      //hoslog('valid');

      //this._loadingService.register();
      this.loading = true;
      this.loginHelperService.doLogin(value.username, value.password)
        .subscribe(
          res => {
            hoslog("Login ok: " + JSON.stringify(res));
            this.loading = false;
            this.redirectToPageIfRequired();
          },
          error => {
            hoslog("Login error: " + error.status);
            this.onLoginError();
            this.loading = false;
          });
      // TODO get the error from the server
    } else {
      hoslog('invalid');

      if(value.username.length==0 && value.password.length==0) {
        this.onLoginError("Please insert username and password");
      } else {
        var inv = this.findInvalidControls(form);
        if(inv.includes('username')) {
          this.onLoginError("Username is not a valid email address. Please check you've entered your email address properly.");
        } else if(inv.includes('password')) {
          this.onLoginError("Please enter the password associated with your HOS account.");
        } else {
          this.onLoginError("Username or password is incorrect. Please try again, or use the link at the top right to <a href=\"" + environment.baseWebsitePath + "/account/reset-password\">reset your password</a>.");
        }
      }
    }
  }

  private findInvalidControls(form) {
    const invalid = [];
    const controls = form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  private onLoginError(msg?: string) {
    if (!msg) {
      msg = "Username or password is incorrect. Please try again, or use the link at the top right to <a href=\"" + environment.baseWebsitePath + "/account/reset-password\">reset your password</a>.";
    }
    hoslog(msg);
    this.alertsUtilService.showAlert(msg);
  }

  private redirectToPageIfRequired() {
    // Get the redirect URL from our auth service
    if (this.loginHelperService.redirectUrl) {
      // Redirect the user
      this.homeRedirectService.setRedirectDone(); // to avoid redirection on user settings
      this.router.navigateByUrl(this.loginHelperService.redirectUrl);
      this.loginHelperService.redirectUrl = null;
    }
  }
}

