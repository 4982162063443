<div id="container-router">
  <div class="favorites-tracks container-fluid inner-component-container">
    <div class="col-lg-12 data-table">
      <table>
        <tr *ngFor="let rowArray of data">
          <td *ngFor="let item of rowArray">
            <div class="cell-content">
              <div class="pull-left image">
                <a [routerLink]="['/', linkPath1, linkPath2, item.id, 'list']" attr.aria-label="{{ariaItemLabel}}: {{item.name}}"><img class="bordered-img" [src]="item.id | HosGenreImageUrl:175" alt="{{item.name}}" aria-hidden="true"></a>
              </div>
              <div class="text">
                <a [routerLink]="['/', linkPath1, linkPath2, item.id, 'list']" attr.aria-label="{{ariaItemLabel}}: {{item.name}}">{{item.name | breakSlash}}</a>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
