import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ServerMessageService} from '../../services/server-message.service';

@Component({
  selector: 'app-server-message',
  templateUrl: './server-message.component.html',
  styleUrls: ['./server-message.component.scss']
})
export class ServerMessageComponent implements OnInit {

  constructor(public serverMessageService: ServerMessageService) { }

  ngOnInit() {
  }

  toggleSystemMaintenance() {
    this.serverMessageService.toggleSystemMaintenance();
  }
}
