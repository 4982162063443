import {Injectable} from '@angular/core';
import {SettingsService} from "../api-client/api/settings.service";
import {AppConstants, hoslog} from "../app.constants";
import {BehaviorSubject} from "rxjs";
import {AppSettings} from "../api-client/model/AppSettings";
import {Genre} from "../api-client/model/Genre";
import {Label} from '../api-client';

@Injectable()
export class AppSettingsService {

  public appSettings: BehaviorSubject<AppSettings> = null;

  // public appSettingsLoaded = false;

  constructor(private settingsService: SettingsService) {
    // First value is empty
    this.appSettings = new BehaviorSubject({
      configParams: [],
      clientErrors: [],
      genres: []
    });

    // this.appSettingsLoaded = false;
    this.refreshSettings();
  }

  refreshSettings() {
    let me = this;
    this.settingsService.getSettings(AppConstants.DeviceType)
      .subscribe(
        res => {
          hoslog("App Settings loaded");
          // me.addSeasonInfo(res.genres);
          res.genres = me.reorderGenres(res.genres);
          res.albumLabels = me.removeUnkownLabels(res.albumLabels);
          me.appSettings.next(res);
          // me.appSettingsLoaded = true;
        },
        error => {
          hoslog("App Settings: error: " + JSON.stringify(error));
        });
  }

  reorderGenres(genres: Genre[]): Genre[] {
    if (genres) {
      return genres.sort((a, b) => {
        return a.ordering - b.ordering;
      });
    }
    return genres;
  }

  removeUnkownLabels(labels: Label[]): Label[] {
    if (labels) {
      return labels.filter(label => !label.name.startsWith('('));
    }
    return labels;
  }


  /*private addSeasonInfo(genres: Array<Genre>) {
    for (let genre of genres) {
      if (AppConstants.SeasonsIds.some(x => x === genre.id)) {
        genre.isSeason = true;
      } else {
        genre.isSeason = false;
      }
    }
  }*/

  public getGlobalSetting(name: string) {
    if (this.appSettings.value && this.appSettings.value.globalSettings) {
      let found = this.appSettings.value.globalSettings.filter(setting => {
        return (setting.paramName === name);
      });
      if (found && found.length > 0) {
        return found[0];
      }
    }
    return null;
  }

  public getTwpId() {
    return this.getGlobalSetting('twpId');
  }

  /*public getServerMessageEnabled(): boolean {
    return this.getGlobalSetting('serverMessageEnabled').paramValue === '1';
  }

  public getServerMessageText(): string {
    return this.getGlobalSetting('serverMessageText').paramValue;
  }*/

  public getGenreById(genreId: number): Genre {
    if (this.appSettings.value && this.appSettings.value.genres) {
      let found = this.appSettings.value.genres.filter(genre => {
        return (genre.id == genreId);
      });
      if (found && found.length > 0) {
        return found[0];
      }
    }
    return null;
  }

  public getLabelById(labelId: number): Label {
    if (this.appSettings.value && this.appSettings.value.albumLabels) {
      let found = this.appSettings.value.albumLabels.filter(label => {
        return (label.id == labelId);
      });
      if (found && found.length > 0) {
        return found[0];
      }
    }
    return null;
  }
}
