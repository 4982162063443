/**
 * HOS API
 *
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 *
 *
 *
 */

import { HosItem } from './HosItem';


export interface FavoriteItemWrapper {
    /**
     * Hos item
     */
    hosItem: HosItem;

    /**
     * Rating
     */
    rating?: number;
}
