<div>

      <div class="row header_info" *ngIf="subscriptionService.pageType != checkPageType.Gift && subscriptionService.pageType != checkPageType.UpdateBilling">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <p class="header">
            One More Step…
          </p>

          <p class="subheader">{{(subscriptionService.isDowngrade$ | async) === true ? 'to a custom Hearts of Space experience!' : 'to a richer Hearts of Space experience!'}}</p>
          <p class="paragraph" *ngIf="!hasCreditCardOnFile">
            Enter your payment information below to complete the process
          </p>
        </div>
      </div>

      <div class="row header_info" *ngIf="subscriptionService.pageType == checkPageType.Gift">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <p class="header">
            Gift Payment Details
          </p>
          <p class="subheader">
            one more step to send your gift
          </p>
        </div>
      </div>

      <!--<div class="row header_info" *ngIf="subscriptionService.pageType == checkPageType.UpdateBilling">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <p class="header">
            Update Billing Info
          </p>
          <p class="subheader">
            Enter your new billing information below
          </p>
        </div>
      </div>-->

      <div class="row hightlight" *ngIf="subscriptionService.pageType != checkPageType.Gift && subscriptionService.pageType != checkPageType.UpdateBilling">
        <div class="col-sm-6 col-md-6 col-lg-6 desc">
          <div>
            <p *ngIf="subscriptionService.selectedPlan?.type != 'timed'">
              You are signing up for a <a>{{subscriptionService.selectedPlan?.name}}</a> plan with a term of <a>{{subscriptionService.selectedPrice?.duration}} {{subscriptionService.selectedPrice?.durationUnit}}</a> and <a>{{subscriptionService.selectedPrice?.billingType === billingType.Recurring ? 'Recurring' : 'One time'}} billing</a>.
            </p>
            <p *ngIf="subscriptionService.selectedPlan?.type == 'timed'">
              You are signing up for <a>{{subscriptionService.selectedPrice?.duration/60}} Prepaid Hours</a>.
            </p>
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6 result-charge">
          <div class="" *ngIf="subscriptionService.selectedPrice?.monthlyPrice && subscriptionService.selectedPrice?.monthlyPrice > 0">
            <p>Price for this configuration:</p>
            <span>{{subscriptionService.selectedPrice?.monthlyPrice | customCurrency}}/<span class="period">mo</span></span>
          </div>
          <div class="small-charge-cost clearfix" *ngIf="(subscriptionService.totalDiscountedPrice$ | async) !== (subscriptionService.totalPrice$ | async) && subscriptionService.selectedPlan.type!='timed'">
            <p>Total:</p>
            <span>{{subscriptionService.totalPrice$ | async | customCurrency}}</span>
          </div>
          <div class="small-charge-cost clearfix" *ngIf="((subscriptionService.prorataDiscount$ | async) && (subscriptionService.prorataDiscount$ | async) > 0 && subscriptionService.selectedPlan.type!='timed')">
            <p>Remaining from the old plan:</p>
            <span>({{subscriptionService.prorataDiscount$ | async | customCurrency}})</span>
          </div>
          <div class="small-charge-cost clearfix" *ngIf="(subscriptionService.discountCodeApplied$ | async) && (subscriptionService.discountCodeValid$ | async)">
            <p>{{subscriptionService.discountCodeDescription$ | async}}</p>
            <span>({{subscriptionService.discountCodeValue$ | async | customCurrency}})</span>
          </div>
          <div class="clearfix">
            <p>{{((subscriptionService.isDowngrade$ | async) === true && (subscriptionService.selectedPlan.type!='timed')) ? 'Total be billed when current subscription ends:' : 'Total billed now will be:' }}</p>
            <span class="green" *ngIf="!((subscriptionService.totalDiscountedPrice$ | async) && (subscriptionService.selectedPlan.type!='timed'))">{{subscriptionService.selectedPrice?.cost | customCurrency}}</span>
            <span class="green" *ngIf="(subscriptionService.totalDiscountedPrice$ | async) && (subscriptionService.selectedPlan.type!='timed')">{{subscriptionService.totalDiscountedPrice$ | async | currency:'USD':'symbol':'1.2-2'}}</span>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 desc clearfix">
          <div class="minutes-plan-description" *ngIf="subscriptionService.selectedPlan?.type == 'timed'" [innerHTML]="(subscriptionService.selectedPlan.description | parseJson)['paymentPageDescription']"></div>
        </div>
      </div>

      <div class="row hightlight" *ngIf="subscriptionService.pageType == checkPageType.Gift">
        <div class="col-sm-6 col-md-6 col-lg-6 desc">
          <p>
            You are purchasing
            <span *ngIf="subscriptionService.selectedPlan?.type != 'timed'">a <a>{{subscriptionService.selectedPlan?.name}}</a> plan with a term of <a>{{subscriptionService.selectedPrice?.duration}} {{subscriptionService.selectedPrice?.durationUnit}}</a></span>
            <a *ngIf="subscriptionService.selectedPlan?.type == 'timed'">{{subscriptionService.selectedPrice?.duration/60}} Prepaid Hours</a>
            for <a>{{subscriptionGiftService.recipientInfo.name}}</a> to be sent on <a>{{subscriptionGiftService.recipientInfo.gift_date}}</a>
          </p>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6 result-charge-gift">
          <div class="">
            <p>
              Gift will be sent to <a>{{subscriptionGiftService.recipientInfo.email}}</a>
            </p>
          </div>
          <div class="">
            <div class="row">
              <div class="col-sm-6 col-md-6 col-lg-6">
                <p>
                  Total billed now will be:
                </p>
              </div>
              <div class="col-sm-6 col-md-6 col-lg-6">
                <span class="green">{{subscriptionService.selectedPrice?.cost | currency:'USD':'symbol':'1.2-2'}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--<app-loading *ngIf="loadingHelperService.isLoading();else loaded"></app-loading>-->
      <!--<ng-template #loaded>
        <app-error-message [error]="loadingHelperService.getError()" *ngIf="loadingHelperService.hasError()" [showTryAgain]="false"></app-error-message>
      </ng-template>-->

      <div class="row" *ngIf="subscriptionService.pageType != checkPageType.Gift && subscriptionService.pageType != checkPageType.UpdateBilling && subscriptionService.changePlanImmediately">
        <div class="col-sm-12 col-md-12 col-lg-12 desc clearfix form">
          <div class="discount">
            <span>Discount code: </span>
            <input [(ngModel)]="discountCode" class="inline" />
            <button class="mini" (click)="subscriptionService.applyDiscountCode(discountCode)">{{loadingHelperService.isLoading() ? 'LOADING' : 'APPLY'}}</button>
<!--            <button class="mini" (click)="subscriptionService.removeDiscountCode()" *ngIf="(subscriptionService.discountCodeApplied$ | async)">{{loadingHelperService.isLoading() ? 'LOADING' : 'REMOVE'}}</button>-->
            <div class="error-message inline" *ngIf="(subscriptionService.discountCodeApplied$ | async) && !(subscriptionService.discountCodeValid$ | async) && !loadingHelperService.isLoading()">Please enter a valid discount code.</div>
          </div>
        </div>
      </div>

      <div class="row result-info" *ngIf="hasCreditCardOnFile && subscriptionService.pageType != checkPageType.UpdateBilling">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <p>
            Payment will be made with the card you have on file: &nbsp;&nbsp;
            <img [src]="'/assets/images/cards/' + (loggedUserPlanService.plan.billingInfo.cardType | cardImg) + '.png'"
                 [srcset]="'/assets/images/cards/' + (loggedUserPlanService.plan.billingInfo.cardType | cardImg) + '@2x.png 2x'" alt="">
            <span *ngIf="loggedUserPlanService.plan.billingInfo.cardType != 'paypal'">***&nbsp;{{loggedUserPlanService.plan.billingInfo.last4cardDigits}}</span>
          </p>
          <p>
            Or you may <a (click)="selectDifferentCard()">Select a different payment method</a>.
          </p>
        </div>
      </div>


      <div class="row" *ngIf="!hasCreditCardOnFile && subscriptionService.pageType != checkPageType.UpdateBilling">
        <div class="col-sm-12 col-md-12 col-lg-12 form-info">
          <p>
            Enter your payment information below or <a (click)="showPayWithPaypalDialog()">Pay with Paypal</a>
          </p>
        </div>
      </div>

      <div *ngIf="hasCreditCardOnFile && subscriptionService.pageType != checkPageType.UpdateBilling">
        <div class="row form-footer">
          <div class="col-sm-6 col-md-6 col-lg-6">
            &nbsp;
          </div>
          <div class="col-sm-6 col-md-6 col-lg-6">
            <button type="submit" id="finishButton" name="finishButton" (click)="payWithCardOnFileClicked()" [disabled]="loadingHelperService.isLoading()">{{loadingHelperService.isLoading() ? 'LOADING' : 'FINISH'}}</button>
          </div>
        </div>
        <div class="row form-footer">
          <div class="col-sm-6 col-md-6 col-lg-6">&nbsp;</div>
          <div class="col-sm-6 col-md-6 col-lg-6">
            <!--<a [routerLink]="['/pages/service-details']" target="_blank">Service Details</a>
            <a [routerLink]="['/pages/terms-and-conditions']" target="_blank">Terms &amp; Conditions</a>
            <a [routerLink]="['/pages/privacy-policy']" target="_blank">Privacy Policy</a>-->
            <div class="hos-checkbox terms">
              <input type="checkbox" id="termsAccepted" [(ngModel)]="termsAccepted" [ngModelOptions]="{standalone: true}">
              <label for="termsAccepted" [ngClass]="{'error': (formSubmitted && !termsAccepted)}">
                I accept the <a [routerLink]="['/pages/terms-and-conditions']" target="_blank">Terms &amp; Conditions</a>, <a [routerLink]="['/pages/service-details']" target="_blank">Service Details</a> and <a [routerLink]="['/pages/privacy-policy']" target="_blank">Privacy Policy</a>
              </label>
              <div class="card-wrapper">
                <img src="/assets/images/cards.png">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="!hasCreditCardOnFile || subscriptionService.pageType == checkPageType.UpdateBilling">
        <app-recurly-form [embeddedForm]="embeddedForm" [isUpdateBilling]="isUpdateBilling" (token)="paymentTokenCreated($event)" [submitEnabled]="!loadingHelperService.isLoading()"></app-recurly-form>
      </div>

</div>
