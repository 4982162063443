<div class="app-modal">
  <div class="modal-body">
    <p>Please select the playlist:</p>
    <app-loading *ngIf="loadingHelperService.isLoading('PLAYLIST_MODAL');else loaded"></app-loading>
    <ng-template #loaded>
      <app-error-message [error]="loadingHelperService.getError('PLAYLIST_MODAL')"
                         *ngIf="loadingHelperService.hasError('PLAYLIST_MODAL')"
                         [showTryAgain]="true"
                         (tryAgain)="loadPlaylists()"></app-error-message>
      <div class="playlists-container" *ngIf="!loadingHelperService.hasError('PLAYLIST_MODAL') && !loadingHelperService.isLoading('ADD_TO_PLAYLIST_MODAL')">
        <a class="playlist" (click)="saveToPlaylist(playlist)" *ngFor="let playlist of playlists;">{{playlist.name}}</a>
      </div>
    </ng-template>

    <app-loading *ngIf="loadingHelperService.isLoading('ADD_TO_PLAYLIST_MODAL');else added"></app-loading>
    <ng-template #added>
      <app-error-message [error]="loadingHelperService.getError('ADD_TO_PLAYLIST_MODAL')"
                         *ngIf="loadingHelperService.hasError('ADD_TO_PLAYLIST_MODAL')"
                         [showTryAgain]="true"
                         (tryAgain)="reTrySaveToPlaylist()"></app-error-message>
    </ng-template>
  </div>
  <div class="modal-actions">
    <button (click)="onCancel(true)" aria-label="Create playlist" [disabled]="loadingHelperService.isLoading('PLAYLIST_MODAL') || loadingHelperService.isLoading('ADD_TO_PLAYLIST_MODAL')">CREATE</button>
    <button type="button" name="button" (click)="onCancel()">CANCEL</button>
  </div>
</div>
<div class="modal-background">
</div>
