<div class="custom-select" (blur)="handlePopup()">
  <div class="input-block">
    <input type="text" name="search" value="{{selectedItem?.name}}" readonly (click)="handlePopup(true)">
    <div class="select-arrow" (click)="handlePopup()">
      <img src="/assets/images/select_arrow.svg">
    </div>
  </div>
  <div [hidden]="!isPopupOpen" class="popup-wrapper">
    <div class="list-box">
      <div class="list-box-inside-wrapper">
        <div>
          <div #filteredItems class="list-item" *ngFor="let item of dataList" (click)="handlePopup(false, item)">
            {{item.name}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
