import {BehaviorSubject, Subject} from 'rxjs';
import {HosPlayItem} from './hos-play-item';
import {hoslog} from '../../app.constants';
import {environment} from '../../../environments/environment';
import Hls from 'hls.js';
import {PlayerService} from './player-service';

declare var videojs;

class HosPlayerException extends Error {

}

export enum HosCurrentPlayState {
  /**
   * Either playback has not started or playback was stopped due to a stop() call or an error.
   */
  idle,
  /**
   * The app is loading all the information needed to play the item
   */
  loading,
  /**
   * The user pressed play, but sufficient data to start playback has not yet loaded.
   */
  // buffering,
  /**
   * The video is currently playing.
   */
  playing,
  /**
   * The video is currently paused.
   */
  paused
}

/*enum HosPlayerEvent {
 ready, setupError, play, pause, stop, clear, next, playlistItem, playlistComplete,
 buffer, idle, complete, error, seek, seeked, time, mute, volume
 }*/

/*interface HosPlayerEventListener {
 /!**
 *
 * @param hosPlayerEvent the event occurred
 *!/
 on(hosPlayerEvent: HosPlayerEvent);
 }*/

/*interface HosPlayerState {

 /!**
 * Intended to return the viewer's current position in a media file.
 * @return the viewer's current position in a media file.
 *!/
 getPosition(): number;

 /!**
 * The total length of the media file.
 * @return the total length of the media file.
 *!/
 getDuration(): number;

 /!**
 * If the player is currently muted or not
 * @return True If the player is currently muted or not
 *!/
 getMute(): boolean;

 /!**
 * Set the mute state of the player.
 * @param isMute True to set the volume to 0.
 *!/
 setMute(isMute: boolean);

 /!**
 * The current playback volume, as a percentage from 0 to 100.
 * @return the current playback volume, as a percentage from 0 to 100.
 *!/
 getVolume(): number;

 /!**
 * Set the volume of the player between 1-100
 * @param volume volume of the player between 1-100
 *!/
 setVolume(volume: number);

 /!**
 * Returns the stream current playback state.
 * @return the stream current playback state.
 *!/
 getState(): HosCurrentPlayState;
 }*/


export interface HosPlayer {

  /**
   *
   * @return the unique id of the plugin
   */
  getPluginId(): string;

  /**
   *
   * @return the name of the plugin
   */
  getPluginName(): string;

  /**
   * Add the event listener for the player's events.
   * @param listener The listener to add
   */

  // addEventListener(listener: HosPlayerEventListener);

  /**
   * Send params to the player
   * @param params The key/value map of params
   */
  setup(params: Map<string, string>);

  /**
   * Load a single item into the player.
   * @param playItem The item to load
   */
  //loadItem(playItem: HosPlayItem);

  /**
   * Load a playlist into the player.
   * @param playlist The playlist to load
   */
  // loadPlaylist(playlist: HosQueue);

  /**
   * Return the class to check the current state of the player.
   * @return the class to check the current state of the player.
   */
  // getCurrentState(): HosPlayerState;

  // getHosPlaylist(): HosQueue;

  /**
   * Load a specific stream
   */
  loadItem(playItem: HosPlayItem, startPosition: number);

  // loadStream(type: string, streamUrl: string);

  /**
   * Sets the play state of the Player.
   * @throws HosPlayerException If there is an error
   */
  play();

  /**
   * Sets the pause state of the Player.
   * @throws HosPlayerException If there is an error
   */
  pause();

  /**
   * Stops the player (returning it to the idle state) and unloads the currently playing media file.
   * @throws HosPlayerException If there is an error
   */
  // stop();

  /**
   * Clear the Player
   * @throws HosPlayerException If there is an error
   */
  // clear();

}

export class HosPlayerImpl implements HosPlayer {
  private hls: Hls; // the hls.js player
  private audio: any; // HTMLAudioElement;

  private preAudioExecuted = false; // safari strick auto play policy hack

  private seekWhenLoaded: number = 0;

  // Observables
  readyObs: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  statusObs: BehaviorSubject<HosCurrentPlayState> = new BehaviorSubject<HosCurrentPlayState>(HosCurrentPlayState.idle);
  statusStrObs: BehaviorSubject<string> = new BehaviorSubject<string>(HosCurrentPlayState[HosCurrentPlayState.idle]);
  volumeObs: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  mutedObs: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  durationChangedObs: BehaviorSubject<number> = new BehaviorSubject<number>(0); // TODO Subject?
  seekingObs: BehaviorSubject<number> = new BehaviorSubject<number>(0); // TODO Subject?
  seekedObs: BehaviorSubject<number> = new BehaviorSubject<number>(0); // TODO Subject?
  streamingQualityObs: BehaviorSubject<number> = new BehaviorSubject<number>(-1);
  playingTimeUpdatedObs: BehaviorSubject<number> = new BehaviorSubject<number>(0); // TODO Subject?
  playerErrorObs: BehaviorSubject<any> = new BehaviorSubject<any>({}); // TODO Subject?

  trackFinished$ = new Subject();

  private playVideoWhenReady = false;
  private htmlElementId: string;

  // private currentPlaylist: HosQueue;

  constructor(private playerService: PlayerService) {
    // this.currentPlaylist = new HosPlaylistMulti([]); // start with empty playlist

    /*var _beforeRequest = isFunction(videojs.Hls.xhr.beforeRequest);
    videojs.Hls.xhr.beforeRequest = function(options) {
      if (_beforeRequest) {
        options = videojs.Hls.xhr.beforeRequest(options);
      }
      /!*if ($localStorage.token) {
        options.headers = options.headers || {};
        options.headers.Authorization = 'Token ' + $localStorage.token;
      }*!/
      options.headers = options.headers || {};
      options.headers.TestDem = 'dem=fil&ciao=test';
      // options.headers.Authorization = 'Token ' + $localStorage.token;
      return options;
    };*/
  }

  getPluginId(): string {
    return 'HLSJS';
  }

  getPluginName(): string {
    return 'HlsJs';
  }

  /*addEventListener(listener: HosPlayerEventListener) {
   }*/

  /**
   * Specific implementation params:
   * * HtmlElementId: the HTML Element ID where to build the player
   */
  setup(params: Map<string, string>) {
    this.htmlElementId = params.get('HtmlElementId');
    if (this.htmlElementId) {
      this.audio = document.getElementById(this.htmlElementId) as HTMLVideoElement;
      if (Hls.isSupported()) {
        this.configureHlsInstance();
      }
    } else {
      console.error('HosPlayer htmlElementId not found')
    }
  }

  private configureHlsInstance() {
    const config = Hls.DefaultConfig;
    config.debug = environment.playerDebugLog;
    config.enableWorker = true;
    config.maxBufferLength = 60;
    config.maxBufferSize = 100;
    config.xhrSetup = function (xhr: XMLHttpRequest, url: string) {
      xhr.withCredentials = true; // do send cookies
    };

    this.hls = new Hls(config);

    this.hls.autoLevelCapping = this.streamingQualityObs.value;
    // const me = this;
    // MEDIA_ATTACHED event is fired by hls object once MediaSource is ready
    /*this.hls.on(Hls.Events.ERROR, function (evt) {
      hoslog('On: error');
      me.changeStatus(HosCurrentPlayState.idle);
      const error = me.handleError(evt);
    });*/
    this.hls.on(Hls.Events.MEDIA_ATTACHED, this.handleMediaAttachedEvent());
    this.hls.on(Hls.Events.ERROR, this.handleErrorEvents());
    this.hls.on(Hls.Events.MANIFEST_PARSED, this.handleManifestParsedEvents());

    // https://www.w3schools.com/tags/ref_av_dom.asp
    this.audio.addEventListener('resize', this.handleVideoEvents());
    this.audio.addEventListener('seeking', this.handleVideoEvents());
    this.audio.addEventListener('seeked', this.handleVideoEvents());
    this.audio.addEventListener('pause', this.handleVideoEvents());
    this.audio.addEventListener('play', this.handleVideoEvents());
    this.audio.addEventListener('canplay', this.handleVideoEvents());
    this.audio.addEventListener('canplaythrough', this.handleVideoEvents());
    this.audio.addEventListener('ended', this.handleVideoEvents());
    this.audio.addEventListener('playing', this.handleVideoEvents());
    this.audio.addEventListener('error', this.handleVideoEvents());
    this.audio.addEventListener('loadedmetadata', this.handleVideoEvents());
    this.audio.addEventListener('loadeddata', this.handleVideoEvents());
    this.audio.addEventListener('durationchange', this.handleVideoEvents());
    this.audio.addEventListener('loadstart', this.handleVideoEvents());
    this.audio.addEventListener('timeupdate', this.handleVideoEvents());
    this.audio.addEventListener('volumechange', this.handleVideoEvents());
    this.volumeObs.subscribe(data => {
      this.audio.volume = data;
    })
  }

  private detachAttachMedia() {
    if (this.hls.media != null) {
      // this.hls.detachMedia();
      this.hls.destroy();

      this.audio.removeAttribute('src'); // empty source
      this.audio.load();

      this.configureHlsInstance();
    }
    // bind them together
    this.hls.attachMedia(this.audio);
  }

  private handleManifestParsedEvents() {
    const me = this;
    return function (event, data: any) {
      hoslog('No of quality levels found: ' + me.hls.levels.length);
      me.hls.autoLevelCapping = me.streamingQualityObs.value;
      hoslog('Manifest successfully loaded');
      if (me.seekWhenLoaded > 0) {
        me.hls.startLoad(me.seekWhenLoaded);
        me.seekWhenLoaded = 0;
      }
      if (me.playVideoWhenReady) {
        me.play();
      }
    };
  }

  private handleErrorEvents() {
    const me = this;
    return function (event, data: any) {
      console.warn('Error event:', data);
      switch (data.details) {
        case Hls.ErrorDetails.MANIFEST_LOAD_ERROR:
          try {
            hoslog('Cannot load ' + data.context.url + ' - HTTP response code: ' + data.response.code + ' - ' + data.response.text);
            if (data.response.code === 0) {
              hoslog('This might be a CORS issue');
            }
          } catch (err) {
            hoslog('Cannot load ' + data.context.url + ' - Response body: ' + data.response.text);
          }
          break;
        case Hls.ErrorDetails.MANIFEST_LOAD_TIMEOUT:
          hoslog('Timeout while loading manifest');
          break;
        case Hls.ErrorDetails.MANIFEST_PARSING_ERROR:
          hoslog('Error while parsing manifest:' + data.reason);
          break;
        case Hls.ErrorDetails.LEVEL_LOAD_ERROR:
          hoslog('Error while loading level playlist');
          break;
        case Hls.ErrorDetails.LEVEL_LOAD_TIMEOUT:
          hoslog('Timeout while loading level playlist');
          break;
        case Hls.ErrorDetails.LEVEL_SWITCH_ERROR:
          hoslog('Error while trying to switch to level ' + data.level);
          break;
        case Hls.ErrorDetails.FRAG_LOAD_ERROR:
          hoslog('Error while loading fragment ' + data.frag.url);
          if ((me.duration - me.currentTime) < 60) { // if it's the end of the song
            // Ending the track early
            hoslog('Problem with the fragments: Ending the track early');
            me.trackFinished();
          }
          break;
        case Hls.ErrorDetails.FRAG_LOAD_TIMEOUT:
          hoslog('Timeout while loading fragment ' + data.frag.url);
          break;
        // case Hls.ErrorDetails.FRAG_LOOP_LOADING_ERROR:
        //   hoslog('Fragment-loop loading error');
        //   break;
        case Hls.ErrorDetails.FRAG_DECRYPT_ERROR:
          hoslog('Decrypting error:' + data.reason);
          break;
        case Hls.ErrorDetails.FRAG_PARSING_ERROR:
          hoslog('Parsing error:' + data.reason);
          break;
        case Hls.ErrorDetails.KEY_LOAD_ERROR:
          hoslog('Error while loading key ' + data.frag.decryptdata.uri);
          break;
        case Hls.ErrorDetails.KEY_LOAD_TIMEOUT:
          hoslog('Timeout while loading key ' + data.frag.decryptdata.uri);
          break;
        case Hls.ErrorDetails.BUFFER_APPEND_ERROR:
          hoslog('Buffer append error');
          break;
        case Hls.ErrorDetails.BUFFER_ADD_CODEC_ERROR:
          hoslog('Buffer add codec error for ' + data.mimeType + ':' + data.err.message);
          break;
        case Hls.ErrorDetails.BUFFER_APPENDING_ERROR:
          hoslog('Buffer appending error');
          break;
        case Hls.ErrorDetails.BUFFER_STALLED_ERROR:
          hoslog('Buffer stalled error');
          break;
        default:
          break;
      }
      if (data.fatal) {
        switch (data.type) {
          case Hls.ErrorTypes.NETWORK_ERROR:
            // try to recover network error
            // hoslog('fatal network error encountered, try to recover');
            me.hls.startLoad();
            break;
          case Hls.ErrorTypes.MEDIA_ERROR:
            // console.log('fatal media error encountered, try to recover');
            me.hls.recoverMediaError();
            break;
          default:
            // cannot recover
            // me.hls.destroy();
            me.playerErrorObs.next('An error has occurred, please try again');
            break;
        }
      }
    };
  }

  private handleMediaAttachedEvent() {
    const me = this;
    return function () {
      // console.log('video and hls.js are now bound together !');

      me.readyObs.next(true);

      // Initial values
      me.onVolumeChanged();
    };
  }

  private handleVideoEvents() {
    const me = this;
    return function (evt) {
      // hoslog('On Video Event: ' + evt.type);
      let data = '';
      switch (evt.type) {
        case 'durationchange':
          /*if (evt.target.duration - me.audio.duration <= 0.5) {
            // some browsers report several duration change events with almost the same value ... avoid spamming video events
            return;
          }*/
          const lastDuration = evt.target.duration;
          me.durationChangedObs.next(lastDuration);
          break;
        case 'resize':
          data = evt.target.videoWidth + '/' + evt.target.videoHeight;
          break;
        case 'loadstart':
          me.changeStatus(HosCurrentPlayState.paused);
          break;
        // case 'loadedmetadata':
        case 'loadeddata':
          // we don't need this here anymore as it's mnaged in handleManifestParsedEvents()
          // me.seek(me.seekWhenLoaded);
          // me.seekWhenLoaded = 0;
          break;
        case 'timeupdate':
          me.onTimeUpdate();
          break;
        // case 'canplay':
        // case 'canplaythrough':
        case 'ended':
          hoslog('ended');
          me.onPlayEnded();
          break;
        case 'seeking':
          me.onSeeking();
          break;
        case 'seeked':
          me.onSeeked();
          break;
        case 'play':
          me.playerService.notifyPlayEvent();
          me.changeStatus(HosCurrentPlayState.playing);
          break;
        case 'playing':
          // lastStartPosition = evt.target.currentTime;
          me.changeStatus(HosCurrentPlayState.playing);
          break;
        case 'pause':
          me.changeStatus(HosCurrentPlayState.paused);
          break;
        case 'volumechange':
          me.onVolumeChanged();
          break;
        // case 'waiting':
        // case 'stalled':
        case 'error':
          // data = Math.round(evt.target.currentTime * 1000);
          if (evt.type === 'error') {
            let errorTxt, mediaError = evt.currentTarget.error;
            switch (mediaError.code) {
              case mediaError.MEDIA_ERR_ABORTED:
                hoslog('You aborted the video playback');
                me.changeStatus(HosCurrentPlayState.idle);
                break;
              case mediaError.MEDIA_ERR_DECODE:
                hoslog('The video playback was aborted due to a corruption problem or because the video used features your browser did not support. Trying to recover media error.');
                me.hls.recoverMediaError();
                break;
              case mediaError.MEDIA_ERR_NETWORK:
                errorTxt = 'A network error caused the video download to fail part-way';
                me.changeStatus(HosCurrentPlayState.idle);
                break;
              case mediaError.MEDIA_ERR_SRC_NOT_SUPPORTED:
                errorTxt = 'The video could not be loaded, either because the server or network failed or because the format is not supported';
                me.changeStatus(HosCurrentPlayState.idle);
                break;
            }

            if (errorTxt && mediaError.message) {
              errorTxt += ' - ' + mediaError.message;
              hoslog(errorTxt);
              me.playerErrorObs.next(errorTxt);
            }
          }
          break;
        default:
          break;
      }
    }
  }

  private onVolumeChanged() {
    let muted = this.audio.muted;
    // hoslog('Volume change: ' + volume);
    // hoslog('Muted: ' + muted);

    if (this.mutedObs.getValue() != muted) {
      this.mutedObs.next(muted);
    }
  }

  /*  private handleError(evt: any): string {
      // data = Math.round(evt.target.currentTime*1000);
      if (evt.type === 'error') {
        let errorTxt, mediaError = evt.currentTarget.error;
        switch (mediaError.code) {
          case mediaError.MEDIA_ERR_ABORTED:
            hoslog('You aborted the video playback');
            this.changeStatus(HosCurrentPlayState.idle);
            break;
          case mediaError.MEDIA_ERR_DECODE:
            hoslog('The video playback was aborted due to a corruption problem or because the video used features your browser did not support. Trying to recover media error.');
            this.hls.recoverMediaError();
            break;
          case mediaError.MEDIA_ERR_NETWORK:
            errorTxt = 'A network error caused the video download to fail part-way';
            this.changeStatus(HosCurrentPlayState.idle);
            break;
          case mediaError.MEDIA_ERR_SRC_NOT_SUPPORTED:
            errorTxt = 'The video could not be loaded, either because the server or network failed or because the format is not supported';
            this.changeStatus(HosCurrentPlayState.idle);
            break;
        }

        if (errorTxt && mediaError.message) {
          errorTxt += ' - ' + mediaError.message;
          this.playerErrorObs.next(errorTxt);
        }

        return errorTxt;
      }
    }*/

  // Safari strict audio policy rules
  loadPreAudioItem() {
    if (!this.preAudioExecuted) {
      hoslog('Loading pre audio...');
      const audioPreload = document.getElementById(this.htmlElementId + '_preload') as HTMLAudioElement;
      audioPreload.src = '/assets/sound/preaudio.mp3';
      audioPreload.load();
      audioPreload.play();
      audioPreload.addEventListener('ended', function () {
        // removing the old audio component
        audioPreload.parentNode.removeChild(audioPreload);
      });
      this.preAudioExecuted = true;
    }
  }

  loadItem(playItem: HosPlayItem, startPosition: number, startPlaying = true) {
    this.detachAttachMedia();

    // handled in handleManifestParsedEvents()
    this.seekWhenLoaded = startPosition;
    this.playVideoWhenReady = startPlaying;

    if (environment.fakePlayer) {
      hoslog('fake player');
      this.hls.loadSource('https://d26hy4mf5spa8t.cloudfront.net/pgm0700.m3u8');
    } else {
      hoslog('src: ' + playItem.getPlayTokenWrapper().signedUrl);
      this.hls.loadSource(playItem.getPlayTokenWrapper().signedUrl);
    }
  }

  play() {
    this.audio.play().then(() => {
      hoslog('Play promise OK');
    })
      .catch((reason) => {
        hoslog('Play promise KO: ' + reason);
      });
  }

  pause() {
    return this.audio.pause();
  }

  muteToggle() {
    this.audio.muted = !this.audio.muted;
  }

  isMuted(): boolean {
    return this.audio.muted;
  }

  setMuted(muted: boolean) {
    this.audio.muted = muted;
  }

  get volume(): number {
    return this.audio.volume;
  }

  set volume(vol: number) {
    this.audio.volume = vol;
  }

  get currentTime(): number {
    return this.audio.currentTime;
  }

  set currentTime(time: number) {
    this.audio.currentTime = time;
  }

  get duration(): number {
    return this.durationChangedObs.value;
  }

  seek(time: number) {
    if (!time) {
      time = 0;
    }

    this.currentTime = time;

    // if the player is not playing I force the time update so we can skip to the next track if needed
    const currentStatus = this.statusObs.value;
    if (currentStatus !== HosCurrentPlayState.playing) {
      this.onTimeUpdate();
    }
  }

  // Events management

  onSeeking() {
    let currentTime = this.audio.currentTime;
    this.seekingObs.next(currentTime);
  }

  onSeeked() {
    let currentTime = this.audio.currentTime;
    this.seekedObs.next(currentTime);
  }

  onTimeUpdate() {
    const lastTime = this.playingTimeUpdatedObs.value ? Math.round(this.playingTimeUpdatedObs.value) : 0;
    const currentTime = Math.round(this.audio.currentTime * 1e0);
    // hoslog('onTimeUpdate: ' + currentTime);
    if (lastTime !== currentTime) {
      // hoslog('onTimeUpdate: notify');
      this.playingTimeUpdatedObs.next(currentTime);
    }
  }

  onPlayEnded() {
    if (this.statusObs.value === HosCurrentPlayState.paused) {
      this.trackFinished();
    }
  }

  changeStatus(status: HosCurrentPlayState) {
    this.statusObs.next(status);
    this.statusStrObs.next(HosCurrentPlayState[status]);

    // Test
    /*
    let qualityLevels = this.jsPlayer.qualityLevels();

    qualityLevels.on('addqualitylevel', function (event) {
      // let qualityLevel = event.qualityLevel;
      /!*
       event:
       * type: 'addqualitylevel'
       * qualityLevel:
       * id: 'pgm1123/40k/s.m3u8'
       * label: 'pgm1123/40k/s.m3u8'
       * bitrate (number): 67000
       *!/
      if (event && event.qualityLevel) {
        hoslog('QL: bitrate: ' + event.qualityLevel.bitrate);
      } else {
        hoslog('QL: invalid');
      }
    });*/
  }

  trackFinished(): any {
    // console.log('trackFinished()');
    this.changeStatus(HosCurrentPlayState.idle);
    this.trackFinished$.next(true);
  }

  shutdown() {
    if (this.hls) {
      this.hls.detachMedia();
      this.hls.destroy();
    }
  }

  setStreamingQuality(number: number) {
    hoslog('setStreamingQuality: ' + number);
    this.streamingQualityObs.next(number);
    if (this.hls) {
      this.hls.autoLevelCapping = number;
    }
  }
}

/*
export class HosPlayerVideoJsImpl implements HosPlayer {
  public jsPlayer; //: Player; // the videojs player

  private preAudioExecuted = false; // safari strick auto play policy hack

  private seekWhenLoaded: number = 0;

  // Observables
  readyObs: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  statusObs: BehaviorSubject<HosCurrentPlayState> = new BehaviorSubject<HosCurrentPlayState>(HosCurrentPlayState.idle);
  statusStrObs: BehaviorSubject<string> = new BehaviorSubject<string>(HosCurrentPlayState[HosCurrentPlayState.idle]);
  volumeObs: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  mutedObs: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  durationChangedObs: BehaviorSubject<number> = new BehaviorSubject<number>(0); // TODO Subject?
  seekingObs: BehaviorSubject<number> = new BehaviorSubject<number>(0); // TODO Subject?
  seekedObs: BehaviorSubject<number> = new BehaviorSubject<number>(0); // TODO Subject?
  playingTimeUpdatedObs: BehaviorSubject<number> = new BehaviorSubject<number>(0); // TODO Subject?
  playerErrorObs: BehaviorSubject<any> = new BehaviorSubject<any>({}); // TODO Subject?

  trackFinished$ = new Subject();

  // private currentPlaylist: HosQueue;

  constructor() {
    // this.currentPlaylist = new HosPlaylistMulti([]); // start with empty playlist

    /!*var _beforeRequest = isFunction(videojs.Hls.xhr.beforeRequest);
    videojs.Hls.xhr.beforeRequest = function(options) {
      if (_beforeRequest) {
        options = videojs.Hls.xhr.beforeRequest(options);
      }
      /!*if ($localStorage.token) {
        options.headers = options.headers || {};
        options.headers.Authorization = 'Token ' + $localStorage.token;
      }*!/
      options.headers = options.headers || {};
      options.headers.TestDem = 'dem=fil&ciao=test';
      // options.headers.Authorization = 'Token ' + $localStorage.token;
      return options;
    };*!/
  }

  getPluginId(): string {
    return 'VJS';
  }

  getPluginName(): string {
    return 'VideoJs';
  }

  /!*addEventListener(listener: HosPlayerEventListener) {
   }*!/

  /!**
   * Specific implementation params:
   * * HtmlElementId: the HTML Element ID where to build the player
   *!/
  setup(params: Map<string, string>) {
    let htmlElementId = params.get('HtmlElementId');
    if (htmlElementId) {
      this.jsPlayer = videojs(htmlElementId, {
        controls: true,
        autoplay: false,
        preload: 'auto'
      });

      let me = this;
      this.jsPlayer.on('ready', function () {
        me.readyObs.next(true);

        // Initial values
        me.onVolumeChanged();
      });
      this.jsPlayer.on('volumechange', function () {
        me.onVolumeChanged();
      });
      this.jsPlayer.on('error', function (e) {
        hoslog('On: error');
        me.changeStatus(HosCurrentPlayState.idle);
        hoslog(e);
        e.stopImmediatePropagation();
        let error = me.jsPlayer.error();
        me.playerErrorObs.next(error);
      });
      this.jsPlayer.on('abort', function () {
        hoslog('On: abort');
        me.changeStatus(HosCurrentPlayState.idle);
      });
      this.jsPlayer.on('stalled', function () {
        hoslog('On: stalled');
      });
      this.jsPlayer.on('canplay', function () {
        hoslog('On: canplay');
      });
      this.jsPlayer.on('durationchange', function () {
        hoslog('On: durationchange');
        me.onDurationChanged()
      });
      this.jsPlayer.on('loadeddata', function () {
        hoslog('On: loadeddata');
        me.seek(me.seekWhenLoaded);
        me.seekWhenLoaded = 0;
      });
      this.jsPlayer.on('loadstart', function () {
        hoslog('On: loadstart');
        me.changeStatus(HosCurrentPlayState.paused);
      });
      this.jsPlayer.on('progress', function () {
        // hoslog('On: progress');
        // Fired while the user agent is downloading media data.
      });
      this.jsPlayer.on('timeupdate', function () {
        // hoslog('On: timeupdate');
        // Fired when the current playback position has changed * During playback this is fired every 15-250 milliseconds, depending on the playback technology in use.
        me.onTimeUpdate();
      });
      this.jsPlayer.on('seeking', function () {
        hoslog('On: seeking');
        me.onSeeking();
      });
      this.jsPlayer.on('seeked', function () {
        hoslog('On: seeked');
        me.onSeeked();
      });
      this.jsPlayer.on('play', function () {
        hoslog('On: play');
        me.changeStatus(HosCurrentPlayState.playing);
      });
      this.jsPlayer.on('playing', function () {
        hoslog('On: playing');
        me.changeStatus(HosCurrentPlayState.playing);
      });
      this.jsPlayer.on('pause', function () {
        hoslog('On: pause');
        me.changeStatus(HosCurrentPlayState.paused);
      });
      this.jsPlayer.on('ended', function () {
        hoslog('On: ended');
        me.trackFinished();
      });
    } else {
      console.error('HosPlayer htmlElementId not found')
    }
  }

  private onVolumeChanged() {
    let volume = this.jsPlayer.volume();
    let muted = this.jsPlayer.muted();
    // hoslog('Volume change: ' + volume);
    // hoslog('Muted: ' + muted);

    if (this.volumeObs.getValue() != volume) {
      this.volumeObs.next(volume);
    }

    if (this.mutedObs.getValue() != muted) {
      this.mutedObs.next(muted);
    }
  }

  /!*loadItem(playItem: HosPlayItem) {
   if (playItem == null) {
   /!*let random = Math.floor(Math.random() * 4);
   hoslog('Random: ' + random);
   this.jsPlayer.src([
   {type: 'application/x-mpegURL', src: testUrls[random]}
   ]);*!/
   } else {
   this.jsPlayer.src([
   {type: 'application/x-mpegURL', src: playItem.getStreamUrl()}
   ]);
   }
   }*!/

  /!*loadPlaylist(playlist: HosQueue) {
   this.jsPlayer.src([
   {type: 'application/x-mpegURL', src: 'https://dev-hos-stream.s3.amazonaws.com/pgm1123.m3u8'}
   ]);
   }*!/

  /!*getHosPlaylist(): HosQueue {
   return undefined;
   }*!/

  // Safari strict audio policy rules
  loadPreAudioItem() {
    if (!this.preAudioExecuted) {
      hoslog('Loading pre audio...');
      this.jsPlayer.src({
        src: '/assets/sound/preaudio.mp3',
        type: 'audio/mp3'
      });
      const me = this;
      this.jsPlayer.ready(function () {
        me.jsPlayer.play();
      });
      this.preAudioExecuted = true;
    }
  }

  loadItem(playItem: HosPlayItem, startPosition: number, startPlaying = true) {
    this.seekWhenLoaded = startPosition;
    /!*
    https://github.com/videojs/videojs-contrib-hls

    bandwidth

Type: number
can be used as an initialization option
When the bandwidth property is set (bits per second), it will be used in the calculation for initial playlist selection, before more bandwidth information is seen by the player.
     *!/
    if (environment.fakePlayer) {
      hoslog('fake player');
      this.jsPlayer.src({
        src: 'https://s3-eu-west-1.amazonaws.com/hos.tmp/42-Rain-60min.mp3',
        type: 'audio/mp3',
        withCredentials: true
      });
    } else {
      hoslog('src: ' + playItem.getPlayTokenWrapper().signedUrl);
      this.jsPlayer.src({
        src: playItem.getPlayTokenWrapper().signedUrl,
        type: 'application/x-mpegURL',
        withCredentials: true
      });
    }

    const me = this;
    this.jsPlayer.ready(function () {
      if (startPlaying) {
        me.jsPlayer.play();
      }
    });
    /!*this.jsPlayer.src([
      {type: 'audio/mp3', src: 'http://localhost/audio5.mp3'}
    ]);*!/
  }


  play() {
    this.jsPlayer.play();
  }

  pause() {
    return this.jsPlayer.pause();
  }

  muteToggle() {
    this.jsPlayer.muted(!this.jsPlayer.muted());
  }

  get volume(): number {
    return this.jsPlayer.volume();
  }

  set volume(vol: number) {
    this.jsPlayer.volume(vol);
  }

  get currentTime(): number {
    return this.jsPlayer.currentTime();
  }

  set currentTime(time: number) {
    this.jsPlayer.currentTime(time);
  }

  seek(time: number) {
    this.currentTime = time;

    // if the player is not playing I force the time update so we can skip to the next track if needed
    const currentStatus = this.statusObs.value;
    if (currentStatus !== HosCurrentPlayState.playing) {
      this.onTimeUpdate();
    }
  }

  // Events management

  onSeeking() {
    let currentTime = this.jsPlayer.currentTime();
    this.seekingObs.next(currentTime);
  }

  onSeeked() {
    let currentTime = this.jsPlayer.currentTime();
    this.seekedObs.next(currentTime);
  }

  onDurationChanged() {
    let duration = this.jsPlayer.duration();
    this.durationChangedObs.next(duration);
  }

  onTimeUpdate() {
    let currentTime = this.jsPlayer.currentTime();
    this.playingTimeUpdatedObs.next(currentTime);
  }

  changeStatus(status: HosCurrentPlayState) {
    this.statusObs.next(status);
    this.statusStrObs.next(HosCurrentPlayState[status]);

    // Test
    let qualityLevels = this.jsPlayer.qualityLevels();

    qualityLevels.on('addqualitylevel', function (event) {
      // let qualityLevel = event.qualityLevel;
      /!*
       event:
       * type: 'addqualitylevel'
       * qualityLevel:
       * id: 'pgm1123/40k/s.m3u8'
       * label: 'pgm1123/40k/s.m3u8'
       * bitrate (number): 67000
       *!/
      if (event && event.qualityLevel) {
        hoslog('QL: bitrate: ' + event.qualityLevel.bitrate);
      } else {
        hoslog('QL: invalid');
      }
    });
  }

  trackFinished(): any {
    this.changeStatus(HosCurrentPlayState.idle);
    this.trackFinished$.next();
  }

  shutdown() {
    if (this.jsPlayer) {
      this.jsPlayer.dispose();
    }
  }
}
*/
