import { Injectable, Injector } from '@angular/core';
import { environment } from "../../environments/environment";

import * as mixpanel from 'mixpanel-browser';
import {LoggedUserService} from '../api-client/helper/logged-user.service';
import {LoginHelperService} from '../api-client/helper/login-helper.service';


@Injectable()
export class MixpanelService {

  private _distinct_id;

  constructor(private loginHelperService: LoginHelperService,
              private loggedUserService: LoggedUserService) {
  }

  /**
   * Initialize mixpanel.
   *
   * @param {string} userToken
   * @memberof MixpanelService
   */
  init(): void {
    if (environment.analyticsEnabled && mixpanel) {
      let that = this;
      mixpanel.init(environment.mixpanelConfig.token, {
        loaded: function () {
          // console.log('init mixpanel');
          that._distinct_id = that.getId();
          that.registerSuperProperties({eventsVersion: 1});
        }
      });
    }
  }

  /**
   * Identify all next mixpanel event with this id.
   *
   * @param {string} userId
   * @memberof MixpanelService
   */
  identify(userId: string): void {
    if (environment.analyticsEnabled && mixpanel) {
      // console.log('identify', userId);
      mixpanel.identify(userId);
    }
  }

  /**
   * Reset id on Sign Out.
   *
   * @memberof MixpanelService
   */
  clear(): void {
    if (environment.analyticsEnabled && mixpanel) {
      mixpanel.reset();
    }
  }

  /**
   * Get distinct_id of mixpanel user.
   *
   * @memberof MixpanelService
   */
  getId(): string {
    return mixpanel.get_distinct_id();
  }

  /**
   * Alias two distinct_id of mixpanel user.
   *
   * @param {string} new_id
   * @param {string} exist_id
   * @memberof MixpanelService
   */
  alias(new_id: string): void {
    if (environment.analyticsEnabled && mixpanel) {
      // console.log('alias', new_id, this._distinct_id);
      if (new_id !== this._distinct_id) {
        mixpanel.alias(new_id, this._distinct_id);
      }
    }
  }


  /**
   * Set data to people in mixpanel.
   *
   * @param {*} [action={}] Actions object with people custom properties.
   * @memberof MixpanelService
   */
  setUser(action: any = {}): void {
    if (environment.analyticsEnabled && mixpanel && mixpanel.people) {
      let peopleData: any = {};
      peopleData.$email = action.email || null;
      peopleData.$phone = action.phoneNumber || null;
      peopleData.$first_name = action.firstName || null;
      peopleData.$last_name = action.lastName || null;
      peopleData.status = action.status || null;

      mixpanel.people.set(peopleData);
    }
  }

  /**
   * Push new action to mixpanel.
   *
   * @param {string} id Name of the action to track.
   * @param {*} [action={}] Actions object with custom properties.
   * @memberof MixpanelService
   */
  track(id: string, action: any = {}): void {
    if (environment.analyticsEnabled && mixpanel) {
      mixpanel.track(id, action);
    }
  }

  trackCharge(value: number): void {
    if (environment.analyticsEnabled && mixpanel && mixpanel.people) {
      mixpanel.people.track_charge(value);
    }
  }

  registerSuperProperties(data: any) {
    if (environment.analyticsEnabled && mixpanel) {
      mixpanel.register(data);
    }
  }

  saveUserProperty(key: string, value: string) {
    let isLoggedIn = this.loginHelperService.isLoggedIn$.value;
    let loggedUser = this.loggedUserService.loggedUserDetailsObs.value;
    if (environment.analyticsEnabled && mixpanel && mixpanel.people && isLoggedIn && loggedUser) {
      let data = {};
      data[key] = value;
      mixpanel.people.set(data);
      mixpanel.identify(loggedUser.email);
    }
  };

  incrementUserProperty(key: string, by: number = 1) {
    if (environment.analyticsEnabled && mixpanel && mixpanel.people) {
      mixpanel.people.increment(key, by);
    }
  };
}
