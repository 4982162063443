/**
 * HOS API
 *
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 */
import {Injectable, Injector} from "@angular/core";
import {HosBaseService} from "./hos-base.service";
import {HttpResponse, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import { map } from 'rxjs/operators';
import {AppConstants} from "../../app.constants";

/* tslint:disable:no-unused-variable member-ordering */


/*@Injectable()
export class ErrorsService extends HosBaseService {


  constructor(injector: Injector) {
     super(injector);
   }

  /!**
   * Test endpoint to show the standard error
   *
   *!/
  public generateBasicError(extraHttpRequestParams?: any): Observable<{}> {
    return this.generateBasicErrorWithHttpInfo(extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /!**
   * Test endpoint to show the error with the custom dialog data for the user
   *
   *!/
  public generateForbiddenErrorWithUpsellDialog(extraHttpRequestParams?: any): Observable<{}> {
    return this.generateForbiddenErrorWithUpsellDialogWithHttpInfo(extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }


  /!**
   * Test endpoint to show the standard error
   *
   *!/
  public generateBasicErrorWithHttpInfo(extraHttpRequestParams?: any): Observable<HttpResponse<Object>> {
    const path = this.getBasePath() + `/errors/test/standard`;

    let queryParameters = new HttpParams();
    let headers = this.getDefaultHeaders();


    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];


    let requestOptions = {
      headers: headers,
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.GET, path, requestOptions);
  }

  /!**
   * Test endpoint to show the error with the custom dialog data for the user
   *
   *!/
  public generateForbiddenErrorWithUpsellDialogWithHttpInfo(extraHttpRequestParams?: any): Observable<HttpResponse<Object>> {
    const path = this.getBasePath() + `/errors/test/dialog`;

    let queryParameters = new HttpParams();
    let headers = this.getDefaultHeaders();


    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];


    let requestOptions = {
      headers: headers,
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.GET, path, requestOptions);
  }

}*/
