import {ModalModule} from './shared/modal.service';
import {ActionModalComponent} from './shared/action-modal/action-modal.component';
import {SharedModule} from './shared/shared.module';

import {routing} from './app.routes';

import {AppComponent} from './app.component';
import {SearchComponent} from './core-components/search/search.component';
import {HeaderComponent} from './core-components/header/header.component';
import {PlayerComponent} from './core-components/player/player.component';
import {AuthComponent} from './core-components/auth/auth.component';
import {NavigationComponent} from './core-components/navigation/navigation.component';
import {GeneralService} from './services/general.service';
import {ApiModule} from './api-client';
import {HosPlayerService} from './services/player/hos-player.service';
import {AppSettingsService} from './services/app-settings.service';
import {SearchTrackItemComponent} from './core-components/search/shared/search-track-item/search-track-item.component';
import {SearchProgramItemComponent} from './core-components/search/shared/search-program-item/search-program-item.component';
import {SearchPlaylistItemComponent} from './core-components/search/shared/search-playlist-item/search-playlist-item.component';
import {SearchAlbumItemComponent} from './core-components/search/shared/search-album-item/search-album-item.component';
import {LoggedUserFavouritesService} from './services/logged-user-favourites.service';
import {LoggedUserBlockedItemsService} from './services/logged-user-blocked-items.service';
import {LoggedUserPlanService} from './services/logged-user-plan.service';
import {AlertsUtilService} from './services/alerts-util.service';
import {HotkeyModule} from 'angular2-hotkeys';
import {MixpanelService} from './services/mixpanel.service';
import {OAuthModule, OAuthResourceServerErrorHandler, OAuthStorage} from 'angular-oauth2-oidc';
import {LatteOAuthResourceServerErrorHandler} from './api-client/helper/login-helper.service';
import {HttpInterceptorService} from './api-client/helper/http-interceptor.service';
import {HomeRedirectService} from './services/home-redirect.service';
import {ErrorsService} from './services/errors.service';
import {TopBarDirective} from './directives/top-bar.directive';
import {TopBarComponentService} from './services/top-bar-component.service';
import {FavouriteListFilterService} from './services/favourite-list-filter.service';
import {SelectPlaylistDialogComponent} from './shared/option-popup/select-playlist-dialog/select-playlist-dialog.component';
import {CreatePlaylistComponent} from './shared/create-playlist/create-playlist.component';
import {PlanHelperService} from './services/plan-helper.service';
import {NotFoundService} from './services/not-found.service';
import {GoogleAnalyticsService} from './services/google-analytics.service';
import {MetaUtilsService} from './services/meta-utils.service';
import {ViewportUtilsService} from './services/viewport-utils.service';
import {ShareService} from './services/share.service';
import {ShareModalComponent} from './shared/share-modal/share-modal.component';
import {HosPlayerStorageService} from './services/player/hos-player-storage.service';
import {HosPlayerAutoStorageService} from './services/player/hos-player-auto-storage.service';
import {UnregisteredUsersStreamingCheckService} from './services/unregistered-users-streaming-check.service';
import {ChannelsListService} from './services/channels-list.service';
import {SleepTimerService} from './services/sleep-timer.service';
import {VisibilityService} from './services/visibility.service';
import {BackgroundService} from './services/background.service';
import {OrderingUtilsService} from './services/ordering-utils.service';
import {SearchValuesHolderService} from './services/search-values-holder.service';
import {HowMinutesWorkModalComponent} from './shared/how-minutes-work-modal/how-minutes-work-modal.component';
import {PlayerVolumeService} from './services/player/player-volume.service';
import {AppStorageService} from './services/app-storage.service';
import {LoginAnalyticsHelperService} from './services/login-analytics-helper.service';
import {StopTimerService} from './services/stop-timer.service';
import {PlaylistsUtilsService} from './services/playlists-utils.service';
import {BrowserModule} from '@angular/platform-browser';
import {ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AngularPageVisibilityModule} from 'angular-page-visibility';
import {NgModule} from '@angular/core';
import {VoiceoverStorageService} from './services/voiceover-storage.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';
import {PlaylistsDialogsUtilsService} from './services/playlists-dialogs-utils.service';
import {NoWhitespaceDirective} from './directives/no-whitespace.directive';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {TooltipConfig, TooltipModule} from 'ngx-bootstrap/tooltip';
import {SubscriptionService} from './services/subscription.service';
import {PaymentConfirmedService} from './services/payment-confirmed.service';
import {SubscriptionPaymentService} from './services/subscription-payment.service';
import {SubscriptionGiftService} from './services/subscription-gift.service';
import {FileSaverService} from 'ngx-filesaver';
import {ChannelsListRefreshService} from './services/channels-list-refresh.service';
import {PlayerService} from './services/player/player-service';

export function getAlertConfig(): TooltipConfig {
  return Object.assign(new TooltipConfig(), {
    placement: 'top',
    container: 'body',
    delay: 500,
    containerClass: 'hos-tooltip'
  });
}

@NgModule({
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    routing,
    // HomeModule,
    // AboutModule,
    // AccountModule,
    // AlbumsModule,
    // ChannelsModule,
    // FavoritesModule,
    // PlaylistModule,
    // ProgramsModule,
    // RecentModule,
    // SubscriptionModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 4000,
      extendedTimeOut: 3000,
      positionClass: 'toast-bottom-center',
      progressBar: true,
      closeButton: true
    }),
    ModalModule,
    SharedModule,
    // FocusModule.forRoot(),
    OAuthModule.forRoot( /*{
          resourceServer: {
            allowedUrls: [/!*'https://www..com', *!/'http://localhost:8585/api/v1/admin'],
            sendAccessToken: true // passes the token automatically to the requests
          }
        }*/),
    ApiModule,
    BsDatepickerModule.forRoot(),
    HotkeyModule.forRoot(),
    AngularPageVisibilityModule,
    TooltipModule
  ],
  declarations: [
    AppComponent,
    SearchComponent,
    SearchAlbumItemComponent,
    SearchPlaylistItemComponent,
    SearchProgramItemComponent,
    SearchTrackItemComponent,
    HeaderComponent,
    PlayerComponent,
    AuthComponent,
    NavigationComponent,
    ActionModalComponent,
    ShareModalComponent,
    TopBarDirective,
    NoWhitespaceDirective,
    HowMinutesWorkModalComponent
  ],
  providers: [
    GeneralService,
    HosPlayerService,
    HosPlayerStorageService,
    PlayerService,
    HosPlayerAutoStorageService,
    AppSettingsService,
    AppStorageService,
    VoiceoverStorageService,
    LoggedUserFavouritesService,
    LoggedUserBlockedItemsService,
    LoggedUserPlanService,
    LoginAnalyticsHelperService,
    PlanHelperService,
    PlaylistsUtilsService,
    PlaylistsDialogsUtilsService,
    AlertsUtilService,
    MixpanelService,
    HomeRedirectService,
    ErrorsService,
    TopBarComponentService,
    FavouriteListFilterService,
    NotFoundService,
    GoogleAnalyticsService,
    MetaUtilsService,
    ViewportUtilsService,
    ShareService,
    UnregisteredUsersStreamingCheckService,
    ChannelsListService,
    ChannelsListRefreshService,
    SleepTimerService,
    VisibilityService,
    BackgroundService,
    OrderingUtilsService,
    SearchValuesHolderService,
    PlayerVolumeService,
    StopTimerService,
    SubscriptionService,
    SubscriptionGiftService,
    SubscriptionPaymentService,
    PaymentConfirmedService,
    FileSaverService,
    {provide: OAuthStorage, useValue: localStorage},
    {
      provide: OAuthResourceServerErrorHandler,
      useClass: LatteOAuthResourceServerErrorHandler
    },
    {provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true},
    { provide: TooltipConfig, useFactory: getAlertConfig },
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
