import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filterGenres'
})
export class FilterGenresPipe implements PipeTransform {

  transform(genres: any, typeName: string): any {
    if (genres) {
      return genres.filter(genre => {
        if (typeName === 'seasons') {
          return genre.season === true && genre.visible === true;
        } else if (typeName === 'holidays') {
          return genre.holiday === true && genre.visible === true;
        } else { // genres
          return genre.season === false && (genre.holiday === false || genre.holiday === null) && genre.visible === true;
        }
      })
    } else {
      return null;
    }
  }

}
