import {Component, OnInit, Input} from '@angular/core';
import {HosPlayerService} from '../../services/player/hos-player.service';
import {Subscription} from 'rxjs';
import {PlayerService} from '../../services/player/player-service';
import { HttpClient } from '@angular/common/http';
import {AlertsUtilService} from '../../services/alerts-util.service';

@Component({
    selector: "app-promo-player",
    templateUrl: './promo-player.component.html',
    styleUrls: ['./promo-player.component.scss']
})

export class PromoPlayerComponent {
  @Input('programId') programId : number;

  public promo30SecPlaying = false;
  private audioElement: any; // HTMLAudioElement;
  private promoUrl: string = null;
  private playEventSubscription: Subscription;

  constructor(private hosPlayerService: HosPlayerService,
              private playerService: PlayerService,
              private http: HttpClient,
              private alertsUtilService: AlertsUtilService) {}

  ngOnInit() {
    if (this.programId) {
      this.promoUrl = this.calculatePromoUrl(this.programId);
      this.audioElement = document.getElementById('hospromoplayer') as HTMLAudioElement;
    }

    this.playEventSubscription = this.playerService.playEvent$.subscribe(() => {
      this.pause30SecPromo();
    });
  }

  private calculatePromoUrl(programId: number) {
    // return 'https://s3.amazonaws.com/mydonedone.com/donedone_issuetracking_2191/478b7064-250c-4d5e-956f-f9c00f5e8179_/promo1006.mp3';
    return 'http://hos.promos.s3-website-us-west-2.amazonaws.com/promo' + programId + '.mp3';
  }

  playMp3(url: string) {
    this.audioElement.src = url;
    this.audioElement.load();
    this.playerService.notifyPromoPlayEvent();
    this.audioElement.play().then(() => {
      this.promo30SecPlaying = true;
    }).catch(error => {
      this.alertsUtilService.showAlert("Sorry, the promo stream for this program is temporarily unavailable.");
      this.promo30SecPlaying = false;
    });
  }

  promo30SecClicked() {
    if(this.promo30SecPlaying) {
      this.pause30SecPromo();
    } else {
      this.play30SecPromo();
    }
  }

  pause30SecPromo() {
    this.promo30SecPlaying = false;
    this.audioElement.pause();
  }

  play30SecPromo() {
    this.promo30SecPlaying = true;
    this.playMp3(this.promoUrl);
  }

  private checkAudioUrl(url: string) {
    return this.http.head(url, { observe: 'response' });
  }
}
