import {Injectable} from '@angular/core';
import {AppConstants, hoslog} from '../../app.constants';
import {HosPlayerService} from '../../services/player/hos-player.service';
import {HosQueueEmpty, HosQueuePlaylist} from '../../services/player/hos-queue';
import {Playlist} from '../../api-client';
import {AlertsUtilService} from '../../services/alerts-util.service';

@Injectable()
export class PlayerUtilsService {

  constructor(private hosPlayerService: HosPlayerService,
              private alertsUtilService: AlertsUtilService) {
  }

  isPlaylistPlaying(playlistId: number): boolean {
    const queue = this.hosPlayerService.currentQueueObs.value;
    if (queue && queue.getQueueUniqueId()) {
      if (queue.getQueueUniqueId().startsWith("PL")) {
        const playlistPlayingId = queue.getQueueUniqueId().replace("PL", "");
        hoslog('Playlist playing: ' + playlistPlayingId);
        return (playlistId === +playlistPlayingId)
      }
    }
    return false;
  }

  showRestartPlaylistAlert(playlistToRestart: Playlist) {
    this.alertsUtilService.showConfirmationDialog(AppConstants.EditPlayingPlaylistAlertMsg,
      "RESTART PLAYLIST", "OK",
      () => {
        hoslog('Restarting the current playlist');
        this.restartPlaylist(playlistToRestart);
      });
  }

  restartPlaylist(playlistToRestart: Playlist) {
    // this.hosPlayerService.player.pause();
    this.hosPlayerService.clear();
    this.hosPlayerService.play(new HosQueueEmpty());
    this.hosPlayerService.play(new HosQueuePlaylist(playlistToRestart));
  }
}
