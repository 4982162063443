<div id="container-content">
  <div id="container-router">
    <div class="container-fluid no-indents inner-component-container" [ngClass]="{'program-detail-indents': programId}">
      <app-loading *ngIf="loadingHelperService.isLoading();else loaded"></app-loading>
      <ng-template #loaded>
        <app-error-message [error]="loadingHelperService.getError()" *ngIf="loadingHelperService.hasError()"
                           [showTryAgain]="true"
                           (tryAgain)="refresh()"></app-error-message>

        <div *ngIf="data">
          <div class="row">
            <div class="col-lg-12">
              <img [src]="data.id | HosProgramImageUrl:1024:(data.genres)" alt="{{data.title}}" class="program-cover"
                   [ngClass]="{'no-border-top': (type === 'this-week')}" aria-hidden="true">
            </div>
          </div>
          <div class="row" *ngIf="data?.galleryUrl">
            <div class="col-lg-12 image-bottom">
              <!--<img src="/assets/images/image-gallery.png" alt="">-->
              <!--<span>Image Gallery</span>-->
              <span class="float-end link-like" (click)="flickrImgGalleryClicked()">Flickr Image Gallery</span>
            </div>
          </div>
          <div class="row program-header">
            <div class="program-name">
              <app-play-channel-track-button [iconSize]="playButtonIconSize.Big" [channelTrackItem]="data"
                                             [item]="isFromChannel"
                                             *ngIf="isFromChannel"></app-play-channel-track-button>
              <app-play-playlist-track-button [iconSize]="playButtonIconSize.Big" [playlistTrackItem]="data"
                                              [item]="isFromPlaylist" *ngIf="isFromPlaylist"
                                              [idx]="programIdxInPlaylist"></app-play-playlist-track-button>
              <app-play-program-button #play [iconSize]="playButtonIconSize.Big" [item]="data"
                                       [pageReferral]="getProgramReferral()"
                                       [twp]="!programId && type === 'this-week'"
                                       *ngIf="(!isFromChannel && !isFromPlaylist)"></app-play-program-button>
              <h2 tabindex="0" class="truncated">{{data.title}}</h2>
              <div class="peek-wrapper">
                <app-program-peek-popup [programId]="data.id"></app-program-peek-popup>
              </div>
            </div>
            <div class="program-description">PGM No. {{data.number}}&nbsp;&nbsp;&nbsp;{{data.date | date}}</div>
            <div class="program-actions" role="group" aria-label="Program actions">
              <div class="flex-wrapper d-flex">
                <div class="d-flex">
                  <app-block-button [item]="data"></app-block-button>
                  <app-rating [item]="data"></app-rating>
                </div>
                <app-popularity class="ms-3 me-3" [popularity]="data.popularity"></app-popularity>
                <app-option-popup [item]="data" [detail]="true" [pageReferral]="getProgramReferral()"
                                  [channel]="isFromChannel" [playlist]="isFromPlaylist"
                                  [programIdxInPlaylist]="programIdxInPlaylist">
                  <img src="/assets/images/ellipsis.svg" alt="" aria-hidden="false">
                </app-option-popup>
              </div>
            </div>
          </div>
          <div class="row description">
            <div class="col-lg-12 description-text-block" role="group" aria-label="Program information" tabindex="0">
              <div class="description-text-column">
                <h3 class="description-header">
                  {{data.shortDescription | upperfirst}}
                </h3>
                <div class="description-text-block">
                  <span class="description-text"
                        *ngIf="data.description"
                        [innerHTML]="(data.description.length > (showLessDescription? 250 : data.description.length))? (data.description | slice:0:250) + '...' : (data.description)">
                  </span>
                  <button class="show-more show-more-inline" *ngIf="data.description.length > 250"
                          [ngClass]="{'show-more-inline': showLessDescription}"
                          (click)="showLessDescription = !showLessDescription">{{showLessDescription ? 'more' : 'Less'}}</button>
                </div>
              </div>
              <div class="description-text-sidebar">
                <p class="producer" tabindex="0" *ngIf="data.producer">Produced by {{data.producer}}</p>

                <div class="genres" role="group" aria-label="Genres" tabindex="0">
                  <ng-container class="genre" *ngFor="let genre of data.genres; let l = last;">
                    <a [routerLink]="['/programs/genres', genre.id, 'list']"
                       attr.aria-label="Category: {{genre.name}}, click to show all the items in the category">{{genre.name}}</a>
                    <span *ngIf="!l"> | </span>
                  </ng-container>
                </div>

                <!-- AIR DATES -->
                <div class="air-dates-container">
                  <div class="air-dates" *ngIf="data.airDates && data.airDates.length>0">
                    <div style="display: block;">
                      <span class="text-uppercase">First broadcast: </span>
                      <span>{{data.airDates[0] | date:'MMM dd, yyyy'}}</span>
                    </div>
                    <div style="display: block; margin-top: 5px;" *ngIf="data.airDates && data.airDates.length>1">
                      <span class="text-uppercase">Re-aired: </span>
                      <ng-container *ngFor="let ad of data.airDates; let l = last; let f= first;">
                        <span class="white-space-no-wrap" *ngIf="!f">{{ad | date:'MMM dd, yyyy'}}</span>
                        <span *ngIf="!f && !l"> | </span>
                      </ng-container>
                    </div>
                  </div>
                </div>

                <div class="weather-report-box" *ngIf="data.weatherReport">
                  <span>Bay Area Weather</span>: {{data.weatherReport}}
                </div>

                <div class="program-detail-buttons-container">
                  <div class="program-detail-buttons-grid-item">
                    <button aria-hidden="true" (click)="shareClicked()" class="program-action-button">
                      <img src="/assets/images/share.svg" alt="Share program" class="share-image-button">
                      Share Program
                    </button>
                  </div>

                  <div class="program-detail-buttons-grid-item">
                    <button aria-hidden="true" (click)="addToPLaylistClicked()" class="program-action-button">
                      <img src="/assets/images/tooltip-plus.svg" alt="Add to Playlist" class="share-image-button">
                      Add to Playlist
                    </button>
                  </div>

                  <div class="program-detail-buttons-grid-item">
                    <button aria-hidden="true" (click)="printProgramClicked()" class="program-action-button"
                            [disabled]="loadingHelperService.isLoading('programDetailOverlayDownloadLoading') == true"
                            [ngClass]="{'disabled': loadingHelperService.isLoading('programDetailOverlayDownloadLoading')}">
                      <img src="/assets/images/print.svg" alt="Print program" class="share-image-button">
                      {{loadingHelperService.isLoading('programDetailOverlayDownloadLoading') ? 'Loading...' : 'Print Program'}}
                    </button>
                  </div>

                  <div class="program-detail-buttons-grid-item">
                      <app-promo-player class="promo-player-container" [programId]="programId"></app-promo-player>
                  </div>


                  <!--
                  <button aria-hidden="true" (click)="flickrImgGalleryClicked()" *ngIf="data?.galleryUrl">
                    Flickr Image Gallery
                  </button>-->
                </div>
              </div>
            </div>
          </div>

          <div class="row program-content">
            <table class="hos-table">
              <thead>
              <tr>
                <th></th>
                <th>ARTIST</th>
                <th>#</th>
                <th>TITLE</th>
                <th>ALBUM</th>
                <th>TIME</th>
              </tr>
              </thead>

              <tbody>
              <tr *ngFor="let item of data?.albums; index as i"
                  [ngClass]="{'active-track': (hosPlayerService.currentItemObs | async).currentItemInfo?.track?.startPositionInStream | activeTrack: item.tracks}">
                <td>
                  <img [src]="item.id | HosAlbumImageUrl:80" alt="{{item.title}}" aria-hidden="true">
                </td>
                <td>
                  <p>
                    <span *ngFor="let track of item.tracks" class="" [title]="track.trackArtists">{{ track.trackArtists }}</span>
                    <span *ngIf="item.composers" class="track-composer">By {{item.composers}}</span>
                  </p>

                  <!--
                  <p *ngFor="let track of item.tracks">
                    <span class="" [title]="track.trackArtists">{{ track.trackArtists }}</span>
                  </p>

                  <p *ngIf="item.composers">
                    <span class="track-composer">By {{item.composers}}</span>
                  </p>
                  -->
                </td>
                <td>
                  <p *ngFor="let track of item.tracks">{{track.sequenceNumber}}</p>
                </td>
                <td>
                  <p *ngFor="let track of item.tracks">
                    <em class="truncated" [title]="track.title">{{ track.title }}<span class="grey-text"
                                                                                       *ngIf="track.attributes"
                                                                                       [title]="track.attributes"
                                                                                       style="font-style: normal; margin-left: 5px;">({{track.attributes}}
                      )</span></em>
                  </p>
                  <p class="grey-text">
                    <!--                    Info: <span *ngFor="let url of item.buyCdUrl | splitUrls; let l = last"><a (click)="openUrl(url)" class="link-like" target="_blank">{{url}}</a><span *ngIf="!l">;&nbsp;</span></span>-->
                    <span class="url">
                        <a [href]="item.artists[0].url | convertToUrl"
                           *ngIf="item.artists && item.artists.length > 0 && item.artists[0].url"
                           target="_blank">Artist</a>
                        <a disabled="true" class="link-disabled"
                           *ngIf="!item.artists || item.artists.length === 0 || !item.artists[0].url">Artist</a>
                      </span>&nbsp;
                    <span class="url">
                        <a [href]="item.label.url | convertToUrl" target="_blank" *ngIf="item.label?.url">Label</a>
                        <a disabled="true" class="link-disabled" *ngIf="!(item.label?.url)">Label</a>
                      </span>&nbsp;
                    <span class="url">
                        <a [href]="item.buyCdUrl | convertToUrl" target="_blank"
                           *ngIf="item.buyCdUrl">Buy This Music</a>
                        <a disabled="true" class="link-disabled" *ngIf="!item.buyCdUrl">Buy This Music</a>
                      </span>
                  </p>
                </td>
                <td class="grey-text">
                  {{item.title}}
                  <p class="grey-text">{{item.label?.name}} <span
                    *ngIf="item.date">{{item.date | date:'y'}}&nbsp;</span></p>
                </td>
                <td>
                  <p *ngFor="let track of item.tracks"
                     class="grey-text">{{ (track.duration) | secondsToMinutes | playerExtraCutPipe }}</p>
                </td>
              </tr>
              <tr class="copyright-row">
                <td colspan="6" style="text-align: center" class="grey-text">
                  <p class="grey-text">
                    ⓒ Hearts of Space{{copyrightYear ? (' ' + copyrightYear) : ''}}. Hearts of Space® is a registered trademark of Hearts of Space, Inc. All
                    rights reserved.
                  </p>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>

