/**
 * HOS API
 *
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 *
 *
 *
 */



export interface News {
    /**
     * Id
     */
    id: number;

    /**
     * Title
     */
    title?: string;

    /**
     * Short description
     */
    shortDescription?: string;

    /**
     * Long description
     */
    longDescription?: string;

    /**
     * Date
     */
    date?: Date;

}
