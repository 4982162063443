/**
 * HOS API
 *
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 *
 *
 *
 */


export interface Genre {
  /**
   * Genre id
   */
  id: number;

  /**
   * Name
   */
  name: string;

  /**
   * Description
   */
  description?: string;

  ordering?: number;

  season?: boolean;
  holiday?: boolean;

  visible?: boolean;
}
