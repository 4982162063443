import {Album} from "../../api-client/model/Album";
import {Program} from "../../api-client/model/Program";
import {HosPlayAlbum, HosPlayAlbumTrack, HosPlayItem, HosPlayProgram} from "./hos-play-item";
import {HosItem} from '../../api-client';

export class HosPlayItemUtils {

  public static getPlayItem(item: HosItem, playlistIdx: number = null): HosPlayItem {
    if (item.type == HosItem.TypeEnum.Album) {
      return new HosPlayAlbum(item as Album, playlistIdx);
    } else if (item.type == HosItem.TypeEnum.Program) {
      return new HosPlayProgram(item as Program, null, playlistIdx);
    } else if (item.type == HosItem.TypeEnum.Track) {
      if (item['album']) {
        return new HosPlayAlbumTrack(item['album'] as Album, item, playlistIdx);
      }
    }
    return null;
  }

}
