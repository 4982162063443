import {Injectable} from '@angular/core';
import {SearchWrapper} from '../api-client';
import {hoslog} from '../app.constants';

@Injectable()
export class SearchValuesHolderService {

  public searchValue: string = '';
  public results: SearchWrapper = null;
  public showAllToggles = {
    "programs": false,
    "playlists": false,
    "tracks": false,
    "albums": false
  };

  constructor() {
  }

  public hasText(): boolean {
    return this.searchValue && this.searchValue.length > 0;
  }

  public isResultsEmpty(): boolean {
    if (this.results) {
      if (
        (this.results.albums && !this.results.albums.empty) ||
        (this.results.playlists && !this.results.playlists.empty) ||
        (this.results.programs && !this.results.programs.empty) ||
        (this.results.tracks && !this.results.tracks.empty)
      ) {
        return false;
      }
    }
    return true;
  }

  public showToggle(section: string) {
    hoslog("showToggle");
    this.showAllToggles[section] = !this.showAllToggles[section];
  }

  public resetAll() {
    this.searchValue = "";
    this.reset();
  }

  public reset() {
    this.results = null;
    this.showAllToggles = {
      "programs": false,
      "playlists": false,
      "tracks": false,
      "albums": false
    };
  }
}
