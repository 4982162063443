<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12 no-indents tab-router-wrapper">
      <div class="container-header">
        <nav>
          <ul role="group" aria-label="Favourite filter" class="favorite-top-bar-ul" tabindex="0">
            <li routerLinkActive="active">
              <a routerLink="favorites/programs">
                PROGRAMS
              </a>
              <div class="filter-rating">
                <app-rating-stars [rating]="favouriteListFilterService.filterValuePrograms | async" (ratingClick)="favouriteListFilterService.setFilterPrograms($event.rating)" [itemId]="-1"></app-rating-stars>
                <span> & up</span>
              </div>
            </li>
            <li routerLinkActive="active">
              <a routerLink="favorites/albums">
                ALBUMS
              </a>
              <div class="filter-rating">
                <app-rating-stars [rating]="favouriteListFilterService.filterValueAlbums | async" (ratingClick)="favouriteListFilterService.setFilterAlbums($event.rating)" [itemId]="-2"></app-rating-stars>
                <span> & up</span>
              </div>
            </li>
            <li routerLinkActive="active">
              <a routerLink="favorites/tracks">
                TRACKS
              </a>
              <div class="filter-rating">
                <app-rating-stars [rating]="favouriteListFilterService.filterValueTracks | async" (ratingClick)="favouriteListFilterService.setFilterTracks($event.rating)" [itemId]="-3"></app-rating-stars>
                <span> & up</span>
              </div>
            </li>

            <li routerLinkActive="active" class="flex-grow-1 action-container">
              <button type="button" class="hos-button" name="button" [routerLink]="['/blocked']">BLOCKED ITEMS</button>
            </li>

            <li class="flex-grow-0 flex-basis-0 action-container">
              <button type="button" name="button" class="hos-button print-favorites" [disabled]="loadingHelperService.isLoading('favoritesOverlayDownloadLoading') == true" [ngClass]="{'disabled': loadingHelperService.isLoading('favoritesOverlayDownloadLoading')}" (click)="printFavorites()" aria-label="Print favorites">
                <img src="/assets/images/print.svg" alt="" aria-hidden="true">
              </button>
            </li>
          </ul>

        </nav>
      </div>
    </div>
  </div>
</div>
