import { Component, OnInit, Input } from "@angular/core";
import { Router } from '@angular/router';

import { ModalService } from "../modal.service";
import { ActionModalComponent } from "../action-modal/action-modal.component";
import {Album} from "../../api-client/model/Album";
import {GeneralService} from '../../services/general.service';
import {Artist} from '../../api-client';
import {PlayButtonIconSize} from '../../app.constants';

@Component({
    selector: "[trackItem]",
    templateUrl: './track-item.component.html',
    styleUrls: ['./track-item.component.scss']
})

export class TrackItemComponent {
  @Input('no') no;
  @Input('trackItem') item;
  @Input('trackAlbum') album: Album;
  @Input('extData') extData;
  @Input('multipleArtists') multipleArtists;

  playButtonIconSize = PlayButtonIconSize;

  constructor(private generalService: GeneralService) {}

  ngOnInit() {
    // Adding the album addociated to the item (it's used in the favorite section)
    if (!this.item['album']) {
      const copiedAlbum: Album = JSON.parse(JSON.stringify(this.album)); // to avoid circular dependencies
      copiedAlbum.tracks = null;
      this.item['album'] = copiedAlbum;
    }
  }

  searchArtist(artist: Artist) {
    this.generalService.requestSearch(artist.name);
  }

    protected readonly PlayButtonIconSize = PlayButtonIconSize;
}
