/**
 * HOS API
 *
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 */
import {Injectable, Injector} from "@angular/core";
import {HosBaseService} from "./hos-base.service";

import {HttpResponse, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import { map } from 'rxjs/operators';
import "../rxjs-operators";
import {AppConstants} from "../../app.constants";
import {FaqSection} from "../model/FaqSection";

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class FaqsService extends HosBaseService {


  constructor(injector: Injector) {
     super(injector);
   }

  /**
   * Return the faq sections
   *
   * @param firstResult First result
   * @param maxResults Max results
   */
  public getFaqSections(platform?: string, faqGroup?: string, firstResult?: number, maxResults?: number, extraHttpRequestParams?: any): Observable<FaqSection[]> {
    return this.getFaqSectionsWithHttpInfo(platform, faqGroup, firstResult, maxResults, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }


  /**
   * Return the faq sections
   *
   * @param firstResult First result
   * @param maxResults Max results
   */
  public getFaqSectionsWithHttpInfo(platform?: string, faqGroup?: string, firstResult?: number, maxResults?: number, extraHttpRequestParams?: any): Observable<HttpResponse<FaqSection[]>> {
    const path = this.getBasePath() + `/faqs/sections`;

    let queryParameters = new HttpParams();
    let headers = this.getNoLoginHeaders();

    if (platform !== undefined && platform != null) {
      queryParameters = queryParameters.set('platform', <any>platform);
    }
    if (firstResult !== undefined) {
      queryParameters = queryParameters.set('firstResult', <any>firstResult);
    }
    if (maxResults !== undefined) {
      queryParameters = queryParameters.set('maxResults', <any>maxResults);
    }
    if (faqGroup !== undefined && faqGroup != null) {
      queryParameters = queryParameters.set('group', <any>faqGroup);
    }


    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];


    let requestOptions = {
      headers: headers,
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.GET, path, requestOptions);
  }

}
