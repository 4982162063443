/**
 * HOS API
 *
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 */
import {Injectable, Injector} from "@angular/core";
import {HosBaseService} from "./hos-base.service";

import {HttpResponse, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import { map } from 'rxjs/operators';
import "../rxjs-operators";
import {AppConstants} from "../../app.constants";
import {Program} from "../model/Program";
import {Page} from '../model/generic/page';
import {RequestsUtils} from '../../shared/utils/requests-utils';
import {PrevNextAlbumContainer} from '../model/PrevNextAlbumContainer';
import {PrevNextProgramContainer} from '../model/PrevNextProgramContainer';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class ProgramsService extends HosBaseService {


  constructor(injector: Injector) {
     super(injector);
   }

  /**
   * Return the program detail
   *
   * @param programId The program id
   */
  public getProgramDetail(programId: number, extraHttpRequestParams?: any): Observable<Program> {
    return this.getProgramDetailWithHttpInfo(programId, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Return the programs
   *
   * @param filterBy The filter by type
   * @param filterValue The filter by value
   * @param orderBy Order by
   * @param page page number
   * @param size page size
   */
  public getPrograms(filterBy?: string, filterValue?: string, orderBy?: string, orderByDirection?: string, page?: number, size?: number, skipThisWeekProgram: boolean = false, ratingOrderUserId?: number, extraHttpRequestParams?: any): Observable<Page<Program>> {
    console.log('getPrograms... orderBy: ' + orderBy + ', direction: ' + orderByDirection)
    return this.getProgramsWithHttpInfo(filterBy, filterValue, orderBy, orderByDirection, page, size, skipThisWeekProgram, ratingOrderUserId, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  public browsePrograms(pgmNoFrom?: number, pgmNoTo?: number, genreIds?: number[], seasonId?: number,
                         holidayId?: number, title?: string, artistName?: string, yearFrom?: number, yearTo?: number,
                         orderBy?: string, orderByDirection?: string, page?: number, size?: number, ratingOrderUserId?: number, extraHttpRequestParams?: any): Observable<Page<Program>> {
    return this.browseProgramsWithHttpInfo(pgmNoFrom, pgmNoTo, genreIds, seasonId, holidayId, title, artistName, yearFrom, yearTo, orderBy, orderByDirection, page, size, ratingOrderUserId, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Return the albums
   *
   * @param currentId
   * @param position
   * @param filterBy The filter by type
   * @param filterValue The filter value
   * @param orderBy The Order by field
   * @param orderByDirection
   * @param page page number
   * @param size page size
   */
  public getProgramsPrevNext(currentId: number, position: number, filterBy?: string, filterValue?: string, orderBy?: string, orderByDirection?: string, ratingOrderUserId?: number, skipThisWeekProgram?: boolean, extraHttpRequestParams?: any): Observable<PrevNextProgramContainer> {
    return this.getProgramsPrevNextWithHttpInfo(currentId, position, filterBy, filterValue, orderBy, orderByDirection, ratingOrderUserId, skipThisWeekProgram, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Return the This-Week program
   *
   */
  public getThisWeekProgram(extraHttpRequestParams?: any): Observable<Program> {
    return this.getThisWeekProgramWithHttpInfo(extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Return the Surprise-me program
   *
   */
  public getSurpriseMeProgram(extraHttpRequestParams?: any): Observable<Program> {
    return this.getSurpriseMeProgramWithHttpInfo(extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Mark the program as blocked
   *
   * @param programId The program id
   */
  public markBlocked(programId: number, extraHttpRequestParams?: any): Observable<{}> {
    return this.markBlockedWithHttpInfo(programId, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Mark the program as favorite
   *
   * @param programId The program id
   * @param rating The rating
   */
  public markFavorite(programId: number, rating: number, extraHttpRequestParams?: any): Observable<{}> {
    return this.markFavoriteWithHttpInfo(programId, rating, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Mark the program as unblocked
   *
   * @param programId The program id
   */
  public unmarkBlocked(programId: number, extraHttpRequestParams?: any): Observable<{}> {
    return this.unmarkBlockedWithHttpInfo(programId, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Unmark the program as favorite
   *
   * @param programId The program id
   */
  public unmarkFavorite(programId: number, extraHttpRequestParams?: any): Observable<{}> {
    return this.unmarkFavoriteWithHttpInfo(programId, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Return the program detail
   *
   * @param programId The program id
   */
  public getProgramDetailWithHttpInfo(programId: number, extraHttpRequestParams?: any): Observable<HttpResponse<Program>> {
    const path = this.getBasePath() + `/programs/${programId}`;

    let queryParameters = new HttpParams();
    let headers = this.getNoLoginHeaders();

    // verify required parameter 'programId' is not null or undefined
    if (programId === null || programId === undefined) {
      throw new Error('Required parameter programId was null or undefined when calling getProgramDetail.');
    }


    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];


    let requestOptions = {
      headers: headers,
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.GET, path, requestOptions);
  }

  /**
   * Return the programs
   *
   * @param filterBy The filter by type
   * @param filterValue The filter by value
   * @param orderBy Order by
   * @param page page number
   * @param size page size
   */
  public getProgramsWithHttpInfo(filterBy?: string, filterValue?: string, orderBy?: string, orderByDirection?: string, page?: number, size?: number, skipThisWeekProgram: boolean = false, ratingOrderUserId?: number, extraHttpRequestParams?: any): Observable<HttpResponse<Page<Program>>> {
    const path = this.getBasePath() + `/programs`;

    let queryParameters = new HttpParams();
    let headers = this.getNoLoginHeaders();

    if (filterBy !== undefined && filterBy !== null) {
      queryParameters = queryParameters.set('filterBy', <any>filterBy);
    }
    if (filterValue !== undefined && filterValue !== null) {
      queryParameters = queryParameters.set('filterValue', <any>filterValue);
    }
    if (orderBy !== undefined && orderBy !== null) {
      queryParameters = queryParameters.set('orderBy', <any>orderBy);
    }
    if (orderByDirection !== undefined && orderByDirection !== null) {
      queryParameters = queryParameters.set('orderByDirection', <any>orderByDirection);
    }
    if (page !== undefined) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined) {
      queryParameters = queryParameters.set('size', <any>size);
    }
    if (skipThisWeekProgram !== undefined) {
      queryParameters = queryParameters.set('skipThisWeekProgram', <any>skipThisWeekProgram);
    }
    if (ratingOrderUserId !== undefined) {
      queryParameters = queryParameters.set('ratingOrderUserId', <any>ratingOrderUserId);
    }

    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];


    let requestOptions = {
      headers: headers,
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }
    return this.executeHttpRequest(AppConstants.RequestMethods.GET, path, requestOptions);
  }

  public browseProgramsWithHttpInfo(pgmNoFrom?: number, pgmNoTo?: number, genreIds?: number[], seasonId?: number,
                                    holidayId?: number, title?: string, artistName?: string, yearFrom?: number, yearTo?: number,
                                    orderBy?: string, orderByDirection?: string, page?: number, size?: number,
                                    ratingOrderUserId?: number, extraHttpRequestParams?: any): Observable<HttpResponse<Page<Program>>> {
    const path = this.getBasePath() + `/programs/browse`;

    let queryParameters = new HttpParams();
    let headers = this.getNoLoginHeaders();

    if (pgmNoFrom !== undefined && pgmNoFrom !== null) {
      queryParameters = queryParameters.set('pgmNoFrom', <any>pgmNoFrom);
    }
    if (pgmNoTo !== undefined && pgmNoTo !== null) {
      queryParameters = queryParameters.set('pgmNoTo', <any>pgmNoTo);
    }
    if (genreIds !== undefined && genreIds !== null) {
      queryParameters = queryParameters.set('genreIds', <any>genreIds);
    }
    if (seasonId !== undefined && seasonId !== null) {
      queryParameters = queryParameters.set('seasonId', <any>seasonId);
    }
    if (holidayId !== undefined && holidayId !== null) {
      queryParameters = queryParameters.set('holidayId', <any>holidayId);
    }
    if (title !== undefined && title !== null) {
      queryParameters = queryParameters.set('title', <any>title);
    }
    if (artistName !== undefined && artistName !== null) {
      queryParameters = queryParameters.set('artistName', <any>artistName);
    }
    if (yearFrom !== undefined && yearFrom !== null) {
      queryParameters = queryParameters.set('yearFrom', <any>yearFrom);
    }
    if (yearTo !== undefined && yearTo !== null) {
      queryParameters = queryParameters.set('yearTo', <any>yearTo);
    }
    if (orderBy !== undefined && orderBy !== null) {
      queryParameters = queryParameters.set('orderBy', <any>orderBy);
    }
    if (orderByDirection !== undefined && orderByDirection !== null) {
      queryParameters = queryParameters.set('orderByDirection', <any>orderByDirection);
    }
    if (page !== undefined) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined) {
      queryParameters = queryParameters.set('size', <any>size);
    }
    if (ratingOrderUserId !== undefined) {
      queryParameters = queryParameters.set('ratingOrderUserId', <any>ratingOrderUserId);
    }


    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];


    let requestOptions = {
      headers: headers,
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }
    return this.executeHttpRequest(AppConstants.RequestMethods.GET, path, requestOptions);
  }

  /**
   * Return the previous and next albums
   *
   * @param currentId
   * @param position
   * @param filterBy The filter by type
   * @param filterValue The filter value
   * @param orderBy The Order by field
   * @param orderByDirection
   * @param page page number
   * @param size page size
   */
  public getProgramsPrevNextWithHttpInfo(currentId: number, position: number, filterBy?: string, filterValue?: string, orderBy?: string, orderByDirection?: string, ratingOrderUserId?: number, skipThisWeekProgram?: boolean, extraHttpRequestParams?: any): Observable<HttpResponse<PrevNextProgramContainer>> {
    const path = this.getBasePath() + `/programs/` + currentId + '/prev-next';

    if (position === null || position === undefined) {
      throw new Error('Required parameter position was null or undefined when calling getProgramsPrevNext.');
    }

    let queryParameters = new HttpParams();
    let headers = this.getNoLoginHeaders();

    if (position !== undefined && position != null) {
      queryParameters = queryParameters.set('position', <any>position);
    }
    if (filterBy !== undefined && filterBy != null) {
      queryParameters = queryParameters.set('filterBy', <any>filterBy);
    }
    if (filterValue !== undefined && filterValue != null) {
      queryParameters = queryParameters.set('filterValue', <any>filterValue);
    }
    if (orderBy !== undefined && orderBy != null) {
      queryParameters = queryParameters.set('orderBy', <any>orderBy);
    }
    if (orderByDirection !== undefined && orderByDirection != null) {
      queryParameters = queryParameters.set('orderByDirection', <any>orderByDirection);
    }
    if (ratingOrderUserId !== undefined && ratingOrderUserId != null) {
      queryParameters = queryParameters.set('ratingOrderUserId', <any>ratingOrderUserId);
    }
    if (skipThisWeekProgram !== undefined && skipThisWeekProgram != null) {
      queryParameters = queryParameters.set('skipThisWeekProgram', <any>skipThisWeekProgram);
    }


    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];


    let requestOptions = {
      headers: headers,
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.GET, path, requestOptions);
  }

  /**
   * Return the This-Week program
   *
   */
  public getThisWeekProgramWithHttpInfo(extraHttpRequestParams?: any): Observable<HttpResponse<Program>> {
    const path = this.getBasePath() + `/programs/this-week`;

    let queryParameters = new HttpParams();
    let headers = this.getNoLoginHeaders();


    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];


    let requestOptions = {
      headers: headers,
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }
    // console.log(requestOptions);
    return this.executeHttpRequest(AppConstants.RequestMethods.GET, path, requestOptions);
  }

  /**
   * Return the Surprise-me program
   *
   */
  public getSurpriseMeProgramWithHttpInfo(extraHttpRequestParams?: any): Observable<HttpResponse<Program>> {
    const path = this.getBasePath() + `/programs/surprise-me`;

    let queryParameters = new HttpParams();
    let headers = this.getNoLoginHeaders();


    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];


    let requestOptions = {
      headers: headers,
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }
    // console.log(requestOptions);
    return this.executeHttpRequest(AppConstants.RequestMethods.GET, path, requestOptions);
  }

  /**
   * Mark the program as blocked
   *
   * @param programId The program id
   */
  public markBlockedWithHttpInfo(programId: number, extraHttpRequestParams?: any): Observable<HttpResponse<Object>> {
    const path = this.getBasePath() + `/programs/${programId}/block`;

    let queryParameters = new HttpParams();
    let headers = this.getDefaultHeaders();

    // verify required parameter 'programId' is not null or undefined
    if (programId === null || programId === undefined) {
      throw new Error('Required parameter programId was null or undefined when calling markBlocked.');
    }


    // to determine the Accept header
    let produces: string[] = [];


    let requestOptions = {
      headers: headers,
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.PUT, path, requestOptions);
  }

  /**
   * Mark the program as favorite
   *
   * @param programId The program id
   * @param rating The rating
   */
  public markFavoriteWithHttpInfo(programId: number, rating: number, extraHttpRequestParams?: any): Observable<HttpResponse<Object>> {
    const path = this.getBasePath() + `/programs/${programId}/favorite`;

    let queryParameters = new HttpParams();
    let headers = this.getDefaultHeaders();

    // verify required parameter 'programId' is not null or undefined
    if (programId === null || programId === undefined) {
      throw new Error('Required parameter programId was null or undefined when calling markFavorite.');
    }
    // verify required parameter 'rating' is not null or undefined
    if (rating === null || rating === undefined) {
      throw new Error('Required parameter rating was null or undefined when calling markFavorite.');
    }


    // to determine the Content-Type header
    let consumes: string[] = [];
    let canConsumeForm = this.canConsumeForm(consumes);
    let useForm = false;
    let formParams = new HttpParams();

    // to determine the Accept header
    let produces: string[] = [];


    if (rating !== undefined) {
      formParams = formParams.set('rating', rating.toString());
    }

    let requestOptions = {
      headers: headers,
      body: RequestsUtils.getJsonObjectFromFormParams(formParams),
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.PUT, path, requestOptions);
  }

  /**
   * Mark the program as unblocked
   *
   * @param programId The program id
   */
  public unmarkBlockedWithHttpInfo(programId: number, extraHttpRequestParams?: any): Observable<HttpResponse<Object>> {
    const path = this.getBasePath() + `/programs/${programId}/block`;

    let queryParameters = new HttpParams();
    let headers = this.getDefaultHeaders();

    // verify required parameter 'programId' is not null or undefined
    if (programId === null || programId === undefined) {
      throw new Error('Required parameter programId was null or undefined when calling unmarkBlocked.');
    }


    // to determine the Accept header
    let produces: string[] = [];


    let requestOptions = {
      headers: headers,
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.DELETE, path, requestOptions);
  }

  /**
   * Unmark the program as favorite
   *
   * @param programId The program id
   */
  public unmarkFavoriteWithHttpInfo(programId: number, extraHttpRequestParams?: any): Observable<HttpResponse<Object>> {
    const path = this.getBasePath() + `/programs/${programId}/favorite`;

    let queryParameters = new HttpParams();
    let headers = this.getDefaultHeaders();

    // verify required parameter 'programId' is not null or undefined
    if (programId === null || programId === undefined) {
      throw new Error('Required parameter programId was null or undefined when calling unmarkFavorite.');
    }


    // to determine the Accept header
    let produces: string[] = [];


    let requestOptions = {
      headers: headers,
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.DELETE, path, requestOptions);
  }

  /**
   * Download the program pdf
   *
   * @param programId The program id
   */
  public downloadProgram(programId: number, observe?: 'body', reportProgress?: boolean) : Observable<Blob> {
    const path = this.getBasePath() + `/programs/${programId}/download`;

    let queryParameters = new HttpParams();
    let headers = this.getNoLoginHeaders();

    // verify required parameter 'programId' is not null or undefined
    if (programId === null || programId === undefined) {
      throw new Error('Required parameter programId was null or undefined when calling print program.');
    }


    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];


    let requestOptions = {
      headers: headers,
      params: queryParameters,
      responseType: 'blob',
      observe: observe,
      reportProgress: reportProgress
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    /*
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }
    */

    return this.executeHttpRequest(AppConstants.RequestMethods.GET, path, requestOptions);
  }
}
