import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'playerExtraCutPipe'
})
export class PlayerExtraCutPipe implements PipeTransform {

  transform(time: string): any {
    return (time.slice(0, 1).toString() === '0') ? time.slice(1, time.length) : time;
  }

}
