import {environment} from '../environments/environment';

export class AppConfig {

  public static basePath = environment.baseWebsitePath;

  // public static imgBreakPoints = [0, 500, 800, 1000, 1250];

  public static metaDefaultTitle = 'Hearts of Space';
  public static metaDefaultDescription = 'Music from the Hearts of Space, Slow Music for Fast Times, spacemusic, space music, slowmusic, slow music, ambient, ambient music, electronic music, electronica, chill, chill music, contemplative, contemplative music';
  public static metaDefaultUrl = AppConfig.basePath;
  public static metaDefaultImageUrl = AppConfig.basePath + '/assets/images/share_image.jpg';

  public static recurlyAccountUrl = 'https://' + environment.recurlySubDomain + '.recurly.com/account/';

  public static extLinkFb = 'https://www.facebook.com/handler/';
  public static extLinkIg = 'https://www.instagram.com/handler/';
  public static extLinkTw = 'https://twitter.com/handler';
  public static extLinkYt = 'https://www.youtube.com/channel/handler';

  public static sonosClientId = environment.sonosClientId;
  public static basePathPrefix = environment.apiConfig.basePathPrefix;
}
