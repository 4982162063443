import {ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, Renderer2} from '@angular/core';
import {GeneralService} from '../../../services/general.service';
import {ProgramsService} from "../../../api-client/api/programs.service";
import {LoadingHelperService} from "../../loading-helper/loading-helper.service";
import {hoslog} from "../../../app.constants";
import {MixpanelService} from '../../../services/mixpanel.service';

@Component({
  selector: 'app-program-peek-popup',
  templateUrl: './peek-popup.component.html',
  styleUrls: ['./peek-popup.component.scss'],
  /*host: {
    '(document:click)': 'close($event)'
  }*/
})
export class ProgramPeekPopupComponent implements OnInit {

  @Input('programId') id;
  public data;
  public isOpened: boolean = false;
  private _headerHeight = 122;
  private _footerHeight = 134;
  // private subscription: Subscription;
  // private tmp = document.createElement('div');
  // private contentContainer = document.getElementsByClassName('router-container')[0];

  constructor(private programsService: ProgramsService,
              public loadingHelperService: LoadingHelperService,
              private _el: ElementRef,
              private renderer: Renderer2,
              private gs: GeneralService,
              private mixpanelService: MixpanelService,
              private changeDetectorRef: ChangeDetectorRef) {
    // this.tmp.className = "peek-overlayNO";
  }

  ngOnInit() {
  }

  handlePopup(event) {
    let subContainerHeader = document.getElementsByClassName('sub-container-header')[0];
    if (subContainerHeader) {
      this._headerHeight += 45; // consider the extra header
    }
    this.gs.setOpenPeekPopup(true);
    this.preventRedirect(event);
    this.isOpened = !this.isOpened;

    if (this.isOpened) {
      this.changeDetectorRef.detectChanges();
      this.positioningPopup();
      this.trackPopupToggle();
      if (this.data == null) {
        this.loadProgramDetail();
      }
    } else {
      this.close(undefined); // close will also call the tracking
    }
  }

  private trackPopupToggle() {
    const action = this.isOpened ? 'open' : 'close';
    this.mixpanelService.track('ProgramPeekPopup', {action: action, programId: this.id});
  }

  public loadProgramDetail() {
    this.loadingHelperService.startLoading("PROGRAM_PEEK_DETAIL");
    this.programsService.getProgramDetail(this.id)
      .subscribe(
        res => {
          hoslog("getProgramDetail loaded"/* + JSON.stringify(res)*/);

          res.albums.map(album => {
            let prevArtistId = null;
            album.tracks.map((track, index) => {
              track['trackArtists'] = '';
              if (track['artists'] != null || track['artists'].length > 0 && track['artists'][0].name != null) {
                if (prevArtistId == null || prevArtistId !== track['artists'][0].id) {
                  track['trackArtists'] = (track['artists'][0].name).replace(/\r/g, ' & ');
                }
                prevArtistId = track['artists'][0].id;
              }
            });
          });

          hoslog('PEEK POPUP DATA: ' + JSON.stringify(res));

          this.data = res;
          this.changeDetectorRef.detectChanges();
          this.positioningPopup();
          this._el.nativeElement.focus();
          setTimeout(() => this.positioningPopup(), 0);
          this.loadingHelperService.loadingOK("PROGRAM_PEEK_DETAIL");
        },
        error => {
          hoslog("getProgramDetail error: " + JSON.stringify(error));
          this.loadingHelperService.loadingKO(error, "PROGRAM_PEEK_DETAIL");
        });
  }

  preventRedirect(event) {
    // do not pass events to parent elements
    if (event && event.stopPropagation) event.stopPropagation();
    if (event && event.preventDefault) event.preventDefault();
  }

  close(event) {
    this.preventRedirect(event);
    // if ((!event || !this._el.nativeElement.contains(event.target)) && this.gs.getOpenPeekPopup()) {
    this.isOpened = false;
    this.gs.setOpenPeekPopup(false);
    this.trackPopupToggle();
    /*if (document.getElementsByClassName('peek-overlay').length > 0) {
      let overlay = document.getElementsByClassName('peek-overlay')[0];
      overlay.parentNode.removeChild(overlay);
    }*/
    //this.renderer.setElementStyle(this._el.nativeElement.getElementsByClassName('custom-tooltip')[0], 'top', -5000 + 'px');  we use ngIf now so this doesn't exist
    // }
  }

  @HostListener("click", ["$event"])
  public onClick(event: any): void
  {
    this.preventRedirect(event);
  }

  // TODO: investigate deeper why we need magic numbers in calculations ('3', '9')
  positioningPopup() {
    let offsetHeight = this._el.nativeElement.getBoundingClientRect().top - this._headerHeight;
    let offsetBottom = window.innerHeight - this._el.nativeElement.getBoundingClientRect().bottom - this._footerHeight;
    let contentAreaHeight = window.innerHeight - this._headerHeight - this._footerHeight;

    let elHeight = this._el.nativeElement.getBoundingClientRect().height;
    let correction = 0;

    let popup = this._el.nativeElement.getElementsByClassName('custom-tooltip')[0];
    let popupInner = this._el.nativeElement.getElementsByClassName('peek-tooltip-inner')[0];
    let popupContent = this._el.nativeElement.getElementsByClassName('tooltip-content')[0];
    let arrow = this._el.nativeElement.getElementsByClassName('tooltip-arrow')[0];
    let arrowMinHeight = 22;
    let popupHeight = popup.offsetHeight;
    let header = document.getElementsByClassName('header-line')[0];
    let headerHeight: number = 0;

    const marginFromParent = 27;
    const popupWidth: number = 510;
    const windowWidth: number = window.innerWidth;
    const parentLeft: any = this._el.nativeElement.getBoundingClientRect().left;
    const rightSpaceEnough: boolean = (windowWidth - parentLeft) > (popupWidth + marginFromParent);

    if (header) {
      headerHeight = header.clientHeight;
      contentAreaHeight = contentAreaHeight - headerHeight;
    }

    // popup bigger than content area
    if (popupHeight + headerHeight > contentAreaHeight) {
      this.renderer.setStyle(popupInner, 'height', contentAreaHeight + 'px');
      popupHeight = contentAreaHeight;
    }
    if ((popupHeight / 2) > (offsetBottom + elHeight / 2)) {
      // popup do not fit from bottom
      correction = -((popupHeight / 2) - (offsetBottom + elHeight / 2) + elHeight / 2 + 3);
    } else if ((popupHeight / 2) > (offsetHeight + elHeight / 2)) {
      // popup do not fit from top
      correction = (popupHeight / 2) - (offsetHeight + elHeight / 2) - elHeight / 2 + headerHeight - 3;
    }

    this.renderer.setStyle(popup, 'left', (rightSpaceEnough ? marginFromParent : popupWidth * -1) + 'px');
    this.renderer.setStyle(popup, 'top', -(popupHeight / 2) + correction + 'px');

    if (correction !== 0) {
      let arrowTop: number = popupHeight/2 - (correction + elHeight / 2 + arrowMinHeight / 2 - 9);
      if (!rightSpaceEnough) {
        const arrowTopCorrection: number = 17;
        arrowTop -= arrowTopCorrection;
        this.renderer.setStyle(arrow, 'transform', 'rotate(180deg)');
        this.renderer.setStyle(arrow, 'left', '500px');
      }
      this.renderer.setStyle(arrow, 'top', arrowTop + 'px');
      this.renderer.setStyle(arrow, 'height', arrowMinHeight + 'px');
    } else {
      this.renderer.setStyle(arrow, 'top', '0px');
      this.renderer.setStyle(arrow, 'height', '100%');
    }
    // this.contentContainer.appendChild(this.tmp);
  }

}
