import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-genres-list',
  templateUrl: './genres-list.component.html',
  styleUrls: ['./genres-list.component.scss']
})
export class GenresListComponent implements OnInit {

  @Input() data: any;
  @Input() linkPath1: string = "programs";
  @Input() linkPath2: string;
  @Input() ariaItemLabel: string;

  constructor() { }

  ngOnInit() {
  }

}
