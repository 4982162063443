import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'colonNewLine'
})
export class ColonNewLinePipe implements PipeTransform {

  transform(text: string): any {
    if (text) {
      return text.replace(':', ':<br/>'); // for the full access exception
    } else {
      return "";
    }
  }

}
