/**
 * HOS API
 * 
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 * 
 * 
 * 
 */



export interface ConfigParam {
    /**
     * Param name
     */
    paramName: string;

    /**
     * Param value
     */
    paramValue?: string;

}
