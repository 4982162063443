import {Component, OnInit, Input} from "@angular/core";
import {Router} from '@angular/router';

import {ModalService} from "../modal.service";
import {ActionModalComponent} from "../action-modal/action-modal.component";
import {Program} from "../../api-client/model/Program";
import {PlayButtonIconSize} from '../../app.constants';

@Component({
  selector: "[programItem]",
  templateUrl: './program-item.component.html',
  styleUrls: ['./program-item.component.scss']
})

export class ProgramItemComponent {
  @Input('programItem') item: Program;
  @Input('queryParams') queryParams: any;

  playButtonIconSize = PlayButtonIconSize;

  public isPlaying = false;

  constructor() {
  }

  ngOnInit() {
    if (this.queryParams) {
      this.queryParams = JSON.parse(JSON.stringify(this.queryParams)); // copy the object
      this.queryParams['position'] = this.item['idx'];
    }
  }
}
