import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'cardImg'
})
export class CardImgPipe implements PipeTransform {

  transform(input: string): string {
    if (input) {
      input = input.toLowerCase();
      if (input === 'visa' ||
        input === 'mastercard' ||
        input === 'maestro' ||
        input === 'jcb' ||
        input === 'discover' ||
        input === 'paypal') {
        return input;
      } else if (input === 'master') {
        return 'mastercard';
      } else if (input === 'american_express') {
        return 'amex';
      }
    }
    return 'generic_card';
  }

}
