/**
 * HOS API
 * 
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 * 
 * 
 * 
 */



export interface MessageFromTheAdministrationToTheUsers {
    /**
     * Date
     */
    date: Date;

    /**
     * Title
     */
    title: string;

    /**
     * Description
     */
    description: string;

}
