import {Injectable} from '@angular/core';
import {QueueType} from './hos-queue';
import {HosPlayerStorageService} from './hos-player-storage.service';
import {HosPlayerService} from './hos-player.service';
import {hoslog} from '../../app.constants';

@Injectable()
export class HosPlayerAutoStorageService {

  constructor(private hosPlayerStorageService: HosPlayerStorageService,
              private hosPlayerService: HosPlayerService) {

    this.hosPlayerService.currentQueueObs
      .subscribe(queue => {
        hoslog('Saving queue to storage');
        if (queue.getQueueType() != QueueType.Empty) {
          hosPlayerStorageService.setSavedQueue(queue);
        }
      });
  }
}
