<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12 no-indents tab-router-wrapper">
      <div class="row container-header">
        <nav class="col-sm-9 col-lg-9">
          <ul role="group" aria-label="Albums filter" tabindex="0">
            <li routerLinkActive="active">
              <a routerLink="albums/recent">
                RECENT
              </a>
            </li>
            <li routerLinkActive="active">
              <a routerLink="albums/popular" >
                POPULAR
              </a>
            </li>
            <li routerLinkActive="active">
              <a routerLink="albums/genres">
                GENRE
              </a>
            </li>
            <li routerLinkActive="active">
              <a routerLink="albums/artists">
                ARTIST
              </a>
            </li>
            <li routerLinkActive="active">
              <a routerLink="albums/titles">
                TITLE
              </a>
            </li>
            <li routerLinkActive="active">
              <a routerLink="albums/years">
                YEAR
              </a>
            </li>
            <li routerLinkActive="active">
              <a routerLink="albums/labels">
                LABEL
              </a>
            </li>
            <li routerLinkActive="active">
              <a routerLink="albums/compilations">
                COMPILATIONS
              </a>
            </li>
          </ul>
        </nav>
        <div class="col-sm-3 col-lg-3 album-actions" *ngIf="data['prev'] || data['next']">
          <button id="albumPrevious" attr.aria-label="Previous" [routerLink]="['/albums/details', data['prev']]" [queryParams]="data['ctxPrev']" *ngIf="data['prev']">PREVIOUS</button>
          <span>ALBUM</span>
          <button id="albumNext" attr.aria-label="Next" [routerLink]="['/albums/details', data['next']]" [queryParams]="data['ctxNext']" *ngIf="data['next']">NEXT</button>
        </div>
      </div>
    </div>
  </div>
</div>
