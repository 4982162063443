import {Pipe, PipeTransform} from '@angular/core';
import {hoslog} from "../../app.constants";

@Pipe({
  name: 'limitToFirst'
})
export class LimitToFirstPipe implements PipeTransform {

  transform(array: any[], howMany: number, active: boolean): any {
    hoslog(`LimitToFirstPipe: active: ${active}`);
    if (active) {
      return array.slice(0, howMany);
    } else {
      return array;
    }
  }

}
