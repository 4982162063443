<div class="app-modal" xmlns:addthis="http://www.w3.org/1999/xhtml">
  <div class="modal-title" *ngIf="title">
    {{title}}
  </div>
  <div class="modal-body center-block" [style.height]="bodyHeight ? bodyHeight+'px' : 'auto'" >
    <p class="share-title">Share</p>
    <p class="share-title" [innerHtml]="message"></p>
    <div class="shareaholic-canvas" data-app="share_buttons" attr.data-title="{{shareTitle}}" attr.data-link="{{shareUrl}}" data-app-id="33043763"></div>
  </div>
  <div class="modal-actions">
    <button type="button" name="button" (click)="onCancel()" *ngIf="showCancel">{{cancelLabel}}</button>
    <button type="button" name="button" (click)="onOk()" *ngIf="showOK">{{okLabel}}</button>
  </div>
</div>
<div class="modal-background">
</div>
