import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {GeneralService} from '../../services/general.service';
import {LoginHelperService} from '../../api-client/helper/login-helper.service';
import {LoggedUserService} from '../../api-client/helper/logged-user.service';
import {User} from '../../api-client/model/User';
import {LoggedUserPlanService} from '../../services/logged-user-plan.service';
import {SubscriptionService} from '../../services/subscription.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  // public pageName = "HOME";

  // public loggedUser?: User = null;

  // private subscription: Subscription;

  private intervalId = null;


  constructor(public loginHelperService: LoginHelperService,
              public loggedUserService: LoggedUserService,
              public gs: GeneralService,
              public loggedUserPlanService: LoggedUserPlanService,
              public subscriptionService: SubscriptionService,
  ) {

  }

  ngOnInit() {
    this.loggedUserPlanService.plan$.subscribe((data: { availablePrepaidMinutes: number }) => {
      if (data && data.availablePrepaidMinutes) {
        this.resetInterval();
        this.intervalId = setInterval(() => this.loggedUserPlanService.refreshMinutes(), 30000);
      }
    })
    /*this.subscription = this.gs.title$
       .subscribe(item => this.pageName = item);*/

    /*this.loggedUserService.loggedUserDetailsObs
      .subscribe(user => this.loggedUser = user);*/
  }

  ngOnDestroy() {
    this.resetInterval();
  }

  resetInterval() {
    if(this.intervalId != null) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }
}
