import { Component, OnInit, Input } from '@angular/core';
import {Channel} from '../../api-client';

@Component({
  selector: 'app-channel-item',
  templateUrl: './channel-item.component.html',
  styleUrls: ['./channel-item.component.scss']
})
export class ChannelItemComponent implements OnInit {

  @Input('channelItem') item : Channel;

  constructor() { }

  ngOnInit() {
  }

}
