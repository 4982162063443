<div class="app-modal">
  <div class="modal-body">
    <p class="header">Pay with Paypal</p>
    <div class="container-fluid hos-checkbox">
      <input type="checkbox" id="termsAcceptedpp" [(ngModel)]="termsAccepted" [ngModelOptions]="{standalone: true}">
      <label for="termsAcceptedpp" [ngClass]="{'error': (formSubmitted && !termsAccepted)}">
        I accept the <a [routerLink]="['/pages/terms-and-conditions']" target="_blank">Terms &amp; Conditions</a>, <a [routerLink]="['/pages/service-details']" target="_blank">Service Details</a> and <a [routerLink]="['/pages/privacy-policy']" target="_blank">Privacy Policy</a>
      </label>
      <div class="card-wrapper">
        <img src="/assets/images/cards.png">
      </div>
    </div>
  </div>
  <div class="modal-actions">
    <button (click)="pay()" aria-label="Create playlist" >PAY</button>
    <button type="button" name="button" (click)="onCancel(true)">CANCEL</button>
  </div>
</div>
<div class="modal-background">
</div>
