/**
 * HOS API
 *
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 *
 *
 *
 */

import { Album } from './Album';
import { Playlist } from './Playlist';
import { Program } from './Program';
import { Track } from './Track';
import {Page} from './generic/page';


export interface SearchWrapper {
    /**
     * Programs
     */
    programs?: Page<Program>;

    /**
     * Playlists
     */
    playlists?: Page<Playlist>;

    /**
     * Albums
     */
    albums?: Page<Album>;

    /**
     * Tracks
     */
    tracks?: Page<Track>;

}
