import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

export function swing(p: number) {
  return 0.5 - Math.cos(p * Math.PI) / 2;
}

@Injectable()
export class PlayerVolumeService implements OnDestroy {
  private htmlElementId = "hosplayer";

  isAnimating$ = new BehaviorSubject<boolean>(false);
  private howManyAnimating = 0; // we keep the count of the isAnimating set to true and we only set to false when the count is 0

  _audio: HTMLVideoElement = null;

  private _currentAudioVolume: number = null;

  constructor() {
    // console.log('PlayerVolumeService');
  }

  ngOnDestroy(): void {
  }

  get audio() {
    if (!this._audio) {
      this._audio = document.getElementById(this.htmlElementId) as HTMLVideoElement;
      this.currentAudioVolume = this._audio.volume;
    }
    return this._audio;
  }

  get volume(): number {
    return this.audio.volume;
  }

  set volume(vol: number) {
    this.audio.volume = vol;
  }


  get currentAudioVolume(): number {
    return this._currentAudioVolume;
  }

  set currentAudioVolume(value: number) {
    if (!this.isAnimating) { // only set it if it's not animating
      this._currentAudioVolume = value;
    }
  }

  get isAnimating() {
    return this.isAnimating$.value;
  }

  set isAnimating(value: boolean) {
    // console.log('isAnimating = ' + value);
    if (value == true) {
      if (this.howManyAnimating == 0) {
        this.isAnimating$.next(true);
      }
      this.howManyAnimating++;
    } else {
      if (this.howManyAnimating == 1) {
        this.isAnimating$.next(false);
      }
      this.howManyAnimating--;
    }
  }

  fadeIn(beforeAnimation: Function, afterAnimation: Function) {
    this.currentAudioVolume = this.volume;
    this.isAnimating = true;
    if (beforeAnimation) beforeAnimation();
    this.volume = 0;
    this.adjustVolume(this.audio, this.currentAudioVolume).then(() => {
      this.isAnimating = false;
      if (afterAnimation) afterAnimation();
    });
  }

  fadeOut(beforeAnimation: Function, afterAnimation: Function) {
    this.currentAudioVolume = this.volume;
    this.isAnimating = true;
    if (beforeAnimation) beforeAnimation();
    const audio = document.getElementById(this.htmlElementId) as HTMLVideoElement;
    this.adjustVolume(audio, 0).then(() => {
      if (afterAnimation) afterAnimation();
      this.volume = this.currentAudioVolume;
      this.isAnimating = false;
    });
  }

  fadeOutIn(beforeAnimation: Function, beforeInAnimation: Function, afterAnimation: Function) {
    this.currentAudioVolume = this.volume;
    this.isAnimating = true;
    if (beforeAnimation) beforeAnimation();
    const audio = document.getElementById(this.htmlElementId) as HTMLVideoElement;
    this.adjustVolume(audio, 0, {duration: 200}).then(() => {
      if (beforeInAnimation) beforeInAnimation();
      this.adjustVolume(this.audio, this.currentAudioVolume, {duration: 200}).then(() => {
        this.isAnimating = false;
        if (afterAnimation) afterAnimation();
      });
    });
  }

  private async adjustVolume(
    element: HTMLMediaElement,
    newVolume: number,
    {
      duration = 400,
      easing = swing,
      interval = 13,
    }: {
      duration?: number,
      easing?: typeof swing,
      interval?: number,
    } = {},
  ) {
    const originalVolume = element.volume;
    const delta = newVolume - originalVolume;
    if (!delta || !duration || !easing || !interval) {
      element.volume = newVolume;
      return Promise.resolve();
    }
    const ticks = Math.floor(duration / interval);
    let tick = 1;
    return new Promise<void>((resolve) => {
      const timer = setInterval(() => {
        element.volume = originalVolume + (
          easing(tick / ticks) * delta
        );
        if (++tick === ticks) {
          clearInterval(timer);
          resolve();
        }
      }, interval);
    });
  }
}
