import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {Playlist} from '../api-client';
import {SelectPlaylistDialogComponent} from '../shared/option-popup/select-playlist-dialog/select-playlist-dialog.component';
import {CreatePlaylistComponent} from '../shared/create-playlist/create-playlist.component';
import {AlertsUtilService} from './alerts-util.service';
import {Router} from '@angular/router';
import {ModalService} from '../shared/modal.service';
import {LoginHelperService} from '../api-client/helper/login-helper.service';

@Injectable()
export class PlaylistsDialogsUtilsService {

  constructor(private alertsUtilService: AlertsUtilService,
              private router: Router,
              private modalService: ModalService,
              private loginHelperService: LoginHelperService) {
  }

  public showAddtoPlaylistDialog(item: any) {
    const isLoggedIn = this.loginHelperService.isLoggedIn$.value;
    if (isLoggedIn) {
      let modal$ = this.modalService.create(SelectPlaylistDialogComponent, {
        ok: (response) => {
          // hoslog("OK: " + JSON.stringify(response));
        },
        cancel: (noPlaylists) => {
          // hoslog("cancel: " + noPlaylists);
          if (noPlaylists) {
            this.showCreatePlaylistDialog(item);
          }
        },
        closeModal: (response) => {
          // hoslog("CloseModal: " + response);
        }
      });

      modal$.subscribe((ref) => {
        let i = ref.instance as SelectPlaylistDialogComponent;
        i.item = item;
        this.router.events.subscribe((val) => {
          ref.destroy();
        });
      });
    } else {
      this.alertsUtilService.showAlert("Please login or register to continue");
    }
  }

  private showCreatePlaylistDialog(item: any) {
    let modal$ = this.modalService.create(CreatePlaylistComponent, {
      ok: (response) => {
        // hoslog("OK: " + JSON.stringify(response));
        this.showAddtoPlaylistDialog(item);
      },
      closeModal: (response) => {
        // hoslog("CloseModal: " + response);
      },

    });

    modal$.subscribe((ref) => {
      let i = ref.instance as CreatePlaylistComponent;
      // i.item = this.item;
      this.router.events.subscribe((val) => {
        ref.destroy();
      });
    });
  }
}
