import {Injectable, OnDestroy} from '@angular/core';
import {CoolLocalStorage} from '@angular-cool/storage';
import {HosQueue} from './hos-queue';
import {DeserializedHosQueue, HosQueueSerializationUtils, SerializedHosQueue} from './hos-queue-serialization-utils';
import {SubSink} from 'subsink';
import {LoginHelperService} from '../../api-client/helper/login-helper.service';

@Injectable()
export class HosPlayerStorageService implements OnDestroy {
  private static savedQueueKey = 'sq';
  private static volumeKey = 'vol';
  private static mutedKey = 'mt';

  private subs = new SubSink();

  readOnly = true;

  constructor(private localStorage: CoolLocalStorage,
              private loginHelperService: LoginHelperService) {
    // Sign Out event
    this.subs.sink = loginHelperService.loggedOut.subscribe(value => {
      this.clear();
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  getSavedQueue(): DeserializedHosQueue {
    const savedQueueStr = this.localStorage.getItem(HosPlayerStorageService.savedQueueKey);
    const savedQueue = JSON.parse(savedQueueStr) as SerializedHosQueue;
    if (savedQueue && savedQueue.type) {
      const queue = HosQueueSerializationUtils.deserialize(savedQueue);

      return {
        type: savedQueue.type,
        queue: queue,
        seconds: savedQueue.seconds
      }
    }
    return null;
  }

  setSavedQueue(queue: HosQueue, seconds: number = 0) {
    if (!this.readOnly) {
      const serializedHosQueue = HosQueueSerializationUtils.serialize(queue, seconds);
      var seen = [];
      const serializedHosQueueStr = JSON.stringify(serializedHosQueue, function (key, val) {
        if (val != null && typeof val == "object") {
          if (seen.indexOf(val) >= 0) {
            return;
          }
          seen.push(val);
        }
        return val;
      });
      this.localStorage.setItem(HosPlayerStorageService.savedQueueKey, serializedHosQueueStr);
    }
  }

  getVolume(): number {
    const percVolumeStr = this.localStorage.getItem(HosPlayerStorageService.volumeKey);
    if (percVolumeStr) {
      return JSON.parse(percVolumeStr) as number;
    }
    return null;
  }

  setVolume(percVolume: number) {
    if (percVolume) {
      const percVolumeStr = JSON.stringify(percVolume);
      this.localStorage.setItem(HosPlayerStorageService.volumeKey, percVolumeStr);
    }
  }

  clear() {
    this.localStorage.removeItem(HosPlayerStorageService.savedQueueKey);
    this.localStorage.removeItem(HosPlayerStorageService.volumeKey);
  }
}
