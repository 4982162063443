import {Inject, Injectable, OnDestroy} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {defer, Subscription} from 'rxjs';
import {delay, repeatWhen} from 'rxjs/operators';
import {AppConstants, hoslog} from '../app.constants';
import {VisibilityService} from './visibility.service';
import {SubSink} from 'subsink';

@Injectable()
export class BackgroundService implements OnDestroy {
  private static MAX_BG_IDX = 8;

  private subs = new SubSink();

  currentBgIdx = 0;

  nextBgClass = '';

  constructor(@Inject(DOCUMENT) private document: Document,
              private visibilityService: VisibilityService) {
    this.startTimer();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  startTimer() {
    this.subs.sink = defer(async () => this.timerTick())
      .pipe(
        repeatWhen(notifications => notifications.pipe(delay(AppConstants.BackgroundRefreshDelayInSeconds * 1000)))
      ).subscribe(
        next => {
          hoslog('BackgroundService: timer: next');
        },
        error => {
          hoslog('BackgroundService: timer: error: ' + error);
        }
      );
  }

  private timerTick() {
    if (!this.visibilityService.pageVisibility$.value) return;

    // Next bg
    this.currentBgIdx = this.getNextBgIdx();
    this.preload(this.getNextBgIdx()); // preload the one after it

    // Next animation
    this.document.body.classList.add('bg' + this.currentBgIdx);
    // Removing the old ones
    this.document.body.classList.remove('bg' + this.getPreviousBgIdx());
    this.nextBgClass = '';

    setTimeout(() => {
      this.nextBgClass = 'bg' + this.getNextBgIdx();
    }, 3000);
  }

  private getNextBgIdx() {
    if (this.currentBgIdx == BackgroundService.MAX_BG_IDX) {
      return 1;
    } else {
      return (this.currentBgIdx + 1);
    }
  }

  private getPreviousBgIdx() {
    if (this.currentBgIdx == 1) {
      return BackgroundService.MAX_BG_IDX;
    } else {
      return (this.currentBgIdx - 1);
    }
  }

  private preload(bgIdx: number) {
    (new Image()).src = '/assets/images/bgs/bg' + bgIdx + '.jpg';
  }
}
