import { Component, OnInit, Input } from '@angular/core';
import {PlayButtonIconSize} from '../../../../app.constants';

@Component({
  selector: 'app-search-track-item',
  templateUrl: './search-track-item.component.html',
  styleUrls: ['./search-track-item.component.scss']
})
export class SearchTrackItemComponent implements OnInit {
  @Input('item') item;

  playButtonIconSize = PlayButtonIconSize;

  constructor() { }

  ngOnInit() {
  }

    protected readonly PlayButtonIconSize = PlayButtonIconSize;
}
