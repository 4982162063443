import {ApiHelperService} from "../helper/api-helper.service";
import {HttpClient, HttpResponse, HttpHeaders} from "@angular/common/http";
import {Injector} from "@angular/core";
import {environment} from "../../../environments/environment";
import {AppConstants} from "../../app.constants";
import {Observable} from "rxjs";

export class HosBaseService {
  protected httpClient: HttpClient;
  private apiHelperService: ApiHelperService;

  constructor(injector: Injector) {
    this.httpClient = injector.get(HttpClient);
    this.apiHelperService = injector.get(ApiHelperService);
  }

  protected getBasePath() {
    return this.apiHelperService.getBasePath();
  }

  public getNoLoginHeaders(): HttpHeaders {
    let headers = new HttpHeaders();//.set("Authorization", "Basic " + environment.apiConfig.publicAuthorization);
    return headers;
  }

  // Only used by Account.getAuthToken
  public getAuthTokenHeaders(username: string, password: string): HttpHeaders {
    let headers = new HttpHeaders(); //.set("Authorization", "Basic " + btoa(username + ":" + password));
    return headers;
  }

  // Only used by Account.getSessionToken
  public getSessionTokenHeaders(): HttpHeaders {
    return this.getNoLoginHeaders();
  }

  public getDefaultHeaders(): HttpHeaders {
    if (this.apiHelperService.getAuthToken()==null) {
      return this.getNoLoginHeaders();
    }

    let headers = new HttpHeaders();
    // console.log('this.apiHelperService.getSessionToken() = ' + this.apiHelperService.getSessionToken());
    if (this.apiHelperService.getSessionToken()) {
      headers = headers.append("Authorization", "Bearer " + this.apiHelperService.getSessionToken().sessionToken);
    }
    return headers;
  }

  protected executeHttpRequest(method: string, url: string, options?): Observable<any> {
    if (options && !options.hasOwnProperty('observe')) {
      options['observe'] = 'response';
      options['responseType'] = 'json';
    }
    return this.httpClient.request(method, url, options);
  }
  /**
   *
   * Extends object by coping non-existing properties.
   * @param objA object to be extended
   * @param objB source object
   */
  protected extendObj<T1,T2>(objA: T1, objB: T2) {
    for (let key in objB) {
      if (objB.hasOwnProperty(key)) {
        (objA as any)[key] = (objB as any)[key];
      }
    }
    return <T1&T2>objA;
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  protected canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (let consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

}
