import {Component, OnInit} from '@angular/core';
import {AppSettingsService} from '../../services/app-settings.service';
import {Genre} from '../../api-client';
import {hoslog} from '../../app.constants';

@Component({
  selector: 'app-select-change-plan-type-dialog',
  templateUrl: './select-change-plan-type-dialog.component.html',
  styleUrls: ['./select-change-plan-type-dialog.component.scss']
})
export class SelectChangePlanTypeDialogComponent implements OnInit {

  ok: Function;
  cancel: Function;
  destroy: Function;
  closeModal: Function;

  expiryDate: Date = null;

  constructor() { }

  ngOnInit() {
  }

  onCancel(): void {
    if (this.closeModal) this.closeModal();
    this.destroy();
    if (this.cancel) this.cancel();
  }

  public onOk(optionNumber: number): void {
    if (this.closeModal) this.closeModal();
    this.destroy();
    if (this.ok) this.ok(optionNumber);
  }

  selectOption(optionNumber: number) {
    hoslog('Selected option: ' + optionNumber);
    this.onOk(optionNumber);
  }
}
