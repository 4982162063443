import {Pipe, PipeTransform} from '@angular/core';
import {environment} from "../../../environments/environment";

@Pipe({
  name: 'HosAlbumImageUrl'
})
export class HosAlbumImageUrlPipe implements PipeTransform {

  transform(albumId: number, size: number): any {
    return HosAlbumImageUrlPipe.getAlbumImageUrl(albumId, size);
  }

  public static getAlbumImageUrl(albumId: number, size: number): string {
    if (albumId) {
      /*if (environment.imagesUseOldServlet) {
        // finding the parts of the image
        let url = '/album/' + albumId; // TODO the path should be plural

        // finding the arguments
        if (size && size > 0) {
          url += "_" + size;
          url += 'w';
        }
        return 'https://cloudfront.hos.com/hos/images' + url + '.jpg';
      } else {*/
        // finding the parts of the image
        let url = '/albums';

        // finding the arguments
        if (size && size > 0) {
          url += `/w/${size}`;
        }
        return `${environment.basePathImageUrl + url}/${albumId}.jpg`;
      // }
    } else {
      return ""; // placeholder?
    }
  }
}
