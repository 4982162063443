import {Component, Input, OnInit} from '@angular/core';
import {HosPlayerService} from "../../../services/player/hos-player.service";
import {HosQueue, HosQueueChannel, HosQueuePlaylist} from "../../../services/player/hos-queue";
import {Playlist} from "../../../api-client/model/Playlist";
import {PlaylistsService} from "../../../api-client/api/playlists.service";
import {hoslog, PlayButtonIconSize} from '../../../app.constants';
import {MixpanelService} from "../../../services/mixpanel.service";
import {Channel, ChannelsService, HosItem} from '../../../api-client';
import {HosPlayItem} from '../../../services/player/hos-play-item';
import {HosPlayItemUtils} from '../../../services/player/hos-play-item-utils';
import {Router} from '@angular/router';
import {StopTimerService} from '../../../services/stop-timer.service';

@Component({
  selector: 'app-play-channel-track-button',
  templateUrl: './play-channel-track-button.component.html',
  styleUrls: ['./play-channel-track-button.component.scss']
})
export class PlayChannelTrackButtonComponent implements OnInit {

  @Input() iconSize : PlayButtonIconSize;
  @Input() item: Channel;
  @Input() extraClasses: string = "";
  @Input() enabled: boolean = true;

  @Input() channelTrackItem: HosItem;

  playButtonIconSize = PlayButtonIconSize;

  channelTrackPlayItem: HosPlayItem;

  public queueItem: HosQueue = null;

  constructor(public hosPlayerService: HosPlayerService,
              private stopTimerService: StopTimerService) {
  }

  ngOnInit() {
    this.channelTrackPlayItem = HosPlayItemUtils.getPlayItem(this.channelTrackItem);
    if (this.item) {
      this.queueItem = new HosQueueChannel(this.item, this.channelTrackItem);
    }
  }

  playChannel() {
    this.stopTimerService.reset();
    if (this.enabled && this.item) {
      const currentQueue = this.hosPlayerService.currentQueueObs.value;
      const currentItemId = this.hosPlayerService.currentItemIdObs.value;
      if (
        currentQueue && currentQueue.getQueueUniqueId() === this.queueItem.getQueueUniqueId() &&
        currentItemId && currentItemId === this.channelTrackPlayItem.getUniqueId()
      ) { // if it's already in the queue
        // getting the queue version as it should be the completed one
        this.queueItem = currentQueue;
      }
      this.hosPlayerService.play(this.queueItem);
    }
  }


}
