import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AlertsUtilService} from "../../../services/alerts-util.service";
import {LoggedUserBlockedItemsService} from "../../../services/logged-user-blocked-items.service";
import {HosItem} from "../../../api-client/model/HosItem";
import {ProgramsService} from "../../../api-client/api/programs.service";
import {AlbumsService} from "../../../api-client/api/albums.service";
import {TracksService} from "../../../api-client/api/tracks.service";
import {LoginHelperService} from "../../../api-client/helper/login-helper.service";
import {hoslog} from "../../../app.constants";
import {SubSink} from 'subsink';
import {MixpanelService} from '../../../services/mixpanel.service';

@Component({
  selector: 'app-block-button',
  templateUrl: './block-button.component.html',
  styleUrls: ['./block-button.component.scss']
})
export class BlockButtonComponent implements OnInit, OnDestroy {
  private subs = new SubSink();

  @Input() item: HosItem;
  @Input() enabled: boolean = true;

  public blocked = false;
  public loading = false;
  public loggedIn = false;

  constructor(private alertsUtilService: AlertsUtilService,
              private mixpanelService: MixpanelService,
              private loggedUserBlockedItemsService: LoggedUserBlockedItemsService,
              private loginHelperService: LoginHelperService) {

    this.subs.sink = this.loginHelperService.isLoggedIn$.subscribe(
      isLoggedIn => {
        this.loggedIn = isLoggedIn;
      }
    );
  }

  ngOnInit() {
    this.subs.sink = this.loggedUserBlockedItemsService.blockedItemsObs
      .subscribe(next => {
        this.blocked = this.loggedUserBlockedItemsService.isBlocked(this.item);
      });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  blockButtonClicked() {
    if (this.enabled) {
      if (this.loggedIn) {
        if (this.blocked) {
          this.unblock();
        } else {
          this.askBlock();
        }
      } else {
        this.alertsUtilService.showAlert("Please login or register to continue");
      }
    } else {
      this.alertsUtilService.showAlert("You cannot block this item");
    }
  }

  private askBlock() {
    this.alertsUtilService.showConfirmationDialog("This will prevent this item from playing on any channels, albums, or playlists. You can unblock it any time in Settings.",
      "BLOCK", "CANCEL",
      (response) => {
        hoslog("block confirmed: " + response);
        this.block();
      });
  }

  private block() {
    this.mixpanelService.track('Item', {action: 'block', itemId: this.item.id, title: this.item.title, type: HosItem.TypeEnum[this.item.type]});
    this.loading = true;
    this.loggedUserBlockedItemsService.block(this.item)
      .subscribe(
        res => {
          hoslog("markBlocked ok");
          this.blocked = true;
          this.loading = false;
        },
        error => {
          hoslog("markBlocked error: " + error.status);
          this.loading = false;
          this.alertsUtilService.showAlert("There was an error while blocking the item, please try again");
        });
  }

  private unblock() {
    this.mixpanelService.track('Item', {action: 'unblock', itemId: this.item.id, title: this.item.title, type: HosItem.TypeEnum[this.item.type]});
    this.loading = true;
    this.loggedUserBlockedItemsService.unblock(this.item)
      .subscribe(
        res => {
          hoslog("unmarkBlocked ok");
          this.blocked = false;
          this.loading = false;
        },
        error => {
          hoslog("unmarkBlocked error: " + error.status);
          this.loading = false;
          this.alertsUtilService.showAlert("There was an error while unblocking the item, please try again");
        });
  }

}
