import {Modal} from "../modal.service";
import {MixpanelService} from "../../services/mixpanel.service";
import {Component} from "@angular/core";

@Component({
  selector: "how-minutes-work-modal",
  templateUrl: './how-minutes-work-modal.component.html',
  styleUrls: ['./how-minutes-work-modal.component.scss']
})
@Modal()
export class HowMinutesWorkModalComponent {
  bodyClass = null;
  title = null;
  message = "";
  moreMessage = "";
  okLabel = "OK";
  cancelLabel = "CANCEL";
  showOK = true;
  showCancel = true;

  showMoreMessage = false;

  constructor(/*private mixpanelService: MixpanelService*/){}

  ok: Function;
  cancel: Function;
  destroy: Function;
  closeModal: Function;
  response = "success";

  onCancel(): void {
    if (this.closeModal) this.closeModal();
    this.destroy();
    if (this.cancel) this.cancel();
    // this.mixpanelService.track('cancelModalClicked');
  }

  onOk(): void {
    if (this.closeModal) this.closeModal();
    this.destroy();
    if (this.ok) this.ok(this.response);
    // this.mixpanelService.track('okModalClicked');
  }

  onMoreClicked() {
    this.showMoreMessage = !this.showMoreMessage;
  }
}
