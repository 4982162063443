/**
 * HOS API
 *
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 */
import {Injectable, Injector} from "@angular/core";
import {HosBaseService} from "./hos-base.service";
import {HttpParams, HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from 'rxjs/operators';
import "../rxjs-operators";
import {AppConstants} from "../../app.constants";
import {Playlist} from "../model/Playlist";
import {RequestsUtils} from '../../shared/utils/requests-utils';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class PlaylistsService extends HosBaseService {


  constructor(injector: Injector) {
     super(injector);
   }

  /**
   * Add a playlist item to the playlist
   *
   * @param playlistId The playlist id
   * @param itemType The item type
   * @param itemId The item id
   */
  public addItemToPlaylist(playlistId: number, itemType: string, itemId: number, extraHttpRequestParams?: any): Observable<{}> {
    return this.addItemToPlaylistWithHttpInfo(playlistId, itemType, itemId, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Create a playlist
   *
   * @param playlistName The playlist name
   */
  public createPlaylist(playlistName: string, extraHttpRequestParams?: any): Observable<Playlist> {
    return this.createPlaylistWithHttpInfo(playlistName, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Return the playlist detail
   *
   * @param playlistId The playlist id
   */
  public getPlaylistDetail(playlistId: number, extraHttpRequestParams?: any): Observable<Playlist> {
    return this.getPlaylistDetailWithHttpInfo(playlistId, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Return the playlists
   *
   * @param orderBy Order by
   * @param page page number
   * @param size page size
   */
  public getPlaylists(orderBy?: string, orderByDirection?: string, page?: number, size?: number, extraHttpRequestParams?: any): Observable<Playlist[]> {
    return this.getPlaylistsWithHttpInfo(orderBy, orderByDirection, page, size, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Remove a playlist-item from the playlist
   *
   * @param playlistId The playlist id
   * @param position The item position in playlist
   * @param itemType The item type
   * @param itemId The item id
   */
  public removeItemFromPlaylist(playlistId: number, playlistItemId: number, extraHttpRequestParams?: any): Observable<{}> {
    return this.removeItemFromPlaylistWithHttpInfo(playlistId, playlistItemId, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Remove the playlist
   *
   * @param playlistId The playlist id
   */
  public removePlaylist(playlistId: number, extraHttpRequestParams?: any): Observable<{}> {
    return this.removePlaylistWithHttpInfo(playlistId, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Rename the playlist
   *
   * @param playlistId The playlist id
   * @param playlistName The playlist name
   */
  public renamePlaylist(playlistId: number, playlistName: string, extraHttpRequestParams?: any): Observable<Playlist> {
    return this.renamePlaylistWithHttpInfo(playlistId, playlistName, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Reorder the items in the playlist
   *
   * @param playlistId The playlist id
   * @param body The ordered items
   */
  public reorderItemsInPlaylist(playlistId: number, body: Array<number>, extraHttpRequestParams?: any): Observable<{}> {
    return this.reorderItemsInPlaylistWithHttpInfo(playlistId, body, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Reorder the playlists
   *
   * @param body The ordered items
   */
  public reorderPlaylists(body: Array<number>, extraHttpRequestParams?: any): Observable<{}> {
    return this.reorderPlaylistsWithHttpInfo(body, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Return the playlist detail
   *
   * @param playlistId The playlist id
   */
  public getPlaylistIfContainsProgram(playlistId: number, programId: number, extraHttpRequestParams?: any): Observable<Playlist> {
    return this.getPlaylistIfContainsProgramWithHttpInfo(playlistId, programId, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Return the playlist detail
   *
   * @param playlistId The playlist id
   */
  public getPlaylistIfContainsAlbum(playlistId: number, albumId: number, extraHttpRequestParams?: any): Observable<Playlist> {
    return this.getPlaylistIfContainsAlbumWithHttpInfo(playlistId, albumId, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }


  /**
   * Add a playlist item to the playlist
   *
   * @param playlistId The playlist id
   * @param itemType The item type
   * @param itemId The item id
   */
  public addItemToPlaylistWithHttpInfo(playlistId: number, itemType: string, itemId: number, extraHttpRequestParams?: any): Observable<HttpResponse<Object>> {
    const path = this.getBasePath() + `/playlists/${playlistId}/add`;

    let queryParameters = new HttpParams();
    let headers = this.getDefaultHeaders();

    // verify required parameter 'playlistId' is not null or undefined
    if (playlistId === null || playlistId === undefined) {
      throw new Error('Required parameter playlistId was null or undefined when calling addItemToPlaylist.');
    }
    // verify required parameter 'itemType' is not null or undefined
    if (itemType === null || itemType === undefined) {
      throw new Error('Required parameter itemType was null or undefined when calling addItemToPlaylist.');
    }
    // verify required parameter 'itemId' is not null or undefined
    if (itemId === null || itemId === undefined) {
      throw new Error('Required parameter itemId was null or undefined when calling addItemToPlaylist.');
    }


    // to determine the Content-Type header
    let consumes: string[] = [];
    let canConsumeForm = this.canConsumeForm(consumes);
    let useForm = false;
    let formParams = new HttpParams();

    // to determine the Accept header
    let produces: string[] = [];


    if (itemType !== undefined) {
      formParams = formParams.set('itemType', itemType);
    }
    if (itemId !== undefined) {
      formParams = formParams.set('itemId', itemId.toString());
    }

    let requestOptions = {
      headers: headers,
      body: RequestsUtils.getJsonObjectFromFormParams(formParams),
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.PUT, path, requestOptions);
  }

  /**
   * Create a playlist
   *
   * @param playlistName The playlist name
   */
  public createPlaylistWithHttpInfo(playlistName: string, extraHttpRequestParams?: any): Observable<HttpResponse<Playlist>> {
    const path = this.getBasePath() + `/playlists`;

    let queryParameters = new HttpParams();
    let headers = this.getDefaultHeaders();

    // verify required parameter 'playlistName' is not null or undefined
    if (playlistName === null || playlistName === undefined) {
      throw new Error('Required parameter playlistName was null or undefined when calling createPlaylist.');
    }


    // to determine the Content-Type header
    let consumes: string[] = [];
    let canConsumeForm = this.canConsumeForm(consumes);
    let useForm = false;
    let formParams = new HttpParams();

    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];


    if (playlistName !== undefined) {
      formParams = formParams.set('playlistName', playlistName);
    }

    let requestOptions = {
      headers: headers,
      body: RequestsUtils.getJsonObjectFromFormParams(formParams),
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.POST, path, requestOptions);
  }

  /**
   * Return the playlist detail
   *
   * @param playlistId The playlist id
   */
  public getPlaylistDetailWithHttpInfo(playlistId: number, extraHttpRequestParams?: any): Observable<HttpResponse<Playlist>> {
    const path = this.getBasePath() + `/playlists/${playlistId}`;

    let queryParameters = new HttpParams();
    let headers = this.getDefaultHeaders();

    // verify required parameter 'playlistId' is not null or undefined
    if (playlistId === null || playlistId === undefined) {
      throw new Error('Required parameter playlistId was null or undefined when calling getPlaylistDetail.');
    }


    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];


    let requestOptions = {
      headers: headers,
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.GET, path, requestOptions);
  }

  /**
   * Return the playlists
   *
   * @param orderBy Order by
   * @param firstResult First result
   * @param maxResults Max results
   */
  public getPlaylistsWithHttpInfo(orderBy?: string, orderByDirection?: string, page?: number, size?: number, extraHttpRequestParams?: any): Observable<HttpResponse<Playlist[]>> {
    const path = this.getBasePath() + `/playlists`;

    let queryParameters = new HttpParams();
    let headers = this.getDefaultHeaders();

    if (orderBy !== undefined) {
      queryParameters = queryParameters.set('orderBy', <any>orderBy);
    }
    if (orderByDirection !== undefined) {
      queryParameters = queryParameters.set('orderByDirection', <any>orderByDirection);
    }
    if (page !== undefined) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined) {
      queryParameters = queryParameters.set('size', <any>size);
    }


    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];


    let requestOptions = {
      headers: headers,
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.GET, path, requestOptions);
  }

  /**
   * Remove a playlist-item from the playlist
   *
   * @param playlistId The playlist id
   * @param position The item position in playlist
   * @param itemType The item type
   * @param itemId The item id
   */
  public removeItemFromPlaylistWithHttpInfo(playlistId: number, playlistItemId: number, extraHttpRequestParams?: any): Observable<HttpResponse<Object>> {
    const path = this.getBasePath() + `/playlists/${playlistId}/remove`;

    let queryParameters = new HttpParams();
    let headers = this.getDefaultHeaders();

    // verify required parameter 'playlistId' is not null or undefined
    if (playlistId === null || playlistId === undefined) {
      throw new Error('Required parameter playlistId was null or undefined when calling removeItemFromPlaylist.');
    }
    // verify required parameter 'position' is not null or undefined
    if (playlistItemId === null || playlistItemId === undefined) {
      throw new Error('Required parameter playlistItemId was null or undefined when calling removeItemFromPlaylist.');
    }

    // to determine the Content-Type header
    let consumes: string[] = [];
    let canConsumeForm = this.canConsumeForm(consumes);
    let useForm = false;
    let formParams = new HttpParams();

    // to determine the Accept header
    let produces: string[] = [];


    if (playlistItemId !== undefined) {
      formParams = formParams.set('playlistItemId', playlistItemId.toString());
    }

    let requestOptions = {
      headers: headers,
      body: RequestsUtils.getJsonObjectFromFormParams(formParams),
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.PUT, path, requestOptions);
  }

  /**
   * Remove the playlist
   *
   * @param playlistId The playlist id
   */
  public removePlaylistWithHttpInfo(playlistId: number, extraHttpRequestParams?: any): Observable<HttpResponse<Object>> {
    const path = this.getBasePath() + `/playlists/${playlistId}`;

    let queryParameters = new HttpParams();
    let headers = this.getDefaultHeaders();

    // verify required parameter 'playlistId' is not null or undefined
    if (playlistId === null || playlistId === undefined) {
      throw new Error('Required parameter playlistId was null or undefined when calling removePlaylist.');
    }


    // to determine the Accept header
    let produces: string[] = [];


    let requestOptions = {
      headers: headers,
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.DELETE, path, requestOptions);
  }

  /**
   * Rename the playlist
   *
   * @param playlistId The playlist id
   * @param playlistName The playlist name
   */
  public renamePlaylistWithHttpInfo(playlistId: number, playlistName: string, extraHttpRequestParams?: any): Observable<HttpResponse<Playlist>> {
    const path = this.getBasePath() + `/playlists/${playlistId}`;

    let queryParameters = new HttpParams();
    let headers = this.getDefaultHeaders();

    // verify required parameter 'playlistId' is not null or undefined
    if (playlistId === null || playlistId === undefined) {
      throw new Error('Required parameter playlistId was null or undefined when calling renamePlaylist.');
    }
    // verify required parameter 'playlistName' is not null or undefined
    if (playlistName === null || playlistName === undefined) {
      throw new Error('Required parameter playlistName was null or undefined when calling renamePlaylist.');
    }


    // to determine the Content-Type header
    let consumes: string[] = [];
    let canConsumeForm = this.canConsumeForm(consumes);
    let useForm = false;
    let formParams = new HttpParams();

    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];


    if (playlistName !== undefined) {
      formParams = formParams.set('playlistName', playlistName);
    }

    let requestOptions = {
      headers: headers,
      body: RequestsUtils.getJsonObjectFromFormParams(formParams),
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.PUT, path, requestOptions);
  }

  /**
   * Reorder the items in the playlist
   *
   * @param playlistId The playlist id
   * @param body The ordered items
   */
  public reorderItemsInPlaylistWithHttpInfo(playlistId: number, body: Array<number>, extraHttpRequestParams?: any): Observable<HttpResponse<Playlist[]>> {
    const path = this.getBasePath() + `/playlists/${playlistId}/sort`;

    let queryParameters = new HttpParams();
    let headers = this.getDefaultHeaders();

    // verify required parameter 'playlistId' is not null or undefined
    if (playlistId === null || playlistId === undefined) {
      throw new Error('Required parameter playlistId was null or undefined when calling reorderItemsInPlaylist.');
    }
    // verify required parameter 'body' is not null or undefined
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling reorderItemsInPlaylist.');
    }


    // to determine the Accept header
    let produces: string[] = [];


    headers = headers.set('Content-Type', 'application/json');


    let requestOptions = {
      headers: headers,
      body: body == null ? '' : JSON.stringify(body), // https://github.com/angular/angular/issues/10612
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.PUT, path, requestOptions);
  }

  /**
   * Reorder the playlists
   *
   * @param body The ordered items
   */
  public reorderPlaylistsWithHttpInfo(body: Array<number>, extraHttpRequestParams?: any): Observable<HttpResponse<Playlist[]>> {
    const path = this.getBasePath() + `/playlists/sort`;

    let queryParameters = new HttpParams();
    let headers = this.getDefaultHeaders();

    // verify required parameter 'body' is not null or undefined
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling reorderPlaylists.');
    }

    // to determine the Accept header
    let produces: string[] = [];

    headers = headers.set('Content-Type', 'application/json');

    let requestOptions = {
      headers: headers,
      body: body == null ? '' : JSON.stringify(body), // https://github.com/angular/angular/issues/10612
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.PUT, path, requestOptions);
  }

  /**
   * Return the playlist detail
   *
   * @param playlistId The playlist id
   * @param programId The program id
   */
  public getPlaylistIfContainsProgramWithHttpInfo(playlistId: number, programId: number, extraHttpRequestParams?: any): Observable<HttpResponse<Playlist>> {
    const path = this.getBasePath() + `/playlists/${playlistId}/contains-program/${programId}`;

    let queryParameters = new HttpParams();
    let headers = this.getDefaultHeaders();

    // verify required parameter 'playlistId' is not null or undefined
    if (playlistId === null || playlistId === undefined) {
      throw new Error('Required parameter playlistId was null or undefined when calling getPlaylistDetail.');
    }

    if (programId === null || programId === undefined) {
      throw new Error('Required parameter programId was null or undefined when calling getPlaylistDetail.');
    }


    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];


    let requestOptions = {
      headers: headers,
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.GET, path, requestOptions);
  }

  /**
   * Return the playlist detail
   *
   * @param playlistId The playlist id
   * @param albumId The album id
   */
  public getPlaylistIfContainsAlbumWithHttpInfo(playlistId: number, albumId: number, extraHttpRequestParams?: any): Observable<HttpResponse<Playlist>> {
    const path = this.getBasePath() + `/playlists/${playlistId}/contains-album/${albumId}`;

    let queryParameters = new HttpParams();
    let headers = this.getDefaultHeaders();

    // verify required parameter 'playlistId' is not null or undefined
    if (playlistId === null || playlistId === undefined) {
      throw new Error('Required parameter playlistId was null or undefined when calling getPlaylistDetail.');
    }

    if (albumId === null || albumId === undefined) {
      throw new Error('Required parameter albumId was null or undefined when calling getPlaylistDetail.');
    }


    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];


    let requestOptions = {
      headers: headers,
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.GET, path, requestOptions);
  }

  /**
   * Download the playlists pdf
   *
   */
  public downloadPlaylists(observe?: 'body', reportProgress?: boolean) : Observable<Blob> {
    const path = this.getBasePath() + `/playlists/download`;

    let queryParameters = new HttpParams();
    let headers = this.getNoLoginHeaders();

    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];


    let requestOptions = {
      headers: headers,
      params: queryParameters,
      responseType: 'blob',
      observe: observe,
      reportProgress: reportProgress
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    /*
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }
    */

    return this.executeHttpRequest(AppConstants.RequestMethods.GET, path, requestOptions);
  }

  /**
   * Download the playlists pdf
   *
   */
  public downloadPlaylist(playlistId: number, observe?: 'body', reportProgress?: boolean) : Observable<Blob> {
    const path = this.getBasePath() + `/playlists/${playlistId}/download`;

    let queryParameters = new HttpParams();
    let headers = this.getNoLoginHeaders();

    // verify required parameter 'programId' is not null or undefined
    if (playlistId === null || playlistId === undefined) {
      throw new Error('Required parameter playlistId was null or undefined when calling print playlist.');
    }


    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];


    let requestOptions = {
      headers: headers,
      params: queryParameters,
      responseType: 'blob',
      observe: observe,
      reportProgress: reportProgress
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    /*
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }
    */

    return this.executeHttpRequest(AppConstants.RequestMethods.GET, path, requestOptions);
  }
}
