export class TextUtils {
  static slugify(input: any): any {
    if (!TextUtils.isString(input)) {
      return input;
    }

    return input
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9\s-]/g, ' ')
      .replace(/[\s-]+/g, '-');
  }

  static isString(value: any): value is string {
    return typeof value === 'string';
  }
}
