import {Injectable, Type} from '@angular/core';
import {Subject} from 'rxjs';
import {TopBarItem} from '../shared/top-bar/top-bar-item';

@Injectable()
export class TopBarComponentService {

  addComponentRequests$ = new Subject<TopBarItem>();
  clearTopBarRequests$ = new Subject<any>();

  constructor() {
  }

  setTopBarComponent(component: Type<any>, data: any = null) {
    let topBarItem = new TopBarItem(component, data);
    this.addComponentRequests$.next(topBarItem);
  }

  clearTopBar() {
    this.clearTopBarRequests$.next(true);
  }
}
