import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HistoryService } from './../../services/history.service';
import {LoginHelperService} from "../../api-client/helper/login-helper.service";
import {PlanHelperService} from '../../services/plan-helper.service';
import {AlertsUtilService} from '../../services/alerts-util.service';
import {LoggedUserPlanService} from '../../services/logged-user-plan.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  constructor(public loginHelperService: LoginHelperService,
              private history: HistoryService,
              private router : Router,
              private alertsUtilService: AlertsUtilService,
              public loggedUserPlanService: LoggedUserPlanService,
              public planHelperService: PlanHelperService) {
  }

  ngOnInit() {
  }

  showHistoryControlBar() {
    return this.history.getNext() || this.history.getPrev();
  }

  goBack() {
    this.history.setSkipSave();
    this.router.navigateByUrl(this.history.getPrev(true));
  }

  goForward() {
    this.history.setSkipSave();
    this.router.navigateByUrl(this.history.getNext(true));
  }

  getNext() {
    return this.history.getNext();
  }

  getPrev() {
    return this.history.getPrev();
  }

  menuClicked($event, navigation) {
    // console.log(`Menu clicked: ${navigation}`);
    if (this.loginHelperService.isLoggedIn$.value) {
      this.router.navigate([navigation]);
    } else {
      this.alertsUtilService.showAlert('This feature is for paid subscribers only.')
    }
  }
}
