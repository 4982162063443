<div class="col-sm-1 col-md-1 col-lg-1 play-cell">
  <app-play-album-button [iconSize]="playButtonIconSize.Medium" [item]="item" [enabled]="item.playable !== false"></app-play-album-button>
</div>
<div class="col-sm-10 col-md-10 col-lg-10" [routerLink]="['/albums/details', item.id]">
  <div class="row">
    <div class="col-sm-2 col-md-2 col-lg-2 cover-img-container">
      <img [src]="item.id | HosAlbumImageUrl:80" alt="{{item.title}}" aria-hidden="true">
    </div>
    <div class="col-sm-10 col-md-10 col-lg-10">
      <p class="title">{{ item.title }}</p>
      <p class="description">{{item.attribution ? item.attribution : item.artistsStr}}</p>
    </div>
  </div>
</div>
<div class="col-sm-1 col-md-1 col-lg-1">
  <div class="option-wrapper">
    <app-option-popup [item]="item" [fullPageHeight]="true" [canPlay]="item.playable !== false" [topMargin]="0">
      <img src="/assets/images/ellipsis.svg" alt="" aria-hidden="true">
    </app-option-popup>
  </div>
</div>
