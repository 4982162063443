import {Injectable, OnDestroy} from '@angular/core';
import {AppConstants, hoslog} from '../app.constants';
import {HosPlayerService} from './player/hos-player.service';
import {HosQueue, HosQueueChannel, QueueType} from './player/hos-queue';
import {HosCurrentPlayState} from './player/hos-player';
import {HosPlayItem, HosPlayProgram} from './player/hos-play-item';
import {Channel, ChannelsService, PlayTokenWrapper, Program, Settings} from '../api-client';
import {LoadingHelperService} from '../shared/loading-helper/loading-helper.service';
import {LoggedUserSettingsService} from '../api-client/helper/logged-user-settings.service';
import {ChannelsListService} from './channels-list.service';
import {defer} from 'rxjs';
import {delay, repeatWhen} from 'rxjs/operators';
import {SubSink} from 'subsink';

@Injectable()
export class ChannelsListRefreshService implements OnDestroy {

  private subs = new SubSink();
  private hemisphere: Channel.HemisphereEnum = Channel.HemisphereEnum.Northern;

  constructor(private hosPlayerService: HosPlayerService,
              private channelsService: ChannelsService,
              private loggedUserSettingsService: LoggedUserSettingsService,
              private channelListService: ChannelsListService,
              private loadingHelperService: LoadingHelperService) {

    this.subs.sink = this.loggedUserSettingsService.loggedUserSettingsObs.subscribe(
      settings => {
        const currentHemisphereValue = this.hemisphere;
        if (settings === null) { // not logged
          this.hemisphere = Channel.HemisphereEnum.Northern;
        } else {
          this.hemisphere = settings.hemisphere === Settings.HemisphereEnum.Northern ? Channel.HemisphereEnum.Northern : Channel.HemisphereEnum.Southern;
        }
        // refresh the channels only if I'm already in the ticking process (to avoid 2 calls at the beginning)
        if (this.channelListService.channels$.value.length > 0 || this.hemisphere !== currentHemisphereValue) {
          this.refresh();
        }
      }
    );

    this.startTimer();
  }

  refresh() {
    hoslog('ChannelsListService: refreshing');
    let me = this;
    this.loadingHelperService.startLoading("CHANNELS_LIST");
    this.channelsService.getChannels(me.hemisphere, true)
      .subscribe(
        res => {
          // hoslog("getChannels loaded: " + JSON.stringify(res));
          me.channelListService.channels$.next(me.channelListRefreshed(res));
          me.loadingHelperService.loadingOK("CHANNELS_LIST");
        },
        error => {
          hoslog("getChannels error: " + JSON.stringify(error));
          me.loadingHelperService.loadingKO(error, "CHANNELS_LIST");
        });
  }

  channelListRefreshed(channels: Channel[]): Channel[] {
    const queue = this.hosPlayerService.currentQueueObs.value;
    if (queue != null && queue.getQueueType() === QueueType.Channel) {
      hoslog('channelListRefreshed: the queue is a channel, checking if there an item playing');
      const channelId = (queue as HosQueueChannel).getChannelId();
      // check if there is a playing program from a channel
      const queueStatus = this.hosPlayerService.player.statusObs.value;
      if (channelId && queueStatus !== HosCurrentPlayState.idle) {
        hoslog('channelListRefreshed: the queue is in non idle state = ' + HosCurrentPlayState[queueStatus].toString());
        const channel = this.hosPlayerService.getChannelByIdInternal(channels, channelId);
        if (channel) {
          hoslog('channelListRefreshed: channel found');
          // Check if the item is in the actual list
          const currentItem = this.hosPlayerService.currentItemObs.value;
          // const currentItemPlayTokenWrapper = currentItem.getPlayTokenWrapper();
          // hoslog('currentItemPlayTokenWrapper: ' + JSON.stringify(currentItemPlayTokenWrapper));

          if (currentItem && currentItem instanceof HosPlayProgram) {
            // const currentItemAsHosPlayProgram = currentItem as HosPlayProgram;
            const itemInChannel = this.isItemInChannel(channel, currentItem.getId());
            if (itemInChannel) {
              hoslog('channelListRefreshed: the playing item is still in the refreshed list');
              // checking if the total programs in the list are the same (in case an old kept item is still there)
              if (channel.programs.length !== queue.getTotalItemsNumber()) {
                hoslog('channelListRefreshed: different number of programs found, updating the queue');
                let originalProgram = currentItem.getOriginalProgram();
                const newQueue = new HosQueueChannel(channel, originalProgram);
                this.overridePlayTokenWrapper(newQueue, currentItem, queue);
                this.hosPlayerService.silentQueueUpdate(newQueue);
                hoslog('channelListRefreshed: queue updated');
              }
            } else {
              hoslog('channelListRefreshed: the playing item is NOT in the refreshed list, adding it on top and updating the queue');
              // Reapply the current item to the channel
              let originalProgram = currentItem.getOriginalProgram();
              originalProgram['isForcedAdded'] = true;
              channel.programs.unshift(originalProgram);
              // hoslog('CHANNEL: + ' + JSON.stringify(channel));
              // update the queue with the new items
              const newQueue = new HosQueueChannel(channel, originalProgram);
              this.overridePlayTokenWrapper(newQueue, currentItem, queue);

              this.hosPlayerService.silentQueueUpdate(newQueue);
              hoslog('channelListRefreshed: queue updated');
            }
          }
        }
      }
    } else {
      hoslog('channelListRefreshed: the queue is NOT a channel, all fine');
    }

    return channels;
  }

  calculatePlayTokenWrapperFromQueue(queue: HosQueue, source: HosPlayItem): PlayTokenWrapper {
    var res = null;
    if(queue!=null && queue.getItems()!=null && queue.getItems().length>0) {
      if (queue.getItems()[0] != null && (source == null || source.getId() == queue.getItems()[0].getId())) {
        res = queue.getItems()[0].getPlayTokenWrapper();
      }
    }
    return res;
  }

  overridePlayTokenWrapper(newQueue: HosQueue, currentItem: HosPlayItem, currentQueue: HosQueue) {
    if (newQueue.getItems() != null && newQueue.getItems().length > 0) {
      const newQueueFirstItem = newQueue.getItems()[0];
      if (newQueueFirstItem != null && newQueueFirstItem.getPlayTokenWrapper() == null && newQueueFirstItem.getId() == currentItem.getId()) {
        if(currentItem.getPlayTokenWrapper()!=null) {
          newQueueFirstItem.setPlayTokenWrapper(currentItem.getPlayTokenWrapper());
        } else {
          const ptw = this.calculatePlayTokenWrapperFromQueue(currentQueue, newQueueFirstItem);
          hoslog('calculatedPlayTokenWrapper: ' + JSON.stringify(ptw));
          newQueueFirstItem.setPlayTokenWrapper(ptw);
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private isItemInChannel(channel: Channel, itemId: number): Program {
    return channel.programs.find(prg => prg.id === itemId);
  }

  startTimer() {
    this.subs.sink = defer(async () => this.timerTick())
      .pipe(
        repeatWhen(notifications => notifications.pipe(delay(AppConstants.ChannelsRefreshDelayInSeconds * 1000)))
      ).subscribe(
        next => {
          hoslog('ChannelsListService: timer: next');
        },
        error => {
          hoslog('ChannelsListService: timer: error: ' + error);
        }
      );
  }

  private timerTick() {
    hoslog('ChannelsListService: timer: tick');
    // refresh the channels
    this.refresh();
  }

  getChannelById(channelId: number): Channel {
    let channels = this.channelListService.channels$.value;
    if (channels !== null && channels.length > 0) {
      return channels.find(ch => ch.id === channelId);
    }
    return undefined;
  }

  isItemInChannelId(channelId: number, itemId: number): boolean {
    const channel = this.getChannelById(channelId);
    if (channel) {
      return this.isItemInChannel(channel, itemId) != undefined;
    }
    return false;
  }
}
