import {
  HosQueue,
  HosQueueChannel,
  HosQueueEmpty,
  HosQueuePlaylist,
  HosQueueSingleAlbum,
  HosQueueSingleProgram,
  QueueType
} from './hos-queue';
import {Album, Channel, Playlist, Program} from '../../api-client';
import {PageReferral} from './hos-play-item';

export interface SerializedHosQueue {
  type: string;
  data: any;
  seconds: number;
}

export interface DeserializedHosQueue {
  type: string;
  queue: HosQueue;
  // track?: Track;
  seconds: number;
}

/*export interface HosQueueSerializable {
  serialize(): SerializedHosQueue;
  deserialize(): HosQueue;
}*/

export class HosQueueSerializationUtils {

  static serialize(queue: HosQueue, seconds: number) {
    const serialisedQueue: any = {
      type: queue.getQueueTypeStr(),
      data: queue.serialize(),
      seconds: seconds
    };
    return serialisedQueue;
  }

  static deserialize(serializedQueue: SerializedHosQueue): HosQueue {
    try {
      const queueType = QueueType[serializedQueue.type];
      switch (queueType) {
        case QueueType.Empty:
          return new HosQueueEmpty();
        case QueueType.SingleProgram:
          return new HosQueueSingleProgram(serializedQueue.data as Program);
        case QueueType.ThisWeekProgram:
          return new HosQueueSingleProgram(serializedQueue.data as Program, PageReferral.twp);
        case QueueType.Recent:
          return new HosQueueSingleProgram(serializedQueue.data as Program, PageReferral.recent);
        case QueueType.SingleAlbum:
          return new HosQueueSingleAlbum(serializedQueue.data as Album);
        case QueueType.Channel:
          const channelTrackItemIdx = serializedQueue.data.channelTrackItemIdx;
          let currentItem = serializedQueue.data.channel.programs[channelTrackItemIdx];
          return new HosQueueChannel(serializedQueue.data.channel as Channel, currentItem);
        case QueueType.Playlist:
          const playlistTrackItemIdx = serializedQueue.data.playlistTrackItemIdx;
          let currentPlItem = serializedQueue.data.playlist.items[playlistTrackItemIdx];
          return new HosQueuePlaylist(serializedQueue.data.playlist as Playlist, currentPlItem ? currentPlItem.hosItem : null, playlistTrackItemIdx);
      }
    } catch (e) {
      // console.error(e);
    }
    return new HosQueueEmpty();
  }
}
