/**
 * HOS API
 *
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 */
import {Injectable, Injector} from "@angular/core";
import {HosBaseService} from "./hos-base.service";

import {HttpResponse, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from 'rxjs/operators';
import "../rxjs-operators";
import {AppConstants} from "../../app.constants";
import {Station} from "../model/Station";
import {Page} from '../model/generic/page';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class StationService extends HosBaseService {

  constructor(injector: Injector) {
    super(injector);
  }

  /**
   * Return the states
   *
   */
  public getStates(extraHttpRequestParams?: any): Observable<string[]> {
    return this.getStatesWithHttpInfo(extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Return the station
   *
   * @param firstResult First results
   * @param maxResults Max results
   */
  public getStations(state?: string, extraHttpRequestParams?: any): Observable<Station[]> {
    return this.getStationsWithHttpInfo(state, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Return the station
   *
   * @param firstResult First results
   * @param maxResults Max results
   */
  public getStatesWithHttpInfo(extraHttpRequestParams?: any): Observable<HttpResponse<string[]>> {
    const path = this.getBasePath() + `/stations/states`;

    let queryParameters = new HttpParams();
    let headers = this.getNoLoginHeaders();

    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];

    let requestOptions = {
      headers: headers,
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.GET, path, requestOptions);
  }

  /**
   * Return the station
   *
   * @param firstResult First results
   * @param maxResults Max results
   */
  public getStationsWithHttpInfo(state?: string, extraHttpRequestParams?: any): Observable<HttpResponse<Station[]>> {
    const path = this.getBasePath() + `/stations`;

    let queryParameters = new HttpParams();
    let headers = this.getNoLoginHeaders();

    // verify required parameter 'searchQuery' is not null or undefined
    if (state === null || state === undefined) {
      throw new Error('Required parameter state was null or undefined when calling search.');
    }

    queryParameters = queryParameters.set('state', <any>state);

    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];

    let requestOptions = {
      headers: headers,
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.GET, path, requestOptions);
  }


}
