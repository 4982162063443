import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";


@Injectable()
export class FavouriteListFilterService {

  private _filterValuePrograms: BehaviorSubject<number>;
  private _filterValueTracks: BehaviorSubject<number>;
  private _filterValueAlbums: BehaviorSubject<number>;

  constructor() {
    // hoslog("FavouriteListFilterService init");
    this._filterValuePrograms = new BehaviorSubject(1); // All ratings initially
    this._filterValueTracks = new BehaviorSubject(1); // All ratings initially
    this._filterValueAlbums = new BehaviorSubject(1); // All ratings initially
  }


  get filterValuePrograms(): BehaviorSubject<number> {
    // hoslog("FavouriteListFilterService filterValuePrograms");
    return this._filterValuePrograms;
  }

  get filterValueTracks(): BehaviorSubject<number> {
    // hoslog("FavouriteListFilterService filterValueTracks");
    return this._filterValueTracks;
  }

  get filterValueAlbums(): BehaviorSubject<number> {
    // hoslog("FavouriteListFilterService filterValueAlbums");
    return this._filterValueAlbums;
  }

  public setFilterPrograms(value: number) {
    // hoslog("Set rating programs filter to: " + value);
    this._filterValuePrograms.next(value);
  }

  public setFilterTracks(value: number) {
    // hoslog("Set rating tracks filter to: " + value);
    this._filterValueTracks.next(value);
  }

  public setFilterAlbums(value: number) {
    // hoslog("Set rating albums filter to: " + value);
    this._filterValueAlbums.next(value);
  }

}
