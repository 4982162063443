import {Inject, Injectable} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {DOCUMENT} from '@angular/common';
import {AppConfig} from '../app.config';
import {Album, Channel, Program} from '../api-client';
import {HosProgramImageUrlPipe} from '../shared/image-url/hos-program-image-url.pipe';
import {Router} from '@angular/router';
import {HosAlbumImageUrlPipe} from '../shared/image-url/hos-album-image-url.pipe';
import {HosChannelImageUrlPipe} from '../shared/image-url/hos-channel-image-url.pipe';

@Injectable()
export class MetaUtilsService {

  constructor(private meta: Meta,
              private titleService: Title,
              private router: Router,
              @Inject(DOCUMENT) private document: Document) {

  }
  updateDefaultInfo() {
    this.updateMeta(AppConfig.metaDefaultTitle,
      AppConfig.metaDefaultUrl,
      AppConfig.metaDefaultDescription,
      AppConfig.metaDefaultImageUrl);
  }

  updateInfo(title: string, url: string, description: string, imageUrl: string) {
    const newTitle = title + ' - ' + AppConfig.metaDefaultTitle;
    const newImageUrl = imageUrl ? imageUrl : AppConfig.metaDefaultImageUrl;
    const newUrl = (url.startsWith('http') ? '' : AppConfig.basePath) + url;

    this.updateMeta(newTitle, newUrl, description, newImageUrl);
  }

  private updateMeta(title: string, url: string, description: string, imageUrl: string) {
    this.titleService.setTitle(title);
    this.meta.updateTag({name: 'description', content: description});
    this.meta.updateTag({property: 'og:title', content: title});
    this.meta.updateTag({property: 'og:image', content: imageUrl});
    this.meta.updateTag({property: 'og:url', content: url});
    this.meta.updateTag({property: 'og:description', content: description});

    this.meta.updateTag({name: 'twitter:title', content: title});
    this.meta.updateTag({name: 'twitter:description', content: description});
    this.meta.updateTag({name: 'twitter:image', content: imageUrl});
  }

  public addCanonicalUrl(url: string) {
    const canonicalLink = <HTMLLinkElement> (this.document.head.querySelector('link[rel=\'canonical\']') || this.document.head.appendChild(this.document.createElement('link')));
    canonicalLink.rel = 'canonical';
    canonicalLink.href = url;
  }

  public removeCanonicalUrl() {
    const canonicalLink = <HTMLLinkElement> this.document.head.querySelector('link[rel=\'canonical\']');
    if (canonicalLink) {
      this.document.head.removeChild(canonicalLink);
    }
  }

  updateProgramInfo(program: Program) {
    this.updateInfo(program.title, this.router.url, program.shortDescription, HosProgramImageUrlPipe.getProgramImageUrl(program.id, 500, program.genres));
  }

  updateAlbumInfo(album: Album) {
    this.updateInfo(album.title, this.router.url, album.shortDescription, HosAlbumImageUrlPipe.getAlbumImageUrl(album.id, 500));
  }

  updatePageInfo(title: string) {
    this.updateInfo(title, this.router.url, AppConfig.metaDefaultDescription, AppConfig.metaDefaultImageUrl);
  }

  updateChannelInfo(channel: Channel) {
    this.updateInfo(channel.name, this.router.url, channel.description, HosChannelImageUrlPipe.getChannelImageUrl(channel.id, 500));
  }
}
