/**
 * HOS API
 *
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 *
 *
 *
 */

import {Artist} from './Artist';
import {Genre} from './Genre';
import {Label} from './Label';
import {Track} from './Track';
import {HosItem} from "./HosItem";
import AlbumTypeEnum = Album.AlbumTypeEnum;


export interface Album extends HosItem {
  /**
   * Album id
   */
  id: number;

  /**
   * Title
   */
  title: string;

  /**
   * Description
   */
  description?: string;

  /**
   * Short description
   */
  shortDescription?: string;

  /**
   * Cover
   */
  // coverUrl?: string;

  /**
   * Tracks
   */
  tracks?: Array<Track>;

  /**
   * Artists
   */
  artists?: Array<Artist>;

  /**
   * TrackArtists
   */
  trackArtists?: Array<Artist>;

  /**
   * Genres
   */
  genres?: Array<Genre>;

  /**
   * Popularity
   */
  popularity?: number;

  /**
   * Duration
   */
  duration?: number;

  /**
   * Date
   */
  date?: Date;

  /**
   * Label
   */
  label?: Label;

  /**
   * Buy cd URL
   */
  buyCdUrl?: string;

  type?: HosItem.TypeEnum;

  playable?: boolean;

  attribution?: string;

  albumType?: AlbumTypeEnum;

  // dynamic
  artistsStr?: string;
}

export namespace Album {
    export enum AlbumTypeEnum {
      OneArtist = <any> 'OneArtist',
      MultipleArtists = <any> 'MultipleArtists',
      Compilation = <any> 'Compilation'
    }
}
