import {HosItem} from "../../api-client/model/HosItem";
import {PlayTokenWrapper} from "../../api-client/model/PlayTokenWrapper";
import {Program} from "../../api-client/model/Program";
import {Album} from "../../api-client/model/Album";
import {HosPlayUtils} from "./hos-play-utils";
import {Track} from "../../api-client/model/Track";
import {HosAlbumImageUrlPipe} from '../../shared/image-url/hos-album-image-url.pipe';
import {HosProgramImageUrlPipe} from '../../shared/image-url/hos-program-image-url.pipe';
import TypeEnum = HosItem.TypeEnum;
import {Page} from '../../api-client/model/generic/page';

export enum PageReferral {
  program, album, channel, twp, recent
}

export interface HosPlayItemInfo {
  fromTime: number;
  toTime: number;
  track: Track;

  prevTrackTime: number;
  nextTrackTime: number;

  line1: string;
  line2: string;
  line3: string;
  imageUrl: string;
  itemUrl: string;
}

export interface HosPlayItem {
  // TODO public String getStreamType();

  getUniqueId(): string;

  getId(): number;

  getIdx(): number; // optional, used for playlists

  getType(): HosItem.TypeEnum;

  getPageReferral(): PageReferral;

  setPageReferral(value: PageReferral);

  getPageReferralRefId(): number;

  setPageReferralRefId(value: number);

  getHosItem(): HosItem;

  getPlayItemInfo(): HosPlayItemInfo;

  updatePlayItemInfo(atTime: number): HosPlayItemInfo;

  getLine1(): string;

  getLine2(): string;

  getLine3(): string;

  /**
   * Return the Image associated to the stream. Could be the track image, the album image or the program image.
   * @return the Image associated to the stream. Could be the track image, the album image or the program image.
   */
  getImageUrl(): string;

  getItemUrl(): string;

  hasPlayTokenWrapper(): boolean;

  getPlayTokenWrapper(): PlayTokenWrapper;

  setPlayTokenWrapper(playTokenWrapper: PlayTokenWrapper): void;

  /**
   * Return the URL of the stream
   * @return the URL of the stream
   */
  getStreamUrl(): string;

  /**
   * Used to play only 1 album track in the playlist.
   * If it's null the position 0 will be used as start of the track will be used.
   */
  getStartPositionOverride(): number;

  /**
   * Used to play only 1 album track in the playlist.
   * If it's null the file duration will be used.
   */
  getDurationOverride(): number;
}

export class HosPlayItemEmpty implements HosPlayItem {
  getUniqueId(): string {
    return "EMPTY";
  }

  getId(): number {
    return null;
  }

  getIdx(): number {
    return null;
  }

  getType(): HosItem.TypeEnum {
    return null;
  }

  getPageReferral(): PageReferral {
    return null;
  }

  setPageReferral(value: PageReferral) {
    // not used in Empty
  }

  getPageReferralRefId(): number {
    return null;
  }

  setPageReferralRefId(value: number) {
    // not used in Empty
  }

  getHosItem(): HosItem {
    return null;
  }

  hasPlayTokenWrapper(): boolean {
    return false;
  }

  getPlayTokenWrapper(): PlayTokenWrapper {
    return null;
  }

  setPlayTokenWrapper(playTokenWrapper: PlayTokenWrapper): void {

  }

  getPlayItemInfo(): HosPlayItemInfo {
    return {
      fromTime: null,
      toTime: null,
      track: null,
      prevTrackTime: null,
      nextTrackTime: null,
      line1: "",
      line2: "",
      line3: "",
      imageUrl: this.getImageUrl(),
      itemUrl: null
    };
  }

  updatePlayItemInfo(atTime: number): HosPlayItemInfo {
    return null;
  }

  getLine1(): string {
    return "";
  }

  getLine2(): string {
    return "";
  }

  getLine3(): string {
    return "";
  }

  getStreamUrl(): string {
    return "";
  }

  getImageUrl(): string {
    return "/assets/images/player-cover-empty.png";
  }

  getItemUrl(): string {
    return null;
  }

  getStartPositionOverride(): number {
    return null;
  }

  getDurationOverride(): number {
    return null;
  }
}

// Debug only
/*let testUrls = [
 "https://dev-hos-stream.s3.amazonaws.com/abm1149.m3u8",
 "https://dev-hos-stream.s3.amazonaws.com/abm72.m3u8",
 "https://dev-hos-stream.s3.amazonaws.com/pgm0868.m3u8",
 "https://dev-hos-stream.s3.amazonaws.com/pgm1123.m3u8"
 ];*/

export class HosPlayProgram implements HosPlayItem {
  private _program: Program;
  private currentItemInfo: HosPlayItemInfo = null;
  // private _streamUrl: string; // TODO temp, remove
  private _playTokenWrapper: PlayTokenWrapper = null;
  private _overridePageReferral: PageReferral = null;
  private _playlistIdx: number = null;
  private _overridePageReferralRefId: number = null;

  constructor(program: Program, overridePageReferral: PageReferral = null, playlistIdx: number = null, overridePageReferralRefId: number = null) {
    this._program = program;
    this._overridePageReferral = overridePageReferral;
    this._playlistIdx = playlistIdx;
    this._overridePageReferralRefId = overridePageReferralRefId;
    this.updatePlayItemInfo(0); // init the current info
    // let random = Math.floor(Math.random() * 4);
    // hoslog("Random: " + random);
    // this._streamUrl = testUrls[random];
  }

  getUniqueId(): string {
    return this._program.type + this._program.id + (this._playlistIdx != null ? '_' + this._playlistIdx : '');
  }

  getId(): number {
    return this._program.id;
  }

  getOriginalProgram(): Program {
    return this._program;
  }

  getIdx(): number {
    return this._playlistIdx;
  }

  hasPlayTokenWrapper(): boolean {
    return this.getPlayTokenWrapper() != null;
  }

  getPlayTokenWrapper(): PlayTokenWrapper {
    return this._playTokenWrapper;
  }

  setPlayTokenWrapper(playTokenWrapper: PlayTokenWrapper): void {
    this._playTokenWrapper = playTokenWrapper;
  }

  getType(): HosItem.TypeEnum {
    return this._program.type;
  }

  getPageReferral(): PageReferral {
    if (this._overridePageReferral) {
      return this._overridePageReferral;
    }
    return PageReferral.program;
  }

  setPageReferral(value: PageReferral) {
    this._overridePageReferral = value;
  }

  getPageReferralRefId(): number {
    if (this._overridePageReferralRefId) {
      return this._overridePageReferralRefId;
    }
    return this._program.id;
  }

  setPageReferralRefId(value: number) {
    this._overridePageReferralRefId = value;
  }

  getHosItem(): HosItem {
    return this._program;
  }

  getPlayItemInfo(): HosPlayItemInfo {
    return this.currentItemInfo;
  }

  updatePlayItemInfo(atTime: number): HosPlayItemInfo {
    if (this.currentItemInfo == null ||
      atTime < this.currentItemInfo.fromTime ||
      atTime > this.currentItemInfo.toTime
    ) {
      this.currentItemInfo = HosPlayUtils.getProgramInfoAtTime(this._program, atTime);
    }
    return this.currentItemInfo;
  }

  getLine1(): string {  // current program name
    if (this.currentItemInfo) {
      return this.currentItemInfo.line1;
    }
    return "PGM " + this._program.id + " \"" + this._program.title + "\"";
  }

  getLine2(): string {  // current album author
    if (this.currentItemInfo) {
      return this.currentItemInfo.line2;
    }
    return "";
  }

  getLine3(): string {  // current album track
    if (this.currentItemInfo) {
      return this.currentItemInfo.line3;
    }
    return "";
  }

  getImageUrl(): string {  // current album cover
    if (this.currentItemInfo) {
      return this.currentItemInfo.imageUrl;
    }
    return "/assets/images/player-cover-empty.png"; //HosProgramImageUrlPipe.getProgramImageUrl(this._program.id, 180);
  }

  getItemUrl(): string {
    return "/programs/details/" + this.getId();
  }

  getStreamUrl(): string {
    if (this._playTokenWrapper) {
      return this._playTokenWrapper.signedUrl;
    }
    // return this._streamUrl;
    return null;
  }

  getStartPositionOverride(): number {
    return null;
  }

  getDurationOverride(): number {
    return null;
  }
}

export class HosPlayAlbum implements HosPlayItem {
  protected _album: Album;
  protected currentItemInfo: HosPlayItemInfo = null;
  protected _playTokenWrapper: PlayTokenWrapper = null;
  protected _playlistIdx: number = null;

  constructor(album: Album, playlistIdx: number = null) {
    this._album = album;
    this._playlistIdx = playlistIdx;
  }

  getUniqueId(): string {
    return this._album.type + this._album.id + (this._playlistIdx != null ? '_' + this._playlistIdx : '');
  }

  getId(): number {
    return this._album.id;
  }

  getIdx(): number {
    return this._playlistIdx;
  }

  hasPlayTokenWrapper(): boolean {
    return this.getPlayTokenWrapper() != null;
  }

  getPlayTokenWrapper(): PlayTokenWrapper {
    return this._playTokenWrapper;
  }

  setPlayTokenWrapper(playTokenWrapper: PlayTokenWrapper): void {
    this._playTokenWrapper = playTokenWrapper;
  }

  getType(): HosItem.TypeEnum {
    return this._album.type;
  }

  getPageReferral(): PageReferral {
    return PageReferral.album;
  }

  setPageReferral(value: PageReferral) {
    // not used in Album
  }

  getPageReferralRefId(): number {
    return this._album.id;
  }

  setPageReferralRefId(value: number) {
  }

  getHosItem(): HosItem {
    return this._album;
  }

  getPlayItemInfo(): HosPlayItemInfo {
    return this.currentItemInfo;
  }

  updatePlayItemInfo(atTime: number): HosPlayItemInfo {
    if (this.currentItemInfo == null ||
      atTime < this.currentItemInfo.fromTime ||
      atTime > this.currentItemInfo.toTime
    ) {
      this.currentItemInfo = HosPlayUtils.getAlbumInfoAtTime(this._album, atTime);
    }
    return this.currentItemInfo;
  }

  getLine1(): string {  // current album name
    if (this.currentItemInfo) {
      return this.currentItemInfo.line1;
    }
    return this._album.title;
  }

  getLine2(): string {  // current album author
    if (this.currentItemInfo) {
      return this.currentItemInfo.line2;
    }
    return "";
  }

  getLine3(): string {  // current album track
    if (this.currentItemInfo) {
      return this.currentItemInfo.line3;
    }
    return "";
  }

  getImageUrl(): string {  // current album cover
    if (this.currentItemInfo) {
      return this.currentItemInfo.imageUrl;
    }
    return HosAlbumImageUrlPipe.getAlbumImageUrl(this._album.id, 150);
  }

  getItemUrl(): string {
    return "/albums/details/" + this.getId();
  }

  getStreamUrl(): string {
    if (this._playTokenWrapper) {
      return this._playTokenWrapper.signedUrl;
    }
    return null;
  }

  getStartPositionOverride(): number {
    return null;
  }

  getDurationOverride(): number {
    return null;
  }
}

export class HosPlayAlbumTrack extends HosPlayAlbum {
  protected _track: Track = null;

  constructor(album: Album, track: HosItem, playlistIdx: number = null) {
    super(album, playlistIdx);
    this._track = track as Track;
    // I have to play only one track
    this._album.tracks = [this._track];
    this._album.type = HosItem.TypeEnum.Album;
    this.updatePlayItemInfo(track.startPositionInStream);
  }

  getUniqueId(): string {
    return this._album.type + this._album.id + "_track_" + this._track.id  + (this._playlistIdx != null ? '_' + this._playlistIdx : '');
  }

  getType(): HosItem.TypeEnum {
    return HosItem.TypeEnum.Track;
  }

  getLine3(): string {  // current album track
    return this._track.title;
  }

  getStartPositionOverride(): number {
    return this._track.startPositionInStream;
  }

  getDurationOverride(): number {
    return this._track.duration;
  }
}
