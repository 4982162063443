import {PlanCost} from "../../api-client/model/PlanCost";
import DurationUnitEnum = PlanCost.DurationUnitEnum;
import BillingTypeEnum = PlanCost.BillingTypeEnum;

export class PriceTableRow {
  duration: number;
  durationUnit?: PlanCost.DurationUnitEnum;
  oneTime: PlanCost;
  recurring: PlanCost;

  constructor(duration: number, durationUnit?: PlanCost.DurationUnitEnum) {
    this.duration = duration;
    this.durationUnit = durationUnit;
    this.clearAll();
  }

  distribute(cost: PlanCost): boolean {
    if (cost.duration == this.duration && cost.durationUnit == this.durationUnit) {
      if (cost.billingType == BillingTypeEnum.OneTime) {
        this.oneTime = cost;
        this.oneTime.monthlyPrice = this.getMonthlyPrice(cost);
        this.oneTime.chargedDescription = this.getOneTimeChargedDescription(cost);
      } else {
        this.recurring = cost;
        this.recurring.monthlyPrice = this.getMonthlyPrice(cost);
        this.recurring.chargedDescription = this.getRecurringChargedDescription(cost);
      }
      return true; // distributed
    } else {
      return false; // not distributed
    }
  }

  isEmpty(): boolean {
    return !this.oneTime && !this.recurring;
  }

  clearAll() {
    this.oneTime = null;
    this.recurring = null;
  }

  private getMonthlyPrice(cost: PlanCost): number {
    let duration = cost.duration;
    let totalPrice = cost.cost;
    if (cost.durationUnit == DurationUnitEnum.Year) {
      duration = cost.duration * 12; // in months so it can calculate the total cost
    }
    return totalPrice / duration;
  }

  private getOneTimeChargedDescription(cost: PlanCost): string {
    let oneTimeChargedDescription = "charged once";
    let duration = cost.duration;
    let totalPrice = cost.cost;
    if (cost.durationUnit == DurationUnitEnum.Year) {
      duration = cost.duration * 12; // in months so it can calculate the total cost
    }
    if (duration > 1) {
      oneTimeChargedDescription += " @ $" + (totalPrice);
    }
    return oneTimeChargedDescription;
  }

  private getRecurringChargedDescription(cost: PlanCost): string {
    let recurringChargedDescription = "charged ";
    let duration = cost.duration;
    let totalPrice = cost.cost;
    if (cost.durationUnit == DurationUnitEnum.Year) {
      duration = cost.duration * 12; // in months so it can calculate the total cost
    }
    if (duration > 1) {
      recurringChargedDescription += " $" + (totalPrice);
    }
    if (cost.durationUnit == DurationUnitEnum.Months) { // months
      if (cost.duration==1) {
        recurringChargedDescription += " monthly";
      } else if (cost.duration==3) {
        recurringChargedDescription += " quarterly";
      } else if (cost.duration==6) {
        recurringChargedDescription += " twice a year";
      } else {
        recurringChargedDescription += " every " + cost.duration + " months";
      }
    } else { // years
      if (cost.duration==1) {
        recurringChargedDescription += " once a year";
      } else {
        recurringChargedDescription += " every " + cost.duration + " years";
      }
    }
    return recurringChargedDescription;
  }
}

export class PriceTable {
  rows: PriceTableRow[] = [];

  constructor() {
    // 4 rows
    this.rows.push(new PriceTableRow(1, DurationUnitEnum.Months));
    this.rows.push(new PriceTableRow(3, DurationUnitEnum.Months));
    this.rows.push(new PriceTableRow(6, DurationUnitEnum.Months));
    this.rows.push(new PriceTableRow(1, DurationUnitEnum.Year));
  }

  hasRow(index: number): boolean {
    return (!this.rows[index].isEmpty());
  }

  isEmpty(): boolean {
    let fullRowFound = false;
    for (let row of this.rows) {
      if (!row.isEmpty()) {
        fullRowFound = true;
      }
    }
    return !fullRowFound;
  }

  distribute(cost: PlanCost): void {
    for (let row of this.rows) {
      if (row.distribute(cost)) {
        break;
      }
    }
  }

  clearAll() {
    for (let row of this.rows) {
      row.clearAll();
    }
  }
}
