import { Component, OnInit, Input } from '@angular/core';
import {PlayButtonIconSize} from '../../../../app.constants';

@Component({
  selector: 'app-search-playlist-item',
  templateUrl: './search-playlist-item.component.html',
  styleUrls: ['./search-playlist-item.component.scss']
})
export class SearchPlaylistItemComponent implements OnInit {
  @Input('item') item;
  public isPlaying = false;

  playButtonIconSize = PlayButtonIconSize;

  constructor() { }

  ngOnInit() {
  }

    protected readonly PlayButtonIconSize = PlayButtonIconSize;
}
