import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-popularity',
    templateUrl: './popularity.component.html',
    styleUrls: ['./popularity.component.scss']
})
export class PopularityComponent {
    @Input() popularity: number;

    ngOnInit() {
      if (!this.popularity) {
        this.popularity = 0;
      }
    }
}
