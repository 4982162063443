/**
 * HOS API
 *
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 *
 *
 *
 */

import {Artist} from './Artist';
import {Label} from "./Label";
import {Track} from "./Track";
import {Album} from "./Album";
import {Genre} from "./Genre";


export interface HosItem {
  id?: number;

  type?: HosItem.TypeEnum;

  title?: string;

  duration?: number;
  startPositionInStream?: number;

  artists?: Array<Artist>;

  popularity?: number;

  // Extra fields

  // coverUrl?: string;
  label?: Label;
  tracks?: Array<Track>;
  album?: Album;
  albums?: Array<Album>;
  genres?: Array<Genre>;
  date?: Date;
  description?: string;

  // Dynamically calculated
  rating?: number;
}
export namespace HosItem {
  export enum TypeEnum {
    Program = <any> 'program',
    Track = <any> 'track',
    Album = <any> 'album'
  }
}
