import {Injectable, OnDestroy} from '@angular/core';
import {CoolLocalStorage} from '@angular-cool/storage';
import {VoiceoverStatus} from './player/hos-player.utils';
import {LoginHelperService} from '../api-client/helper/login-helper.service';
import {SubSink} from 'subsink';

@Injectable()
export class VoiceoverStorageService implements OnDestroy {
  private static voiceoverKey = 'voiceover';

  private subs = new SubSink();

  constructor(private localStorage: CoolLocalStorage,
              private loginHelperService: LoginHelperService) {

    // Sign Out event
    this.subs.sink = loginHelperService.loggedOut.subscribe(value => {
      this.clear();
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  hasInfo(): boolean {
    return this.getVoiceoverStatus() != null;
  }

  getVoiceoverStatus(): VoiceoverStatus {
    const voiceoverStatusStr = this.localStorage.getItem(VoiceoverStorageService.voiceoverKey);
    if (voiceoverStatusStr) {
      return VoiceoverStatus[voiceoverStatusStr];
    } else {
      this.setVoiceoverStatus(VoiceoverStatus.On);
    }
    return VoiceoverStatus.On;
  }

  setVoiceoverStatus(voiceoverStatus: VoiceoverStatus) {
    if (voiceoverStatus != null) {
      this.localStorage.setItem(VoiceoverStorageService.voiceoverKey, VoiceoverStatus[voiceoverStatus]);
    }
  }

  clear() {
    this.localStorage.removeItem(VoiceoverStorageService.voiceoverKey);
  }
}
