import {Component, Input, OnInit} from '@angular/core';
import {HosPlayerService} from "../../../services/player/hos-player.service";
import {HosQueue, HosQueuePlaylist} from "../../../services/player/hos-queue";
import {Playlist} from "../../../api-client/model/Playlist";
import {PlaylistsService} from "../../../api-client/api/playlists.service";
import {hoslog, PlayButtonIconSize} from '../../../app.constants';
import {MixpanelService} from "../../../services/mixpanel.service";
import {Router} from '@angular/router';
import {StopTimerService} from '../../../services/stop-timer.service';

@Component({
  selector: 'app-play-playlist-button',
  templateUrl: './play-playlist-button.component.html',
  styleUrls: ['./play-playlist-button.component.scss']
})
export class PlayPlaylistButtonComponent implements OnInit {
  @Input() iconSize : PlayButtonIconSize;
  @Input() item: Playlist;
  @Input() extraClasses: string = "";
  @Input() enabled: boolean = true;

  private played: boolean = false;

  playButtonIconSize = PlayButtonIconSize;

  public queueItem: HosQueue = null;

  constructor(public hosPlayerService: HosPlayerService,
              private playlistsService: PlaylistsService,
              private stopTimerService: StopTimerService) {
  }

  ngOnInit() {
    if (this.item) {
      this.queueItem = new HosQueuePlaylist(this.item);
    }
  }

  playPlaylist() {
    this.stopTimerService.reset();
    if (this.enabled && this.item) {
      const currentQueue = this.hosPlayerService.currentQueueObs.value;
      if (currentQueue && currentQueue.getQueueUniqueId() === this.queueItem.getQueueUniqueId()) { // if it's already in the queue
        // getting the queue version as it should be the completed one
        this.queueItem = currentQueue;
        // setting as played so it doesn't redownload the playlist
        this.played = true;
      }
      if (!this.played) {
        if (this.item.items) {
          this.queueItem = new HosQueuePlaylist(this.item);
          this.hosPlayerService.play(this.queueItem);
          // setting as played so it doesn't redownload the playlist
          this.played = true;
        } else {
          // loading the items
          hoslog("Playlist " + this.item.id + ": loading items");
          this.playlistsService.getPlaylistDetail(this.item.id)
            .subscribe(
              res => {
                hoslog("Playlist " + this.item.id + " loaded");
                this.item = res;
                this.queueItem = new HosQueuePlaylist(this.item);
                this.hosPlayerService.play(this.queueItem);
                //this.loadingHelperService.loadingOK('PLAYLIST');
                // setting as played so it doesn't redownload the playlist
                this.played = true;
              },
              error => {
                hoslog("Playlist " + this.item.id + " error: " + JSON.stringify(error));
                //this.loadingHelperService.loadingKO(error, 'PLAYLIST');
                //this.data = null;
              });
        }
      } else { // already played
        this.hosPlayerService.play(this.queueItem);
      }
    }
  }


}
