import {Injectable} from '@angular/core';
import {AlertsUtilService} from './alerts-util.service';
import {ModalService} from '../shared/modal.service';
import {Router} from '@angular/router';
import {ShareModalComponent} from '../shared/share-modal/share-modal.component';
import {Album, Playlist, Program, Track} from '../api-client';
import {environment} from '../../environments/environment';
import {HosProgramImageUrlPipe} from '../shared/image-url/hos-program-image-url.pipe';
import {HosAlbumImageUrlPipe} from '../shared/image-url/hos-album-image-url.pipe';
import {hoslog} from '../app.constants';
import {AppConfig} from '../app.config';
import {MixpanelService} from './mixpanel.service';

@Injectable()
export class ShareService {

  private shownDialogRef = null; // if a dialog is currently shown it's != null

  constructor(private router: Router,
              private alertsUtilService: AlertsUtilService,
              private mixpanelService: MixpanelService,
              private modalService: ModalService) {
  }

  showShareModal(shareTitle: string, shareUrl: string, shareDescription: string, shareImageUrl: string = null, attachWebsiteTitle = true) {
    let modal$ = this.modalService.create(ShareModalComponent, {
      closeModal: (response) => {
        //hoslog("CloseModal: " + response);
      }
    });

    modal$.subscribe((ref) => {
      this.shownDialogRef = ref;
      let i = ref.instance as ShareModalComponent;
      i.message = '\'' + shareTitle + '\'';
      i.shareTitle = shareTitle + (attachWebsiteTitle ? (' - ' + AppConfig.metaDefaultTitle) : '');
      i.shareUrl = shareUrl;
      i.shareDescription = shareDescription;
      i.shareImageUrl = shareImageUrl;
      i.okLabel = "";
      i.cancelLabel = "CLOSE";
      i.showOK = false;
      i.showCancel = true;
      this.router.events.subscribe((val) => {
        ref.destroy();
        this.shownDialogRef = null;
      });
    });
  }

  showShareProgramModal(program: Program) {
    hoslog('showShareProgramModal');
    this.mixpanelService.track('share', {programId: program.id, title: program.title, shareType: 'Program'});
    this.showShareModal(program.title, environment.baseWebsitePath + '/programs/details/' + program.id, program.shortDescription, HosProgramImageUrlPipe.getProgramImageUrl(program.id, 500, program.genres));
  }

  showShareAlbumModal(album: Album) {
    hoslog('showShareAlbumModal');
    this.mixpanelService.track('share', {albumId: album.id, title: album.title, shareType: 'Album'});
    this.showShareModal(album.title, environment.baseWebsitePath + '/albums/details/' + album.id, album.shortDescription, HosAlbumImageUrlPipe.getAlbumImageUrl(album.id, 500));
  }

  showShareTrackModal(track: Track, album: Album) {
    hoslog('showShareTrackModal');
    this.mixpanelService.track('share', {shareType: 'Album', trackId: track.id, trackTitle: track.title, albumId: album.id, albumTitle: album.title});
    this.showShareModal(track.title + ' - ' + album.title, environment.baseWebsitePath + '/albums/details/' + album.id, album.shortDescription, HosAlbumImageUrlPipe.getAlbumImageUrl(album.id, 500));
  }

  showSharePlaylistModal(playlist: Playlist) {
    hoslog('showShareTrackModal');
    this.mixpanelService.track('share', {playlistId: playlist.id, name: playlist.name, shareType: 'Playlist'});
    this.showShareModal(playlist.name, environment.baseWebsitePath + '/playlists/details/' + playlist.id + '/share', AppConfig.metaDefaultDescription, AppConfig.metaDefaultImageUrl);
  }
}
