import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rowize'
})
export class RowizePipe implements PipeTransform {

  transform(value: any, columns: number, fillExtraElements: boolean = false): any {
    var arr = [];
    var rowIdx = 0;
    arr[rowIdx] = [];
    for (let i = 0; i < value.length; i++) {
      arr[rowIdx].push(value[i]);
      if (((i+1) % columns) == 0) {
        rowIdx++;
        arr[rowIdx] = [];
      }
    }

    if (fillExtraElements) {
      // checking the last row and adding empty elements
      const lastRowLength = arr[rowIdx].length;
      if (lastRowLength > 0) {
        const diff = columns - lastRowLength;
        for (let i = 0; i < diff; i++) {
          arr[rowIdx].push({});
        }
      }
    }

    return arr;
  }

}
