import {IOrderableList} from "./iorderable-list";

export abstract class OrderableList implements IOrderableList {

  public orderBy: string = null;
  public orderDirectionAsc: boolean = true;

  public abstract reload();

  public headerSortClicked(headerName: string) {
    if (headerName === this.orderBy) {
      // change direction
      this.orderDirectionAsc = !this.orderDirectionAsc;
    } else {
      // new order
      this.orderBy = headerName;
      this.orderDirectionAsc = true;
    }
    // refresh immediately
    this.reload();
  }

  public getOrderByValue() {
    if (this.orderBy != null) {
      return this.orderBy;
    } else {
      return null;
    }
  }

  public getOrderByDirection() {
    return (this.orderDirectionAsc ? "ASC" : "DESC");
  }
}
