import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClient, HttpClientModule} from '@angular/common/http';

import {AccountService} from './api/account.service';
import {AlbumsService} from './api/albums.service';
import {ChannelsService} from './api/channels.service';
import {FaqsService} from './api/faqs.service';
import {NewsService} from './api/news.service';
import {TextFragmentsService} from './api/text-fragments.service';
import {PlansService} from './api/plans.service';
import {PlaylistsService} from './api/playlists.service';
import {ProgramsService} from './api/programs.service';
import {ReviewsService} from './api/reviews.service';
import {SearchService} from './api/search.service';
import {SettingsService} from './api/settings.service';
import {TracksService} from './api/tracks.service';
import {ApiHelperService} from "./helper/api-helper.service";
import {LoginHelperService} from "./helper/login-helper.service";
import {CoolStorageModule} from "@angular-cool/storage";
import {PlayerApiService} from "./api/player-api.service";
import {LoggedUserService} from "./helper/logged-user.service";
import {AuthGuard} from "./helper/guards/auth-guard.service";
import {LoggedUserSettingsService} from "./helper/logged-user-settings.service";
import {PeopleFeedbackService} from './api/people-feedback.service';
import {StationService} from './api/station.service';
import {MailchimpService} from './api/mailchimp.service';
import {HelpService} from './api/help.service';
import {NoInternetConnectionService} from '../services/no-internet-connection.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    CoolStorageModule.forRoot()
  ],
  declarations: [],
  exports: [],
  providers: [
    AccountService,
    AlbumsService,
    ChannelsService,
    FaqsService,
    HelpService,
    NewsService,
    TextFragmentsService,
    PlansService,
    PlayerApiService,
    PlaylistsService,
    ProgramsService,
    ReviewsService,
    SearchService,
    SettingsService,
    TracksService,
    ApiHelperService,
    LoginHelperService,
    LoggedUserService,
    LoggedUserSettingsService,
    AuthGuard,
    HttpClient,
    PeopleFeedbackService,
    StationService,
    MailchimpService,
    NoInternetConnectionService
  ]
})
export class ApiModule {
  /*public static forConfig(configuration: Configuration): ModuleWithProviders {
   return {
   ngModule: ApiModule,
   providers: [ {provide: Configuration, useValue: configuration}]
   }
   }*/
}
