export class GenericUtils {

  public static isUndefinedOrNull(value?: any): boolean {
    if (value !== undefined && value !== null) {
      return false;
    } else {
      return true;
    }
  }
}
