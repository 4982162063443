/**
 * HOS API
 * 
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 * 
 * 
 * 
 */



export interface HosErrorDialog {
    dialogType?: HosErrorDialog.DialogTypeEnum;

    dialogTitle?: string;

    dialogMessage?: string;

    dialogOKButtonLabel?: string;

    dialogOKButtonRedirect?: string;

}
export namespace HosErrorDialog {
    export enum DialogTypeEnum {
        None = <any> 'None',
        Info = <any> 'Info',
        Dialog = <any> 'Dialog'
    }
}
