import {ModuleWithProviders} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ProgramDetailComponent} from './shared/program-detail/program-detail.component';

export const routes: Routes = [
  {path: '', redirectTo: 'home', pathMatch: 'full'},

  {path: 'home', loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)},
  {path: ':type/program', component: ProgramDetailComponent},
  {path: 'recent', loadChildren: () => import('./modules/recent/recent.module').then(m => m.RecentModule)},
  {path: 'programs', loadChildren: () => import('./modules/programs/programs.module').then(m => m.ProgramsModule)},
  {path: 'albums', loadChildren: () => import('./modules/albums/albums.module').then(m => m.AlbumsModule)},
  {path: 'channels', loadChildren: () => import('./modules/channels/channels.module').then(m => m.ChannelsModule)},
  {path: 'playlists', loadChildren: () => import('./modules/playlist/playlist.module').then(m => m.PlaylistModule)},
  {path: 'favorites', loadChildren: () => import('./modules/favorites/favorites.module').then(m => m.FavoritesModule)},
  {path: 'blocked', loadChildren: () => import('./modules/blocked/blocked.module').then(m => m.BlockedModule)},
  {path: 'account', loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule)},
  {path: 'news', loadChildren: () => import('./modules/news/news.module').then(m => m.NewsModule), data: {noPageScroll: true}},
  {path: 'subscription', loadChildren: () => import('./modules/subscription/subscription.module').then(m => m.SubscriptionModule)},
  {path: 'pages', loadChildren: () => import('./modules/pages/pages.module').then(m => m.PagesModule)},
  {path: 'about', loadChildren: () => import('./modules/about/about.module').then(m => m.AboutModule)},
  {
    path: 'headless-subscription',
    loadChildren: () => import('./modules/subscription/subscription.module').then(m => m.SubscriptionModule),
    data: {headless: true}
  },
  {path: 'headless-about', loadChildren: () => import('./modules/about/about.module').then(m => m.AboutModule), data: {headless: true}},
  {path: 'headless-pages', loadChildren: () => import('./modules/pages/pages.module').then(m => m.PagesModule), data: {headless: true}},

  {
    path: 'headless-account',
    loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule),
    data: {headless: true}
  },
  {path: 'headless-account-delete', loadChildren: () => import('./modules/account-delete/account-delete.module').then(m => m.AccountDeleteModule), data: {headless: true}},
  {path: '404', loadChildren: () => import('./modules/four-ou-four/four-ou-four.module').then(m => m.FourOuFourModule)},
  {path: '**', redirectTo: '404', pathMatch: 'full'}
];

export const routing: ModuleWithProviders<any> = RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' });
