import {
  Component, OnInit, ViewEncapsulation, ElementRef, Input, Renderer2, ViewChildren, AfterViewInit,
  Output, EventEmitter
} from '@angular/core';
import { SearchPipe } from '../search/search.pipe';

export interface CustomSelectElement {
  id: any;
  name: string;
}

@Component({
  selector: 'app-select',
  templateUrl: './customSelect.component.html',
  styleUrls: ['./customSelect.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    '(document:click)': 'lostFocus($event)',
    '(document:wheel)': 'lostFocus($event)'
  }
})
export class CustomSelectComponent implements OnInit {

  @Input() dataList: CustomSelectElement[] = [];
  @Input() selectedItem: CustomSelectElement = null;
  @Input() formControlName: string;
  @Output() itemSelected = new EventEmitter<CustomSelectElement>();
  @ViewChildren('filteredItems') filteredList;

  public isPopupOpen: boolean;
  public wasSelected: boolean = false;
  private maxCountPerHeight: number = 6;
  private parentHeightContainer: number = 0;

  constructor(private _el: ElementRef, public renderer: Renderer2) {

  }

  ngOnInit() {
    this.isPopupOpen = false;
    if (this.selectedItem) {
      if (this.dataList.indexOf(this.selectedItem) >= 0) {
        this.wasSelected = true;
      }
    }
  }

  ngAfterViewInit() {
  }

  lostFocus(event) {
    if (!this._el.nativeElement.contains(event.target)) {
      this.isPopupOpen = false;
    }
  }

  onChange(newValue) {
    setTimeout(() => {
      this.rerenderHeight();
    }, 50);
  }

  private rerenderHeight() {
    let elHeight: number;
    if (this.filteredList) {
      if (this.filteredList.toArray().length > 0 && this.filteredList.toArray().length < this.maxCountPerHeight) {
        elHeight = (this.filteredList.toArray().length * 30) + 7;
      } else if (this.selectedItem && this.filteredList.toArray().length == 0) {
        // do not show popup
        this.isPopupOpen = false;
        return;
      } else {
        // default
        elHeight = this.maxCountPerHeight * 30 + 7;
      }
    } else {
      if (this.dataList.length > 0 && this.dataList.length < 6) {
        elHeight = (this.dataList.length * 30) + 7;
      }
    }
    this.renderer.setStyle(this._el.nativeElement.getElementsByClassName('popup-wrapper')[0], 'height', elHeight + 'px');
    this.renderer.setStyle(this._el.nativeElement.getElementsByClassName('list-box-inside-wrapper')[0], 'height', elHeight + 'px');
  }

  public handlePopup (onlyOpen: boolean = false, item = undefined) {
    let elWidth: number;
    let elTop: number;
    let mainDiv = <HTMLElement>document.getElementById('mainContainer');
    // let childContainer = <Element>mainDiv.childNodes[1].childNodes[2].firstChild;
    let headerHeight = 65;

    this.parentHeightContainer = mainDiv.offsetHeight;
    this.maxCountPerHeight = Math.floor((this.parentHeightContainer - (this._el.nativeElement.getElementsByClassName('input-block')[0].getBoundingClientRect().bottom - headerHeight)) / 30);

    if (item) {
      this.selectedItem = item;
      this.wasSelected = true;
      this.itemSelected.emit(item);
    } else {
      if (this.dataList.indexOf(this.selectedItem) === -1) {
        this.wasSelected = false;
      }
    }

    elWidth = Math.round(this._el.nativeElement.getElementsByClassName('input-block')[0].getBoundingClientRect().width);
    elTop = this._el.nativeElement.parentNode.offsetHeight;
    this.renderer.setStyle(this._el.nativeElement.getElementsByClassName('popup-wrapper')[0], 'width', elWidth + 'px');
    this.renderer.setStyle(this._el.nativeElement.getElementsByClassName('popup-wrapper')[0], 'top', (elTop - 7) + 'px');

    this.rerenderHeight();

    this.isPopupOpen = (onlyOpen) ? true : !this.isPopupOpen;
  }

}
