import {Injectable} from '@angular/core';
import {OnPageVisibilityChange} from 'angular-page-visibility';
import {BehaviorSubject} from 'rxjs';

var VisibilityServiceInstance = null;

@Injectable()
export class VisibilityService {

  pageVisibility$ = new BehaviorSubject<boolean>(true);

  constructor() {
    VisibilityServiceInstance = this;
  }

  toggleVisibility(isPageVisible: boolean) {
    // console.log('toggleVisibility: ' + isPageVisible);
    this.pageVisibility$.next(isPageVisible);
  }

  /*@OnPageVisible()
  logWhenPageVisible(): void {
    console.log( 'OnPageVisible' );
    console.log( 'visible' );
  }

  @OnPageHidden()
  logWhenPageHidden(): void {
    console.log( 'OnPageHidden' );
    console.log( 'hidden' );
  }*/

  @OnPageVisibilityChange()
  logWhenPageVisibilityChange( isPageVisible: boolean ): void {
    // console.log( 'OnPageVisibilityChange' );
    if ( isPageVisible ) {
      // console.log( 'visible' );
    } else {
      // console.log( 'hidden' );
    }
    // this.canShowUI = isPageVisible;
    VisibilityServiceInstance.toggleVisibility(isPageVisible);
  }

}
