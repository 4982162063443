<div class="app-modal">
  <div class="modal-body">
    <p>Create New Playlist</p>
      <div class="playlists-container">
        <input focus type="text" aria-label="Insert the name of the new playlist" placeholder="Insert the name of the new playlist"
               [(ngModel)]="newPlaylistName" [disabled]="loadingHelperService.isLoading('CREATE_PLAYLIST_MODAL')">
      </div>

    <app-loading *ngIf="loadingHelperService.isLoading('CREATE_PLAYLIST_MODAL');else added"></app-loading>
    <ng-template #added>
      <app-error-message [error]="loadingHelperService.getError('CREATE_PLAYLIST_MODAL')"
                         *ngIf="loadingHelperService.hasError('CREATE_PLAYLIST_MODAL')"
                         [showTryAgain]="false"
                         (tryAgain)="createBtn()"></app-error-message>
    </ng-template>
  </div>
  <div class="modal-actions">
    <button (click)="createBtn()" aria-label="Create playlist" [disabled]="!isNameValid() || loadingHelperService.isLoading('CREATE_PLAYLIST_MODAL')">CREATE</button>
    <button type="button" name="button" (click)="onCancel()">CANCEL</button>
  </div>
</div>
<div class="modal-background">
</div>





<!--<div>
  <span></span>

  <button (click)="createBtn()" aria-label="Create playlist" [disabled]="!isNameValid() || loading">CREATE</button>
  <button (click)="onCancel()" aria-label="Cancel creating of playlist">CANCEL</button>
</div>
<div class="row error-message" *ngIf="error">
  There was a problem while creating the new playlist, please check your internet connection and try again
</div>-->
