import {Component, OnInit, Input} from '@angular/core';
import {AppConstants} from "../../app.constants";

@Component({
  selector: 'app-debug-json',
  templateUrl: './debug-json.component.html',
  styleUrls: ['./debug-json.component.scss']
})
export class DebugJsonComponent implements OnInit {

  @Input() description : any;
  @Input() data : any;

  public opened : boolean = false;

  public debugEnabled: boolean = false;

  constructor() { }

  ngOnInit() {
    this.debugEnabled = AppConstants.DebugEnabled;
  }

  openCloseClicked() {
    this.opened = !this.opened;
  }

}
