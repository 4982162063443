import {HttpParams} from '@angular/common/http';

export class RequestsUtils {

  static getJsonObjectFromFormParams(formParams: HttpParams): any {
    const formParamsObject = {};
    formParams.keys().forEach(x => formParamsObject[x] = formParams.get(x));
    // console.log(JSON.stringify(formParamsObject));
    return formParamsObject;
  }
}
