<div class="box" [ngClass]="{'selected': selected}">
  <div class="plan-name" [innerHTML]="item.name | colonNewLine"></div>
  <div class="plan-desc">
    {{ item.description }}
  </div>
  <div class="plan-price">
    <div class="footer-block">
      <div class="add-info">
        as low as:
      </div>
      <div class="price-box">
        <span class="value">{{item.asLowAs | customCurrency}}/</span>
        <span class="per">mo</span>
      </div>
    </div>
  </div>
</div>
