/**
 * HOS API
 *
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 *
 *
 *
 */

import {Payment} from './Payment';
import {Plan} from './Plan';
import {BillingInfo} from './BillingInfo';
import {NextPlanInfo} from './NextPlanInfo';
import {UserPlanInfo} from './UserPlanInfo';
import {SubscriptionInfo} from './SubscriptionInfo';


export interface UserPlan {
  /**
   * Plan
   */
  plan?: Plan;

  /**
   * Available prepaid minutes
   */
  availablePrepaidMinutes?: number;

  /**
   * Last payment
   */
  lastPayment?: Payment;

  billingInfo?: BillingInfo;

  userPlanStatus?: string;

  userPlanExpiryDate?: Date;

  nextPlanInfo?: NextPlanInfo;

  /**
   * Platform
   */
  userPlanPlatform?: UserPlan.UserPlanPlatformEnum;

  lastCustomerPlan?: UserPlanInfo;

  gracePeriod?: boolean;

  gracePeriodExpiryDate?: Date;

  currentSubscriptionInfo?: SubscriptionInfo;

  accountBalance?: number;
}

export namespace UserPlan {
  export type UserPlanPlatformEnum = 'recurly' | 'ios' | 'android';
  export const UserPlanPlatformEnum = {
    Recurly: 'recurly' as UserPlanPlatformEnum,
    Ios: 'ios' as UserPlanPlatformEnum,
    Android: 'android' as UserPlanPlatformEnum
  };
}
