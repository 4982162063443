/**
 * HOS API
 *
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 *
 *
 *
 */

import {ErrorTemplate} from './ErrorTemplate';
import {ConfigParam} from './ConfigParam';
import {Genre} from './Genre';
import {Label} from './Label';


export interface AppSettings {
  /**
   * Config params
   */
  configParams?: Array<ConfigParam>;

  /**
   * Client errors
   */
  clientErrors?: Array<ErrorTemplate>;

  /**
   * Genres
   */
  genres?: Array<Genre>;

  albumLabels?: Array<Label>;

  albumYears?: Array<number>;

  globalSettings?: Array<ConfigParam>;

  mostRecentProgramId?: number;

}
