<button class="{{ iconSize === playButtonIconSize.Medium ? 'medium-play' : (iconSize === playButtonIconSize.Big ? 'big-play' : 'small-play') }} {{ extraClasses }}"
        [class.loading]="(hosPlayerService.loadingQueueIdObs | async)==queueItem?.getQueueUniqueId() && (hosPlayerService.loadingTrackIdObs | async)==channelTrackPlayItem.getUniqueId() && (hosPlayerService.player.statusStrObs | async)=='loading'"
        [class.playing]="(hosPlayerService.currentQueueObs | async)?.getQueueUniqueId()==queueItem?.getQueueUniqueId() && (hosPlayerService.currentItemIdObs | async)==channelTrackPlayItem.getUniqueId() && (hosPlayerService.player.statusStrObs | async)=='playing'"
        [class.paused]="(hosPlayerService.currentQueueObs | async)?.getQueueUniqueId()==queueItem?.getQueueUniqueId() && (hosPlayerService.currentItemIdObs | async)==channelTrackPlayItem.getUniqueId() && (hosPlayerService.player.statusStrObs | async)=='paused'"
        [class.disabled]="!enabled"
        (click)="playChannel()"
        tooltip="{{((hosPlayerService.currentQueueObs | async)?.getQueueUniqueId()==queueItem?.getQueueUniqueId() && (hosPlayerService.currentItemIdObs | async)==channelTrackPlayItem.getUniqueId() && (hosPlayerService.player.statusStrObs | async)=='playing' ? 'Click to Pause' : 'Send to Player and Play')}}"
        attr.aria-label="{{((hosPlayerService.currentQueueObs | async)?.getQueueUniqueId()==queueItem?.getQueueUniqueId() && (hosPlayerService.currentItemIdObs | async)==channelTrackPlayItem.getUniqueId() && (hosPlayerService.player.statusStrObs | async)=='playing') ? 'Play button, playing the channel: ' + item?.name + ', click to pause' : 'Play button, paused, click to play the channel: ' + item?.name}}"></button>
<!--
<div>{{(hosPlayerService.currentQueueObs | async)?.getQueueUniqueId() + ' == ' + queueItem?.getQueueUniqueId() + ' / ' + (hosPlayerService.currentItemIdObs | async) + ' == ' + channelTrackPlayItem.getUniqueId() + ' / ' + (hosPlayerService.player.statusStrObs | async)}}</div>
<div>
<span>{{(hosPlayerService.currentQueueObs | async)?.getQueueUniqueId()==queueItem?.getQueueUniqueId() && (hosPlayerService.currentItemIdObs | async)==channelTrackPlayItem.getUniqueId() && (hosPlayerService.player.statusStrObs | async)=='playing'}}</span>
-<span>{{(hosPlayerService.currentQueueObs | async)?.getQueueUniqueId()==queueItem?.getQueueUniqueId()}}</span>
-<span>{{(hosPlayerService.currentItemIdObs | async)===channelTrackPlayItem.getUniqueId()}}</span>
-<span>{{(hosPlayerService.player.statusStrObs | async)=='playing'}}</span>
</div>
-->
