import {Component, ViewEncapsulation, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-carousel-pagination',
  templateUrl: './carouselPagination.component.html',
  styleUrls: ['./carouselPagination.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CarouselPaginationComponent {

  @Input() items: any[] = [];
  @Input() itemSize: number = 0;
  @Input() activePosition: number = 0;

  @Output() chosenItem = new EventEmitter<number>();

  constructor() {
  }

  /**
   * Trigger emitter with chosen item index for next usage
   * @param index
   */
  selected(index: number) {
    this.chosenItem.next(index);
  }

  /**
   * Calculate if dot is active
   * @param index
   */
  isDotActive(index: number): boolean {
    return this.activePosition * -1 / this.itemSize === index;
  }
}
