/**
 * HOS API
 *
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 *
 *
 *
 */

import { FavoriteItemWrapper } from './FavoriteItemWrapper';
import {Page} from './generic/page';


export interface FavoriteWrapper {
    /**
     * Programs
     */
    programs?: Page<FavoriteItemWrapper>;

    /**
     * Albums
     */
    albums?: Page<FavoriteItemWrapper>;

    /**
     * Tracks
     */
    tracks?: Page<FavoriteItemWrapper>;

}
