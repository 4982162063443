/**
 * HOS API
 *
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 *
 *
 *
 */



export interface Review {
    /**
     * Id
     */
    id: number;

    /**
     * Name
     */
    name: string;

    /**
     * Text
     */
    text?: string;

    /**
     * Date
     */
    date?: Date;

}
