import { Component, OnInit, Input, ElementRef, Renderer2 } from '@angular/core';


@Component({
  selector: '[smallPopupItem]',
  templateUrl: './small-popup-item.component.html',
  styleUrls: ['./small-popup-item.component.scss']
})
export class SmallPopupItemComponent implements OnInit {

  @Input('smallPopupItem') item;

  constructor() {
  }

  ngOnInit() {
  }
}
