<div class="player_container" role="group" aria-labelledby="play" tabindex="0">
  <audio id="hosplayer_preload" width="0" height="0"></audio>
  <audio id="hosplayer" width="0" height="0"></audio>
  <div class="player">
    <div class="player-cover">
      <img width="100" [src]="(hosPlayerService.currentItemObs | async).getImageUrl()" alt=""
           *ngIf="(hosPlayerService.currentItemObs | async)?.getUniqueId() == 'EMPTY'">
      <img width="100" [src]="(hosPlayerService.currentItemObs | async).getImageUrl()" alt=""
           *ngIf="(hosPlayerService.currentItemObs | async)?.getUniqueId() != 'EMPTY'">
    </div>
    <div class="player-content">
      <div class="row scrub">
        <div class="col-lg-12">
          <p class="time pull-left">{{ convertSecToTime(currentSongPoint) | playerExtraCutPipe}}</p>
          <div class="player-progress pull-left">
            <div [style.visibility]="(showTrackInfo && currentTrackNumber > 0) ? 'visible': 'hidden'" id="description-tracks"
                 class="description-tracks">
              <p>{{currentTrackNumber}} - {{currentTrack?.title}}</p>
            </div>
            <input id="songTrackBar"
                   class="highlight"
                   type="range"
                   (mouseover)="showTrackInfo = true;"
                   (mousemove)="currentEventMove($event)"
                   (change)="currentTimeMoved($event)"
                   (input)="currentTimeMovedInput($event)"
                   (mouseup)="moveTimeFinished();"
                   (mouseout)="showTrackInfo = false;"
                   [max]="songLength"
                   [(ngModel)]="currentSongPoint"
                   aria-label="Seek bar">
            <!-- <input type="range" (input)="moveCurrentTime($event)" (mouseup)="moveTimeFinished()" [max]="songLength" [(ngModel)]="currentSongPoint"> -->
            <!--<div class="current-time-slide" id="currentTimePopup" [hidden]="!progressBarIsDragging">-->
            <!--{{ convertSecToTime(currentSongPoint) }} / {{ convertSecToTime(songLength) }}-->
            <!--</div>-->
            <div id="currentProgressLine"></div>
            <div id="currentTimeThumb"></div>
          </div>

          <p class="time pull-left">{{ convertSecToTime(songLength - currentSongPoint) | playerExtraCutPipe}}</p>
        </div>
      </div>
      <div class="row player-content-container">
        <div class="col-lg-3" *ngIf="(hosPlayerService.currentItemObs | async) as currentItem">
          <div class="player-content-metadata">
            <p class="line0" (click)="metadataLine1Clicked(currentItem)"
               [ngClass]="{'link-like': getCurrentItemQueueUrl()}" [innerHtml]="getCurrentItemMetadataType()"
               *ngIf="currentItem"></p>
            <p class="line1" (click)="metadataLine2Clicked(currentItem)"
               [ngClass]="{'link-like': currentItem.getItemUrl()}" [innerHtml]="currentItem.getLine1()"
               *ngIf="currentItem.getLine1()"></p>
            <p class="line2" [innerHtml]="currentItem.getLine2()" *ngIf="currentItem.getLine2()"></p>
            <p class="line3" [innerHtml]="currentItem.getLine3()" *ngIf="currentItem.getLine3()"></p>
          </div>
        </div>
        <div class="col-lg-5 control-panel">
          <div class="button-actions">
            <!--
            <button type="button" name="button" id="testReset" aria-label="Test Reset" (click)="testReset()"
                    tooltip="Test reset">TEST RESET</button>
            -->

            <button type="button" name="button" id="shuffle"
                    attr.aria-label="Shuffle {{(hosPlayerService.currentQueueObs | async).isShuffleEnabled() ? 'ON':'OFF'}}"
                    [ngClass]="{'inactive': (hosPlayerService.currentQueueObs | async).isShuffleEnabled() == false, 'disabled': (hosPlayerService.currentQueueObs | async).canShuffle() === false}"
                    (click)="doShuffle()" [disabled]="!(hosPlayerService.currentQueueObs | async).canShuffle()"
                    tooltip="{{(hosPlayerService.currentQueueObs | async).canShuffle() ? 'Toggles Shuffle' : getControlNotAvailableMessage('Shuffle')}}"></button>
            <button type="button" name="button" id="prevTrack" aria-label="Previous Program or Album" (click)="doPreviousTrack()"
                    [disabled]="(hosPlayerService.currentQueueObs | async).canPrevious() == false"
                    tooltip="Previous Program or Album"></button>

            <button type="button" name="button" id="rewind" aria-label="Rewind or Previous Track" (click)="doRewind()"
                    [disabled]="(hosPlayerService.currentQueueObs | async).getCurrentItem() == null || currentSongPoint === 0"
                    tooltip="Rewind or Previous Track"></button>
            <!-- [disabled]="(hosPlayerService.currentQueueObs | async).getCurrentItem() == null || (hosPlayerService.currentItemObs | async).getPlayItemInfo()?.prevTrackTime == null" -->
            <button type="button" name="button" class="big-play-button active" (click)="play()"
                    [disabled]="(hosPlayerService.currentQueueObs | async).getCurrentItem() == null"
                    attr.aria-label="Click to pause"
                    tooltip="Click to Pause" *ngIf="isPlaying"></button>
            <button type="button" name="button" class="big-play-button" (click)="play()"
                    [disabled]="(hosPlayerService.currentQueueObs | async).getCurrentItem() == null"
                    attr.aria-label="Play item in player"
                    tooltip="Play item in player"
                    *ngIf="!isPlaying"></button>
            <button type="button" name="button" id="forward" aria-label="Forward or next Track" (click)="doForward()"
                    [disabled]="(hosPlayerService.currentQueueObs | async).getCurrentItem() == null || (hosPlayerService.currentItemObs | async).getPlayItemInfo()?.nextTrackTime == null"
                    tooltip="Forward or next Track"></button>
            <button type="button" name="button" id="nextTrack" aria-label="Next Program or Album" (click)="doNextTrack()"
                    [disabled]="(hosPlayerService.currentQueueObs | async).canNext() == false"
                    tooltip="Next Program or Album"></button>
            <button type="button" name="button" id="repeat"
                    attr.aria-label="Repeat {{(hosPlayerService.currentQueueObs | async).isRepeatEnabled() ? 'ON':'OFF'}}"
                    [ngClass]="{'inactive': (hosPlayerService.currentQueueObs | async).isRepeatEnabled() == false, 'disabled': (hosPlayerService.currentQueueObs | async).canRepeat() === false}"
                    [disabled]="!(hosPlayerService.currentQueueObs | async).canRepeat()"
                    (click)="doRepeat()"
                    tooltip="{{(hosPlayerService.currentQueueObs | async).canRepeat() ? 'Toggles Repeat' : getControlNotAvailableMessage('Repeat')}}"></button>
            <div class="oval-in"></div>
            <div class="oval-out"></div>
          </div>
        </div>
        <div class="col-lg-4 sound">
          <div class="pull-left sound-volume">
            <button type="button" name="volumeMute" id="volumeMute" (click)="mute()"
                    [class.inactive]="(hosPlayerService.player.mutedObs | async)"
                    attr.aria-label="{{(hosPlayerService.player.mutedObs | async) ? 'Muted button, click to unmute' : 'Mute button, click to mute'}}"
                    tooltip="Toggles Mute"></button>
            <div class="pull-left voice-level" [class.inactive]="(hosPlayerService.player.mutedObs | async)">
              <input id="volumeTrackBar" class="highlight small" type="range" [(ngModel)]="volume" max="100"
                     (input)="volumeMoved($event)" aria-label="Volume bar">
            </div>
          </div>
          <div class="sound-actions">
            <button id="sleepTimer" attr.aria-label="Sleep timer"
                    [disabled]="(hosPlayerService.currentItemObs | async).getUniqueId() === 'EMPTY'"
                    [ngClass]="{'enabled': (sleepTimerService.sleepTimerEnabled$ | async)}"
                    (click)="sleepTimerOpen(!isSleepPanelOpen)"
                    tooltip="Opens the Sleep Timer settings panel">SLEEP
              TIMER
            </button>
            <button id="voiceOver"
                    attr.aria-label="Voice over {{hosPlayerService.getVoiceOverLabelFromStatus(hosPlayerService.voiceoverObs | async)}}"
                    (click)="voiceoverClicked()"
                    tooltip="Toggles Voiceover (when applicable)">
              VOICEOVER: {{hosPlayerService.getVoiceOverLabelFromStatus(hosPlayerService.voiceoverObs | async)}}</button>
          </div>
        </div>
        <div *ngIf="isSleepPanelOpen">
          <app-sleep-panel (sleepTimerCancel)="canSleepTimer()" (sleepTimerSet)="setSleepTimer($event)"
                           (sleepTimerPanelClose)="sleepTimerOpen(false)"></app-sleep-panel>
        </div>
      </div>
    </div>
  </div>
</div>
