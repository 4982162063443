import {Injectable, OnDestroy} from '@angular/core';
import {SubSink} from 'subsink';
import {BehaviorSubject} from 'rxjs';
import {SettingsService} from '../api-client';
import {AppConstants, hoslog} from '../app.constants';

@Injectable({
  providedIn: 'root'
})
export class ServerMessageService implements OnDestroy {
  public systemMaintenanceVisible$ = new BehaviorSubject<boolean>(false);
  public serverMessage$ = new BehaviorSubject<string>(null);

  private subs = new SubSink();

  constructor(private settingsService: SettingsService) {
    this.settingsService.getServerMessage(AppConstants.DeviceType).subscribe(
      res => {
        hoslog("ServerMessage Settings loaded");
        this.serverMessage$.next(res.text);
        this.systemMaintenanceVisible$.next(res.enabled);
      },
      error => {
        hoslog("ServerMessage Settings: error: " + JSON.stringify(error));
      });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  toggleSystemMaintenance() {
    this.systemMaintenanceVisible$.next(!this.systemMaintenanceVisible$.value);
  }
}
