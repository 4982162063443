import { Directive, ElementRef, Renderer2, Input } from '@angular/core';

@Directive({
  selector: '[appMoreText]'
})
export class MoreTextDirective {

  @Input() maxLines: number;
  private defaultMaxLines: number = 3;
  private textExpanded: boolean = false;
  private button;
  private lineHeight;
  private initialHeight;

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.button = document.createElement("a");
    this.button.innerText = "more";
    this.button.className = "more-button";
    // this.button.onclick = this.handleExpandText;

    renderer.listen(this.button, 'click', (event) => {
      this.handleExpandText();
    });

    this.maxLines = this.maxLines || this.defaultMaxLines;
  }

  ngAfterContentChecked() {
    let elHeight = this.el.nativeElement.offsetHeight;
    let lineHeight = parseInt(document.defaultView.getComputedStyle(this.el.nativeElement, null).getPropertyValue("line-height"));
    let lines = elHeight / lineHeight;
    // create more button and overflow text
    if (lines > this.maxLines && !this.textExpanded) {
      this.renderer.setStyle(this.el.nativeElement, 'position', 'relative');
      this.renderer.setStyle(this.el.nativeElement, 'overflow-y', 'hidden');
      this.renderer.setStyle(this.el.nativeElement, 'height', this.maxLines * lineHeight + 'px');
      this.renderer.setStyle(this.el.nativeElement, 'padding-right', '40px');

      this.el.nativeElement.appendChild(this.button);

      this.initialHeight = elHeight;
      this.lineHeight = lineHeight;
    }
  }

  handleExpandText() {
    this.textExpanded = !this.textExpanded;
    if (this.textExpanded) {
      this.renderer.setStyle(this.el.nativeElement, 'overflow-y', 'initial');
      this.renderer.setStyle(this.el.nativeElement, 'height', this.initialHeight + this.lineHeight + 'px');
      // this.el.nativeElement.removeChild(this.el.nativeElement.getElementsByClassName('more-button')[0]);
      // this.renderer.setText(this.el.nativeElement.getElementsByClassName('more-button')[0], 'less');
      this.el.nativeElement.getElementsByClassName('more-button')[0].innerHTML = 'less';
    } else {
      this.renderer.setStyle(this.el.nativeElement, 'overflow-y', 'hidden');
      this.renderer.setStyle(this.el.nativeElement, 'height', this.maxLines * this.lineHeight + 'px');
      // this.renderer.setStyle(this.el.nativeElement.getElementsByClassName('more-button')[0], 'display', 'block');
      // this.renderer.setText(this.el.nativeElement.getElementsByClassName('more-button')[0], 'more');
      this.el.nativeElement.getElementsByClassName('more-button')[0].innerHTML = 'more';
    }
  }

}
