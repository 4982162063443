import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {HosError} from "../../api-client/model/HosError";
import {HosLocalError} from "../../api-client/helper/api-helper.service";
import {AppConstants} from '../../app.constants';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss']
})
export class ErrorMessageComponent implements OnInit {

  @Input() overlay = false;

  @Input() error: HosError;
  @Input() showTryAgain: boolean = false;
  @Input() tryAgainText: string = "Click here to try again";
  @Output() tryAgain = new EventEmitter();

  public errorHeader: string;
  public errorMessage: string;

  constructor() {
  }

  ngOnInit() {
    let errorFound = null;
    if (this.error['error']) {
      errorFound = this.error['error'];
    } else {
      errorFound = this.error;
    }
    if (!errorFound) {
      this.error = new HosLocalError();
      this.errorHeader = null;
      this.errorMessage = errorFound.errorDescription;
    } else {
      this.errorHeader = "Error";
      if (!errorFound.errorDescription && !errorFound.errorName) {
        this.errorMessage = AppConstants.clientErrors['GENERIC_ERROR'].errorDescription;
      } else if (errorFound.errorDescription) {
        this.errorMessage = errorFound.errorDescription + (errorFound.errorCode ? " (" + errorFound.errorCode + ")" : "");
      } else if (errorFound.errorName) {
        this.errorMessage = errorFound.errorName + (errorFound.errorCode ? " (" + errorFound.errorCode + ")" : "");
      }
    }
  }

  tryAgainClicked() {
    this.tryAgain.emit();
  }

}
