/**
 * HOS API
 *
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 */
import {Injectable, Injector} from "@angular/core";
import {HosBaseService} from "./hos-base.service";

import {HttpResponse, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import { map } from 'rxjs/operators';
import "../rxjs-operators";
import {AppConstants} from "../../app.constants";
import {SearchWrapper} from "../model/SearchWrapper";

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class SearchService extends HosBaseService {


  constructor(injector: Injector) {
     super(injector);
   }

  /**
   * Return the search items
   *
   * @param searchQuery The search query
   */
  public search(searchQuery: string, extraHttpRequestParams?: any): Observable<SearchWrapper> {
    return this.searchWithHttpInfo(searchQuery, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }


  /**
   * Return the search items
   *
   * @param searchQuery The search query
   */
  public searchWithHttpInfo(searchQuery: string, extraHttpRequestParams?: any): Observable<HttpResponse<Object>> {
    const path = this.getBasePath() + `/search`;

    let queryParameters = new HttpParams();
    let headers = this.getNoLoginHeaders();

    // verify required parameter 'searchQuery' is not null or undefined
    if (searchQuery === null || searchQuery === undefined) {
      throw new Error('Required parameter searchQuery was null or undefined when calling search.');
    }
    if (searchQuery !== undefined) {
      queryParameters = queryParameters.set('searchQuery', <any>searchQuery);
    }


    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];


    let requestOptions = {
      headers: headers,
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.GET, path, requestOptions);
  }

  /**
   * Return the search items
   *
   * @param searchQuery The search query
   */
  public searchFullText(searchQuery: string, extraHttpRequestParams?: any): Observable<SearchWrapper> {
    return this.searchFullTextWithHttpInfo(searchQuery, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }


  /**
   * Return the search items
   *
   * @param searchQuery The search query
   */
  public searchFullTextWithHttpInfo(searchQuery: string, extraHttpRequestParams?: any): Observable<HttpResponse<Object>> {
    const path = this.getBasePath() + `/search/fulltext`;

    let queryParameters = new HttpParams();
    let headers = this.getNoLoginHeaders();

    // verify required parameter 'searchQuery' is not null or undefined
    if (searchQuery === null || searchQuery === undefined) {
      throw new Error('Required parameter searchQuery was null or undefined when calling search.');
    }
    if (searchQuery !== undefined) {
      queryParameters = queryParameters.set('searchQuery', <any>searchQuery);
    }


    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];


    let requestOptions = {
      headers: headers,
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.GET, path, requestOptions);
  }

}
