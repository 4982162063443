/**
 * HOS API
 *
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 *
 *
 *
 */

import {Program} from './Program';
import {CustomSelectElement} from "../../shared/custom-select/customSelect.component";


export interface Channel extends CustomSelectElement {
  /**
   * Channel id
   */
  id: number;

  /**
   * Name
   */
  name: string;

  /**
   * Description
   */
  description?: string;

  /**
   * Programs
   */
  programs?: Array<Program>;

  /**
   * Banner URL
   */
  bannerUrl?: string;

  /**
   * Hemisphere
   */
  hemisphere: Channel.HemisphereEnum;

}
export namespace Channel {
  export enum HemisphereEnum {
    Northern = <any> 'northern',
    Southern = <any> 'southern'
  }
}
