import {Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter} from '@angular/core';
import {SleepTimerService} from '../../services/sleep-timer.service';

export interface SleepData {
  playToStreamEnd: boolean;
  sleepTimer: number;
}
export interface SleepDuration {
  hr: number;
  min: number;
}
export interface SleepOptions {
  stepHr: number;
  stepMin: number;
  hrMax: number;
  minMax: number;
}

@Component({
  selector: 'app-sleep-panel',
  templateUrl: './sleepPanel.component.html',
  styleUrls: ['./sleepPanel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SleepPanelComponent implements OnInit {

  @Input() sleepData: SleepData = {
    playToStreamEnd: false,
    sleepTimer: 0
  };

  @Output() sleepTimerSet = new EventEmitter<SleepData>();
  @Output() sleepTimerCancel = new EventEmitter<any>();
  @Output() sleepTimerPanelClose = new EventEmitter<any>();

  public minDurationInSec: number = 60;
  public hrDurationInSec: number = 60 * this.minDurationInSec;
  // Maximum sleep time duration is 12 hours converted to seconds.
  public sleepMaxDuration: number = 12 * this.hrDurationInSec;
  public sleepValue: SleepDuration = {
    hr: 0,
    min: 0
  };
  public sleepOptions: SleepOptions = {
    stepHr: this.hrDurationInSec,
    stepMin: this.minDurationInSec * 15,
    hrMax: Math.floor(this.sleepMaxDuration / this.hrDurationInSec),
    minMax: Math.floor((this.sleepMaxDuration - (Math.floor(this.sleepMaxDuration / this.hrDurationInSec)) * this.hrDurationInSec) / this.minDurationInSec)
  };

  constructor(public sleepTimerService: SleepTimerService) {
  }

  ngOnInit() {
    // Update sleep timer view values if sleepTimer was provided on component init
    if (this.sleepData.sleepTimer) {
      this.updateSleepViewValues();
    }
  }

  /**
   * Calculate input display values as they are only for user display purpose
   */
  updateSleepViewValues() {
    this.sleepValue.hr = Math.floor(this.sleepData.sleepTimer / this.hrDurationInSec);
    this.sleepValue.min = Math.floor((this.sleepData.sleepTimer - (this.sleepValue.hr * this.hrDurationInSec)) / this.minDurationInSec);
  }

  togglePlayToEndStream() {
    this.sleepData.playToStreamEnd = !this.sleepData.playToStreamEnd;
  }

  /**
   * Emit close action
   */
  public closePanel () {
    this.sleepTimerPanelClose.next(true);
  }

  /**
   * Emit cancel action
   */
  public cancelTimer () {
    this.sleepTimerCancel.next(true);
  }

  /**
   * Emit external action on duration set
   * @param data
   */
  public setTimer (data: SleepData) {
    this.sleepTimerSet.next(data);
  }

  /**
   * Change sleep timer duration according to user action and available limits
   * @param seconds
   */
  public changeDuration (seconds?: number) {
    if (this.sleepData.sleepTimer + seconds >= 0 && this.sleepData.sleepTimer + seconds < this.sleepMaxDuration) {
      // On each iteration ensure the value can be divided on min step without residue
      this.sleepData.sleepTimer = this.sleepData.sleepTimer - (this.sleepData.sleepTimer % this.sleepOptions.stepMin);
      this.sleepData.sleepTimer += seconds;
    } else if (this.sleepData.sleepTimer + seconds < 0) {
      // Fallback to zero value
      this.sleepData.sleepTimer = 0;
    } else if (this.sleepData.sleepTimer + seconds >= this.sleepMaxDuration) {
      // Handle max value of duration
      this.sleepData.sleepTimer = this.sleepMaxDuration;
    }
    this.updateSleepViewValues();
  }
}
