import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlayerService {
  private playEventSource = new Subject<void>();
  private promoPlayEventSource = new Subject<void>();

  playEvent$ = this.playEventSource.asObservable();
  promoPlayEvent$ = this.promoPlayEventSource.asObservable();

  notifyPlayEvent() {
    this.playEventSource.next();
  }

  notifyPromoPlayEvent() {
    this.promoPlayEventSource.next();
  }
}
