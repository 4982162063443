/**
 * HOS API
 *
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 *
 *
 *
 */



export interface PlanCost {

    planId?: number;

    /**
     * Duration
     */
    duration: number;

    durationUnit?: PlanCost.DurationUnitEnum;

    /**
     * Billing type
     */
    billingType: PlanCost.BillingTypeEnum;

    /**
     * Cost
     */
    cost: number;

    /* Custom internal fields */
    monthlyPrice?: number;
    chargedDescription?: string;
}
export namespace PlanCost {
    export enum DurationUnitEnum {
        Hours = <any> 'hour',
        Months = <any> 'month',
        Year = <any> 'year'
    }
    export enum BillingTypeEnum {
        Recurring = <any> 'recurring',
        OneTime = <any> 'one_time'

    }
}
