import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";
import {MetaUtilsService} from './meta-utils.service';
import {hoslog} from '../app.constants';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  // Observable navItem source
  title$ = new BehaviorSubject<string>(null);
  public firstPageLoaded = true; // it's true only for the first time a page is shown after a refresh

  peekPopup$ = new BehaviorSubject<boolean>(false);

  // another way to call event emit from child component
  // but it is required to add add. code to all components
  private childLoaded = new BehaviorSubject<boolean>(false);
  // Observable navItem stream
  childLoaded$ = this.childLoaded.asObservable();

  pageScroll$ = new BehaviorSubject<boolean>(true);

  public loginFormVisible$ = new BehaviorSubject<boolean>(false);

  public requestSearch$ = new Subject<string>();

  constructor(private metaUtilsService: MetaUtilsService) {
  }

  // service command
  setTitlePageAndMetadata(title) {
    this.setTitlePage(title);
    this.metaUtilsService.updatePageInfo(title);
  }

  setTitlePage(title) {
    if (this.title$.value != null) {
      this.firstPageLoaded = false;
    }
    this.title$.next(title);
  }

  setOpenPeekPopup(value) {
    hoslog(`setOpenPeekPopup: ${value}`);
    this.peekPopup$.next(value);
  }

  setChildCompLoaded(value) {
    this.childLoaded.next(value);
  }

  getOpenPeekPopup$() {
    return this.peekPopup$;
  }

  checkChildLoaded() {
    let that = this;
    setTimeout(
      function() {
        if (document.getElementsByClassName('inner-component-container')[0]) {
          that.childLoaded.next(true);
        } else {
          that.checkChildLoaded()
        }
      },
    10);
  }

  setPageScroll(value: boolean) {
    this.pageScroll$.next(value);
  }

  setLoginFormVisible(value: boolean) {
    this.loginFormVisible$.next(value);
  }

  showLoginForm() {
    this.setLoginFormVisible(true);
  }

  hideLoginForm() {
    this.setLoginFormVisible(false);
  }

  requestSearch(query: string) {
    this.requestSearch$.next(query);
  }
}
