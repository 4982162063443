import {Injectable, OnDestroy} from '@angular/core';
import {CoolLocalStorage} from '@angular-cool/storage';
import {BehaviorSubject, Subscription} from 'rxjs';
import {hoslog} from '../app.constants';
import * as dayjs from 'dayjs';
import {LoginHelperService} from '../api-client/helper/login-helper.service';
import {SubSink} from 'subsink';

export enum SleepTimerType {
  Timed, EndOfStream
}

@Injectable()
export class SleepTimerService implements OnDestroy {

  sleepTimerDate: number | dayjs.Dayjs;

  sleepTimerEnabled$ = new BehaviorSubject<boolean>(false);
  // private trackFinishedSub: Subscription = null;
  // private pingSub: Subscription = null;

  private subs = new SubSink();

  constructor(private localStorage: CoolLocalStorage,
              private loginHelperService: LoginHelperService/*,
              private hosPlayerService: HosPlayerService*/) {
    this.refreshCookies();

    // Sign Out event
    this.subs.sink = loginHelperService.loggedOut.subscribe(value => {
      this.cancelSleepTimer();
    });

   /* this.sleepTimerEnabled$.subscribe(enabled => {
      if (enabled) {
        this.setObservers();
      } else {
        this.cancelObservers();
      }
    });*/
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  enableSleepTimer(type: SleepTimerType, sleepTimer?: number) {
    if (type == SleepTimerType.EndOfStream) {
      this.sleepTimerDate = -1;
    } else {
      let timerDate = dayjs();
      timerDate = timerDate.add(sleepTimer, 'second');
      this.sleepTimerDate = timerDate;
    }
    this.saveCookies();
    this.sleepTimerEnabled$.next(true);
  }

  cancelSleepTimer() {
    this.sleepTimerDate = null;
    this.saveCookies();
    this.sleepTimerEnabled$.next(false);
    this.localStorage.removeItem('st');
  }

  getFormattedDate(): string {
    return dayjs(this.sleepTimerDate as dayjs.Dayjs).format("h:mm a");
  }

  private refreshCookies() {
    // Loading from the local storage
    const sleepTimerDateStr = this.localStorage.tryGetObject('st');
    if (sleepTimerDateStr) {
      if (sleepTimerDateStr === -1) {
        this.sleepTimerDate = -1;
      } else {
        this.sleepTimerDate = dayjs(sleepTimerDateStr.toString());
      }
      this.sleepTimerEnabled$.next(true);
      hoslog('Sleep timer enabled: ' + this.sleepTimerDate);
    } else {
      hoslog('Sleep timer disabled');
    }
  }

  private saveCookies() {
    // Loading from the local storage
    this.localStorage.setObject('st', this.sleepTimerDate);
  }

  trackFinishedSleepTimerCheck(): boolean {
    // if is enabled && == -1 then stop the player
    if (this.sleepTimerEnabled$.value && this.sleepTimerDate === -1) {
      this.cancelSleepTimer();
      return true;
    } else {
      return false;
    }
  }

  pingSleepTimerCheck(): boolean {
    // if is enabled && != -1 check the end date and stop the player if we went over it
    const now = dayjs();
    if (this.sleepTimerEnabled$.value && this.sleepTimerDate !== -1 && dayjs(this.sleepTimerDate).isBefore(now)) {
      this.cancelSleepTimer();
      return true;
    } else {
      return false;
    }
  }

  /*private setObservers() {
    this.trackFinishedSub = this.hosPlayerService.player.trackFinished$.subscribe(
      trackFinished => {
        // if is enabled && == -1 then stop the player
        if (this.sleepTimerEnabled$.value && this.sleepTimerDate === -1) {
          this.cancelSleepTimer();
          setTimeout(() => {
            this.hosPlayerService.player.pause();
          }, 100);
        }
      }
    );

    this.pingSub = this.hosPlayerService.ping$.subscribe(
      ping => {
        // if is enabled && != -1 check the end date and stop the player if we went over it
        const now = dayjs();
        if (this.sleepTimerEnabled$.value && this.sleepTimerDate !== -1 && (this.sleepTimerDate as moment.Moment).isBefore(now)) {
          this.cancelSleepTimer();
          this.hosPlayerService.player.pause();
        }
      }
    );
  }

  private cancelObservers() {
    if (this.trackFinishedSub) {
      this.trackFinishedSub.unsubscribe();
    }
  }*/
}
