import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {Playlist} from '../api-client';
import {AlertsUtilService} from './alerts-util.service';
import {Router} from '@angular/router';
import {ModalService} from '../shared/modal.service';
import {LoginHelperService} from '../api-client/helper/login-helper.service';

@Injectable()
export class PlaylistsUtilsService {

  lastPlaylistEdited$ = new Subject<Playlist>();

  constructor() {
  }

  setPlaylistEdited(playlist: Playlist) {
    this.lastPlaylistEdited$.next(playlist);
  }

}
