import { Injectable } from '@angular/core';
import {SubscriptionOrderWrapper} from '../api-client/model/subscriptionOrderWrapper';

@Injectable()
export class PaymentConfirmedService {
  private _lastPaymentOrderInfo: SubscriptionOrderWrapper;
  private _changePlanImmediately: boolean;

  constructor() { }

  get lastPaymentOrderInfo(): SubscriptionOrderWrapper {
    return this._lastPaymentOrderInfo;
  }


  get changePlanImmediately(): boolean {
    return this._changePlanImmediately;
  }

  setLastPaymentOrderInfo(orderInfo: SubscriptionOrderWrapper, changePlanImmediately: boolean) {
    this._changePlanImmediately = changePlanImmediately;
    this._lastPaymentOrderInfo = orderInfo;
  }

  clearLastPaymentOrderInfo() {
    this._lastPaymentOrderInfo = null;
    this._changePlanImmediately = null;
  }
}
