import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {PlaylistsService} from "../../api-client/api/playlists.service";
import {hoslog} from "../../app.constants";
import {Playlist} from '../../api-client';
import {LoadingHelperService} from '../loading-helper/loading-helper.service';
import {MixpanelService} from '../../services/mixpanel.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-create-playlist',
  templateUrl: './create-playlist.component.html',
  styleUrls: ['./create-playlist.component.scss']
})
export class CreatePlaylistComponent implements OnInit {

  public newPlaylistName: string = "";

  ok: Function;
  cancel: Function;
  destroy: Function;
  closeModal: Function;

  constructor(private playlistsService: PlaylistsService,
              private mixpanelService: MixpanelService,
              private toastr: ToastrService,
              public loadingHelperService: LoadingHelperService) {
  }

  ngOnInit() {
  }

  onCancel(): void {
    if (this.closeModal) this.closeModal();
    this.destroy();
    if (this.cancel) this.cancel();
  }

  onOk(playlist: Playlist): void {
    if (this.closeModal) this.closeModal();
    this.destroy();
    if (this.ok) this.ok(playlist);
  }

  createBtn() {
    this.mixpanelService.incrementUserProperty('Playlists');
    this.mixpanelService.track("Playlist", {action: 'create'});
    this.loadingHelperService.startLoading('CREATE_PLAYLIST_MODAL');
    this.playlistsService.createPlaylist(this.newPlaylistName)
      .subscribe(
        res => {
          hoslog("createPlaylist ok: " + JSON.stringify(res));
          this.onOk(res);
          this.loadingHelperService.loadingOK('CREATE_PLAYLIST_MODAL');
          this.toastr.success('Playlist created');
        },
        error => {
          hoslog("createPlaylist error: " + error.status);
          this.loadingHelperService.loadingKO(error, 'CREATE_PLAYLIST_MODAL');
          this.toastr.error('An error happened while creating the playlist');
        });
  }

  isNameValid(): boolean {
    return this.newPlaylistName && this.newPlaylistName.trim().length > 0;
  }

}
