import {Injectable} from '@angular/core';
import {LoginHelperService} from '../api-client/helper/login-helper.service';
import {BehaviorSubject} from 'rxjs';
import {LoggedUserPlanService} from './logged-user-plan.service';
import {PlanCost, UserPlan} from '../api-client';
import DurationUnitEnum = PlanCost.DurationUnitEnum;

@Injectable()
export class PlanHelperService {

  private static LABEL_VIEW_PLANS = 'VIEW PLANS';
  private static LABEL_SUBSCRIBE = 'SUBSCRIBE';
  private static LABEL_UPGRADE = 'UPGRADE';
  private static LABEL_CHANGE_PLAN = 'CHANGE PLAN';

  private static LINK_VIEW_PLANS = ['/subscription'];
  private static LINK_SUBSCRIBE = ['/subscription'];
  private static LINK_UPGRADE = ['/subscription'];
  private static LINK_CHANGE_PLAN = ['/subscription'];

  subscribeUpgradeButtonText$ = new BehaviorSubject<string>(null);
  subscribeUpgradeButtonUrl$ = new BehaviorSubject<string[]>(null);

  constructor(private loginHelperService: LoginHelperService,
              private loggedUserPlanService: LoggedUserPlanService) {
    loginHelperService.isLoggedIn$.subscribe(
      isLoggedIn => {
        const userPlan = this.loggedUserPlanService.plan$.value;
        this.runLogic(userPlan);
      }
    );

    loggedUserPlanService.plan$.subscribe(
      userPlan => {
        this.runLogic(userPlan);
    });
  }


  private runLogic(userPlan: UserPlan) {
    const isLoggedIn = this.loginHelperService.isLoggedIn$.value;
    if (!isLoggedIn) {
      this.sendViewPlans();
    } else {
      // const userPlan = this.loggedUserPlanService.plan$.value;
      if (userPlan && userPlan.plan) {
        if (userPlan.plan.type === 'xlifetime' ||
          (userPlan.plan.type === 'full' && userPlan.plan.prices && userPlan.plan.prices.length > 0 &&
            userPlan.plan.prices[0].duration === 1 && userPlan.plan.prices[0].durationUnit === DurationUnitEnum.Year)
        ) {
          this.sendChangePlan();
        } else {
          this.sendUpgrade();
        }
      } else {
        // no plan but registered
        this.sendSubscribe();
      }
    }
  }

  private sendViewPlans() {
    this.send(PlanHelperService.LABEL_VIEW_PLANS, PlanHelperService.LINK_VIEW_PLANS);
  }

  private sendSubscribe() {
    this.send(PlanHelperService.LABEL_SUBSCRIBE, PlanHelperService.LINK_SUBSCRIBE);
  }

  private sendUpgrade() {
    this.send(PlanHelperService.LABEL_UPGRADE, PlanHelperService.LINK_UPGRADE);
  }

  private sendChangePlan() {
    this.send(PlanHelperService.LABEL_CHANGE_PLAN, PlanHelperService.LINK_CHANGE_PLAN);
  }

  private send(label: string, link: string[]) {
    this.subscribeUpgradeButtonText$.next(label);
    this.subscribeUpgradeButtonUrl$.next(link);
  }
}
