<div class="app-modal">
  <div class="modal-body">
    <p>Please select the genre to add:</p>
      <div class="playlists-container">
        <a class="playlist" (click)="selectGenre(genre)" *ngFor="let genre of ((appSettingsService.appSettings | async).genres | filterGenres:'genres');">{{genre.name}}</a>
      </div>
  </div>
  <div class="modal-actions">
    <button type="button" name="button" (click)="onCancel()">CANCEL</button>
  </div>
</div>
<div class="modal-background">
</div>
